import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import DecreaseIcon from '@mui/icons-material/RemoveCircleOutline';
import IncreaseIcon from '@mui/icons-material/AddCircleOutline';
import { Theme } from '@mui/material/styles';
import { WithStyles, StyleRules } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { isUndefined } from 'util';

const styles = (theme: Theme) => {
    return {
        root: {
            marginLeft: -8
        },
        value: {
            width: 100,
            textAlign: 'center',
            display: 'inline-block'
        },
        btn: {
            height: 36,
            width: 36
        }
    } as StyleRules;
};

export interface NumberStepperWidgetProps {
    value: number;
    values?: Array<number>;
    step?: number;
    max?: number;
    min?: number;
    format: (val: number) => string;
    onChanged: (val: number) => void;
}

const NumberStepperWidget = class extends React.Component<NumberStepperWidgetProps & WithStyles<typeof styles>> {

    private handleChange = (sign: -1 | 1) => {
        const { value, values, step, max, min } = this.props;
        if (values) {
            const idx = values.indexOf(value);
            const newIdx = Math.max(Math.min((idx + sign), values.length - 1), 0);
            this.props.onChanged(values[newIdx]);
        } else if (step) {
            let val = value + sign * step;
            if (!isUndefined(max)) {
                val = Math.min(val, max);
            }
            if (!isUndefined(min)) {
                val = Math.max(val, min);
            }
            this.props.onChanged(val);
        }
    }

    render() {
        const { classes, format, value } = this.props;
        const val = format(value);
        return (
            <div className={classes.root}>
                <IconButton
                    className={classes.btn}
                    onClick={() => this.handleChange(-1)}
                    size="large"><DecreaseIcon /></IconButton>
                <span className={classes.value}>{val}</span>
                <IconButton className={classes.btn} onClick={() => this.handleChange(1)} size="large"><IncreaseIcon /></IconButton>
            </div>
        );
    }
};

export default withStyles(styles)(NumberStepperWidget);
