import * as React from 'react';
import { AppBar, Toolbar } from '@mui/material';
import { CookiesProvider } from "react-cookie";
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { BrowserRouter, Redirect, Switch, Route, withRouter, RouteComponentProps } from 'react-router-dom';
import RefreshIcon from '@mui/icons-material/Refresh';
import { DndProvider } from 'react-dnd';
import TouchBackend from 'react-dnd-touch-backend';
import HTML5Backend from 'react-dnd-html5-backend';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { detectMob } from '../util/react_utils';
import * as Backend from '../util/firebase';
import NavigationMenu from './NavigationMenu';
import UserProvider, { UserAware, userProviderContextTypes } from '../auth/Auth';
import Login, { LoginRedirect } from '../auth/Login';
import Account, { remoteLogin, remoteSignup } from '../auth/Account';
import { theme } from './Theme';
import Logo from '../common/Logo';
import EventList from '../event/list/EventList';
import { ReduxRoot, pushUrl, replaceUrl } from '../redux/ReduxConfig';
import BackHandler from '../common/dialog/BackHandler';
import EventInfo from '../event/list/EventInfo';
import Public, { PublicConfirmation } from '../public/Public';
import TVLeaderboard from '../public/TVLeaderboard';
import UserList from '../users/UserList';
import TestList from './TestList';
import ErrorBoundary from './ErrorBoundary';
import { styles, welcomeImage } from '../styles';
import { PayPalConfig, DEBUG, TEST_SERVER, Urls, VERSION, ITEM_ABOUT_ID } from '../util/config';
import LeaderboardInfo from '../public/Leaderboard';
import { getTimeZoneOffsetMs, Func, dbgLog, logInfo } from "../util/utility";
import { Announcement, Event } from '../types/EventTypes';
import { registerServiceWorker, detectServiceWorkerUpdate } from './SWRegistration';
import { Flex } from '../common/Misc';
import { FirebaseDocComponent } from 'src/common/WithData';
import AnnouncementAlert from './AnnouncementAlert';
import { PayPalScriptProvider, ReactPayPalScriptOptions } from "@paypal/react-paypal-js";
import LocationProviderComponent from "../common/components/LocationProviderComponent";
import HandicapCalc from './HandicapCalc';

const ITEM_SET_EID = "setEID";
const reloading = window.location.search.indexOf('reload') > 0;

function saveAbout() {
    location.search.split(/[&]|[?]/).forEach(par => {
        const X = 'about=';
        if (par.startsWith(X)) {
            const aboutID = par.substring(X.length);
            dbgLog('From about:', aboutID, 'old value:', localStorage.getItem(ITEM_ABOUT_ID));
            localStorage.setItem(ITEM_ABOUT_ID, aboutID);
            replaceUrl(window.location.pathname);
        }
    })
}

if (window.location.search) {
    setTimeout(saveAbout, 0);
}

if (reloading) {
    registerServiceWorker(false, () => window.location.replace(window.location.pathname));
}

function processSearchParams() {
    location.search.split(/[&]|[?]/).forEach(par => {
        const fromPar = 'from=';
        if (par.startsWith(fromPar)) {
            const from = par.substring(fromPar.length);
            dbgLog('From:', from, window.location.pathname);
            if (from === 'about') {
                Backend.trackEvent('top_get_started');
            }
            replaceUrl(window.location.pathname);
        }
    });
}

if (window.location.search) {
    setTimeout(processSearchParams, 0);
}

class Err extends React.Component {
    state = { err: false };
    render() {
        const { err } = this.state;
        if (err) {
            throw new Error(`Simulated crash`);
        }
        setTimeout(() => this.setState({ err: true }), 3000);
        return <span>Test page</span>;
    }
}

type AdminAppBarState = {
    announcement?: Announcement;
    updateAvailable?: boolean;
    updateCalled?: boolean;
};

class AdminAppBar extends React.Component<{}, AdminAppBarState> {
    state: AdminAppBarState = {};
    static contextTypes = userProviderContextTypes;
    context!: UserAware;
    constructor(props: WithStyles<typeof styles>) {
        super(props);
        if (!reloading) {
            this.detectUpdate();
        }
    }
    detectUpdate = () => {
        detectServiceWorkerUpdate(() => {
            this.context.setHasUpdated(true);
            this.setState({ updateAvailable: true });
        });
    }
    doUpdate = () => {
        this.setState({ updateCalled: true });
        window.location.reload();
    }
    openEvents = () => {
        const { updateAvailable } = this.state;
        return updateAvailable ? window.location.replace('/events') : pushUrl('/events')
    }
    render() {
        const { updateAvailable, updateCalled, announcement } = this.state;
        const { user, effectiveUserId } = this.context;
        return (
            <AppBar position="static">
                <Toolbar variant="dense" sx={{ height: 56 }}>
                    <Logo ext={DEBUG ? ((user?.uid || '') + ' ' + (effectiveUserId ?? '')) : ''} onclk={this.openEvents} extclk={() => { }} />
                    {updateAvailable && !updateCalled && <NewVersionMenu updateServiceWorker={this.doUpdate} />}
                    <NavigationMenu />
                </Toolbar>
                <AnnouncementAlert announcement={announcement} />
                <FirebaseDocComponent
                    docReference={Backend.mainAnnouncementDoc()}
                    onDoc={doc => this.setState({ announcement: Backend.fromEntity<Announcement>(doc) })} />
            </AppBar>
        );
    }
}

class NewVersionMenu extends React.Component<{ updateServiceWorker: Func<void> }> {
    updateSite = (e: React.MouseEvent) => {
        e.preventDefault();
        const { updateServiceWorker } = this.props;
        updateServiceWorker();
    }
    render() {
        return (
            <Flex>
                New version is available,&nbsp; <a href="/" onClick={this.updateSite}><Flex>refresh now <RefreshIcon /></Flex></a>
            </Flex>
        );
    }
}

type EventInviteRouteProps = {
    eventInviteId?: string;
};

const EventInviteRouteComponent = (props: EventInviteRouteProps) => {
    return <Route path={"/"} component={() => {
        const onError = () => window.location.replace(`${Urls.baseUrl}/events`);
        Backend.getEntity<Event>(Backend.eventsDb, props.eventInviteId)
            .then(event => {
                if (!event.exists || !event.publicId || event.publicId === '') {
                    onError();
                    return;
                }
                window.location.replace(`${Urls.baseUrl}/event/${event.publicId}/standings/invite`);
            })
            .catch(onError);
        return null;
    }} />;
}

type RemoteLoginComponentProps = {
    loggedIn: boolean;
};

const RemoteLoginComponent = (props: RemoteLoginComponentProps) => {
    if (props.loggedIn) {
        return <Redirect from="/remoteLogin" to="/events" />;
    } else {
        remoteLogin();
        return null;
    }
}

const RemoteSignupComponent = (props: RemoteLoginComponentProps) => {
    if (props.loggedIn) {
        return <Redirect from="/remoteSignup" to="/events" />;
    } else {
        remoteSignup();
        return null;
    }
}

interface DefaultRoutingProps {
    loggedIn: boolean;
    hasSup?: boolean;
    signingOut?: boolean;
}

class DefaultRoutingComponent extends React.Component<DefaultRoutingProps, {}> {
    render() {
        const { loggedIn, signingOut, hasSup } = this.props;
        const locationPath = window.location.toString();
        const isEventInviteLink: boolean = locationPath.includes('/eventInvites/');
        let eventInvitesFound = false;
        const eventInviteId = isEventInviteLink ? locationPath.split('/').find(value => {
            if (eventInvitesFound) {
                return true;
            }
            eventInvitesFound = value === 'eventInvites';
            return false;
        }) : undefined;
        return (
            <React.Fragment>
                <AdminAppBar />
                <Switch>
                    {isEventInviteLink && eventInviteId && <EventInviteRouteComponent eventInviteId={eventInviteId} />}
                    <Route path={"/remoteLogin"} component={RemoteLoginComponent} />
                    <Route path={"/remoteSignup"} component={RemoteSignupComponent} />

                    {loggedIn && <Redirect from="/login" to="/events" />}
                    {(TEST_SERVER || window.location.origin.includes('://localhost:3000')) && <Route path="/login" component={Login} />}
                    <Route path="/sso/:token" component={LoginRedirect} />

                    {!loggedIn && !TEST_SERVER && !isEventInviteLink && <Route path={"/"} component={() => {
                        window.location.replace(Urls.landingPageLink);
                        return null;
                    }} />}
                    {!loggedIn && !signingOut && <Redirect to="/login" />}
                    {(hasSup || TEST_SERVER || DEBUG) && <Route path="/sup" component={UserList} exact={true} />}

                    <Route path="/account" component={Account} />
                    <Route path="/events" component={EventList} exact={true} />
                    <Route path="/events/:id" component={EventInfo} />
                    <Route path="/errtest" component={Err} />
                    <Redirect from="/" to="/events" />
                </Switch>
            </React.Fragment>
        );
    }
}

class RoutingContent extends React.Component<WithStyles<typeof styles> & RouteComponentProps<any>> {
    static contextTypes = userProviderContextTypes;
    context!: UserAware;

    render() {
        const { classes, location } = this.props;
        const { loggedIn, hasSup, signingOut } = this.context;
        if (loggedIn === undefined) {
            return null;
        }
        const locPath = location.pathname;
        const eidToSet = localStorage.getItem(ITEM_SET_EID);
        if (eidToSet) {
            this.context.setEffectiveUserId(eidToSet);
            localStorage.removeItem(ITEM_SET_EID);
        }
        return (
            <React.Fragment>
                <div className={classes.welcome}>
                    <Switch>
                        <Route path='/leaderboards/:id' component={LeaderboardInfo} />
                        <Route render={() =>
                            <DefaultRoutingComponent loggedIn={loggedIn} signingOut={signingOut} hasSup={hasSup} />
                        } />
                    </Switch>
                </div>
                {locPath === '/login' && <footer className={classes.welcomeFooter}>
                    <img className={classes.welcomeImage} src={welcomeImage} alt="" />
                </footer>}
            </React.Fragment>
        );
    }
}

const routingContent = withRouter(withStyles(styles)(RoutingContent));

class AdminRoot extends React.Component<WithStyles<typeof styles>> {
    render() {
        return (
            <UserProvider>
                <ErrorBoundary>
                    <Route component={routingContent} />
                </ErrorBoundary>
                <BackHandler />
            </UserProvider>
        );
    }
}

const paypalInitialOptions: ReactPayPalScriptOptions = {
    clientId: PayPalConfig.payPalClientId,
    intent: 'capture'
};

const adminRoot = withStyles(styles)(AdminRoot);

class App extends React.Component {
    componentDidMount() {
        Backend.trackEvent('app_start');
    }
    render() {
        logInfo(`lang: ${navigator.language}`);
        logInfo(`ver: ${VERSION}`);
        logInfo(`tz : ${getTimeZoneOffsetMs(Date.now())}`);
        logInfo(`env: ${process.env.NODE_ENV}`);
        const hasNative = document && (document.elementsFromPoint);
        function getDropTargetElementsAtPoint(x: number, y: number, dropTargets: HTMLElement[]) {
            return dropTargets.filter(t => {
                const rect = t.getBoundingClientRect();
                return (
                    x >= rect.left &&
                    x <= rect.right &&
                    y <= rect.bottom &&
                    y >= rect.top
                );
            });
        }
        // use custom function only if elementsFromPoint is not supported
        const backendOptions = {
            enableTouchEvents: true,
            enableMouseEvents: false,
            ignoreContextMenu: true,
            getDropTargetElementsAtPoint: !hasNative && getDropTargetElementsAtPoint,
        };
        return (
            <PayPalScriptProvider options={paypalInitialOptions}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DndProvider backend={detectMob() ? TouchBackend : HTML5Backend} options={backendOptions}>
                        <StyledEngineProvider injectFirst>
                            <ThemeProvider theme={theme}>
                                <CookiesProvider>
                                    <BrowserRouter>
                                        <ReduxRoot>
                                            <Switch>
                                                // ++ public
                                                <Route path="/event/:id" component={Public} />
                                                <Route path="/invite-accept/:id" component={PublicConfirmation} />
                                                <Route path="/invite-decline/:id" component={PublicConfirmation} />
                                                <Route path="/tv/:id" component={TVLeaderboard} />
                                                <Route path="/test" component={TestList} />
                                                <Route path="/calc" component={HandicapCalc} />
                                                // -- public

                                                <Route component={adminRoot} />
                                            </Switch>
                                        </ReduxRoot>
                                    </BrowserRouter>
                                </CookiesProvider>
                            </ThemeProvider>
                        </StyledEngineProvider>
                    </DndProvider>
                </LocalizationProvider>
                <LocationProviderComponent />
            </PayPalScriptProvider>
        );
    }
}

export default App;
