import * as React from 'react';
import NumberStepperWidget from '../../../../common/form/NumberStepperWidget';

export interface GroupSizeProps {
    value: number;
    teamSize: number;
    onChanged: (val: number) => void;
}

function format(val: number, teamSize: number) {
    if (teamSize > 1) {
        return `${val} ${val === 1 ? 'team' : 'teams'}`;
    }
    return `${val} ${val === 1 ? 'golfer' : 'golfers'}`;
}

function GroupSize(props: GroupSizeProps) {
    return <NumberStepperWidget value={props.value} min={1} max={Math.floor(8 / props.teamSize)} step={1} onChanged={props.onChanged} format={() => format(props.value, props.teamSize)} />;
}

export default GroupSize;
