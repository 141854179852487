import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { List, Typography, useMediaQuery, useTheme } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import LinkIcon from '@mui/icons-material/OpenInNew';
import * as Scoring from '../scoring/scoring';
import { shortName, fullName, getSameNameGolfersIds } from '../contact/Contact';
import { FirebaseDocComponent, FirebaseDataComponent, FirebaseUserPubDataComponent, withDataItemFromUrl } from '../common/WithData';
import * as Backend from '../util/firebase';
import { showProgress } from '../redux/ReduxConfig';
import {
    Competition, Event, Contact, GolferGroup, Score, ReportedScore, EventMapping, Portal, Team, ScoringFormatDistance, Units, Distance, ScoringData, PayoutSettings,
    isDistanceScoring, isSkinsScoring, isTeamScoring, isNetMode, isGrossMode, isMainScoring, isTeamFormat, getTee, getCompetitionFlightScoresCount, teamsOf, golfersOfCompetition,
    isStablefordScoringOrMode, ContactScoringState, CalculatedScores, isCompatibleCompetitionSkins, getEventMainCompetition, isTeamScoringExceptBB,
    ScoringFormatTeams, getHolesRange, rollEvents, fixLegacyTees, fixLegacyRounds, Round, isRound, EventBase,
    getRoundsCompetitions, sortCompetitions, hasFirstRoundCompetition, getTotalHoles, ContactRoundsScores,
    getCurrentRound
} from '../types/EventTypes';
import {
    isFullScoresCompetition, getDistanceScores, getHolesCount, SkinsScoringState, getSkinsScores, HolesScoringState, skinCompetitionAward, formatDistance, hasTees,
    genderFromEvent, golferMainCompetitionAward, getPayouts
} from '../event/Event';
import Logo from '../common/Logo';
import { Flex, Container, Item, ItemS, Spacing } from '../common/Misc';
import { NoEvent } from './Public';
import { appStyles, styles } from '../styles';
import { array, dbgLog } from '../util/utility';
import { ContainerOdd as ContainerStyled } from "./Standings";
import useDidUpdateEffect from "../hooks/useDidUpdateEffect";
import { Urls } from "../util/config";
import { fixTeamsHandicapAndGender } from 'src/scoring/handicap';

const TEST_MODE = window.location.search.indexOf('testmode') > 0;;
const defaultBanner = '/img/banner.jpg';
const defaultPerson = '/img/baseline-person.svg';
const NAX_GROUP = 10;
const NEXT_DELAY = TEST_MODE ? 300000 : 15000;
const USE_TRANSPERENT_SPACE = true;

type CompetitionScores = {
    competitionName: string;
    scoring: ScoringData;
    flight: number;
    noTees: boolean;
    withWinnings: boolean;
    skinsMixed: boolean;
    compatibleCompetitionSkins: boolean;
    competitionLength: number;
    winnings: Map<string, number>;
    skinsWinnings: Map<number, number | undefined>;
    payoutSettings: PayoutSettings | null | undefined;
    reportedScores: Map<string, ReportedScore>;
    reportedTeamScores: Map<string, ReportedScore>;
    contactsWithRoundsScores: Array<ContactRoundsScores>;
    sameNameGolfersIdsSet: Set<string>;
    skins: Array<SkinsScoringState>;
    distanceInfo: Array<HolesScoringState>;
    roundsCompetitions: Array<Competition>;
};

const Title = (props: { eventName: string, competitionName: string, portal: Portal }) => {
    const classes = appStyles();
    const { portal, eventName, competitionName } = props;
    return (
        <Flex placeMiddle className={classes.tvHeader}>
            <img className={classes.tvBadge} src={portal.badgeUrl || defaultBanner} alt="" />
            <div>
                <span className={classes.uppercaseText}>{eventName}</span>
                <br />
                <span className={classes.tvHeaderSecondary}>{competitionName}</span>
            </div>
        </Flex>
    );
};

const Icon = (props: { contact: Contact }) => {
    const classes = appStyles();
    const { contact } = props;
    return <img src={contact.avatar || defaultPerson} className={classes.tvAvatar} alt="" />;
};

const NoIcon = () => {
    const classes = appStyles();
    return <span className={classes.width0 + ' ' + classes.tvAvatar} />;
};

const NamesIcons = (props: { contacts: Contact[], maxContacts?: number, sameNameGolfersIdsSet: Set<string> }) => {
    const classes = appStyles();
    const { maxContacts, sameNameGolfersIdsSet } = props;
    let { contacts } = props;
    const empties = contacts.filter(c => !c.id).length;
    let more = '';
    if (contacts.length > 0) {
        const teamFormat = contacts.length > 1;
        if (maxContacts && contacts.length > maxContacts + empties) {
            more = `(+${contacts.length - maxContacts - empties} more)`;
            contacts = contacts.slice(0, maxContacts + empties);
        }
        return (
            <Flex placeCenter className={classes.tvItem}>
                {contacts.map((contact, idx) => !contact.id ?
                    (<React.Fragment key={idx}>
                        <span className={classes.tvItemElem}>&nbsp;&nbsp;&nbsp;</span>
                    </React.Fragment>) :
                    (<React.Fragment key={idx}>
                        <Icon contact={contact} />
                        <span className={classes.tvItemElem}>{teamFormat ? shortName(contact, false, 20) : fullName(contact, 30, 30)}&nbsp;</span>
                        <span className={classes.homeCourseOrCityTV}>{contact?.homeCourseOrCity && sameNameGolfersIdsSet.has(contact.id) ? ` (${contact.homeCourseOrCity})` : ''}</span>
                    </React.Fragment>)
                )}
                {!!more &&
                    <>
                        <NoIcon />
                        <span className={classes.tvItemElem} style={{ flexShrink: 0 }}>{more}</span>
                    </>}
            </Flex>
        );
    } else {
        return (
            <Container spacing={0}>
                {''}
            </Container>
        );
    }
};

const Footer = (props: { event: Event }) => {
    const classes = appStyles();
    const { event } = props;
    return (
        <Container className={classes.tvFooter}>
            <ItemS xs={12} sm={4} color={'white'}><Logo /></ItemS>
            <ItemS xs={12} sm={8} placeRight baseline>
                <a href={`${Urls.baseUrl}/event/${event.publicId}`} className={classes.tvLink} target="_blank" rel="noopener noreferrer">
                    <LinkIcon className={classes.tvTextIcon} />{Urls.baseUrl.split('//')[1]}/event/{event.publicId}
                </a>
            </ItemS>
        </Container>
    );
};

const HeaderScore = (props: { scoring: ScoringData; withWinnings: boolean; scoreRounds?: Array<number>; roundNo: number }) => {
    const classes = appStyles();
    const { scoring, withWinnings, scoreRounds, roundNo } = props;
    const isNet = isNetMode(scoring.mode);
    const isGross = isGrossMode(scoring.mode);
    const isTeam = isTeamScoring(scoring);
    const isStableford = isStablefordScoringOrMode(scoring);
    let numColumns = 1;
    const useTransperentSpace = USE_TRANSPERENT_SPACE && useMediaQuery(useTheme().breakpoints.up('lg'));
    if (scoreRounds) {
        numColumns += 3 + scoreRounds.length;
        return <Container spacing={0} wrap="nowrap" className={classes.tvListItemHeader}>
            <Item className={classes.tvListItemElem + ' ' + classes.tvListItemHeaderFirst} placeCenter xs={1}>Pos</Item>
            <Item className={classes.tvListItemTextElem} xs={12 - numColumns}>{isTeam ? 'Team' : 'Golfer'}</Item>
            <Item className={classes.tvListItemElem + ' ' + classes.tvListItemTotal} placeCenter xs={1}>Total <br /> {isStableford ? 'pts' : ''} {isNet ? 'net' : ''}</Item>
            <Item className={classes.tvListItemElem + ' ' + classes.tvListItemThru} placeCenter xs={1}>Round {roundNo} <br /> thru</Item>
            <Item className={classes.tvListItemElem + ' ' + classes.tvListItemRelScore} placeCenter xs={1}>Round {roundNo} <br /> {isStableford ? 'to par' : ''} {isNet ? 'net' : ''}</Item>
            {useTransperentSpace && <Item className={classes.tvListItemSpacing} xs={1}>&nbsp;</Item>}
            {scoreRounds.map((scoreRound, idx) =>
                <Item key={scoreRound} xs={1} className={classes.tvListItemElem + ' ' + (idx === scoreRounds.length - 1 ? classes.tvListItemHeaderLast : '')} placeCenter >R{scoreRound}</Item>)}
        </Container>;
    }
    numColumns += 3 + (isStableford || isNet ? 1 : 0) + (withWinnings ? 1 : 0);
    let isLastElem = 1;
    if (!isStableford && isNet) {
        isLastElem = 2;
    }
    if (isStableford && isGross) {
        isLastElem = 3;
    }
    if (isStableford && isNet) {
        isLastElem = 4;
    }
    if (withWinnings) {
        isLastElem = 5;
    }
    return <Container spacing={0} wrap="nowrap" className={classes.tvListItemHeader}>
        <Item className={classes.tvListItemElem + ' ' + classes.tvListItemHeaderFirst} placeCenter xs={1}>Pos</Item>
        <Item className={classes.tvListItemTextElem} xs={12 - numColumns}>{isTeam ? 'Team' : 'Golfer'}</Item>
        <Item className={classes.tvListItemElem + ' ' + classes.tvListItemScore0} placeCenter xs={1}>{isNet ? 'Net' : 'Score'}</Item>
        <Item className={classes.tvListItemElem} placeCenter xs={1}>Thru</Item>
        <Item className={classes.tvListItemElem + ' ' + (isLastElem === 1 ? classes.tvListItemHeaderLast : '')}
            placeCenter xs={1}>Total<br />{isNet ? ' Gross' : ''}</Item>
        {!isStableford && isNet && <Item className={classes.tvListItemElem + ' ' + (isLastElem === 2 ? classes.tvListItemHeaderLast : '')}
            placeCenter xs={1}>Total<br /> Net</Item>}
        {isStableford && isGross && <Item className={classes.tvListItemElem + ' ' + (isLastElem === 3 ? classes.tvListItemHeaderLast : '')}
            placeCenter xs={1}>Pts</Item>}
        {isStableford && isNet && <Item className={classes.tvListItemElem + ' ' + (isLastElem === 4 ? classes.tvListItemHeaderLast : '')}
            placeCenter xs={1}>Pts Net</Item>}
        {withWinnings && <Item className={classes.tvListItemElem + ' ' + (isLastElem === 5 ? classes.tvListItemHeaderLast : '')}
            placeCenter xs={1}>Purse</Item>}
    </Container>;
};

interface RowProps {
    roundNo: number;
    scoring: ScoringData;
    last: boolean;
    index: number;
    winning?: number;
    sameNameGolfersIdsSet: Set<string>;
    reportedScores: Map<string, ReportedScore>;
    contactRoundsScores: ContactRoundsScores;
}

const RowScore = (props: RowProps) => {
    const classes = appStyles();
    const { roundNo, contactRoundsScores, scoring, last, index, winning, sameNameGolfersIdsSet, reportedScores } = props;
    const { scoringStates } = contactRoundsScores;
    const firstScore = scoringStates[0]!;
    const hasRounds = scoringStates.length > 1;
    const isNet = isNetMode(scoring.mode);
    const isStableford = isStablefordScoringOrMode(scoring);
    const posWinner = hasRounds ?
        contactRoundsScores.pos + (firstScore.winnerIn?.find(w => w === scoring.mode) ? '*' : '') :
        firstScore.pos;
    const className = classes.tvListItem + (last ? ' ' + classes.tvListItemLast : '');
    const bestBall = scoring.format === ScoringFormatTeams.best_ball;
    const golferDisStatus = (score?: ContactScoringState) => score?.contactInfo.withdrawn ? 'WD' : score?.contactInfo.disqualified ? 'DQ' : undefined;
    const golferDisStatusR = contactRoundsScores.withdrawn ? 'WD' : contactRoundsScores.disqualified ? 'DQ' : undefined;
    const scoreTotal = (score?: ContactScoringState) => golferDisStatus(score) ??
        Scoring.formatTotalScore(scoringStates.length, score?.total);
    const scoreRel = (score?: ContactScoringState) => golferDisStatus(score) ??
        Scoring.formatRelativeScore(isNet ? score?.relativeNet : score?.relativeTotal);
    const scoreToDisplay = (score?: ContactScoringState) => golferDisStatus(score) ??
        (isStableford ? (isNet ? score?.stablefordNet : score?.stableford) :
            Scoring.formatTotalScore(scoringStates.length, isNet ? score?.net : score?.total));
    const scoreRelToDisplay = (score?: ContactScoringState) => golferDisStatus(score) ??
        (isStableford ? (isNet ? score?.stablefordNet : score?.stableford) :
            Scoring.formatRelativeScore(isNet ? score?.relativeNet : score?.relativeTotal));
    const scoreThru = (score?: ContactScoringState) => golferDisStatus(score) ??
        (score?.holes === getTotalHoles(score?.event.holesType) ? 'F' : (score?.holes || '0'));
    let totalScore = 0;
    if (isStableford) {
        totalScore = (isNet ? contactRoundsScores.stablefordNet : contactRoundsScores.stableford) ?? 0;
    } else {
        totalScore = (isNet ? contactRoundsScores.net : contactRoundsScores.total) ?? 0;
    }
    const useTransperentSpace = USE_TRANSPERENT_SPACE && useMediaQuery(useTheme().breakpoints.up('lg'));
    let numColumns = 1;
    if (hasRounds) {
        numColumns += 3 + scoringStates.length;
    } else {
        numColumns += 3 + (isStableford || isNet ? 1 : 0) + (winning || winning === 0 ? 1 : 0);
    }
    let isLastElem = 1;
    if (isStableford || isNet) {
        isLastElem = 2;
    }
    if (winning || winning === 0) {
        isLastElem = 3;
    }
    const getComponent = (original: boolean = true) => (
        <Container spacing={0} wrap="nowrap" className={original ? className : ''}>
            <Item className={classes.tvListItemElem + ' ' + (last ? classes.tvListItemElemFirst : '')} placeCenter xs={1}>{posWinner}</Item>
            <Item className={classes.tvListItemTextElem} xs={12 - numColumns}>
                <NamesIcons contacts={firstScore.contactInfo.contacts} sameNameGolfersIdsSet={sameNameGolfersIdsSet} />
            </Item>
            {hasRounds ? <>
                <Item xs={1} className={classes.tvListItemElem + ' ' + classes.tvListItemTotal} placeCenter>{golferDisStatusR ?? (totalScore || '-')}</Item>
                <Item xs={1} className={classes.tvListItemElem + ' ' + classes.tvListItemThru} placeCenter>{scoreThru(scoringStates[roundNo - 1])}</Item>
                <Item xs={1} className={classes.tvListItemElem + ' ' + classes.tvListItemRelScore} placeCenter><Item>{scoreRelToDisplay(scoringStates[roundNo - 1])}</Item></Item>
                {useTransperentSpace && <Item className={classes.tvListItemSpacing} xs={1}>&nbsp;</Item>}
                {scoringStates.map((score, idx) =>
                    <Item xs={1} className={classes.tvListItemElem + ' ' + (last && idx === scoringStates.length - 1 ? classes.tvListItemElemLast : '')} placeCenter noWrap key={idx}>
                        {scoreToDisplay(score)}
                    </Item>)}
            </> : <>
                <Item className={classes.tvListItemElem + ' ' + classes['tvListItemScore' + (index < 8 ? index + 1 : 8)]} placeCenter xs={1}>
                    {scoreRel(firstScore)}
                </Item>
                <Item className={classes.tvListItemElem} placeCenter xs={1}>
                    {scoreThru(firstScore)}
                </Item>
                <Item className={classes.tvListItemElem + ' ' + (last && isLastElem === 1 ? classes.tvListItemElemLast : '')} placeCenter placeMiddle xs={1}>
                    {scoreTotal(firstScore)}
                </Item>
                {(isStableford || isNet) &&
                    <Item className={classes.tvListItemElem + ' ' + (last && isLastElem === 2 ? classes.tvListItemElemLast : '')} placeCenter xs={1}>
                        {scoreToDisplay(firstScore)}
                    </Item>}
                {(winning || winning === 0) &&
                    <Item className={classes.tvListItemElem + ' ' + (last && isLastElem === 3 ? classes.tvListItemElemLast : '')} placeCenter xs={1}>
                        {golferDisStatus(firstScore) ?? '$' + winning}
                    </Item>}
            </>}
        </Container>
    );
    const [scoresUpdated, setScoresUpdated] = React.useState<boolean>(false);
    const styledComponent = <ContainerStyled className={className} onAnimationEnd={() => setScoresUpdated(false)}>
        {getComponent(false)}
    </ContainerStyled>;
    const holesRange = getHolesRange(firstScore.event.holesType);
    const holeCount = holesRange.last - holesRange.first;
    const scoresToCheck = Scoring.getMonitoringReportedScoresArray(reportedScores, holeCount, bestBall, firstScore);
    useDidUpdateEffect(() => setScoresUpdated(true), scoresToCheck);
    return scoresUpdated ? styledComponent : getComponent();
};

const LDHeader = (props: { withWinnings: boolean }) => {
    const classes = appStyles();
    const { withWinnings } = props;
    let isLastElem = 1;
    if (withWinnings) {
        isLastElem = 2;
    }
    return (
        <Container spacing={0} wrap="nowrap" className={classes.tvListItemHeader}>
            <Item className={classes.tvListItemElem + ' ' + classes.tvListItemHeaderFirst} placeCenter xs={1}>Hole</Item>
            <Item className={classes.tvListItemElem + ' ' + (isLastElem === 1 ? classes.tvListItemHeaderLast : '')} xs={withWinnings ? 10 : 11}>Winner</Item>
            {withWinnings && <Item className={classes.tvListItemElem + ' ' + (isLastElem === 2 ? classes.tvListItemHeaderLast : '')} xs={1}>Purse</Item>}
        </Container>
    );
};

const LDRow = (props: HolesScoringState & { last: boolean, sameNameGolfersIdsSet: Set<string> } & { winning?: number }) => {
    const classes = appStyles();
    const { last, hole, contacts, winning, sameNameGolfersIdsSet } = props;
    let isLastElem = 1;
    if (winning || winning === 0) {
        isLastElem = 2;
    }
    return (
        <Container spacing={0} wrap="nowrap" className={classes.tvListItemHeader}>
            <Item className={classes.tvListItemElem + ' ' + (last ? classes.tvListItemElemFirst : '')} placeCenter xs={1}>{hole + 1}</Item>
            <Item className={classes.tvListItemElem + ' ' + (last && isLastElem === 1 ? classes.tvListItemElemLast : '')} xs={(winning || winning === 0) ? 10 : 11}><NamesIcons contacts={contacts} sameNameGolfersIdsSet={sameNameGolfersIdsSet} /></Item>
            {(winning || winning === 0) && <Item className={classes.tvListItemElem + ' ' + (last && isLastElem === 2 ? classes.tvListItemElemLast : '')} xs={1}>{'$' + winning}</Item>}
        </Container>
    );
};

const KPHeader = (props: { withWinnings: boolean }) => {
    const classes = appStyles();
    const { withWinnings } = props;
    let isLastElem = 1;
    if (withWinnings) {
        isLastElem = 2;
    }
    return (
        <Container spacing={0} wrap="nowrap" className={classes.tvListItemHeader}>
            <Item className={classes.tvListItemElem + ' ' + classes.tvListItemHeaderFirst} placeCenter xs={1}>Hole</Item>
            <Item className={classes.tvListItemElem} xs={withWinnings ? 8 : 9}>Winner</Item>
            <Item className={classes.tvListItemElem + ' ' + (isLastElem === 1 ? classes.tvListItemHeaderLast : '')} placeCenter xs={2}>Distance</Item>
            {withWinnings && <Item className={classes.tvListItemElem + ' ' + (isLastElem === 2 ? classes.tvListItemHeaderLast : '')} placeCenter xs={1}>Purse</Item>}
        </Container>
    );
};

const KPRow = (props: { units?: Units } & HolesScoringState & { last: boolean, sameNameGolfersIdsSet: Set<string> } & { winning?: number }) => {
    const classes = appStyles();
    const { last, units, hole, score, contacts, winning, sameNameGolfersIdsSet } = props;
    let isLastElem = 1;
    if (winning || winning === 0) {
        isLastElem = 2;
    }
    return (
        <Container spacing={0} wrap="nowrap" className={classes.tvListItemHeader}>
            <Item className={classes.tvListItemElem + ' ' + (last ? classes.tvListItemElemFirst : '')} placeCenter xs={1}>{hole + 1}</Item>
            <Item className={classes.tvListItemElem} xs={(winning || winning === 0) ? 8 : 9}><NamesIcons contacts={contacts} sameNameGolfersIdsSet={sameNameGolfersIdsSet} /></Item>
            <Item className={classes.tvListItemElem + ' ' + (last && isLastElem === 1 ? classes.tvListItemElemLast : '')} placeCenter xs={2}>{formatDistance(units, score)}</Item>
            {(winning || winning === 0) && <Item className={classes.tvListItemElem + ' ' + (last && isLastElem === 2 ? classes.tvListItemElemLast : '')} placeCenter xs={1}>{'$' + winning}</Item>}
        </Container>
    );
};

const SkinsHeader = (props: { scoring: ScoringData } & { withWinnings: boolean }) => {
    const classes = appStyles();
    const { scoring, withWinnings } = props;
    const isNet = isNetMode(scoring.mode);
    let isLastElem = 1;
    if (withWinnings) {
        isLastElem = 2;
    }
    return (
        <Container spacing={0} wrap="nowrap" className={classes.tvListItemHeader}>
            <Item className={classes.tvListItemElem + ' ' + classes.tvListItemHeaderFirst} xs={1} placeCenter>Hole</Item>
            <Item className={classes.tvListItemElem} xs={1} placeCenter>Par</Item>
            <Item className={classes.tvListItemElem} xs={1} placeCenter>Lowest<br />{isNet ? 'net' : 'gross'}</Item>
            <Item className={classes.tvListItemTextElem + ' ' + (isLastElem === 1 ? classes.tvListItemHeaderLast : '')} xs={withWinnings ? 8 : 9}>Golfer(s)</Item>
            {withWinnings && <Item className={classes.tvListItemElem + ' ' + (isLastElem === 2 ? classes.tvListItemHeaderLast : '')} xs={1}>Purse</Item>}
        </Container>
    );
};

// <Item className={classes.tvListItemElem + ' ' + (last && isLastElem === 3 ? classes.tvListItemElemLast : '')} placeCenter xs={1}>

const SkinsRow = (props: SkinsScoringState & { last: boolean, teamSize: number, sameNameGolfersIdsSet: Set<string> } & { winning?: number }) => {
    const classes = appStyles();
    const { hole, par, score, contacts, last, teamSize, winning, sameNameGolfersIdsSet } = props;
    const oneWinner = contacts.length === 1;
    let allContacts: Contact[] = [];
    if (teamSize > 1) {
        contacts.forEach(contact => allContacts = allContacts.concat(contact.contacts).concat({ id: '', lastName: '', gender: '', hidden: false }));
    } else {
        contacts.forEach(contact => allContacts = allContacts.concat(contact.contacts));
    }
    let isLastElem = 1;
    if (winning || winning === 0) {
        isLastElem = 2;
    }
    return (
        <Container spacing={0} wrap="nowrap" className={classes.tvListItemHeader}>
            <Item className={classes.tvListItemElem + ' ' + (last ? classes.tvListItemElemFirst : '')} xs={1} placeCenter>{hole + 1}</Item>
            <Item className={classes.tvListItemElem} xs={1} placeCenter>{par}</Item>
            <Item className={classes.tvListItemElem + (oneWinner ? ' ' + classes.winner : '')} xs={1} placeCenter>{score === null ? '' : score}</Item>
            <Item className={classes.tvListItemElem + ' ' + (last && isLastElem === 1 ? classes.tvListItemElemLast : '') + (oneWinner ? ' ' + classes.winner : '')} xs={(winning || winning === 0) ? 8 : 9}>
                <NamesIcons contacts={allContacts} maxContacts={3} sameNameGolfersIdsSet={sameNameGolfersIdsSet} />
            </Item>
            {(winning || winning === 0) && <Item className={classes.tvListItemElem + ' ' + (last && isLastElem === 2 ? classes.tvListItemElemLast : '') + (oneWinner ? ' ' + classes.winner : '')} xs={1} placeCenter>
                {'$' + winning}
            </Item>}
        </Container>
    );
};

const TransparentRow = () => {
    const classes = appStyles();
    return (
        <Container spacing={0} wrap="nowrap" className={classes.transparent}>
            <Item className={classes.tvItem} placeCenter xs={12}>{' '}</Item>
        </Container>
    );
};

const Transparent = (props: { size: number }) => {
    const { size } = props;
    if (size <= 0) {
        return null;
    }
    return <>
        {array(size, 0).map((_n, i) => <TransparentRow key={i} />)}
    </>;
};

const HeaderNoScore = () => {
    const classes = appStyles();
    return (
        <Container className={classes.tvListItemHeader}>
            <ItemS>
                <NoIcon />
                <Flex>Live scoring is currently disabled by event administrator</Flex>
            </ItemS>
        </Container>
    );
};

const HeaderNoTees = () => {
    const classes = appStyles();
    return (
        <Container className={classes.tvListItemHeader}>
            <ItemS>
                <NoIcon />
                <Flex>This competition has not been set up by event administrator yet</Flex>
            </ItemS>
        </Container>
    );
};

interface State {
    rounds: Array<Round>;
    groupsMap: Map<string, Array<GolferGroup>>;
    golfersMap: Map<string, Map<string, Contact>>;
    teamsMap: Map<string, Map<string, Team>>;
    distancesMap: Map<string, Map<string, Distance>>;
    golferScoresMap: Map<string, Map<string, Score>>;
    teamScoresMap: Map<string, Map<string, Score>>;
    competitionsMap: Map<string, Array<Competition>>;
    reportedScoresMap: Map<string, Map<string, ReportedScore>>;
    reportedTeamScoresMap: Map<string, Map<string, ReportedScore>>;
    calculatedScoresMap: Map<string, Map<string, CalculatedScores>>;
    loadedRounds: number;
    loadedTeams: number;
    loadedGroups: number;
    loadedGolfers: number;
    loadedCompetitions: number;
    competitonScores: Array<CompetitionScores>;
    curCompetition: number;
    groupOffset: number;
    units?: Units;
    eventId?: string;
    event?: Event;
    portal?: Portal;
}

type Props = WithStyles<typeof styles> & RouteComponentProps<any>;

class TVLeaderboard extends React.Component<Props, State> {
    state: State = {
        rounds: [],
        groupsMap: new Map(),
        golfersMap: new Map(),
        teamsMap: new Map(),
        distancesMap: new Map(),
        golferScoresMap: new Map(),
        teamScoresMap: new Map(),
        competitionsMap: new Map(),
        reportedScoresMap: new Map(),
        reportedTeamScoresMap: new Map(),
        calculatedScoresMap: new Map(),
        loadedTeams: 0,
        loadedGroups: 0,
        loadedGolfers: 0,
        loadedRounds: 0,
        loadedCompetitions: 0,
        competitonScores: [],
        curCompetition: 0,
        groupOffset: 0
    };

    private interval?: NodeJS.Timeout;

    async componentDidMount() {
        const { match } = this.props;
        const hideProgress = showProgress();
        const eventMappingId = await Backend.getEntity<EventMapping>(Backend.eventMappingDb, match.params.id);
        this.setEventId(eventMappingId);
        hideProgress();
        this.interval = setInterval(() => this.update(), NEXT_DELAY);
    }

    componentWillUnmount() {
        if (this.interval) {
            clearInterval(this.interval);
        }
    }

    private setEventId = (em?: EventMapping) => this.setState({ eventId: em?.eventId || '' });
    private setEvent = (event: Event) => this.setState({ event });
    private setPortal = (portal: Portal) => this.setState({ portal });

    private onRounds = (rounds: Array<Round>) => {
        fixLegacyRounds(rounds).sort((a, b) => a.roundOrder - b.roundOrder);
        this.setState({ rounds });
    }

    private fixLegacyTees = (baseEventId: string, competitions: Array<Competition>) => {
        const { event } = this.state;
        if (baseEventId === event?.id) {
            return fixLegacyTees(event, competitions);
        } else {
            return false;
        }
    }

    private getRound = (eventOrRoundId?: number | string) => {
        const { rounds } = this.state;
        if (eventOrRoundId) {
            return typeof eventOrRoundId == 'number' ?
                rounds.find(round => round.roundOrder === eventOrRoundId) :
                rounds.find(round => round.id === eventOrRoundId);
        } else {
            return undefined;
        }
    }

    private getStaff(eventOrRoundId: number | string) {
        const { event, competitionsMap, golfersMap, teamsMap, groupsMap, golferScoresMap, teamScoresMap, reportedScoresMap, reportedTeamScoresMap } = this.state;
        const { distancesMap } = this.state;
        const eventOrRound = this.getRound(eventOrRoundId) ?? event;
        eventOrRoundId = eventOrRound?.id ?? '';
        const golfers = golfersMap.get(eventOrRoundId) ?? new Map<string, Contact>();
        const teams = teamsMap.get(eventOrRoundId) ?? new Map<string, Team>();
        const groups = groupsMap.get(eventOrRoundId) ?? [];
        const distances = distancesMap.get(eventOrRoundId) ?? new Map<string, Distance>();
        const competitions = competitionsMap.get(eventOrRoundId) ?? [];
        const mainCompetition = getEventMainCompetition(competitions);
        const golferScores = golferScoresMap.get(eventOrRoundId) ?? new Map<string, Score>();
        const teamScores = teamScoresMap.get(eventOrRoundId) ?? new Map<string, Score>();
        const reportedScores = reportedScoresMap.get(eventOrRoundId) ?? new Map<string, ReportedScore>();
        const reportedTeamScores = reportedTeamScoresMap.get(eventOrRoundId) ?? new Map<string, ReportedScore>();
        return { eventOrRound, golfers, teams, groups, distances, competitions, mainCompetition, teamScores, golferScores, reportedScores, reportedTeamScores };
    }

    private getScores = (eventOrRoundId: string) => {
        const { golferScoresMap, teamScoresMap, reportedScoresMap, reportedTeamScoresMap } = this.state;
        const golferScores = golferScoresMap.get(eventOrRoundId) ?? new Map<string, Score>();
        const teamScores = teamScoresMap.get(eventOrRoundId) ?? new Map<string, Score>();
        const reportedScores = reportedScoresMap.get(eventOrRoundId) ?? new Map<string, ReportedScore>();
        const reportedTeamScores = reportedTeamScoresMap.get(eventOrRoundId) ?? new Map<string, ReportedScore>();
        return { golferScores, teamScores, reportedScores, reportedTeamScores };
    }

    private onGolfers = (eventOrRound: EventBase, golfers: Map<string, Contact>) => {
        const { golfersMap, loadedGolfers } = this.state;
        golfersMap.set(eventOrRound.id, golfers);
        this.setState({ golfersMap, loadedGolfers: loadedGolfers + 1 }, () => this.updateEventOrRoundScores(eventOrRound.id));
    }

    private onGroups = (eventOrRound: EventBase, groups: Array<GolferGroup>) => {
        const { loadedGroups, groupsMap } = this.state;
        groupsMap.set(eventOrRound.id, groups);
        this.setState({ groupsMap, loadedGroups: loadedGroups + 1 }, () => this.updateEventOrRoundScores(eventOrRound.id));
    }

    private onTeams = (eventOrRound: EventBase, allTeams: Map<string, Team>) => {
        const { loadedTeams, golfersMap, teamsMap } = this.state;
        const golfers = golfersMap.get(eventOrRound.id) ?? new Map<string, Contact>();
        const teams = new Map(
            Array.from(allTeams.entries())
                .filter(entry => entry[1].contactIds.some(golferId => golfers.has(golferId)))
        );
        fixTeamsHandicapAndGender(teams, golfers);
        teamsMap.set(eventOrRound.id, teams);
        this.setState({ teamsMap, loadedTeams: loadedTeams + 1 }, () => this.updateEventOrRoundScores(eventOrRound.id));
    }

    private onGolferScores = (eventOrRound: EventBase, golferScores: Map<string, Score>) => {
        const { golferScoresMap } = this.state;
        golferScoresMap.set(eventOrRound.id, golferScores);
        this.setState({ golferScoresMap }, () => this.updateEventOrRoundScores(eventOrRound.id));
    }

    private onTeamScores = (eventOrRound: EventBase, teamScores: Map<string, Score>) => {
        const { teamScoresMap } = this.state;
        teamScoresMap.set(eventOrRound.id, teamScores);
        this.setState({ teamScoresMap }, () => this.updateEventOrRoundScores(eventOrRound.id));
    }

    private onReportedGolferScores = (eventOrRound: EventBase, reportedScores: Map<string, ReportedScore>) => {
        const { reportedScoresMap } = this.state;
        reportedScoresMap.set(eventOrRound.id, reportedScores);
        this.setState({ reportedScoresMap }, () => this.updateEventOrRoundScores(eventOrRound.id));
    }

    private onReportedTeamScores = (eventOrRound: EventBase, reportedTeamScores: Map<string, ReportedScore>) => {
        const { reportedTeamScoresMap } = this.state;
        reportedTeamScoresMap.set(eventOrRound.id, reportedTeamScores);
        this.setState({ reportedTeamScoresMap }, () => this.updateEventOrRoundScores(eventOrRound.id));
    }

    private onDistances = (eventOrRound: EventBase, distances: Map<string, Distance>) => {
        const { distancesMap } = this.state;
        distancesMap.set(eventOrRound.id, distances);
        this.setState({ distancesMap }, () => this.updateEventOrRoundScores(eventOrRound.id));
    }

    private onCompetitions = (eventOrRound: EventBase, competitions: Array<Competition>) => {
        competitions.forEach(competition => competition.eventOrRoundId = eventOrRound.id);
        if (isRound(eventOrRound)) {
            competitions.forEach(competition => competition.roundOrder = eventOrRound.roundOrder);
        }
        const { event, competitionsMap, loadedCompetitions } = this.state;
        competitionsMap.set(eventOrRound.id, competitions);
        this.fixLegacyTees(eventOrRound.id, competitions)
        this.setState({ event, competitionsMap, loadedCompetitions: loadedCompetitions + 1 }, () => this.updateEventOrRoundScores(eventOrRound.id));
    }

    private updateEventOrRoundScores = (eventOrRoundId?: string) => {
        const { competitionsMap, calculatedScoresMap } = this.state;
        if (eventOrRoundId) {
            const competitions = competitionsMap.get(eventOrRoundId);
            if (competitions) {
                this.updateScores(eventOrRoundId, competitions, calculatedScoresMap);
                this.buildCompetitionsForDisplay();
            }
        } else {
            competitionsMap.forEach((competitions, eId) => this.updateScores(eId, competitions, calculatedScoresMap));
            this.buildCompetitionsForDisplay();
        }
        this.setState({ calculatedScoresMap });
    }

    private updateScores = (eventOrRoundId: string, competitions: Array<Competition>, calculatedScoresMap: Map<string, Map<string, CalculatedScores>>) => {
        const { eventOrRound, teams, golfers, groups, mainCompetition } = this.getStaff(eventOrRoundId);
        if (eventOrRound) {
            const { golferScores, teamScores, reportedScores, reportedTeamScores } = this.getScores(eventOrRoundId);
            let calculatedScores = calculatedScoresMap.get(eventOrRoundId) ?? new Map<string, CalculatedScores>();
            calculatedScoresMap.set(eventOrRoundId, calculatedScores);
            const competitionScores = Scoring.getGolferScores(eventOrRound, competitions, golfers, teams, groups, golferScores, teamScores, reportedScores, reportedTeamScores, mainCompetition);
            competitionScores.forEach((competitionScore, competitionId) => calculatedScores.set(competitionId, competitionScore));
        }
    }

    private buildCompetitionsForDisplay = () => {
        const { event, rounds, competitionsMap, calculatedScoresMap } = this.state;
        if (!event) {
            return;
        }
        const t0 = Date.now();
        const totalMode = event.type === 'multiday';
        let allCompetitions = new Array<Competition>();
        const events = rollEvents(event, rounds);
        events.forEach(eventOrRound => {
            const roundCompetitions = competitionsMap.get(eventOrRound.id);
            if (roundCompetitions) {
                allCompetitions = allCompetitions.concat(roundCompetitions);
            }
        });
        
        const competitonScores = new Array<CompetitionScores>();
        const mainCompetitions = sortCompetitions(allCompetitions.filter(competition => isMainScoring(competition.scoring)), totalMode);
        const competitionsSideGames = sortCompetitions(allCompetitions.filter(competition => !isMainScoring(competition.scoring)), totalMode);
        const prepareRoundScores = (competition: Competition, flight: number) => {
            if (!competition.eventOrRoundId) {
                return;
            }
            if (totalMode && hasFirstRoundCompetition(competition, allCompetitions)) {
                return;
            }
            const roundsCompetitions = totalMode ? getRoundsCompetitions(competition, allCompetitions) : [competition];
            const roundsScores = totalMode ?
                Scoring.combineCompetitionScores(roundsCompetitions, flight, calculatedScoresMap) :
                Scoring.getCompetitionScores(roundsCompetitions, flight, calculatedScoresMap);
            const { eventOrRound, golfers, competitions, groups, mainCompetition, teams, teamScores, golferScores, reportedScores, reportedTeamScores, distances } = this.getStaff(competition.eventOrRoundId);
            if (!eventOrRound) {
                return;
            }
            const skinsMixed = !!competitions.find(c => c.scoring.format === ScoringFormatTeams.best_ball);
            const roundSuffix = (isRound(eventOrRound) ? ` (Round ${eventOrRound.roundOrder})` : '');
            const competitionName = isDistanceScoring(competition.scoring) ?
                Scoring.scoringName(competition) + roundSuffix :
                isSkinsScoring(competition.scoring) ?
                    Scoring.scoringName(competition, eventOrRound.eventGender, competition.competitionGender, skinsMixed) + roundSuffix :
                    Scoring.scoringName(competition, eventOrRound.eventGender, competition.competitionGender, skinsMixed, flight, competition.flightsNaming);
            const competitionLength = isSkinsScoring(competition.scoring) || isDistanceScoring(competition.scoring) ?
                getHolesCount(competition, eventOrRound.holesType) :
                getCompetitionFlightScoresCount(competition, golfers, teams, flight);
            const payoutSettings = getPayouts(competition, competition.scoring.mode, flight);
            //used for distance: payoutSettings = isNet && competition.payoutsNet ? competition.payoutsNet[0] : competition.payoutsGross ? competition.payoutsGross[0] : undefined;
            const noTees = !hasTees(eventOrRound, competition) && eventOrRound.type !== 'leaderboard';
            const withWinnings = !!payoutSettings && payoutSettings.enabled && isFullScoresCompetition(eventOrRound, competition, golferScores, teamScores, reportedScores, golfers, teams);
            // used fr skins: withWinnings = (isNetPayouts(competition) || isGrossPayouts(competition)) && isFullScoresCompetition(eventOrRound, competition, golferScores, teamScores, reportedScores, golfers, teams);
            const sameNameGolfersIdsSet = getSameNameGolfersIds(Array.from(golfers.values()));
            const compatibleCompetitionSkins = !!mainCompetition && isCompatibleCompetitionSkins(competition, mainCompetition);
            const winnings = new Map<string, number>();
            const skins = isSkinsScoring(competition.scoring) ? getSkinsScores(eventOrRound, competition, roundsScores[0], getTee(eventOrRound, mainCompetition, genderFromEvent(eventOrRound))) : [];
            const skinsWinnings = new Map<number, number | undefined>();
            const distanceInfo = isDistanceScoring(competition.scoring) ? getDistanceScores(eventOrRound, competition, golfers, distances, getTee(eventOrRound, mainCompetition, genderFromEvent(eventOrRound))) : [];
            // TODO: winnings for multiday
            if (isSkinsScoring(competition.scoring)) {
                const allWinners = skins.filter(s => s.contacts.length === 1).length;
                skins.forEach(skin =>
                    skinsWinnings.set(skin.hole, withWinnings ? skinCompetitionAward(skin, competition, skins, golfers, teams, allWinners) : undefined));
            } else if (isTeamFormat(competition.scoring)) {
                if (competition.scoring.format !== ScoringFormatTeams.best_ball) {
                    teamsOf(competition, golfers, teams).forEach(team =>
                        winnings.set(team.id, teamScores.has(team.id) ?
                            golferMainCompetitionAward(team.id, eventOrRound, competition, golferScores, teamScores, reportedScores, reportedTeamScores, golfers, teams, groups, mainCompetition) : 0));
                } else {
                    teamsOf(competition, golfers, teams).forEach(team =>
                        winnings.set(team.id, team.contactIds.reduce((arr, curr) => arr.length > 0 || !golferScores.has(curr) ? curr : '', '').length === 0 ?
                            golferMainCompetitionAward(team.id, eventOrRound, competition, golferScores, teamScores, reportedScores, reportedTeamScores, golfers, teams, groups, mainCompetition) : 0));
                }
            } else {
                golfersOfCompetition(competition, golfers, teams).forEach(contact =>
                    winnings.set(contact.id, golferScores.has(contact.id) ? golferMainCompetitionAward(contact.id, eventOrRound, competition, golferScores, teamScores, reportedScores, reportedTeamScores, golfers, teams, groups, mainCompetition) : 0));
            }
            const contactsWithRoundsScores = Scoring.contactsWithRoundsScoresOf(roundsScores[0].map(rs => rs.uniqueId), roundsScores, competition);
            competitonScores.push({
                scoring: competition.scoring,
                competitionName,
                flight,
                contactsWithRoundsScores,
                winnings,
                skinsWinnings,
                payoutSettings,
                noTees,
                withWinnings,
                skinsMixed,
                compatibleCompetitionSkins,
                reportedScores,
                reportedTeamScores,
                sameNameGolfersIdsSet,
                competitionLength,
                skins,
                distanceInfo,
                roundsCompetitions
            });
        }
        const prepareCompetition = (competition: Competition) => {
            if (competition.flights) {
                for (let flight = 1; flight <= competition.flights; flight) {
                    prepareRoundScores(competition, flight);
                }
            } else {
                prepareRoundScores(competition, 0);
            }
        };
        mainCompetitions.forEach(competition => {
            if (isNetMode(competition.scoring.mode)) {
                prepareCompetition(Scoring.netCompetition(competition));
            }
            if (isGrossMode(competition.scoring.mode)) {
                prepareCompetition(Scoring.grossCompetition(competition));
            }
        });
        competitionsSideGames.forEach(competition => prepareCompetition(competition));
        const t1 = Date.now();
        dbgLog(`CompetitionsForDisplay allCompetitions: ${allCompetitions.length}, competitonScores: ${competitonScores.length}, t1: ${t1 - t0}`);
        this.setState({ competitonScores });
    }

    private update = () => {
        const { event, competitonScores } = this.state;
        let { curCompetition, groupOffset } = this.state;
        if (event && curCompetition < competitonScores.length) {
            const competitionScore = competitonScores[curCompetition];
            if (groupOffset + NAX_GROUP < competitionScore.competitionLength) {
                groupOffset += NAX_GROUP;
            } else {
                curCompetition++;
                if (curCompetition === competitonScores.length) {
                    curCompetition = 0;
                }
                groupOffset = 0;
            }
        } else {
            curCompetition = 0;
            groupOffset = 0;
        }
        this.setState({ curCompetition, groupOffset });
    }

    Competition = (params: { event: Event, portal: Portal, competitionScore: CompetitionScores }) => {
        const { event, portal, competitionScore } = params;
        return <>
            {isDistanceScoring(competitionScore.scoring) ?
                this.DistanceCompetition({ event, portal, competitionScore }) :
                isSkinsScoring(competitionScore.scoring) ?
                    this.SkinsCompetition({ event, portal, competitionScore }) :
                    this.ScoreCompetition({ event, portal, competitionScore })
            }
        </>;
    }

    SkinsCompetition = (params: { event: Event, portal: Portal, competitionScore: CompetitionScores }) => {
        const { event, portal, competitionScore } = params;
        const { groupOffset } = this.state;
        if (!competitionScore.compatibleCompetitionSkins) {
            return null;
        }
        let skins = competitionScore.skins;
        if (!TEST_MODE) {
            skins = skins.slice(groupOffset, groupOffset + NAX_GROUP);
        }
        return <>
            <Title
                portal={portal}
                eventName={event.name}
                competitionName={competitionScore.competitionName} />
            {competitionScore.noTees ?
                <HeaderNoTees /> :
                <List disablePadding>
                    <SkinsHeader scoring={competitionScore.scoring} withWinnings={competitionScore.withWinnings} />
                    {skins.map((skin, i) =>
                        <SkinsRow
                            teamSize={event.teamSize}
                            key={skin.hole}
                            last={i === skins.length - 1}
                            {...skin}
                            winning={competitionScore.skinsWinnings.get(skin.hole)}
                            sameNameGolfersIdsSet={competitionScore.sameNameGolfersIdsSet}
                        />)}
                    <Transparent size={NAX_GROUP - skins.length} />
                </List>}
            <Spacing />
        </>;
    }

    DistanceCompetition = (params: { event: Event, portal: Portal, competitionScore: CompetitionScores }) => {
        const { event, portal, competitionScore } = params;
        const { groupOffset, units } = this.state;
        let distanceInfo = competitionScore.distanceInfo;
        if (!TEST_MODE) {
            distanceInfo = distanceInfo.slice(groupOffset, groupOffset + NAX_GROUP);
        }
        const payoutSettings = competitionScore.payoutSettings;
        const payoutPerHole = payoutSettings?.payoutPerHole;
        const payoutsEligible = Boolean(payoutSettings?.enabled) && payoutPerHole != null && payoutPerHole >= 0;
        return <>
            <Title
                portal={portal}
                eventName={event.name}
                competitionName={competitionScore.competitionName} />
            {competitionScore.scoring.format === ScoringFormatDistance.longest_drive ?
                <List disablePadding>
                    <LDHeader withWinnings={payoutsEligible && distanceInfo.findIndex(info => info.contacts && info.contacts.length > 0) > -1} />
                    {distanceInfo.map((info, index) =>
                        <LDRow
                            key={info.hole}
                            last={index === distanceInfo.length - 1}
                            {...info}
                            winning={(payoutsEligible && info.contacts && info.contacts.length > 0) ? payoutPerHole : undefined}
                            sameNameGolfersIdsSet={competitionScore.sameNameGolfersIdsSet}
                        />)}
                    <Transparent size={NAX_GROUP - distanceInfo.length} />
                </List > : <List disablePadding>
                    <KPHeader withWinnings={payoutsEligible && distanceInfo.findIndex(info => info.contacts && info.contacts.length > 0) > -1} />
                    {distanceInfo.map((info, index) =>
                        <KPRow
                            key={info.hole}
                            last={index === distanceInfo.length - 1}
                            {...info}
                            units={units}
                            winning={(payoutsEligible && info.contacts && info.contacts.length > 0) ? payoutPerHole : undefined}
                            sameNameGolfersIdsSet={competitionScore.sameNameGolfersIdsSet}
                        />)}
                    <Transparent size={NAX_GROUP - distanceInfo.length} />
                </List >}
            <Spacing />
        </>;
    }

    ScoreCompetition = (params: { event: Event, portal: Portal, competitionScore: CompetitionScores }) => {
        const { classes } = this.props;
        const { event, portal, competitionScore } = params;
        const { groupOffset, rounds } = this.state;
        const scoreRounds = competitionScore.roundsCompetitions.length > 1 ? competitionScore.roundsCompetitions.map(rc => rc.roundOrder!) : undefined;
        let scoresSlice = competitionScore.contactsWithRoundsScores;
        if (!TEST_MODE) {
            scoresSlice = scoresSlice?.slice(groupOffset, groupOffset + NAX_GROUP);
        }
        if (!scoresSlice) {
            return null;
        }
        const withTieBreaker = () => scoresSlice.find(s => !!s.winnerIn) ? <Typography className={`${classes.tvListItem} ${classes.tvTieBreaker}`}>*Winner by tie-breaker<br /><br /></Typography> : undefined;
        const isTeamFormatExceptBB = isTeamScoringExceptBB(competitionScore.scoring);
        const roundNo = getCurrentRound(event, rounds)?.roundOrder ?? 1;
        return <>
            <Title
                portal={portal}
                eventName={event.name}
                competitionName={competitionScore.competitionName} />
            {competitionScore.noTees ?
                <HeaderNoTees /> :
                <>
                    <List disablePadding>
                        <HeaderScore
                            roundNo={roundNo}
                            scoring={competitionScore.scoring}
                            withWinnings={competitionScore.withWinnings}
                            scoreRounds={scoreRounds}
                        />
                        {scoresSlice.map((contactRoundsScores, idx) => <RowScore
                            key={idx}
                            index={idx}
                            roundNo={roundNo}
                            last={idx === scoresSlice.length - 1}
                            scoring={competitionScore.scoring}
                            winning={competitionScore.withWinnings ? competitionScore.winnings.get(contactRoundsScores.scoringStates[0]?.contactId ?? '') : undefined}
                            contactRoundsScores={contactRoundsScores}
                            reportedScores={isTeamFormatExceptBB ? competitionScore.reportedTeamScores : competitionScore.reportedScores}
                            sameNameGolfersIdsSet={competitionScore.sameNameGolfersIdsSet}
                        />)}
                        {withTieBreaker()}
                        <Transparent size={NAX_GROUP - scoresSlice.length} />
                    </List>
                </>}
            <Spacing />
        </>;
    }

    HideLiveScores = () => {
        const { event, portal } = this.state;
        return <>
            <Title portal={portal!} eventName={event?.name ?? ''} competitionName={''} />
            <List disablePadding><HeaderNoScore /></List>
        </>;
    }

    render() {
        const { eventId } = this.state;
        if (eventId === undefined) {
            return <NoEvent text="Loading" />;
        }
        if (eventId === '') {
            return <NoEvent />;
        }
        const { classes } = this.props;
        const { event, rounds, portal, competitonScores, curCompetition } = this.state;
        let content;
        if (!event || !portal) {
            content = <NoEvent text="Loading" />;
        } else if (!event.exists || event.deleted) {
            content = <NoEvent />;
        } else {
            const hideLiveScores = !event || event.hideLiveScores === true || event.hideLiveScores === 'OFF';
            let comps = [];
            if (TEST_MODE) {
                comps = competitonScores;
            } else if (competitonScores[curCompetition]) {
                comps.push(competitonScores[curCompetition]);
            }
            const multiday = event.type === 'multiday';
            const events = rollEvents(event, rounds);
            content = <div className={classes.tvRoot} onClick={() => { this.update() }} onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => { if (e.key === 'ArrowRight' || e.key === 'Enter' || e.key === ' ') this.update() }} tabIndex={0} >
                <div className={classes.tvContent}>
                    {hideLiveScores && <this.HideLiveScores />}
                    {!hideLiveScores && comps.map((competitionScore, idx) => <this.Competition key={idx} event={event} portal={portal} competitionScore={competitionScore} />)}
                    {!hideLiveScores && competitonScores.length === 0 && <HeaderNoTees />}
                    <Footer event={event} />
                </div>
                {multiday && <FirebaseDataComponent
                    name="rounds"
                    query={Backend.eventRoundsQuery(event.id)}
                    onData={this.onRounds} />}
                {events.map(eventOrRound => <FirebaseDataComponent<Competition>
                    key={eventOrRound.id}
                    name="competitions"
                    queryPath={eventOrRound.id}
                    query={Backend.eventCompetitionsQuery(eventOrRound.id)}
                    onData={competitions => this.onCompetitions(eventOrRound, competitions)} />)}
                {events.map(eventOrRound => <FirebaseDataComponent<Contact>
                    key={eventOrRound.id}
                    name="golfers"
                    queryPath={eventOrRound.id}
                    query={Backend.eventGolfersQuery(eventOrRound.id)}
                    onMap={golfers => this.onGolfers(eventOrRound, golfers)} />)}
                {events.map(eventOrRound => <FirebaseDataComponent<Team>
                    key={eventOrRound.id}
                    name="teams"
                    queryPath={eventOrRound.id}
                    query={Backend.eventTeamsQuery(eventOrRound.id)}
                    onMap={teams => this.onTeams(eventOrRound, teams)} />)}
                {events.map(eventOrRound => <FirebaseDataComponent<GolferGroup>
                    key={eventOrRound.id}
                    name="groups"
                    queryPath={eventOrRound.id}
                    query={Backend.eventGroupsQuery(eventOrRound.id)}
                    onData={groups => this.onGroups(eventOrRound, groups)} />)}
                {!hideLiveScores && events.map(eventOrRound => <FirebaseDataComponent<Score>
                    key={eventOrRound.id}
                    name="golferScores"
                    queryPath={eventOrRound.id}
                    query={Backend.golferScoresDb(eventOrRound.id)}
                    onMap={scores => this.onGolferScores(eventOrRound, scores)} />)}
                {!hideLiveScores && events.map(eventOrRound => <FirebaseDataComponent<ReportedScore>
                    key={eventOrRound.id}
                    name="reportedGolferScores"
                    queryPath={eventOrRound.id}
                    query={Backend.reportedGolferScoresDb(eventOrRound.id)}
                    onMap={scores => this.onReportedGolferScores(eventOrRound, scores)} />)}
                {!hideLiveScores && events.map(eventOrRound => <FirebaseDataComponent<Score>
                    key={eventOrRound.id}
                    name="teamScores"
                    queryPath={eventOrRound.id}
                    query={Backend.golferTeamScoresDb(eventOrRound.id)}
                    onMap={scores => this.onTeamScores(eventOrRound, scores)} />)}
                {!hideLiveScores && events.map(eventOrRound => <FirebaseDataComponent<ReportedScore>
                    key={eventOrRound.id}
                    name="reportedTeamScores"
                    queryPath={eventOrRound.id}
                    query={Backend.reportedTeamScoresDb(eventOrRound.id)}
                    onMap={scores => this.onReportedTeamScores(eventOrRound, scores)} />)}
                {!hideLiveScores && events.map(eventOrRound => <FirebaseDataComponent<Distance>
                    key={eventOrRound.id}
                    name="distances"
                    queryPath={eventOrRound.id}
                    query={Backend.golferDistancesDb(eventOrRound.id)}
                    onMap={distances => this.onDistances(eventOrRound, distances)} />)}
                <FirebaseUserPubDataComponent uid={event.userId} onData={data => this.setState({ units: data.units })} />
            </div>;
        }
        return <>
            {content}
            <FirebaseDocComponent onDoc={doc => this.setEvent(Backend.fromEntity<Event>(doc))} docReference={Backend.eventFields(eventId)} />
            <FirebaseDocComponent onDoc={doc => this.setPortal(Backend.fromEntity<Portal>(doc))} docReference={Backend.portalFields(eventId)} />
        </>;
    }
}

export default withDataItemFromUrl(Backend.eventMappingDb)(withStyles(styles)(TVLeaderboard));
