import * as React from 'react';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { FormControl, DialogActions, DialogContent, InputLabel } from '@mui/material';
import { ScoringTeamSize, Competition, isIndividualScoring, isCompatibleWithTeamSize } from '../../../types/EventTypes';
import { teamSizeName } from '../../Event';
import { range } from '../../../util/utility';
import { processEnterKey } from '../../../util/react_utils';
import { XSMobileDialog } from '../../../common/dialog/MobileDialog';
import DialogAppBar from '../../../common/dialog/DialogAppBar';
import AppButton from '../../../common/components/AppButton';
import TypedFormRadioLabel from '../../../common/form/TypedFormRadioLabel';
import ConfirmDialog from '../../../common/dialog/ConfirmDialog';
import { styles } from '../../../styles';

type Props = WithStyles<typeof styles> & {
    close: () => void;
    teamSize: ScoringTeamSize;
    competitions: Array<Competition>;
    saveTeamSize: (newTeamSize: ScoringTeamSize, oldTeamSize: ScoringTeamSize) => void;
};

type State = {
    confirmingChange?: () => void;
    teamSize: ScoringTeamSize;
    open?: boolean;
};

class SelectTeamSizeDialog extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = { teamSize: props.teamSize };
    }

    private handleTeamsChange = (val: string) => this.setState({ teamSize: parseInt(val, 10) as ScoringTeamSize });
    private skipChange = () => this.setState({ confirmingChange: undefined });

    private handleSave = () => {
        const { competitions, saveTeamSize } = this.props;
        if (competitions.find(c => !isIndividualScoring(c.scoring) && !isCompatibleWithTeamSize(c, this.state.teamSize))) {
            this.setState({ confirmingChange: () => saveTeamSize(this.state.teamSize, this.props.teamSize) });
        } else {
            saveTeamSize(this.state.teamSize, this.props.teamSize);
        }
    }

    render() {
        const { classes, close } = this.props;
        const { teamSize } = this.state;
        return (
            <XSMobileDialog open={true} onClose={close}>
                <DialogAppBar label={'Teams settings'} close={close} />
                <DialogContent onKeyDown={uiEvent => processEnterKey(uiEvent, () => this.handleSave)} >
                    <FormControl
                        variant="standard"
                        margin="dense"
                        fullWidth
                        style={{ flexDirection: 'column' }}>
                        <InputLabel shrink={true}>Teams</InputLabel>
                        {range(1, 5).map(t => <TypedFormRadioLabel key={t} value={'' + t} label={teamSizeName(t as ScoringTeamSize)}
                            currentValue={'' + teamSize} handleChange={this.handleTeamsChange} className={classes.formSelector} />)}
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <AppButton color="info" onClick={close}>Cancel</AppButton>
                    <AppButton color="secondary" onClick={this.handleSave}>Save</AppButton>
                </DialogActions>
                {!!this.state.confirmingChange && <ConfirmDialog
                    open={!!this.state.confirmingChange}
                    onOk={this.state.confirmingChange}
                    onCancel={this.skipChange}
                    content="Some existing competitions or pairings use different team size and will be reset"
                    okLabel="Proceed" />}
            </XSMobileDialog>
        );
    }
}

export default withStyles(styles)(SelectTeamSizeDialog);
