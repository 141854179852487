import * as React from 'react';
import { DesktopDatePicker } from "@mui/x-date-pickers";
import moment, { Moment } from "moment";
import { IconButton, InputAdornment } from "@mui/material";
import DateRangeIcon from "@mui/icons-material/DateRange";
import { PopperPlacementType } from "@mui/base/Popper/Popper.types";

type Props = {
    value: number | null;
    onChange: (date: Date) => void;
    autoFocus?: boolean;
    label?: React.ReactNode;
    maxDate?: number;
    minDate?: number;
    className?: string;
    enableUnderline?: boolean;
    style?: React.CSSProperties;
    popperPlacement?: PopperPlacementType;
    icon?: boolean;
    disabled?: boolean;
    inputRef?: React.Ref<any>;
    onClose?: () => void;
    format?: string;
    shouldDisableDate?: (m: Moment) => boolean;
    disablePast?: boolean;
};

const MaterialDate = (props: Props) => {
    const [selectedDate, setSelectedDate] = React.useState(props.value ? new Date(props.value) : new Date());
    const [isOpen, setIsOpen] = React.useState(false);
    const { maxDate, minDate, onChange, autoFocus, className, style, onClose, disabled, icon, enableUnderline,
        format, label, inputRef, popperPlacement, shouldDisableDate, disablePast } = props;
    const endAdornment = icon ?
        <InputAdornment position="end">
            <IconButton tabIndex={-1} color="secondary" disabled={disabled} size="large"><DateRangeIcon /></IconButton>
        </InputAdornment> : null;
    return (<DesktopDatePicker
        shouldDisableDate={shouldDisableDate}
        open={isOpen}
        orientation='portrait'
        closeOnSelect
        slotProps={{
            textField: {
                variant: 'standard',
                InputProps: {
                    style: style ?? { width: 120 },
                    disableUnderline: !enableUnderline,
                    endAdornment
                },
                InputLabelProps: { shrink: true },
                onClick: () => setIsOpen(true)
            },
            toolbar: {
                toolbarFormat: "MMMM D, dddd",
            },
            popper: {
                placement: popperPlacement ?? 'bottom-start',
            }
        }}
        ref={inputRef}
        className={className}
        format={format ?? "D-MMM-YYYY"}
        value={moment(selectedDate)}
        minDate={minDate ? moment(minDate) : undefined}
        maxDate={maxDate ? moment(maxDate) : undefined}
        disablePast={disablePast ?? false}
        label={label}
        autoFocus={autoFocus}
        onChange={moment => setSelectedDate(moment?.toDate() ?? new Date())}
        onAccept={moment => onChange(moment?.toDate() ?? new Date())}
        onClose={() => {
            setIsOpen(false);
            onClose?.();
        }}
        sx={style}
        disabled={disabled}
    />);
}

export default MaterialDate;
