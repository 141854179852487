import * as React from 'react';
import { ChangeEvent, ReactNode } from 'react';
import Typography from '@mui/material/Typography';
import { ValidateProps } from '../../../../validation/ValidatedForm';
import { CommonEditDialogProps, EditDialogProps } from '../../../../common/form/WithEditDialog';
import ValidatedTextField, { minLength, regexp, required, maxLength, ValidatedTextFieldProps } from '../../../../validation/ValidatedTextField';
import { toSafeString } from '../../../../util/utility';
import withEditableField from '../../../../common/form/WithEditField';

type Props = EditDialogProps<string> & ValidatedTextFieldProps & CommonEditDialogProps<string> & ValidateProps;

class InnerField extends React.Component<Props> {

    private handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { label } = this.props;
        const val = toSafeString(event.target.value.trim().toUpperCase());
        if (this.props.validateField) {
            this.props.validateField(label, val)
        }
        this.props.setValue(val);
    }

    render() {
        const { label, hint, register, value, rules } = this.props;
        const onChange = this.handleChange;
        const localRules = [
            required,
            regexp(/^[a-zA-Z0-9-]*$/, 'Event id can only include letters, numbers and hyphens.'),
            minLength(5),
            maxLength(40),
        ];
        return <>
            <Typography variant="caption">
                {hint}
                Example: {new Date().getFullYear() + '-CLUB-OPEN'}.
            </Typography>
            <ValidatedTextField textFieldProps={{
                id: label, label, placeholder: hint, value, margin: 'normal', onChange, InputLabelProps: { shrink: true }, autoFocus: true
            }} register={register} keepError rules={rules ? localRules.concat(rules) : localRules} />
        </>;
    }
}

export function getEditIdField(toShow: (val: string) => string | ReactNode) {
    return withEditableField<ValidatedTextFieldProps, string>({ toShow })(InnerField);
}
