import * as React from 'react';
import { DialogProps } from '@mui/material/Dialog';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { FormControl, DialogActions, Typography, Radio, RadioGroup, FormControlLabel } from '@mui/material';
import { Event, Contact, EventGender, EVENT_GENDER_LABELS } from '../../../../types/EventTypes';
import { EventDeps, deleteGolfersFromEvent, elog } from '../../../Event';
import { XSMobileDialog } from '../../../../common/dialog/MobileDialog';
import DialogAppBar from '../../../../common/dialog/DialogAppBar';
import AppButton from '../../../../common/components/AppButton';
import { showAlert } from '../../../../redux/ReduxConfig';
import { Spacing } from '../../../../common/Misc';
import { styles } from '../../../../styles';
import * as Utils from '../../../../util/utility';
import { withProgress } from 'src/util/ProgressPromise';

type Props = { event: Event; } & WithStyles<typeof styles> & DialogProps;
type State = { eventGender: EventGender; };

class EventGenderSettingsDialog extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            eventGender: props.event.eventGender || 'both',
        };
    }

    private handleSave = async () => {
        const { event } = this.props;
        const { eventGender } = this.state;
        if (eventGender === 'men' || eventGender === 'women') {
            const deps = new EventDeps();
            await deps.loadGolfers(event);
            const excludeGolfersMap = new Map<string, Contact>();
            for (const eventOrRound of deps.eventAndRounds) {
                const golfers = deps.golfersArray(eventOrRound.id);
                const excludeGolfers = (eventGender === 'men' ? golfers.filter(g => g.gender === 'female') : golfers.filter(g => g.gender === 'male'));
                excludeGolfers.forEach(g => excludeGolfersMap.set(g.id, g));
            }
            if (excludeGolfersMap.size > 0) {
                const countStr = eventGender === 'men' ?
                    Utils.withIs(excludeGolfersMap.size, 'women') :
                    Utils.withIs(excludeGolfersMap.size, 'men');
                const theyStr = excludeGolfersMap.size === 1 ?
                    (eventGender === 'men' ? 'she' : `he`) :
                    ('they');
                showAlert(`There ${countStr} already added in the event. If you proceed with this change, ${theyStr} will be deleted. Please confirm the change.`, [
                    { title: 'Cancel' },
                    { title: 'Delete golfers', color: 'secondary', action: () => this.finishSave(Array.from(excludeGolfersMap.values())) }
                ]);
            } else {
                this.finishSave();
            }
        } else {
            this.finishSave();
        }
    }

    private finishSave = (golfersToDelete?: Array<Contact>) => {
        const { event } = this.props;
        const { eventGender } = this.state;
        withProgress(deleteGolfersFromEvent(event, golfersToDelete ?? [], eventGender))
            .then(() => elog(event, `Gender settings modified`, eventGender === 'both' ? 'men and women' : `${eventGender}. ${golfersToDelete ?? 'No'} golfers deleted.`, `Id: ${event.id}`))
            .then(this.handleClose);
    }

    private handleClose = () => {
        const close = this.props.onClose;
        if (close) {
            close({} as React.SyntheticEvent<any>, 'escapeKeyDown');
        }
    }

    render() {
        const { classes, open } = this.props;
        const { eventGender } = this.state;
        return <>
            <XSMobileDialog open={open} onClose={this.handleClose} maxWidth="sm" fullWidth={true}>
                <DialogAppBar label="Gender" close={this.handleClose} />
                <div style={{ padding: '16px' }}>
                    <FormControl
                        fullWidth
                        margin="dense"
                        variant="standard"
                        style={{ flexDirection: 'column' }}>
                        <Typography gutterBottom>Golfers in this event are:</Typography>
                        <RadioGroup value={eventGender}
                            onChange={e => this.setState({ eventGender: e.target.value as EventGender })}>
                            <FormControlLabel
                                value={'both'}
                                control={<Radio color="primary" />}
                                label={EVENT_GENDER_LABELS[0]} />
                            <FormControlLabel
                                value={'men'}
                                control={<Radio color="primary" />}
                                label={EVENT_GENDER_LABELS[1]} />
                            <FormControlLabel
                                value={'women'}
                                control={<Radio color="primary" />}
                                label={EVENT_GENDER_LABELS[2]} />
                        </RadioGroup>
                        <Spacing />
                    </FormControl>
                </div>
                <DialogActions>
                    <AppButton color="info" onClick={this.handleClose}>Cancel</AppButton>
                    <AppButton color="secondary" className={classes.iconButton} onClick={this.handleSave}>Save</AppButton>
                </DialogActions>
            </XSMobileDialog>
        </>;
    }
}

export default withStyles(styles)(EventGenderSettingsDialog);
