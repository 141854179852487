import * as React from 'react';
import { DialogProps } from '@mui/material/Dialog';
import { useTheme, useMediaQuery } from '@mui/material';
import { HandledDialog } from './BackHandler';

export const XSMobileDialog = (props: DialogProps) => {
    const { ref, ...other } = props;
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    return (<HandledDialog fullScreen={fullScreen} fullWidth={true} {...other} />);
};

export const SMMobileDialog = (props: DialogProps) => {
    const { ref, ...other } = props;
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    return (<HandledDialog fullScreen={fullScreen} fullWidth={true} {...other} />);
};
