import React, { useEffect } from "react";
import { FUNDING, PayPalButtons, usePayPalScriptReducer } from "@paypal/react-paypal-js";
import { CreateOrderData, CreateOrderActions, OnApproveData, OnApproveActions } from "@paypal/paypal-js/types/components/buttons";
import { LinkDescription, OrderResponseBody, OrderResponseBodyMinimal } from "@paypal/paypal-js/types/apis/orders";
import axios, { AxiosError, AxiosRequestConfig } from "axios";
import { Urls } from "../../util/config";
import { PayPalCaptureOrderRequest, PayPalCreateOrderRequest } from "./PayPalRequests";
import { showError } from "../../redux/ReduxConfig";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { styles } from "../../styles";
import { createPaymentDoc, onContactTheOrganizerClick } from "../../event/Event";
import { Contact, Event } from "../../types/EventTypes";
import AppButton from "../../common/components/AppButton";
import * as Backend from "../../util/firebase";
import { golferDb, setWithMergeInTransaction } from "../../util/firebase";

type PayPalPaymentProps = {
    feeCost: number;
    payeeEmailAddress: string;
    eventId: string;
    contact: Contact;
    currencyCode: string;
    onPaymentSuccess: (withException: boolean, paymentDocId?: string) => void;
    onPaymentFailure: () => void;
};

type PayPalPaymentComponentProps = PayPalPaymentProps & WithStyles<typeof styles> &
{ style: React.CSSProperties, event: Event, skipPaymentAvailable: boolean, onRegisterWithoutPayment: () => void; };

export const PayPalPayment = withStyles(styles)((props: PayPalPaymentComponentProps) => {
    const { classes, style, event, skipPaymentAvailable, onRegisterWithoutPayment, ...other } = props;
    const isXs = useMediaQuery(useTheme().breakpoints.down('sm'));
    const [showPaymentSkipping, setShowPaymentSkipping] = React.useState(false);
    const eventName = event.name ?? '';
    const adminEmail = event.userEmail ?? '';
    const [{ options }, dispatch] = usePayPalScriptReducer();
    useEffect(() => {
        dispatch({
            type: "resetOptions",
            value: {
                ...options,
                currency: other.currencyCode,
            },
        });
    }, [other.currencyCode]);
    return (
        <Box display="flex" flexDirection="column" style={style}>
            <Box display="flex" flexDirection={isXs ? 'column' : 'row'} justifyContent="space-between">
                <Box width={isXs ? '100%' : '38%'}>
                    <PayPalButtons style={{ shape: 'pill', layout: 'horizontal', height: 48 }}
                        fundingSource={FUNDING.PAYPAL}
                        createOrder={(data, actions) => createOrder(data, actions, other)}
                        onApprove={(data, actions) => onApprove(data, actions, other)} />
                </Box>
                <Box width={isXs ? '100%' : '60%'}>
                    <PayPalButtons style={{ shape: 'pill', layout: 'horizontal', height: 48 }}
                        fundingSource={FUNDING.CARD}
                        createOrder={(data, actions) => createOrder(data, actions, other)}
                        onApprove={(data, actions) => onApprove(data, actions, other)} />
                </Box>
            </Box>
            <Box height={8} />
            <Typography style={{
                fontSize: 12, lineHeight: '18px', fontStyle: 'italic', display: 'flex',
                justifyContent: 'center', alignItems: 'center'
            }}>
                Powered by&nbsp;<img src={Urls.payPalLogoFull} alt={''} />
            </Typography>
            {skipPaymentAvailable && <Box height={16} />}
            {skipPaymentAvailable && <Box sx={{ backgroundColor: '#F5F5F5' }} flexDirection="column" display="flex"
                borderRadius="4px" padding="16px">
                <Box display="flex" flexDirection="row" justifyContent="space-between">
                    <Box className={classes.boldLabel} style={{ textAlign: 'left' }}>Skip payment for now</Box>
                    <Box width="24px" height="24px">
                        <img onClick={() => setShowPaymentSkipping(!showPaymentSkipping)} src={Urls.iconSwitch} alt=""
                            style={{
                                cursor: 'pointer', transform: showPaymentSkipping ? 'rotate(180deg)' : undefined
                            }} />
                    </Box>
                </Box>
                {showPaymentSkipping && <Box display="flex" flexDirection="column">
                    <Box height={8} />
                    <Typography style={{ fontSize: 14, lineHeight: '21px' }}>
                        Please coordinate payment with the tournament organizer directly.
                    </Typography>
                    <Box height={8} />
                    <Box display="flex" flexDirection="column" width="60%" marginTop={1}>
                        <AppButton color="secondary" onClick={onRegisterWithoutPayment}>
                            Register without payment
                        </AppButton>
                        <Box height={8} />
                        <AppButton color="info" onClick={() => onContactTheOrganizerClick(eventName, adminEmail)}>
                            Contact the organizer
                        </AppButton>
                    </Box>
                </Box>}
            </Box>}
        </Box>
    );
});

const requestConfig: AxiosRequestConfig = {
    headers: {
        "Content-Type": "application/json",
    }
};

const createOrder = async (data: CreateOrderData, actions: CreateOrderActions, props: PayPalPaymentProps) => {
    const { feeCost, payeeEmailAddress, currencyCode } = props;
    const response = await executeCreateOrderRequest(feeCost, payeeEmailAddress, currencyCode);
    return response?.id ?? '';
};

const executeCreateOrderRequest = async (feeCost: number, payeeEmailAddress: string, currencyCode: string) => {
    const createOrderRequest: PayPalCreateOrderRequest = {
        description: "An Event's tournament membership entry fee.",
        cost: feeCost.toString(10),
        payeeEmailAddress,
        currencyCode
    };
    try {
        const res = await axios.post(Urls.createPaypalOrder, createOrderRequest, requestConfig);
        return res.data as OrderResponseBodyMinimal;
    } catch (err) {
        if (err instanceof AxiosError) {
            const errData = err.response?.data as PayPalErrorResponseData;
            const errorDetail = errData?.details?.[0];
            const errorMessage = errorDetail
                ? `${errorDetail.issue} ${errorDetail.description} (${errData.debug_id})`
                : JSON.stringify(errData);
            showError(<Typography>Could not initiate PayPal Checkout...<br /><br />{errorMessage}</Typography>);
        } else {
            showError(<Typography>An error occurred while processing payment. Please, try again later.</Typography>);
        }
        return undefined;
    }
};

export const generatePaymentLink = async (feeCost: number, payeeEmailAddress: string, currencyCode: string) => {
    const response = await executeCreateOrderRequest(feeCost, payeeEmailAddress, currencyCode);
    return response?.links?.filter((link: any) => link.rel === 'approve')?.[0]?.href ?? undefined;
};

const onApprove = async (data: OnApproveData, actions: OnApproveActions, props: PayPalPaymentProps) => {
    const captureOrderRequest: PayPalCaptureOrderRequest = {
        orderId: data.orderID
    };
    const { eventId, contact, payeeEmailAddress, feeCost, currencyCode, onPaymentSuccess, onPaymentFailure } = props;
    let orderData: OrderResponseBody | undefined = undefined;
    try {
        const res = await axios.post(Urls.capturePaypalOrder, captureOrderRequest, requestConfig);
        orderData = res.data;
        //console.log('capture res', res, 'orderData', JSON.stringify(orderData, null, 2));
        if (!orderData?.purchase_units) {
            showError(<Typography>Sorry, your transaction could not be processed...<br /><br />No purchase units found.</Typography>);
            return;
        }
        const paymentCapture = orderData?.purchase_units?.[0]?.payments?.captures?.[0] as PayPalCapture | undefined;
        const platformFee = paymentCapture && parseFloat(paymentCapture.seller_receivable_breakdown?.paypal_fee?.value ?? '0');
        const grossPaymentAmount = paymentCapture && parseFloat(paymentCapture.amount?.value ?? '0');
        const timeStampUTC = paymentCapture && Date.parse(paymentCapture.create_time);
        if (orderData?.status === 'COMPLETED') {
            await Backend.withTransaction(async transaction => {
                const paymentDocId = createPaymentDoc(transaction, eventId, contact, grossPaymentAmount || feeCost,
                    currencyCode, payeeEmailAddress, platformFee || 0, timeStampUTC ?? Date.now());
                if (contact.id) {
                    setWithMergeInTransaction(
                        golferDb(eventId),
                        { paymentId: paymentDocId },
                        transaction,
                        contact.id
                    );
                }
                onPaymentSuccess(false, paymentDocId);
            });
        }
    } catch (err) {
        console.log(err);
        if (err instanceof AxiosError) {
            const errData = err.response?.data as PayPalErrorResponseData;
            const errorDetail = errData?.details?.[0];
            if (errorDetail?.issue === "INSTRUMENT_DECLINED") {
                return actions.restart();
            } else if (errorDetail) {
                const { issue, description } = errorDetail;
                showError(<Typography>Sorry, your transaction could not be processed...<br /><br />{(issue ? `${issue}: ` : '') + (description ?? '')}</Typography>);
            }
            onPaymentFailure();
            return;
        }
        if (orderData?.status === 'COMPLETED') {
            onPaymentSuccess(true);
        } else {
            showError(<Typography>An error occurred while processing payment. Please, try again later.</Typography>);
            onPaymentFailure();
        }
    }
};

type PayPalErrorResponseData = {
    debug_id: string;
    details?: Array<{ description: string, issue: string }>;
    links: LinkDescription[];
    message: string;
    name: string;
};

type PayPalCapture = {
    create_time: string;
    amount: {
        currency_code: string,
        value: string
    },
    seller_receivable_breakdown: {
        paypal_fee: {
            currency_code: string,
            value: string
        },
    }
};
