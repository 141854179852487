import * as React from 'react';
import Typography from '@mui/material/Typography';
import { ReactNode } from 'react';
import { Grid, ListItem, useMediaQuery } from '@mui/material';
import { useTheme, WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { styles } from '../../styles';

export interface LabeledFieldProperties {
    label: string;
    value: string | ReactNode;
}

type Props = LabeledFieldProperties & WithStyles<typeof styles>;

const LabeledField2 = (props: Props) => {
    const { label, value, classes } = props;
    const theme = useTheme();
    const variant = useMediaQuery(theme.breakpoints.up('xs')) ? 'body2' : 'caption';
    const val = (typeof value === 'string') ? (<Typography variant="body1" noWrap>{value}</Typography>) : value;
    return (
        <div>
            <ListItem className={classes.listItem1}>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={12} md={5} lg={3} xl={2}><Typography variant={variant} className={classes.boldText} noWrap>{label}</Typography></Grid>
                    <Grid item xs={12} sm={12} md={7} lg={9} xl={10}>{val}</Grid>
                </Grid>
            </ListItem>
        </div>
    );
}

export default withStyles(styles)(LabeledField2);
