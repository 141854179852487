import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import { SyntheticEvent } from 'react';
import * as History from 'history';

interface Handler {
    id: number;
    handle: () => void;
}

const handlers: Handler[] = [];

function addHandler(handler: Handler) {
    if (!handlers.find(h => h.id === handler.id)) {
        handlers.push(handler);
        return true;
    } else {
        return false;
    }
}

function removeHandler(handler: Handler) {
    let index = handlers.findIndex(h => h.id === handler.id);
    if (index >= 0) {
        handlers.splice(index, 1);
        return true;
    } else {
        return false;
    }
}

class BackHandler extends React.Component<RouteComponentProps<any>> {
    private unregister!: () => void;

    componentDidMount() {
        this.unregister = this.props.history.block((location: History.Location, action: History.Action) => {
            if (handlers.length > 0 && action === 'POP') {
                handlers[handlers.length - 1].handle();
                return false;
            }
            return;
        });
    }

    componentWillUnmount() {
        this.unregister();
    }

    render() {
        return null;
    }
}

export default withRouter(BackHandler);

export class HandledDialog extends React.Component<DialogProps> {
    private readonly handler: Handler;

    constructor(props: DialogProps) {
        super(props);
        const that = this;
        this.handler = {
            id: Math.floor((Math.random() * 1000000) + 1),
            handle: () => {
                if (!that.props.disableEscapeKeyDown) {
                    that.props.onClose!({} as SyntheticEvent<{}>, 'backdropClick');
                }
            }
        };

    }

    componentDidMount() {
        this.addOrRemoveHandler(this.props.open || false);
    }

    componentWillUnmount() {
        this.addOrRemoveHandler(false);
    }

    componentDidUpdate() {
        this.addOrRemoveHandler(this.props.open || false);
    }

    private addOrRemoveHandler(add: boolean) {
        if (add) {
            return addHandler(this.handler);
        } else {
            return removeHandler(this.handler);
        }
    }

    render() {
        return (<Dialog {...this.props} />);
    }
}
