import * as React from 'react';
import { FormControl, FormLabel, Radio, RadioGroup, FormControlLabel, Checkbox, DialogActions } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { DialogProps } from '@mui/material/Dialog';
import { Competition, Contact, EventGender, EVENT_GENDER_LABELS, Team, ScoringFormatSkins, isDistanceScoring, EventBase } from '../../../types/EventTypes';
import { getParticipantsName } from '../../Event';
import * as Utils from '../../../util/utility';
import { XSMobileDialog } from '../../../common/dialog/MobileDialog';
import DialogAppBar from '../../../common/dialog/DialogAppBar';
import AppButton from '../../../common/components/AppButton';
import SelectGolfersDialog from '../../tabs/common/SelectGolfersDialog';
import SelectTeamsDialog from '../../tabs/common/SelectTeamsDialog';
import { showAlert } from '../../../redux/ReduxConfig';
import { Spacing } from '../../../common/Misc';
import { styles } from '../../../styles';

function warn(text: string) {
    return 'There ' + text + ' already added to this competition. ' +
        'If you proceed with this change, they will remain in the event but will be removed from this competition. Please confirm the change.';
}

type Props = {
    event: EventBase;
    golfers: Map<string, Contact>;
    teams: Map<string, Team>;
    competition: Competition;
    handleSave: (competition: Competition) => void;
} & WithStyles<typeof styles> & DialogProps;

type State = {
    everyone: boolean;
    competitionGender: EventGender;
    contactIds?: Array<string>;
    selectingGolfers?: boolean;
};

class ParticipantsDialog extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            everyone: props.competition.everyone,
            contactIds: props.competition.contactIds,
            competitionGender: props.competition.competitionGender ?? props.event.eventGender ?? 'both'
        };
    }

    private handleClose = () => {
        const close = this.props.onClose;
        if (close) {
            close({} as React.SyntheticEvent<any>, 'escapeKeyDown');
        }
    }

    private handleSave = () => this.props.handleSave(this.getCompetition());
    private handleEveryoneChange = () => this.setState({ everyone: !this.state.everyone });
    private handleUpdateGolfers = (selected: Array<Contact>) => this.setState({ selectingGolfers: false, contactIds: selected.map(t => t.id) });
    private handleSelectTeams = (selected: Array<Team>) => this.setState({ selectingGolfers: false, contactIds: selected.map(team => team.id) });

    private SelectGolfersOrTeam = () => {
        const { event, competition, golfers, teams } = this.props;
        const { contactIds, competitionGender } = this.state;
        if (event.teamSize === 1 || isDistanceScoring(competition.scoring) ||
            competition.scoring.format === ScoringFormatSkins.skins_individual) {
            const selectedGolfersIds: Array<string> = contactIds ? contactIds.slice() : [];
            return (
                <SelectGolfersDialog
                    golfers={golfers}
                    label={'Select golfers'}
                    selectGender={competitionGender === 'men' ? 'male' : competitionGender === 'women' ? 'female' : ''}
                    handleSelect={this.handleUpdateGolfers}
                    handleCancel={() => this.setState({ selectingGolfers: false })}
                    selectedGolferIds={selectedGolfersIds} />
            );
        } else {
            const selectedTeamsIds: Array<string> = contactIds ? contactIds.slice() : [];
            return (
                <SelectTeamsDialog
                    event={event}
                    teams={teams}
                    golfers={golfers}
                    selectGender={competitionGender}
                    handleSelectTeams={this.handleSelectTeams}
                    handleCancel={() => this.setState({ selectingGolfers: false })}
                    selectedTeamsIds={selectedTeamsIds} />
            );
        }
    }

    getCompetition = () => {
        const { competition } = this.props;
        const { everyone, competitionGender, contactIds } = this.state;
        const competitionCopy = { ...competition } as Competition;
        competitionCopy.everyone = everyone;
        competitionCopy.competitionGender = competitionGender;
        if (contactIds?.length) {
            competitionCopy.contactIds = contactIds;
        }
        return competitionCopy;
    }

    setGender = (competitionGender: EventGender) => {
        const { golfers } = this.props;
        let { contactIds } = this.state;
        if (contactIds && competitionGender !== 'both') {
            const selectGender = competitionGender === 'men' ? 'male' : competitionGender === 'women' ? 'female' : '';
            const unselectGender = competitionGender === 'men' ? 'women' : competitionGender === 'women' ? 'men' : '';
            let count = contactIds.length;
            contactIds = contactIds.filter(contactId => golfers.get(contactId)?.gender === selectGender);
            count -= contactIds.length;
            if (count > 0) {
                showAlert(warn(Utils.withIs(count, unselectGender)), [
                    { title: 'Cancel' },
                    { title: 'Remove golfers', color: 'secondary', action: () => this.setState({ contactIds, competitionGender }) }
                ]);
                return;
            }
        }
        this.setState({ contactIds, competitionGender });
    }

    render() {
        const { classes, event, golfers, teams, open } = this.props;
        const { everyone, competitionGender, selectingGolfers } = this.state;
        return (
            <XSMobileDialog open={open} onClose={this.handleClose} maxWidth="sm" fullWidth={true}>
                <DialogAppBar label="Participants" close={this.handleClose} />
                <div style={{ padding: 16 }}>
                    {(event.eventGender || 'both') === 'both' &&
                        <FormControl
                            variant="standard"
                            component="fieldset"
                            fullWidth
                            style={{ flexDirection: 'column' }}>
                            <FormLabel component="legend">Gender</FormLabel>
                            <RadioGroup
                                value={competitionGender || 'both'}
                                style={{ marginLeft: 3 }}
                                onChange={e => this.setGender(e.target.value as EventGender)}>
                                <FormControlLabel
                                    value={'both'}
                                    control={<Radio color="primary" />}
                                    label={EVENT_GENDER_LABELS[0]} />
                                <FormControlLabel
                                    value={'men'}
                                    control={<Radio color="primary" />}
                                    label={EVENT_GENDER_LABELS[1]} />
                                <FormControlLabel
                                    value={'women'}
                                    control={<Radio color="primary" />}
                                    label={EVENT_GENDER_LABELS[2]} />
                            </RadioGroup>
                            <Spacing />
                        </FormControl>}
                    <FormControl
                        variant="standard"
                        component="fieldset"
                        fullWidth
                        style={{ flexDirection: 'column' }}>
                        <FormLabel component="legend">Golfers</FormLabel>
                        <FormControlLabel
                            style={{ marginTop: 8, marginLeft: 0 }}
                            label={competitionGender === 'men' ? 'All men' : competitionGender === 'women' ? 'All women' : 'Everyone'}
                            control={<Checkbox color="secondary" checked={everyone} onChange={this.handleEveryoneChange} />}
                        />
                    </FormControl>
                    <FormControlLabel
                        style={{ marginTop: 8 }}
                        label={getParticipantsName(this.getCompetition(), golfers, teams)}
                        labelPlacement="start"
                        control={<AppButton
                            color="info"
                            style={{ marginLeft: '1em' }}
                            className={everyone ? classes.invisible : ''}
                            onClick={() => this.setState({ selectingGolfers: true })}>Select</AppButton>}
                    />
                </div>
                <DialogActions>
                    <AppButton color="info" onClick={this.handleClose}>Cancel</AppButton>
                    <AppButton color="secondary" className={classes.iconButton} onClick={this.handleSave}>Save</AppButton>
                </DialogActions>
                {selectingGolfers && <this.SelectGolfersOrTeam />}
            </XSMobileDialog>
        );
    }
}

export default withStyles(styles)(ParticipantsDialog);
