import * as React from 'react';
import { WithStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import Avatar from '@mui/material/Avatar';
import withStyles from '@mui/styles/withStyles';

const photoIcon = '/img/userPhoto.svg';

const styles = (theme: Theme) => {
    return {
        avatar: {
            backgroundColor: theme.palette.background.default,
            color: theme.palette.grey[500]
        }
    };
};

interface ContactAvatarProps {
    url?: string;
}

function ContactAvatarStylable(props: ContactAvatarProps & WithStyles<'avatar'>) {
    return props.url ? <Avatar className={props.classes.avatar} src={props.url} /> : <img src={photoIcon} alt="" />;
}

export default withStyles(styles, { withTheme: true })(ContactAvatarStylable);
