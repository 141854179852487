import * as React from 'react';
import { msToMin } from '../../../../util/utility';
import NumberStepperWidget from '../../../../common/form/NumberStepperWidget';

export interface IntervalFieldProps {
    value: number;
    onChanged: (val: number) => void;
}

const valMs = [5, 6, 7, 7.5, 8, 8.5, 9, 10, 11, 12, 13, 14, 15, 20, 30].map((v) => v * 60 * 1000);

function format(val: number) {
    return `${msToMin(val)} minutes`;
}

function TeeTimeInterval(props: IntervalFieldProps) {
    return <NumberStepperWidget value={props.value} values={valMs} onChanged={props.onChanged} format={format} />;
}

export default TeeTimeInterval;