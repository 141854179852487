import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { History } from 'history';
import { MenuItem, Fab } from '@mui/material';

interface WithRouting {
    replace?: boolean;
    to: History.LocationDescriptor;
    target?: string;
    onClick?: (event?: any) => void;
}

function withRouting<P>(Component: React.ComponentType<P & { onClick?: (event?: any) => void }>) {
    const result = class extends React.Component<P & WithRouting & RouteComponentProps<any>> {
        handleClick = () => {
            const { onClick, history, target, to, replace } = this.props as any;
            if (target) {
                //pushUrl(to);
            } else {
                replace ? history.replace(to) : history.push(to);
            }
            if (onClick) {
                onClick();
            }
        }

        render() {
            const { onClick, history, location, match, staticContext, replace, to, ...others } = this.props as any;
            return (
                <Component onClick={this.handleClick} {...others} />
            );
        }
    };
    return withRouter(result);
}

export const RoutingButton = withRouting(Fab);
export const RoutingMenuItem = withRouting(MenuItem);
