import * as React from 'react';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { Paper, FormControlLabel, Checkbox, Badge } from '@mui/material';
import { ItemS, FlexGrid } from './Misc';

type GridItemFunc = (items: Array<GridItem>, checked: boolean) => void;
type GridItemFuncSingle = (items: GridItem, checked: boolean) => void;

export interface GridItem {
    id(): string;
    isDisabled(): boolean;
    getBadge(): React.ReactNode;
    getLabel(): React.ReactNode;
}

const useStyles = makeStyles((theme: Theme) => {
    return {
        formControlLabel: {
            width: theme.spacing(23)
        },
        label: {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            paddingLeft: theme.spacing(.5),
        },
        labelBadge: {
            lineHeight: 1.2,
            overflow: 'hidden',
            display: '-webkit-box',
            WebkitLineClamp: 2,
            WebkitBoxOrient: 'vertical'
        },
        paper: {
            width: '100%',
            paddingLeft: theme.spacing(3),
            paddingRight: theme.spacing(2),
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(1),
            maxWidth: theme.spacing(23),
            minWidth: theme.spacing(23),
        },
        paperBadge: {
            width: '100%',
            paddingLeft: theme.spacing(3),
            paddingRight: theme.spacing(2),
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(2),
            maxWidth: theme.spacing(32),
            minWidth: theme.spacing(32),
        },
        badge: {
            top: theme.spacing(6.5),
            right: theme.spacing(2.5),
            height: theme.spacing(2.5),
            width: theme.spacing(8),
            borderRadius: theme.spacing(1.25)
        },
    }
});

export function GridSelectorItem(props: { item: GridItem, handleSelected: GridItemFuncSingle, selected: Set<string> }) {
    const { item, selected, handleSelected } = props;
    const classes = useStyles();
    const onChange = (e: React.ChangeEvent, checked: boolean) => {
        e.stopPropagation();
        handleSelected(item, checked);
    };
    const badge = item.getBadge();
    const content = (
        <Paper className={badge ? classes.paperBadge : classes.paper}>
            <FormControlLabel className={classes.formControlLabel}
                classes={{ label: badge ? classes.labelBadge : classes.label }}
                control={<Checkbox
                    color="secondary"
                    onChange={onChange}
                    checked={selected.has(item.id())}
                    disabled={item.isDisabled()} />}
                label={item.getLabel()} />
        </Paper>
    );
    return badge ? (
        <ItemS>
            <Badge classes={{ badge: classes.badge }} color="primary" badgeContent={badge}>
                {content}
            </Badge>
        </ItemS>
    ) : (
        <ItemS>
            {content}
        </ItemS>
    );
}

interface GridProps {
    items: Array<GridItem>;
    preselectedSet?: Set<string>;
    preselected?: Array<string>;
    selectMode?: 'multi' | 'single' | 'single-or-none';
    handleSelected?: GridItemFunc;
}

interface GridState {
    selected: Set<string>;
}

export default class GridSelector extends React.Component<GridProps, GridState> {
    state: GridState = {
        selected: this.props.preselectedSet ? new Set(this.props.preselectedSet) : new Set(this.props.preselected)
    };

    private handleChange = (item: GridItem, checked: boolean) => {
        const { handleSelected, selectMode } = this.props;
        const { selected } = this.state;
        if (checked) {
            if (selectMode === 'single' || selectMode === 'single-or-none') {
                selected.clear();
            }
            selected.add(item.id());
        } else if (!selectMode || selectMode === 'single-or-none') {
            selected.delete(item.id());
        }
        this.setState({ selected });
        if (handleSelected) {
            handleSelected([item], checked);
        }
    }

    public selectAll = () => {
        const { items, selectMode, handleSelected } = this.props;
        if (selectMode === 'single' || selectMode === 'single-or-none') {
            return;
        }
        const { selected: oldSelected } = this.state;
        const selected = new Set<string>(items.filter(item => !item.isDisabled()).map(item => item.id()));
        this.setState({ selected }, () => {
            if (handleSelected) {
                const selectedItems = items.filter(item => !item.isDisabled() && !oldSelected.has(item.id()));
                handleSelected(selectedItems, true);
            }
        });
    }

    public selectNone = () => {
        const { items, selectMode, handleSelected } = this.props;
        const { selected: oldSelected } = this.state;
        if (selectMode === 'single') {
            return;
        }
        this.setState({ selected: new Set<string>() }, () => {
            if (handleSelected) {
                const selectedItems = items.filter(item => !item.isDisabled() && oldSelected.has(item.id()));
                handleSelected(selectedItems, false);
            }
        });
    }

    public selectedCount = () => {
        return this.state.selected.size;
    }

    public validateState = () => {
        const { items } = this.props;
        const { selected: oldSelected } = this.state;
        const selected = new Set<string>(items.filter(item => !item.isDisabled() && oldSelected.has(item.id())).map(item => item.id()));
        this.setState({ selected });
    }

    render() {
        const { items } = this.props;
        const { selected } = this.state;
        return (
            <FlexGrid spacing={2}>
                {items.map(item => <GridSelectorItem
                    key={item.id()}
                    item={item}
                    selected={selected}
                    handleSelected={this.handleChange} />)}
            </FlexGrid>
        );
    }
}
