import * as React from 'react';
import { FirebaseDocComponent, WithDataItem, withDataItemFromUrl } from '../common/WithData';
import { ErrorPage } from '../main/ErrorBoundary';
import Standings from './Standings';
import { Event, Portal } from '../types/EventTypes';
import { eventsDb } from '../util/firebase';
import { showAlert } from "../redux/ReduxConfig";
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { styles } from '../styles';
import * as Backend from "../util/firebase";
import * as Public from './Public';
import { Urls } from "../util/config";
import { isEventOver } from "../event/Event";

type LeaderboardInfoProps = WithDataItem<Event> & WithStyles<typeof styles>;

interface State {
    portal?: Portal;
    notificationShown: boolean;
}

class LeaderboardInfo extends React.Component<LeaderboardInfoProps, State> {

    private readonly supportArticleLink: JSX.Element =
        <a className={this.props.classes.linkBlue}
            href={Urls.whatIsLLLink}
            rel='noopener noreferrer'
        >Learn more</a>;

    private readonly landingPageLink: JSX.Element =
        <a className={this.props.classes.linkBlue}
            href={Urls.landingPageLink}
            rel='noopener noreferrer'
        >Learn more</a>;

    public state: State = {
        notificationShown: false,
    };

    private setPortal = (portal: Portal) => this.setState({ portal });

    render() {
        const event = this.props.item;
        if (!event?.exists) {
            const err = 'Event not found: ' + event?.id;
            return <ErrorPage error={err} errorInfo={""} noheader />;
        } else {
            const { classes } = this.props;
            const { portal, notificationShown } = this.state;
            const startingAlertString: string = isEventOver(event)
                ?
                'This leaderboard has been completed.'
                :
                'Trying to join the leaderboard? Please download the Golf Pad app on your phone and open the link there.';
            const alertFragment =
                <React.Fragment>
                    <span className={classes.spanStyle}>{startingAlertString}</span>
                    <br />
                    <br />
                    <span className={classes.spanStyle}>{this.supportArticleLink}&nbsp;about Live Leaderboards in Golf Pad.</span>
                </React.Fragment>;
            if (!notificationShown && !event.deleted) {
                showAlert(alertFragment, [{ title: 'CLOSE', color: 'primary', action: () => this.setState({ notificationShown: true }) },]);
            }
            let content: JSX.Element | string = '';
            if (portal && (event.type === 'leaderboard' || event.leaderboard)) {
                content = event.deleted ?
                    <div className={classes.midScreenRoot}>
                        <h1>The leaderboard you are trying to access has been deleted.</h1>
                        <br />
                        <p>Click here to {this.landingPageLink} about Golf Pad Live Leaderboards.</p>
                    </div>
                    :
                    <React.Fragment>
                        <Public.Header event={event} portal={portal} withTabs={['standings',]} />
                        <Standings event={event} rounds={[]} competitionsMap={new Map()} loadedRounds={0} loadedCompetitions={0} setSelectedRound={() => { }} />
                    </React.Fragment>;
            }
            return (
                <React.Fragment>
                    <div className={classes.pubRoot}>{content}</div>
                    <FirebaseDocComponent onDoc={doc => this.setPortal(Backend.fromEntity<Portal>(doc))} docReference={Backend.portalFields(event.id)} />
                </React.Fragment>
            );
        }
    }
}

export default withStyles(styles)(withDataItemFromUrl<Event, {}>(eventsDb)(LeaderboardInfo));
