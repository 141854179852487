import * as React from 'react';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Action, isOfType, withKey } from './ReduxCommon';
import { AnyAction, Reducer } from 'redux';
import { connect, DispatchProp } from 'react-redux';
import { ReactNode } from 'react';

interface ErrorMsgState {
    errorMsg?: string | ReactNode;
    show: boolean;
}

const DEFAULT_STATE: ErrorMsgState = {
    show: false
};

const ERROR_TYPE = 'error';

interface ErrorAction extends Action {
    msg?: string | ReactNode;
    show: boolean;
}

export function showErrorAction(msg: string | ReactNode): ErrorAction {
    return { type: ERROR_TYPE, show: true, msg: msg };
}

const hideError: ErrorAction = { type: ERROR_TYPE, show: false };

export const errorReducer: Reducer<ErrorMsgState> = (state: ErrorMsgState | undefined, action: AnyAction) => {
    if (state && isOfType<ErrorAction>(action, ERROR_TYPE)) {
        const copy = { ...state };
        copy.show = action.show;
        copy.errorMsg = action.msg;
        return copy;
    }
    return state || DEFAULT_STATE;
};

class WithError extends React.Component<ErrorMsgState & DispatchProp<any>> {

    handleErrorClose = () => {
        this.props.dispatch!(hideError);
    }

    render() {
        return (
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                open={this.props.show}
                autoHideDuration={3000}
                onClose={this.handleErrorClose}
                message={<span>{this.props.errorMsg}</span>}
                action={(
                    <IconButton key="close" color="inherit" onClick={this.handleErrorClose} size="large">
                        <CloseIcon />
                    </IconButton>
                )}
            />
        );
    }
}

export const ErrorHandler = connect(withKey<ErrorMsgState>('errorReducer'))(WithError);