import * as React from 'react';
import Button, { ButtonProps } from '@mui/material/Button';

const AppButton = React.forwardRef((props: ButtonProps, ref: React.ForwardedRef<HTMLButtonElement>) => {
    const { children, ...other } = props;
    return (
        <Button variant="contained" {...other} ref={ref}>
            {children}
        </Button>
    );
});

export default AppButton;
