import React, { useRef, useState, useCallback, useImperativeHandle } from 'react';
import update from 'immutability-helper'
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { CSVLink } from 'react-csv';
import { Badge, List, ListItem, Menu, MenuItem } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { DragSource, DragSourceConnector, DragSourceMonitor, ConnectDragSource, DropTarget, DropTargetConnector, DropTargetMonitor, ConnectDropTarget } from 'react-dnd';
import PersonIcon from '@mui/icons-material/Person';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { FixedWidthChip, FixedWidthChipLong, FixedWidthChipLonger, FixedWidthChipLongest } from '../../../../common/components/FixedWidthChip';
import ButtonBar from '../../../../common/components/ButtonBar';
import AppButton from '../../../../common/components/AppButton';
import LabeledField from '../../../../common/form/LabeledField';
import { ButtonBadge, NoWrapOverflow } from '../../../../common/Misc';
import { WizardIcon } from '../../../../common/Icons';
import {
    EventBase, EventData, Score, Distance, ReportedScore, Contact, ContactDetails, Team, GolferGroup, TeeTimeSettings, HandicapMode, GenderMode,
    golfersOrTeamsPerGroup, getGroupFullness, teeTimeName, golfersOrTeams, getHolesRange
} from '../../../../types/EventTypes';
import { fullName, golferTeamName, golfersOfTeam, shortName, getSameNameGolfersIds } from '../../../../contact/Contact';
import { addGolferOrTeamToGroup, elog, deleteGolfersFromEvent, saveContact, deleteInviteCode, scheduleGroupsAndSave, getUnscheduledCount } from '../../../Event';
import * as Backend from '../../../../util/firebase';
import { teeSettingsName, getTeeTime, formatTeeTime } from '../../../TeeTimes';
import AutoScheduleDialog from '../../common/AutoScheduleDialog';
import { EditTeeTimeDialog } from '../../settings/format/EditTeeTimeField';
import EditGroupsDialog from './EditGroupsDialog';
import EditContactDialog from '../../../../contact/EditContactDialog';
import * as Utils from '../../../../util/utility';
import { styles } from '../../../../styles';
import { InfoElement, showAlert, showProgress } from '../../../../redux/ReduxConfig';
import { withProgress } from 'src/util/ProgressPromise';
import { AppColors } from 'src/main/Theme';
import { printCartSigns, printSchedule, printScorecards } from '../../tools/PrintTab';
import { EmailVariant, sendPaymentMessage } from "../../../../util/email_utils";

const DRAG_TYPE_GROUP = 'GROUP';

function showAdjustmentsAlert(reportedContactNames: Array<string>) {
    showAlert(`${reportedContactNames.join(', ')} can no longer be rescheduled, as they have already started scoring for this event. Schedule adjustments can be made prior to golfers starting the round and adding scores.`, [
        { title: 'Ok', color: 'secondary' }
    ]);
}

function reportedContacts(teamOrGolferId: string, golfers: Map<string, Contact>, teams: Map<string, Team>) {
    let reportedContactsName: Array<string> = [];
    const golfer = golfers.get(teamOrGolferId);
    if (golfer && golfer.reportedBy) {
        reportedContactsName = [fullName(golfer)];
    } else {
        const team = teams.get(teamOrGolferId);
        if (team) {
            reportedContactsName = golfersOfTeam(team, golfers).filter(g => g.reportedBy).map(g => fullName(g));
        }
    }
    return reportedContactsName;
}

export interface GroupWithContacts {
    group: GolferGroup;
    teams: Array<Team>;
    golfers: Array<Contact>;
    label: string;
}

interface GroupRowItem {
    id: string
    names: Array<string>
    homeCourseOrCities?: string[];
    hidden: boolean;
    isPlaceholder: boolean;
    isReported: boolean;
    version: number;
}

interface GroupRowProps {
    initialItems: GroupRowItem[];
    eventOrRound: EventBase;
    groups: GolferGroup[];
    group: GroupWithContacts;
    teams: Map<string, Team>;
    golfers: Map<string, Contact>;
    groupFullness: number;
    addToGroup: (group: GroupWithContacts) => void;
    dropToGroup: (teamOrGolferId: string) => void;
    openGolfer: (id: string) => void;
    deleteFromGroup: (group: GroupWithContacts, teamOrGolferId: string) => void;
}

interface GroupItemProps {
    id: string;
    names: Array<string>;
    homeCourseOrCities?: string[];
    isPlaceholder?: boolean;
    isGroupOver: boolean;
    isReported: boolean;
    group: GroupWithContacts;
    teams: Map<string, Team>;
    golfers: Map<string, Contact>;
    groupSize: number;
    groupFullness: number;
    index: number;
    dragIndex: number;
    dragY?: number;
    moveChip: (chip: GroupRowItem, groupFrom: GroupWithContacts, draggedIndex: number, groupTo: GroupWithContacts, hoverIndex: number) => void;
    dropToGroup: (group: GroupWithContacts, golferId: string, index?: number) => void;
    onDelete: () => void;
    onOpen: (id: string) => void;
}

interface GroupItemTargetProps {
    connectDropTarget: ConnectDropTarget;
    canDrop: boolean;
    isOver: boolean;
    item: GroupItemProps;
}

interface GroupItemSourceProps {
    connectDragSource: ConnectDragSource;
    isDragging: boolean;
    item: GroupItemProps;
}

interface ItemInstance {
    getNode(): HTMLDivElement | null
}

const itemTarget = {
    hover(props: GroupItemProps, monitor: DropTargetMonitor, component: ItemInstance) {
        const item = monitor.getItem() as GroupItemProps;
        const node = component.getNode()
        if (!node) {
            return null
        }
        if (!item.dragY) {
            item.dragY = monitor.getInitialClientOffset()!.y;
        }
        const clientRect = node.getBoundingClientRect();
        if (item.group.group.id === props.group.group.id) {
            const chip: GroupRowItem = {
                id: item.id,
                names: item.names,
                isPlaceholder: false,
                isReported: item.isReported,
                hidden: false,
                version: -1
            };
            if (item.dragY < clientRect.y - 10 || item.dragY > clientRect.y + 10) {
                item.dragIndex = props.index;
                item.dragY = clientRect.y;
                item.moveChip(chip, item.group, item.index, props.group, props.index);
            } else if (item.dragIndex < props.index && (monitor.getClientOffset()!.x > clientRect.x + clientRect.width / 3)) {
                item.dragIndex = props.index;
                item.dragY = clientRect.y;
                item.moveChip(chip, item.group, item.index, props.group, props.index);
            } else if (item.dragIndex > props.index && (monitor.getClientOffset()!.x < clientRect.x + clientRect.width / 3)) {
                item.dragIndex = props.index;
                item.dragY = clientRect.y;
                item.moveChip(chip, item.group, item.index, props.group, props.index);
            }
        } else {
            const chip: GroupRowItem = { id: item.id, names: item.names, isPlaceholder: true, isReported: item.isReported, hidden: false, version: -1 }
            if (item.dragY < clientRect.y - 10 || item.dragY > clientRect.y + 10) {
                item.dragIndex = props.index;
                item.dragY = clientRect.y;
                props.moveChip(chip, item.group, item.index, props.group, props.index);
            } else if ((item.dragIndex < props.index && (monitor.getClientOffset()!.x > clientRect.x + clientRect.width / 3))) {
                item.dragIndex = props.index;
                item.dragY = clientRect.y;
                props.moveChip(chip, item.group, item.index, props.group, props.index);
            } else if (item.dragIndex >= props.index && (monitor.getClientOffset()!.x < clientRect.x + clientRect.width / 3)) {
                item.dragIndex = props.index;
                item.dragY = clientRect.y;
                props.moveChip(chip, item.group, item.index, props.group, props.index);
            }
        }
        return null;
    },
    canDrop(props: GroupItemProps, monitor: DropTargetMonitor) {
        const item = monitor.getItem() as GroupItemProps;
        return props.groupFullness < 1 || (!!props.group.golfers.find(c => c.id === item.id) || !!props.group.teams.find(t => t.id === item.id));
    },
    drop(props: GroupItemProps, monitor: DropTargetMonitor) {
        const item = monitor.getItem() as GroupItemProps;
        const reportedContactNames = reportedContacts(item.id, props.golfers, props.teams);
        if (reportedContactNames.length > 0) {
            showAdjustmentsAlert(reportedContactNames);
        } else {
            if (item.group.group.id === props.group.group.id) {
                item.dropToGroup(props.group, item.id, props.index);
            } else {
                props.dropToGroup(props.group, item.id, props.index);
            }
        }
    }
};

const groupTarget = {
    canDrop(props: GroupRowProps, monitor: DropTargetMonitor) {
        const item = monitor.getItem() as GroupItemProps;
        return (props.groupFullness < 1 || !!props.group.golfers.find(c => c.id === item.id) || !!props.group.teams.find(t => t.id === item.id));
    },
    drop(props: GroupRowProps, monitor: DropTargetMonitor) {
        if (!monitor.didDrop()) {
            const item = monitor.getItem() as GroupItemProps;
            const reportedContactNames = reportedContacts(item.id, props.golfers, props.teams);
            if (reportedContactNames.length > 0) {
                showAdjustmentsAlert(reportedContactNames);
            } else {
                props.dropToGroup(item.id);
            }
        }
    }
};

const collectItemSource = (connect: DragSourceConnector, monitor: DragSourceMonitor) => ({
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging(),
    item: monitor.getItem() as GroupItemProps
});

const collectItemTarget = (connect: DropTargetConnector, monitor: DropTargetMonitor) => ({
    connectDropTarget: connect.dropTarget(),
    canDrop: monitor.canDrop(),
    isOver: monitor.isOver(),
    item: monitor.getItem() as GroupItemProps
});

const collectGroupTarget = (connect: DropTargetConnector, monitor: DropTargetMonitor) => ({
    connectDropTarget: connect.dropTarget(),
    canDrop: monitor.canDrop(),
    isOver: monitor.isOver(),
    item: monitor.getItem() as GroupItemProps
});

const GroupItemDnD = React.forwardRef<HTMLDivElement, GroupItemProps & GroupItemSourceProps & GroupItemTargetProps & WithStyles<typeof styles>>((props, ref) => {
    const { id, names, homeCourseOrCities, onOpen, onDelete, connectDragSource, connectDropTarget, isPlaceholder, isGroupOver, isOver, item, classes } = props;
    const elementRef = useRef(null);
    connectDragSource(elementRef);
    connectDropTarget(elementRef);
    useImperativeHandle<{}, ItemInstance>(ref, () => ({
        getNode: () => elementRef.current,
    }));
    const label = (
        <NoWrapOverflow className={classes.chipPaper}>
            {names.map((name, idx) => <React.Fragment key={idx}>
                <PersonIcon className={classes.textIcon} />{name}
                <span className={classes.homeCourseOrCity}>
                    {homeCourseOrCities && homeCourseOrCities[idx] ? ` (${homeCourseOrCities[idx]})` : ''}
                </span>
            </React.Fragment>)}
        </NoWrapOverflow>
    );
    return !isPlaceholder || (isGroupOver && id === item.id) ?
        <span style={{ height: '100%', borderRadius: 16 }}>
            {names.length === 1 && <FixedWidthChip ref={elementRef} label={label} onDelete={onDelete} color={isOver && !!item && item.id === id ? "secondary" : "default"} onDoubleClick={_e => onOpen(id)} />}
            {names.length === 2 && <FixedWidthChipLong ref={elementRef} label={label} onDelete={onDelete} color={isOver && !!item && item.id === id ? "secondary" : "default"} onDoubleClick={_e => onOpen(id)} />}
            {names.length === 3 && <FixedWidthChipLonger ref={elementRef} label={label} onDelete={onDelete} color={isOver && !!item && item.id === id ? "secondary" : "default"} onDoubleClick={_e => onOpen(id)} />}
            {names.length === 4 && <FixedWidthChipLongest ref={elementRef} label={label} onDelete={onDelete} color={isOver && !!item && item.id === id ? "secondary" : "default"} onDoubleClick={_e => onOpen(id)} />}
        </span> : <span />;
});

const GroupItem = withStyles(styles)(DropTarget(DRAG_TYPE_GROUP, itemTarget, collectItemTarget)(DragSource(DRAG_TYPE_GROUP, {
    beginDrag: (props: GroupItemProps) => ({
        id: props.id,
        names: props.names,
        index: props.index,
        dragIndex: props.index,
        isPlaceholder: props.isPlaceholder,
        isGroupOver: props.isGroupOver,
        group: props.group,
        groupSize: props.groupSize,
        groupFullness: props.groupFullness,
        moveChip: props.moveChip,
        dropToGroup: props.dropToGroup,
        onDelete: props.onDelete,
        onOpen: props.onOpen,
    }),
}, collectItemSource)(GroupItemDnD)));

export interface GroupRowState {
    items: Array<GroupRowItem>;
}

function gatherItems(event: EventBase, group: GroupWithContacts, golfers: Map<string, Contact>, version: number): GroupRowItem[] {
    const items: Array<GroupRowItem> = [];
    const sameNameGolfersIdsSet = getSameNameGolfersIds(Array.from(golfers.values()));
    if (event.teamSize > 1) {
        group.teams.forEach(team => {
            const teamMembers = golfersOfTeam(team, golfers);
            const coursesOrCities = teamMembers.filter(contact => sameNameGolfersIdsSet.has(contact.id) && contact.homeCourseOrCity).map(contact => contact.homeCourseOrCity!);
            items.push({
                id: team.id,
                names: teamMembers.map(golfer => shortName(golfer, undefined, 10)),
                homeCourseOrCities: coursesOrCities.length === 0 ? undefined : coursesOrCities,
                isPlaceholder: false,
                hidden: false,
                isReported: !!teamMembers.find(g => !!g.reportedBy),
                version
            })
        })
    } else {
        group.golfers.forEach(contact => items.push({
            id: contact.id,
            names: [fullName(contact)],
            homeCourseOrCities: sameNameGolfersIdsSet.has(contact.id) && contact.homeCourseOrCity ? [contact.homeCourseOrCity] : undefined,
            isPlaceholder: false,
            hidden: !golfers.has(contact.id),
            isReported: !!contact.reportedBy,
            version
        }));
    }
    return items;
}

const GroupRowDnD = React.forwardRef<HTMLDivElement, GroupRowProps & GroupItemTargetProps & WithStyles<typeof styles>>((props, _ref) => {
    const { initialItems, eventOrRound, groups, group, addToGroup, openGolfer, deleteFromGroup, classes, groupFullness, canDrop, isOver, item, connectDropTarget, golfers, teams } = props;
    const showIcon = groupFullness < 1 && !item;
    const [initialState, setInitialState] = useState(initialItems);
    const [items, setItems] = useState(initialState);
    if (initialState.length !== initialItems.length || (initialState.length > 0 && initialState[0].version !== initialItems[0].version)) {
        setInitialState(initialItems);
        setItems(initialItems);
    }
    const dropToGroup = (group: GroupWithContacts, teamOrGolferId: string, index?: number) => {
        addGolferOrTeamToGroup(teamOrGolferId, group.group, groups, eventOrRound, index);
    }
    const moveChip = useCallback(
        (chip: GroupRowItem, groupFrom: GroupWithContacts, dragIndex: number, groupTo: GroupWithContacts, hoverIndex: number) => {
            const pos = items.findIndex(i => i.id === chip.id);
            if (groupFrom.group.id === groupTo.group.id) {
                setItems(update(items, { $splice: [[dragIndex, 1], [hoverIndex, 0, chip]] }));
            } else if (groupFullness < 1) {
                if (items.length === 0 || pos < 0) {
                    setItems(update(items, { $splice: [[hoverIndex, 0, chip]] }));
                } else {
                    setItems(update(items, { $splice: [[pos, 1], [hoverIndex, 0, chip]] }));
                }
            }
        },
        [items, groupFullness]);
    const contactsList = items.filter(i => !i.hidden).map((item: GroupRowItem, i: number) =>
        <GroupItem
            index={i}
            id={item.id}
            key={item.id}
            dragIndex={i}
            names={item.names}
            isGroupOver={isOver}
            isReported={item.isReported}
            isPlaceholder={item.isPlaceholder}
            groupSize={eventOrRound.teamSize}
            homeCourseOrCities={item.homeCourseOrCities}
            {...{ group, groupFullness, moveChip, golfers, teams }}
            onOpen={id => openGolfer(id)}
            onDelete={() => deleteFromGroup(group, item.id)}
            dropToGroup={(group, golferId, index) => dropToGroup(group, golferId, index)} />);
    if (item && canDrop && isOver && item.group.group.id !== group.group.id && items.findIndex(i => i.id === item.id) < 0) {
        if (item.names.length === 1) {
            contactsList.push(<FixedWidthChip id={item.id} key={item.id} label={item.names} className={classes.dragActive} />);
        }
        if (item.names.length === 2) {
            contactsList.push(<FixedWidthChipLong id={item.id} key={item.id} label={item.names} className={classes.dragActive} />);
        }
        if (item.names.length > 2) {
            contactsList.push(<FixedWidthChipLonger id={item.id} key={item.id} label={item.names} className={classes.dragActive} />);
        }
    }
    const holesRange = getHolesRange(eventOrRound.holesType);
    const teeTime = teeTimeName(getTeeTime(eventOrRound.teeTime, holesRange, group.group.order));
    return connectDropTarget(<div>
        <ListItem className={classes.listItem}>
            <Grid container wrap="nowrap">
                <Grid className={classes.centeredGrid + ' ' + (eventOrRound.teeTime.mode === 'regular' ? classes.labelTeeRegular : classes.labelTeeShotgun)} item style={{ height: 52 }}>
                    <Typography variant="body2" noWrap color={'initial'} fontFamily="roboto">{teeTime}</Typography>
                    {groupFullness >= 1 && <span style={{ color: 'red', fontSize: '0.65rem' }}>{Utils.withS(contactsList.length, eventOrRound.teamSize === 1 ? 'golfer' : 'team')}</span>}
                </Grid>
                <Grid className={classes.centeredGrid} item>
                    <div>
                        {contactsList}
                        {showIcon && <IconButton
                            className={classes.smallIconButton}
                            onClick={() => addToGroup(group)}
                            size="large"><AddCircleOutlineIcon />
                        </IconButton>}
                    </div>
                </Grid>
            </Grid>
        </ListItem >
        <Divider />
    </div>);
});

const GroupRow = withStyles(styles)(DropTarget(DRAG_TYPE_GROUP, groupTarget, collectGroupTarget)(GroupRowDnD));

interface State {
    doSchedule: boolean;
    editingTeeTimes: boolean;
    editingGroup: number;
    editedContact?: ContactDetails;
    handleExport: boolean;
    printMenuOpen: boolean;
    count: number;
}

type Props = { eventData: EventData; } & WithStyles<typeof styles>;

class ScheduleList extends React.Component<Props, State> {

    private readonly printMenuRef: React.RefObject<HTMLButtonElement> = React.createRef();

    state: State = {
        doSchedule: false,
        editingTeeTimes: false,
        editingGroup: -1,
        handleExport: false,
        printMenuOpen: false,
        count: 0
    };

    componentDidMount() {
        Backend.trackEvent('view_schedule');
    }

    private handleExport = () => this.setState({ handleExport: true });
    private handleTeeTimeSettingsClose = () => this.setState({ editingTeeTimes: false });
    private addToGroup = (group: GroupWithContacts) => this.setState({ editingGroup: group.group.order });

    private eventOrRound() {
        const { event, selectedRound } = this.props.eventData;
        return selectedRound ?? event;
    }

    private getStaff() {
        const { event, golfersMap, teamsMap, groupsMap, teeTimesMap, competitionsMap } = this.props.eventData;
        const eventOrRound = this.eventOrRound();
        const teams = teamsMap.get(eventOrRound.id) ?? new Map<string, Team>();
        const groups = groupsMap.get(eventOrRound.id) ?? [];
        const golfers = golfersMap.get(eventOrRound.id) ?? new Map<string, Contact>();
        const teeTimes = teeTimesMap.get(eventOrRound.id) ?? [];
        const competitions = competitionsMap.get(this.eventOrRound().id) ?? [];
        return { event, eventOrRound, golfers, teams, groups, competitions, teeTimes };
    }

    private handleReset = () => {
        const { eventOrRound, golfers, groups } = this.getStaff();
        let reportedGolfers: Array<Contact> = []
        groups.forEach(g => g.contactIds.forEach(id => {
            if (golfers.get(id)?.reportedBy) {
                reportedGolfers.push(golfers.get(id)!);
            }
        }));
        if (reportedGolfers.length === 0) {
            withProgress(Backend.removeBatch(Backend.golferGroupDb(eventOrRound.id), groups, deleteInviteCode)
                .then(() => elog(eventOrRound, `Groups reset `, `${groups.length} removed`, '')));
        } else {
            const reportedContactNames = reportedGolfers.map(golfer => fullName(golfer));
            showAdjustmentsAlert(reportedContactNames);
        }
    }

    private saveTeeTime = (val: TeeTimeSettings, eventTime?: number) => {
        const { eventOrRound, golfers, teams, groups } = this.getStaff();
        const groupCount = golfersOrTeamsPerGroup(eventOrRound, val.golfersPerGroup);
        const updatedGroups = groups.filter(g => g.contactIds.length > groupCount);
        if (updatedGroups.length > 0) {
            let reportedContactNames: Array<string> = [];
            updatedGroups.forEach(g => g.contactIds.forEach(id => reportedContactNames = reportedContactNames.concat(reportedContacts(id, golfers, teams))))
            if (reportedContactNames.length > 0) {
                showAdjustmentsAlert(reportedContactNames);
            } else {
                showAlert("Reducing the group size will automatically remove some golfers from the existing groups. ", [
                    { title: 'Cancel' },
                    { title: 'Proceed', color: 'secondary', action: () => this.saveTeeTimeConfirmed(val, eventTime) }
                ]);
            }
        } else {
            this.saveTeeTimeConfirmed(val, eventTime);
        }
    }

    private saveTeeTimeConfirmed = async (val: TeeTimeSettings, eventTime?: number) => {
        const eventOrRound = this.eventOrRound();
        this.setState({ editingTeeTimes: false });
        const eventChange = {
            id: eventOrRound.id,
            exists: true,
            teeTime: val,
            date: eventTime ?? eventOrRound.date
        };
        const hideProgress = showProgress();
        try {
            await Backend.updateOrAdd(Backend.eventsDb, eventChange);
            await this.adjustGroupsSize(val.golfersPerGroup);
            this.generateTeeTimes(eventOrRound);
            elog(eventOrRound, 'Tee times changed',
                `startTime: ${formatTeeTime(val)} mode: ${val.mode} groups size: ${val.golfersPerGroup} starting type: ${val.startingHolesType}`,
                `Id: ${eventOrRound.id}`)
        } finally {
            hideProgress();
        }
    }

    private adjustGroupsSize = async (golfersPerGroup: number) => {
        const { eventOrRound, groups } = this.getStaff();
        const groupCount = golfersOrTeamsPerGroup(eventOrRound, golfersPerGroup);
        const updatedGroups = groups.filter(g => g.contactIds.length > groupCount);
        if (updatedGroups.length > 0) {
            const usedOrders = new Set();
            groups.filter(g => g.contactIds.length > 0).forEach(g => usedOrders.add(g.order));
            updatedGroups.forEach(g => g.contactIds.splice(groupCount, g.contactIds.length - groupCount));
            await Backend.updateOrAddBatch(Backend.golferGroupDb(eventOrRound.id), updatedGroups);
        }
    }

    private deleteFromGroup = (groupc: GroupWithContacts, teamOrGolferId: string) => {
        const { eventOrRound, golfers, teams } = this.getStaff();
        const reportedContactNames = reportedContacts(teamOrGolferId, golfers, teams);
        if (reportedContactNames.length > 0) {
            showAdjustmentsAlert(reportedContactNames);
        } else {
            const group = groupc.group;
            const idx = group.contactIds.indexOf(teamOrGolferId);
            group.contactIds.splice(idx, 1);
            Backend.updateOrAdd(Backend.golferGroupDb(eventOrRound.id), group);
        }
    }

    private generateTeeTimes(eventOrRound: EventBase) {
        const { genTeeTimes } = this.props.eventData;
        genTeeTimes(eventOrRound);
    }

    private groupWithTeams(group: GolferGroup): GroupWithContacts {
        const { teams } = this.getStaff();
        const groupTeams: Array<Team> = [];
        group.contactIds.forEach(id => {
            const team = teams.get(id);
            if (team) {
                groupTeams.push(team);
            } else {
                groupTeams.push({ id, order: -1, contactIds: [] });
            }
        });
        return {
            group: group,
            teams: groupTeams,
            golfers: [],
            label: ''
        };
    }

    private groupWithGolfers(group: GolferGroup): GroupWithContacts {
        const { golfers } = this.getStaff();
        const groupGolfers: Array<Contact> = [];
        let label = '';
        group.contactIds.forEach(id => {
            const golfer = golfers.get(id);
            if (golfer) {
                groupGolfers.push(golfer);
                label += golfer.lastName + ',';
            } else {
                groupGolfers.push({ id, lastName: 'id:' + id.substring(0, 6) + '..', gender: 'male', hidden: false });
            }
        });
        return {
            label,
            group,
            teams: [],
            golfers: groupGolfers
        };
    }

    private scheduleGroups = (genderMode: GenderMode, handicapMode: HandicapMode, keepGroups: boolean) => {
        const { eventOrRound } = this.getStaff();
        const { eventData } = this.props;
        withProgress(scheduleGroupsAndSave(eventOrRound, eventData, genderMode, handicapMode, keepGroups));
    }

    private handleScheduleGroups = (genderMode: GenderMode, handicapMode: HandicapMode, keepGroups: boolean) =>
        this.setState({ doSchedule: false }, () => this.scheduleGroups(genderMode, handicapMode, keepGroups));

    private openGolfer = (id: string) => this.setState({ editedContact: this.props.eventData.golfers.get(id) });

    private printScorecards = async () => {
        const { event, golfers, teams, groups, competitions } = this.getStaff();
        this.setState({ printMenuOpen: false });
        withProgress(printScorecards(event, this.eventOrRound(), golfers, teams, groups, competitions));
    }

    private printSchedule = () => {
        const { groups, golfers, teams } = this.getStaff();
        this.setState({ printMenuOpen: false });
        withProgress(printSchedule(this.eventOrRound(), golfers, teams, groups));
    }

    private printCartsigns = () => {
        const { groups, golfers, teams } = this.getStaff();
        this.setState({ printMenuOpen: false });
        withProgress(printCartSigns(this.eventOrRound(), golfers, teams, groups));
    }

    private handleContactDeleted = (golfers: Array<Contact>) => {
        const { event } = this.props.eventData;
        withProgress(deleteGolfersFromEvent(event, golfers, true));
    }

    private handleContactChanged = (contactDetails: ContactDetails) => {
        const { event } = this.props.eventData;
        saveContact(event, contactDetails, 'Golfer modified')
            .then(() => this.setState({ editedContact: undefined }));
    }

    private exportData = () => {
        const { eventOrRound, golfers, teams, groups } = this.getStaff();
        const groupSize = Math.max(Math.floor(((eventOrRound.teeTime && eventOrRound.teeTime.golfersPerGroup) ? eventOrRound.teeTime.golfersPerGroup : 4) / eventOrRound.teamSize), 1);
        const exportData: string[][] = [];
        const exportHeader = ['Start'];
        if (exportData) {
            for (let i = 1; i <= groupSize; i += 1) {
                if (eventOrRound.teamSize === 1) {
                    exportHeader.push('Golfer ' + i);
                } else {
                    exportHeader.push('Team ' + i);
                }
            }
            exportData.push(exportHeader);
            groups.forEach(group => {
                if (group.contactIds.length > 0) {
                    let exportRow: string[] = [];
                    const holesRange = getHolesRange(eventOrRound.holesType);
                    const groupTime = teeTimeName(getTeeTime(eventOrRound.teeTime, holesRange, group.order));
                    exportRow.push(groupTime);
                    group.contactIds.forEach(contactId => {
                        if (eventOrRound.teamSize === 1) {
                            const contact = golfers.get(contactId);
                            if (contact) {
                                exportRow.push(fullName(contact!));
                            }
                        } else {
                            const team = teams.get(contactId);
                            if (team) {
                                exportRow.push(golferTeamName(team!, golfers));
                            }
                        }
                    });
                    exportData.push(exportRow);
                }
            });
        }
        return exportData;
    }

    private unscheduledGolfersOrTeams = (eventOrRound: EventBase) => {
        const { golfersMap, teamsMap, groupsMap, loadedTeams, loadedGolfers, loadedGroups } = this.props.eventData;
        const teams = teamsMap.get(eventOrRound.id) ?? new Map<string, Team>();
        const groups = groupsMap.get(eventOrRound.id) ?? [];
        const golfers = golfersMap.get(eventOrRound.id) ?? new Map<string, Contact>();
        return loadedTeams > 0 && loadedGolfers > 0 && loadedGroups > 0 ? getUnscheduledCount(groups, teams, golfers, eventOrRound.teamSize) : 0;
    }

    private Groups = () => {
        const { classes } = this.props;
        const { event, rounds, selectedRound, setSelectedRound, loadedGolfers, loadedTeams, loadedGroups } = this.props.eventData;
        const { eventOrRound, golfers, teams, groups } = this.getStaff();
        const { handleExport, printMenuOpen, count } = this.state;
        const moveGroup = (group: GolferGroup, teamOrGolferId: string) => {
            addGolferOrTeamToGroup(teamOrGolferId, group, groups, eventOrRound)
                .then(() => this.setState({ count: count + 1 }));
        }
        const groupSize = Math.max(Math.floor((eventOrRound.teeTime?.golfersPerGroup || 4) / eventOrRound.teamSize), 1);
        const teeTimeInfo = <>
            <Typography>{teeSettingsName(eventOrRound)}</Typography>
            <Typography>{groupSize === 1 ? (eventOrRound.teamSize > 1 ? '1 team per tee time' : '1 person per tee time') :
                groupSize + (eventOrRound.teamSize > 1 ? ' teams per tee time' : ' people per tee time')}
            </Typography>
        </>;
        let unscheduledInfo: React.ReactNode = '';
        const scheduled = new Set<string>();
        if (loadedGroups > 0) {
            groups.forEach(group => group.contactIds.forEach(contactId => scheduled.add(contactId)));
            const unscheduled = getUnscheduledCount(groups, teams, golfers, eventOrRound.teamSize);
            if (scheduled.size === 0) {
                unscheduledInfo = <InfoElement iconColor={AppColors.webWarning}>
                    The round will start soon, set the schedule for the participants.
                </InfoElement>;
            } else if (unscheduled > 0) {
                unscheduledInfo = <Typography variant="body1" style={{ marginLeft: 16 }}>
                    {Utils.withS(unscheduled, eventOrRound.teamSize === 1 ? 'golfer' : 'team') + ' not scheduled yet'}
                    <Badge sx={{ marginBottom: 2, marginLeft: 1 }} color="error" variant="dot" overlap="rectangular" />
                </Typography>;
            }
        }
        const fileName = event.name.replace(' ', '-') + (selectedRound ? `-round${selectedRound.roundOrder}` : '') + '-' + Utils.formatDateDashed2(eventOrRound.date);
        const exportFile = `${fileName}-schedule.csv`;
        const exportData = handleExport ? this.exportData() : '';
        const selectedRoundNameInMenu = selectedRound ? `(Round ${selectedRound.roundOrder})` : '';
        const version = loadedGroups * 1000000 + loadedTeams * 10000 + loadedGolfers + 1000 * (selectedRound?.roundOrder ?? 0) + count;
        /*
                {groups.map((group, idx) => {
                    const groupWithContacts = eventOrRound.teamSize === 1 ? this.groupWithGolfers(group) : this.groupWithTeams(group);
                    return <pre key={idx}>{group.order} - {group.id} - {version} - [{groupWithContacts.golfers.map(g => fullName(g)).join('] [')}]</pre>
                })}
        */
        return <div className={classes.listRootGrey}>
            {event.type === 'multiday' && <ButtonBar margin>
                {rounds.map(round => <AppButton
                    key={round.roundOrder}
                    className={classes.eventRoundButton}
                    color={round.roundOrder === selectedRound?.roundOrder ? "primary" : "info"}
                    sx={{ backgroundColor: round.roundOrder === selectedRound?.roundOrder ? undefined : AppColors.white }}
                    onClick={() => setSelectedRound(round)} >
                    Round {round.roundOrder}
                    <ButtonBadge
                        invisible={this.unscheduledGolfersOrTeams(round) === 0}
                        selected={round.roundOrder === selectedRound?.roundOrder} />
                </AppButton>)}
            </ButtonBar>}
            <List disablePadding className={classes.listRootGreySmallVert}>
                <LabeledField label={'Tee times'} itemClass={classes.listItem} value={teeTimeInfo} edit={() => this.setState({ editingTeeTimes: true })} />
            </List>
            <List disablePadding className={classes.listRootGrey}>
                <ButtonBar margin style={{ backgroundColor: AppColors.white, margin: 0, padding: '16px', paddingTop: '4px' }}>
                    <AppButton color="secondary" onClick={() => this.setState({ doSchedule: true })}>
                        <WizardIcon className={classes.leftButtonIcon} />Schedule...
                    </AppButton>
                    {scheduled.size > 0 &&
                        <CSVLink data={exportData} filename={exportFile} style={{ textDecoration: 'none' }} >
                            <AppButton color="info" onClick={this.handleExport}>Export</AppButton>
                        </CSVLink>}
                    {scheduled.size > 0 &&
                        <AppButton color="info" onClick={this.handleReset}>Reset all</AppButton>}
                    {unscheduledInfo}
                    <AppButton color="info"
                        ref={this.printMenuRef}
                        style={{ paddingRight: 8, marginLeft: 'auto', marginRight: 0 }}
                        onClick={() => this.setState({ printMenuOpen: !printMenuOpen })}>
                        Print
                        <ArrowDropDownIcon className={classes.rightButtonIcon} />
                    </AppButton>
                    <Menu
                        open={printMenuOpen}
                        anchorEl={this.printMenuRef.current}
                        onClose={() => this.setState({ printMenuOpen: false })}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}>
                        <MenuItem onClick={this.printScorecards}>Scorecards {selectedRoundNameInMenu}</MenuItem>
                        <MenuItem onClick={this.printSchedule}>Schedule {selectedRoundNameInMenu}</MenuItem>
                        <MenuItem onClick={this.printCartsigns}>Cart signs {selectedRoundNameInMenu}</MenuItem>
                    </Menu>
                </ButtonBar>
                {groups.map((group, idx) => {
                    const groupWithContacts = eventOrRound.teamSize === 1 ? this.groupWithGolfers(group) : this.groupWithTeams(group);
                    return <GroupRow
                        key={idx}
                        initialItems={gatherItems(eventOrRound, groupWithContacts, golfers, version)}
                        eventOrRound={eventOrRound}
                        teams={teams}
                        golfers={golfers}
                        groups={groups}
                        group={groupWithContacts}
                        groupFullness={getGroupFullness(eventOrRound, group)}
                        addToGroup={this.addToGroup}
                        dropToGroup={(teamOrGolferId: string) => moveGroup(group, teamOrGolferId)}
                        openGolfer={this.openGolfer}
                        deleteFromGroup={this.deleteFromGroup}
                    />
                })}
            </List>
        </div>;
    }

    private handleSendMessage = async (contactsToSend: Contact[], variant: EmailVariant) => {
        const { eventData } = this.props;
        await sendPaymentMessage(contactsToSend, eventData, new Map<string, Score>(), new Map<string, Score>(),
            new Map<string, ReportedScore>(), new Map<string, ReportedScore>(), new Map<string, Distance>(), variant);
    };

    render() {
        const { eventData } = this.props;
        const { eventOrRound, golfers, teams, groups, teeTimes } = this.getStaff();
        const { doSchedule, editingTeeTimes, editedContact, editingGroup, count } = this.state;
        const { event, rounds, loadedTeams, loadedGroups, loadedGolfers } = eventData;
        const countPerGroup = golfersOrTeamsPerGroup(eventOrRound);
        return <>
            <this.Groups />
            {!!editedContact && <EditContactDialog
                open
                event={event}
                rounds={rounds}
                actionMode="edit"
                initialContact={editedContact}
                saveToEvent={this.handleContactChanged}
                sendEmail={this.handleSendMessage}
                deleteFromEvent={this.handleContactDeleted}
                handleClose={() => this.setState({ editedContact: undefined })}
            />}
            {(teeTimes.length > 0 && doSchedule) && <AutoScheduleDialog
                pairing={false}
                eventOrRound={eventOrRound}
                open={doSchedule}
                close={() => this.setState({ doSchedule: false })}
                save={this.handleScheduleGroups}
                golfersOrTeams={golfersOrTeams(eventOrRound.teamSize === 1 ? golfers : teams, groups, true)}
                groups={groups}
                count={teeTimes.length}
                groupSize={countPerGroup}
            />}
            {editingTeeTimes && <EditTeeTimeDialog
                eventOrRound={eventOrRound}
                settings={eventOrRound.teeTime}
                saveSettings={this.saveTeeTime}
                onClose={this.handleTeeTimeSettingsClose}
            />}
            {editingGroup >= 0 && <EditGroupsDialog
                eventOrRound={eventOrRound}
                {...{ teams, groups, golfers, loadedTeams, loadedGroups, loadedGolfers }}
                editingGroup={editingGroup}
                handleClose={() => this.setState({ editingGroup: -1, count: count + 1 })}
            />}
        </>;
    }
}

export default withStyles(styles)(ScheduleList);
