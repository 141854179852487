import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import AppButton from '../../common/components/AppButton';

type Props = {
    open: boolean;
    title: string;
    content: string | React.ReactNode;
    onClose?: () => void;
    disableEscapeKeyDown?: boolean;
    disableBackdropClick?: boolean;
};

class MessageDialog extends React.Component<Props> {

    private handleClose = (uiEvent: string, reason: string) => {
        const { onClose, disableEscapeKeyDown, disableBackdropClick } = this.props;
        if ("backdropClick" === reason && disableBackdropClick) { 
            return;
        }
        if ("escapeKeyDown" === reason && disableEscapeKeyDown) { 
            return;
        }
        onClose?.();
    }

    render() {
        const { open, onClose, content, title = 'Confirm operation' } = this.props;
        return (
            <Dialog open={open} onClose={this.handleClose}>
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>
                    <DialogContentText>{content}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <AppButton onClick={onClose} color="secondary">Ok</AppButton>
                </DialogActions>
            </Dialog >
        );
    }
}

export default MessageDialog;
