import {
    Event, Contact, Tee, Team, Score, ReportedScore, GolferGroup, Units, Distance, MAX_HOLES, HOLES_9, HOLES_9_9, getCourseName, getStartingHoles,
    TeeTime, teeTimeName, isRegular, isMainScoring, isIndividualScoringOrBB, getHolesRange, isTeamScoring, isNetMode, isGrossMode, NINE_HOLES,
    isSkinsScoring, getTee, getFlightOfContact, isStablefordScoringOrMode, getHoleLabel, isNetPayouts, isGrossPayouts,
    ContactScoringState, Competition, ContactPayoutState, isIndividualScoring, getEventMainCompetition, ScoringFormatTeams, isSideScoringWithPayouts, EventBase, eventName
} from '../types/EventTypes';
import {
    getSkinsScores,
    getDistanceScores,
    ContactGroup,
    hasTees,
    formatDistance,
    genderFromEvent,
    eventPayoutsStates,
    getFullCompetitionPurse,
    getSplitCompetitionsWithPayouts
} from './Event';
import * as Scoring from '../scoring/scoring';
import {
    getTeeNameEx,
    getTeeNameWg,
    getHoleHandicapByHC,
    getPlayingHandicap,
    getGolferRangeHandicap,
    getHandicapsAllowance
} from '../scoring/handicap';
import {
    contactInitials,
    shortName,
    fullName,
    fullLastName,
    formatHandicap,
    golferTeamName,
    golfersOfTeam,
    getSameNameGolfersIds
} from '../contact/Contact';
import {
    ScheduleItem,
    CartSignItem,
    formatTeeTime,
    getTeeTime,
    getTeeTimeShotgunHole,
    evalStartTime,
    getFlightName
} from './TeeTimes';
import {
    formatTime,
    formatDateDashed1,
    makeFriendlyString,
    cutString,
    Pair,
    formatCurrency,
    range,
    round,
    sumArray
} from '../util/utility';
import { getContrastTextColor, COLOR_NAMES } from '../util/colors';
import GolfPadLogoImg from './resources/GolfPadKnowYourGame.png';
//
// PDF import
//
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as PdfDefs from 'pdfmake/interfaces';
import { Content, ContentQr, Style, Table, TFontDictionary } from 'pdfmake/interfaces';
import { Urls } from '../util/config';
import { scoringName } from '../scoring/scoring';
import pdfFonts from 'pdfmake/build/vfs_fonts';

const pdfMakeX = require('pdfmake/build/pdfmake.js');
const pdfFontsX = require('pdfmake-unicode/dist/pdfmake-unicode.js');

pdfMakeX.vfs = {
    ...pdfFontsX.pdfMake.vfs,
    ...pdfFonts.pdfMake.vfs
};

const pdfMakeFonts: TFontDictionary = {
    Roboto: {
        normal: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
        bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
        italics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
        bolditalics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf'
    },
    PingFangSC: {
        normal: `${window.location.origin}/fonts/PingFang-SC-Regular.ttf`,
        bold: `${window.location.origin}/fonts/PingFang-SC-Bold.ttf`,
        italics: `${window.location.origin}/fonts/PingFang-SC-Light.ttf`,
        bolditalics: `${window.location.origin}/fonts/PingFang-SC-Bold.ttf`
    }
};

// 
// PDF formatting methods 
//
export const LINK_BLUE = '#0B85FD';
export const LIGHT_BLUE = '#88CBFF';
export const LIGHT_RED = '#FFCCCC';
export const LIGHT_GREY = '#EEEEEE';
export const GREY = '#808080';
export const WHITE = '#FFFFFF';
export const BLACK = '#000000';
export const LIGHT_BROWN = '#F7F2E0';
export const LIGHT_YELLOW = '#FFFEAC';
export const LIGHT_YELLOW_GREY = '#EEEEBF';

const MAX_NAME_LENGTH = 16;
const shorterName = (name: string) => name.length > MAX_NAME_LENGTH ? name.substring(0, MAX_NAME_LENGTH) + '...' : name;

export function teeStartingHoleLabel(event: EventBase, order: number): string {
    const holesRange = getHolesRange(event.holesType);
    const teeTime = getTeeTime(event.teeTime, holesRange, order);
    if (!teeTime) {
        return 'n/a';
    }
    if (isRegular(teeTime)) {
        const holes = getStartingHoles(event.teeTime.startingHolesType, event.teeTime.startingHoles, holesRange, event.teeTime.mode);
        let startHole = holes.length > 0 ? holes[0] : 0;
        if (holesRange.first !== 0) {
            startHole -= holesRange.first;
        }
        return `${startHole + 1}`;
    } else {
        return teeTime;
    }
}

export function teeTimeLabel(event: EventBase, order: number): string {
    const holesRange = getHolesRange(event.holesType);
    const teeTime = getTeeTime(event.teeTime, holesRange, order);
    if (!teeTime) {
        return 'n/a';
    }
    if (isRegular(teeTime)) {
        return formatTime(teeTime);
    } else {
        return formatTeeTime(event.teeTime);
    }
}

export function docStyles() {
    return {
        header: {
            fontSize: 17,
            bold: true,
            margin: [0, 0, 0, 0],
            alignment: 'center'
        },
        subheader: {
            fontSize: 11,
            bold: true,
            margin: [0, 6, 0, 6],
            alignment: 'center'
        },
        subheaderM: {
            fontSize: 11,
            bold: true,
            margin: [0, 22, 0, 6],
            alignment: 'center'
        },
        tableHeader: {
            fontSize: 11,
            // bold: true,
            fillColor: GREY,
            margin: [3, 0, 3, 0],
            color: WHITE,
            alignment: 'left'
        },
        tableData: {
            fontSize: 11,
            margin: [3, 0, 3, 0],
            border: [0, 0, 0, 0],
            color: 'black',
            alignment: 'left'
        },
        tableDataRed: {
            fontSize: 11,
            margin: [3, 0, 3, 0],
            border: [0, 0, 0, 0],
            color: 'red',
            alignment: 'left'
        }
    } as PdfDefs.StyleDictionary;
}

export function docTable(body: any, widths: any) {
    return {
        table: {
            headerRows: 1,
            widths,
            body
        },
        layout: {
            fillColor: (i: number, node: any) => (i % 2 === 0) ? LIGHT_GREY : null,
            //            hLineWidth: (i: number, node: any) => {
            //                if (i === 0 || i === node.table.body.length) {
            //                    return 0;
            //                }
            //                return (i === node.table.headerRows) ? 2 : 1;
            //            },
            vLineWidth: (i: number, node: any) => 0,
            hLineWidth: (i: number, node: any) => 0,
            // hLineColor: (i: number) => i === 1 ? 'black' : '#aaa',
            paddingTop: (i: number, node: any) => 6,
            paddingBottom: (i: number, node: any) => 2,
            paddingLeft: (i: number, node: any) => 8,
            paddingRight: (i: number, node: any) => (i === node.table.widths.length - 1) ? 0 : 8
        }
    };
}

function createPdfDoc(docDef: PdfDefs.TDocumentDefinitions, usePingFangFont: boolean = true) {
    if (usePingFangFont) {
        docDef.defaultStyle = {
            font: 'PingFangSC'
        };
    }
    return pdfMake.createPdf(docDef, undefined, pdfMakeFonts) as any;
}

export function createScheduleDoc(event: EventBase, schedule: ScheduleItem[], golfers: Map<string, Contact>) {
    if (!schedule) {
        return;
    }
    const docName = eventName(event);
    const body: Array<any> = [
        [{ text: (event.teeTime.mode === 'regular' ? 'Time' : 'Hole'), style: 'tableHeader' },
        { text: 'Golfers', style: 'tableHeader' }]
    ];
    const sameNameGolfersIdsSet = getSameNameGolfersIds(Array.from(golfers.values()));
    if (event.teamSize === 1) {
        schedule.map(s => body.push([
            { text: teeTimeName(s.time), style: 'tableData' },
            {
                text: s.golfers.map(golfer => fullName(golfer).concat(sameNameGolfersIdsSet.has(golfer.id) && golfer.homeCourseOrCity ? ` (${golfer.homeCourseOrCity})` : '')).filter(name => name).join(', '),
                style: 'tableData'
            }
        ]));
    } else if (event.teamSize > 1) {
        schedule.map(s => body.push([
            { text: teeTimeName(s.time), style: 'tableData' },
            {
                text: s.teams.map(team => golferTeamName(team, golfers, sameNameGolfersIdsSet)).filter(name => name).join(', '),
                style: 'tableData'
            }
        ]));
    }
    const docDef = {
        content: [
            {
                text: docName,
                style: 'header'
            },
            {
                text: 'SCHEDULE',
                style: 'subheader'
            },
            docTable(body, ['auto', '*'])
        ],
        styles: docStyles()
    };
    return createPdfDoc(docDef);
    // return pdfMake.createPdf(docDef) as any;
    //    (pdfDocGenerator as any).getBase64((out: string) => {
    //        this.setState({ out });
    //    });
    // pdfMake.createPdf(docDef).open();
    // .download('schedule.pdf');
}

function calcWidth(isTeam: boolean, isNet: boolean, isStableford: boolean) {
    const widths = [25, '*', 35, 35, 40];
    if (isNet) {
        widths.push(40);
    }
    if (isStableford) {
        widths.push(25);
        if (isNet) {
            widths.push(25);
        }
    }
    return widths;
}

function printScores(scores: Array<ContactScoringState>, isTeam: boolean, isNet: boolean, isStableford: boolean, holeCount: number) {
    const body: Array<any> = [[
        { text: 'Pos', style: 'tableHeader' },
        { text: isTeam ? 'Team' : 'Name', style: 'tableHeader' },
        { text: isNet ? 'Net' : 'Score', style: 'tableHeader' },
        { text: 'Thru', style: 'tableHeader' },
        { text: isNet ? 'Total Gross' : 'Total', style: 'tableHeader' }
    ]];
    if (isNet) {
        body[0].push({ text: 'Total Net', style: 'tableHeader' });
    }
    if (isStableford) {
        body[0].push({ text: 'Pts', style: 'tableHeader' });
        if (isNet) {
            body[0].push({ text: 'Pts Net', style: 'tableHeader' });
        }
    }
    scores.forEach(s => {
        const disOrWit = s.contactInfo.withdrawn ? 'WD' : s.contactInfo.disqualified ? 'DQ' : undefined;
        const row = [
            { text: s.pos, style: 'tableData' },
            { text: s.names.join(' + '), style: 'tableData' },
            {
                text: !disOrWit ? Scoring.formatRelativeScore(isNet ? s.relativeNet : s.relativeTotal) : disOrWit,
                style: 'tableData'
            },
            { text: s.holes === holeCount ? 'F' : s.holes, style: 'tableData' },
            { text: !disOrWit ? s.total : disOrWit, style: 'tableData' }
        ];
        if (isNet) {
            row.push({ text: !disOrWit ? s.net : disOrWit, style: 'tableData' });
        }
        if (isStableford) {
            row.push({ text: !disOrWit ? s.stableford : disOrWit, style: 'tableData' });
            if (isNet) {
                row.push({ text: !disOrWit ? s.stablefordNet : disOrWit, style: 'tableData' });
            }
        }
        body.push(row);
    });
    return body;
}

export function createScoringDoc(event: EventBase, competitions: Array<Competition>, golferScores: Map<string, Score>, teamScores: Map<string, Score>, reportedScores: Map<string, ReportedScore>,
    reportedTeamScores: Map<string, ReportedScore>, golfers: Map<string, Contact>, teams: Map<string, Team>, groups: Array<GolferGroup>, distances: Map<string, Distance>, units?: Units) {
    const docName = eventName(event);
    const docDef: any = {
        content: [],
        pageMargins: [10, 10, 10, 10],
        styles: docStyles()
    };
    docDef.content.push({
        text: docName,
        style: 'header'
    });
    docDef.content.push({
        text: 'RESULTS',
        style: 'subheader'
    });
    const competitionsMainNotSkins = competitions.filter(competition => isMainScoring(competition.scoring) && !isSkinsScoring(competition.scoring));
    const mainCompetition = getEventMainCompetition(competitions);
    const sameNameGolfersIdsSet = getSameNameGolfersIds(Array.from(golfers.values()));
    competitionsMainNotSkins.forEach(competition => {
        const isTeam = isTeamScoring(competition.scoring);
        const isStableford = isStablefordScoringOrMode(competition.scoring);
        const isNet = isNetMode(competition.scoring.mode);
        const isGross = isGrossMode(competition.scoring.mode);
        const holesRange = getHolesRange(event.holesType);
        const holeCount = holesRange.last - holesRange.first;
        if (!competition.flights) {
            const subComps: Array<any> = [];
            if (isNet) {
                subComps.push(Scoring.netCompetition(competition));
            }
            if (isGross) {
                subComps.push(Scoring.grossCompetition(competition));
            }
            subComps.forEach(subComp => {
                const scores: ContactScoringState[] = Scoring.golferHoleScores(event, subComp, 0, golferScores, teamScores, reportedScores, reportedTeamScores, golfers, teams, groups, mainCompetition);
                const body = printScores(scores, isTeam, isNetMode(subComp.scoring.mode), isStableford, holeCount);
                const widths = calcWidth(isTeam, isNetMode(subComp.scoring.mode), isStableford);
                docDef.content.push({
                    text: Scoring.scoringName(subComp, event.eventGender, subComp.competitionGender).toLocaleUpperCase(),
                    style: 'subheaderM'
                });
                docDef.content.push(docTable(body, widths));
            });
        } else {
            range(1, competition.flights + 1).forEach(i => {
                const subComps: Array<any> = [];
                if (isNet) {
                    subComps.push(Scoring.netCompetition(competition));
                }
                if (isGross) {
                    subComps.push(Scoring.grossCompetition(competition));
                }
                subComps.forEach(subComp => {
                    const scores: ContactScoringState[] = Scoring.golferHoleScores(event, subComp, i, golferScores, teamScores, reportedScores, reportedTeamScores, golfers, teams, groups, mainCompetition);
                    const body = printScores(scores, isTeam, isNetMode(subComp.scoring.mode), isStableford, holeCount);
                    const widths = calcWidth(isTeam, isNetMode(subComp.scoring.mode), isStableford);
                    const flightName = getFlightName(i, competition.flightsNaming, true);
                    const header = Scoring.scoringName(subComp, event.eventGender, subComp.competitionGender).toLocaleUpperCase() + ' ' + flightName;
                    docDef.content.push({
                        text: header,
                        style: 'subheaderM'
                    });
                    docDef.content.push(docTable(body, widths));
                });
            });
        }
    });
    competitions.filter(competition => competition.scoring.format === 'closest_to_the_pin').forEach(competition => {
        const gender = genderFromEvent(event);
        const distanceInfo = getDistanceScores(event, competition, golfers, distances, getTee(event, mainCompetition, gender, undefined));
        const widths = ['auto', '*', 'auto'];
        const body: Array<any> = [[
            { text: 'Hole', style: 'tableHeader' },
            { text: 'Winner', style: 'tableHeader' },
            { text: 'Distance', style: 'tableHeader' }
        ]];
        distanceInfo.forEach(distanceProp => {
            const contact: Contact = distanceProp.contacts[0];
            const contactName = contact ? (shortName(contact) +
                (sameNameGolfersIdsSet.has(contact.id) && contact.homeCourseOrCity ? ` (${contact.homeCourseOrCity})` : '')) : '';
            return body.push([
                { text: distanceProp.hole + 1, style: 'tableData' },
                { text: contactName || 'Not selected', style: 'tableData' },
                { text: formatDistance(units, distanceProp.score) || '', style: 'tableData' }
            ]);
        });
        docDef.content.push({
            text: Scoring.scoringName(competition, event.eventGender, competition.competitionGender).toLocaleUpperCase(),
            style: 'subheaderM'
        });
        docDef.content.push(docTable(body, widths));
    });
    competitions.filter(competition => competition.scoring.format === 'longest_drive').forEach(competition => {
        const gender = genderFromEvent(event);
        const distanceInfo = getDistanceScores(event, competition, golfers, distances, getTee(event, mainCompetition, gender, undefined));
        const widths = ['auto', '*'];
        const body: Array<any> = [[
            { text: 'Hole', style: 'tableHeader' },
            { text: 'Winner', style: 'tableHeader' }
        ]];
        distanceInfo.forEach(distanceProp => {
            const contact: Contact = distanceProp.contacts[0];
            const contactName = contact ? (shortName(contact) +
                (sameNameGolfersIdsSet.has(contact.id) && contact.homeCourseOrCity ? ` (${contact.homeCourseOrCity})` : '')) : '';
            return body.push([
                { text: distanceProp.hole + 1, style: 'tableData' },
                { text: contactName || 'Not selected', style: 'tableData' }
            ]);
        });
        docDef.content.push({
            text: Scoring.scoringName(competition, event.eventGender, competition.competitionGender).toLocaleUpperCase(),
            style: 'subheaderM'
        });
        docDef.content.push(docTable(body, widths));
    });
    const skinsMixed = !!competitions.find(comp => comp.scoring.format === 'best_ball');
    competitions.filter(competition => isSkinsScoring(competition.scoring)).forEach(competition => {
        const gender = genderFromEvent(event);
        const noTees = !mainCompetition || !hasTees(event, mainCompetition);
        if (noTees) {
            return;
        }
        const isNet = isNetMode(competition.scoring.mode);
        const scores = Scoring.golferHoleScores(event, competition, 0, golferScores, teamScores, reportedScores, reportedTeamScores, golfers, teams, groups, mainCompetition);
        const tee = getTee(event, mainCompetition, gender, undefined);
        const skins = getSkinsScores(event, competition, scores, tee);
        const widths = ['auto', 'auto', 'auto', '*'];
        const body: Array<any> = [[
            { text: 'Hole', style: 'tableHeader' },
            { text: 'Par', style: 'tableHeader' },
            { text: isNet ? 'Lowest net' : 'Lowest gross', style: 'tableHeader' },
            { text: 'Golfer(s)', style: 'tableHeader' }
        ]];
        skins.forEach(skin => body.push([
            { text: skin.hole + 1, style: 'tableData' },
            { text: skin.par, style: 'tableData' },
            { text: skin.score === null ? '' : skin.score, style: 'tableData' },
            {
                text: skin.contacts.length === 1 ?
                    skin.contacts.map(contact => contact.contacts.map(c => shortName(c) + (sameNameGolfersIdsSet.has(c.id) && c.homeCourseOrCity ? ` (${c.homeCourseOrCity})` : '')).join(', ')).join(', ') :
                    skin.contacts.length > 1 ? skin.contacts.length + (competition.scoring.format === 'skins_individual' ? ' golfers' : ' teams') : '',
                style: 'tableData'
            }
        ]));
        docDef.content.push({
            text: Scoring.scoringName(competition, event.eventGender, competition.competitionGender, skinsMixed).toLocaleUpperCase(),
            style: 'subheaderM'
        });
        docDef.content.push(docTable(body, widths));
    });
    return createPdfDoc(docDef);
}

export function createScorecardsDoc(event: EventBase, badgeData: string | null | undefined, teeGroups: Array<ContactGroup>, golferScores: Map<string, Score>, teamScores: Map<string, Score>, golfers: Map<string, Contact>, teams: Map<string, Team>, groups: Array<GolferGroup>, distances: Map<string, Distance>, competitions: Array<Competition>, groupCodes: Map<string, string>, includeScores?: boolean, notes?: string) {
    const docName = eventName(event);
    const competitionsMain = competitions.filter(comp => isMainScoring(comp.scoring));
    if (competitionsMain.length === 0) {
        return;
    }
    const eventInfo = getGolferInfo(event, competitions, golfers, teams, groups);
    const noBorder = [false, false, false, false];
    const holesRange = getHolesRange(event.holesType);
    const totalHoles = holesRange.last - holesRange.first;
    const rowHeightLabels = 16;
    const rowHeight = 16;
    const mainCompetition = getEventMainCompetition(competitionsMain);
    const bestBall = mainCompetition.scoring.format === 'best_ball';
    const isNet = isNetMode(mainCompetition.scoring.mode);
    const courseHoleCount = event.holesType === HOLES_9 || event.holesType === HOLES_9_9 ? 9 : 18;
    const courseName = getCourseName(event.course);
    teeGroups = teeGroups.filter(groupItem => groupItem.group && groupItem.group.contactIds.length > 0);
    const docDef: any = {
        pageSize: 'LETTER',
        pageOrientation: 'landscape',
        content: [],
        pageMargins: [10, 10, 10, 10],
        styles: docStyles()
    };
    if (badgeData) {
        docDef.images = { badge: badgeData };
    }
    if (GolfPadLogoImg) {
        docDef.images = { ...docDef.images, logo: GolfPadLogoImg };
    }
    // Iterate schedule groups
    const sameNameGolfersIdsSet = getSameNameGolfersIds(Array.from(golfers.values()));
    const individualMainWithTeams = isIndividualScoring(mainCompetition.scoring) && event.teamSize > 1;
    teeGroups.forEach((groupItem, groupIdx) => {
        const group = groupItem.group!;
        const checkTeams = isTeamScoring(mainCompetition.scoring) || individualMainWithTeams;
        if (!group.contactIds.some(id => checkTeams ?
            teams.get(id)?.contactIds?.some(cid => golfers.get(cid) && !golfers.get(cid)?.hidden) :
            golfers.get(id) && !golfers.get(id)?.hidden)) { // group has no golfers to show
            if (docDef.content?.length) {
                docDef.content[docDef.content.length - 1].pageBreak = undefined;
            }
            return;
        }
        const startingHoles = getStartingHoles(event.teeTime.startingHolesType, event.teeTime.startingHoles, holesRange, event.teeTime.mode);
        const groupGolfers: Array<{
            id: string,
            hasHcp: boolean,
            lineAfter: boolean,
            bestBallLine?: boolean,
            name: string,
            initials: string,
            scoreItem: ContactScoringState
        }> = [];
        const tees: Array<Tee> = checkTeams ?
            eventInfo.getGroupTees(group.contactIds.map(cid => teams.has(cid) ? teams.get(cid)!.contactIds : []).reduce((arr, curr) => [...arr, ...curr], [])) :
            eventInfo.getGroupTees(group.contactIds);
        const hasLens = !!eventInfo.getTees().find(t => (t.len && t.len.find(l => !!l)));
        const headerSize = hasLens ? 3 + tees.filter(t => t.len && t.len.find(l => !!l)).length : 3;
        const highlightStartingHoles = !(event.teeTime.mode === 'regular' && (startingHoles[0] || 0) === 0);
        if (individualMainWithTeams) {
            group.contactIds = group.contactIds.map(cid => {
                if (individualMainWithTeams && teams.get(cid)) {
                    return teams.get(cid)!.contactIds;
                }
                return [];
            }).reduce((prev, cur) => [...prev, ...cur], []);
        }
        // Iterate golfers or team contacts in the group
        group.contactIds.forEach(contactId => {
            const player: Contact | Team | undefined = isTeamScoring(mainCompetition.scoring) ? teams.get(contactId) : golfers.get(contactId);
            if (!player) {
                return;
            }
            const reportedScores = new Map<string, ReportedScore>();
            const reportedTeamScores = new Map<string, ReportedScore>();
            const scoreItem: ContactScoringState = Scoring.golferHoleScore(player, event, mainCompetition, golferScores, teamScores, reportedScores, reportedTeamScores, golfers, groups, Array.from<Team>(teams.values()), mainCompetition);
            if (!scoreItem) {
                return;
            }
            if (isTeamScoring(mainCompetition.scoring)) {
                const teamContacts = scoreItem.contactInfo.contacts;
                teamContacts.forEach((contact, idx) => {
                    const initials = contactInitials(contact, true);
                    const handicapAllowance = getHandicapsAllowance(mainCompetition.scoring, idx);
                    let name = shorterName(fullName(contact));
                    if (sameNameGolfersIdsSet.has(contact.id) && contact.homeCourseOrCity) {
                        name += ` (${contact.homeCourseOrCity})`;
                    }
                    if (bestBall) {
                        name += ' (' + formatHandicap(getPlayingHandicap(event.holesType, getTee(event, mainCompetition, contact.gender, contact), event.handicapSystem, getGolferRangeHandicap(event.holesType, event.handicapSystem, contact.handicapIndex || 0), handicapAllowance)) + ')';
                    } else if (idx === teamContacts.length - 1) {
                        name += ' (' + formatHandicap(scoreItem.contactInfo.teamPlayingHandicap ?? scoreItem.contactInfo.playingHandicaps.reduce((sum, val) => sum += val)) + ')';
                    }
                    if (bestBall) {
                        groupGolfers.push({
                            id: contact.id,
                            hasHcp: true,
                            lineAfter: true,
                            scoreItem: { ...scoreItem, courseHandicap: scoreItem.contactInfo.playingHandicaps[idx] },
                            initials,
                            name
                        });
                    } else {
                        groupGolfers.push({
                            id: contact.id,
                            hasHcp: idx === 0,
                            lineAfter: idx === event.teamSize - 1,
                            scoreItem,
                            initials,
                            name
                        });
                    }
                });
                for (let idx = teamContacts.length; idx < event.teamSize; idx++) {
                    groupGolfers.push({
                        id: '',
                        hasHcp: idx === 0 || bestBall,
                        lineAfter: idx === event.teamSize - 1 || bestBall,
                        scoreItem,
                        initials: '',
                        name: ''
                    });
                }
                if (bestBall) {
                    groupGolfers.push({
                        id: '',
                        hasHcp: true,
                        lineAfter: true,
                        bestBallLine: true,
                        scoreItem,
                        initials: '',
                        name: isNet ? 'Best ball, net' : 'Best ball'
                    });
                }
            } else {
                const initials = contactInitials(scoreItem.player as Contact, true);
                const handicapAllowance = getHandicapsAllowance(mainCompetition.scoring, 0);
                let homeCourseOrCity: string | undefined;
                if (sameNameGolfersIdsSet.has(contactId)) {
                    const contact: Contact | undefined = scoreItem.player as Contact;
                    homeCourseOrCity = contact?.homeCourseOrCity && contact.homeCourseOrCity;
                }
                const name = shorterName(fullName(scoreItem.player as Contact)) + (homeCourseOrCity ? ` (${homeCourseOrCity})` : '') + ' (' + formatHandicap(getPlayingHandicap(event.holesType, getTee(event, mainCompetition, scoreItem.player.gender, scoreItem.player as Contact), event.handicapSystem, getGolferRangeHandicap(event.holesType, event.handicapSystem, scoreItem.player.handicapIndex || 0), handicapAllowance)) + ')';
                groupGolfers.push({
                    id: contactId,
                    hasHcp: true,
                    lineAfter: true,
                    scoreItem,
                    initials,
                    name
                });
            }
        });
        if (groupGolfers.length === 0) {
            return;
        }

        // CARD HEADER
        const eventHeader: Array<any> = [{ text: docName + '\n', fontSize: 17, bold: true }];
        if (courseName) {
            eventHeader.push({ text: courseName + '\n', bold: true });
        }
        if (mainCompetition) {
            eventHeader.push({ text: makeFriendlyString(scoringName(mainCompetition) + '\n', true), bold: true });
        }
        eventHeader.push({ text: formatDateDashed1(event.date) + '\n' });
        const headerBody: Array<any> = [
            { image: 'badge' },
            { text: eventHeader },
            {
                image: 'logo',
                alignment: 'right',
                width: 212,
                height: 60
            }
        ];
        const cardHeader = {
            table: {
                widths: [100, '*', '*'],
                body: [headerBody]
            },
            layout: 'noBorders'
        };
        if (!badgeData) {
            cardHeader.table.widths.splice(0, 1);
            cardHeader.table.body[0].splice(0, 1);
        }
        docDef.content.push(cardHeader);

        let teesInfo = '';
        tees.forEach((t, i) => {
            if (tees.length === 2 && tees.filter((tee, i, arr) => arr.findIndex(tt => tt.gender === tee.gender) === i).length === 2) {
                teesInfo += (t.gender === 'female' ? 'Women - ' : 'Men - ') + getTeeNameEx(t, event.holesType);
            } else {
                teesInfo += eventInfo.isGroupTeeMees(group.contactIds) ? getTeeNameWg(t, event.holesType) : getTeeNameEx(t, event.holesType);
            }
            if (i < tees.length - 1) {
                teesInfo += ' ';
            };
        });

        const teeHeader = {
            table: {
                body: [[
                    { text: teesInfo + '\n' }
                ]]
            },
            layout: 'noBorders'
        };
        docDef.content.push(teeHeader);

        if (tees.length < 2 || groupGolfers.length < 6) {
            docDef.content.push({ text: ' ' });
        }

        const teeFillColor: Map<string, string> = new Map();
        const teeColor: Map<string, string> = new Map();
        const emptiness = { border: noBorder, text: '_', color: WHITE, fillColor: WHITE };
        const heights: Array<number | string> = [rowHeightLabels, rowHeightLabels, rowHeightLabels];
        const widths: Array<number | string> = [totalHoles === 9 ? 200 : 120];
        const labels: Array<any> = [{ text: 'Hole', alignment: 'left' }];
        const par1: Array<any> = [{ text: 'Par', alignment: 'left' }];
        const hcp1: Array<any> = [{ text: 'Handicap', alignment: 'left' }];
        const body = [labels];
        let lens = new Map<string, Array<any>>();
        let hcpMap = new Map<string, Array<any>>();
        let teeLines = 0;
        tees.forEach(t => {
            const teeName = eventInfo.isGroupTeeMees(group.contactIds) ? t.name + (t.gender === 'male' ? ' - men' : ' - women') : t.name;
            let lenFillColor = t.name.toLowerCase().replace(' ', '');
            if (COLOR_NAMES.indexOf(lenFillColor) < 0) {
                lenFillColor = LIGHT_GREY;
            }
            const lenColor = getContrastTextColor(lenFillColor) || '';
            teeFillColor.set(t.id, lenFillColor);
            teeColor.set(t.id, lenColor);
            const hcp1InMap: Array<any> = [{
                text: `Handicap ${teeName}`,
                alignment: 'left',
                color: lenColor,
                fillColor: lenFillColor
            }];
            hcpMap.set(t.id, hcp1InMap);
            if (t.len && t.len.find(l => !!l)) {
                const len: Array<any> = [{ text: teeName, alignment: 'left', color: lenColor, fillColor: lenFillColor }];
                lens.set(t.id, len);
                body.push(len);
                teeLines++;
            }
        });
        body.push(par1);
        tees.forEach(t => {
            if (hcpMap.has(t.id)) {
                body.push(hcpMap.get(t.id)!);
                teeLines++;
            }
        });
        const teeTodoRef1 = tees[0];
        const teeTodoRef2 = tees[1];
        const displayHCP = (tee: Tee, hole: number, useHcp2: boolean) => {
            if (tee) {
                if (event.holesType === HOLES_9_9) {
                    if (hole < 9) {
                        if (!useHcp2 || !tee.handicap2 || !tee.handicap2[hole]) {
                            return 2 * tee.handicap[hole % courseHoleCount] - 1;
                        } else {
                            return (2 * tee.handicap[hole % courseHoleCount] - 1) + '/' + (2 * tee.handicap2[hole % courseHoleCount] - 1);
                        }
                    } else {
                        if (!useHcp2 || !tee.handicap2 || !tee.handicap2[hole]) {
                            return 2 * tee.handicap[hole % courseHoleCount];
                        } else {
                            return (2 * tee.handicap[hole % courseHoleCount]) + '/' + (2 * tee.handicap2[hole % courseHoleCount]);
                        }
                    }
                }
                if (totalHoles === 9 && tee.handicap.length === 18) {
                    if (!useHcp2 || !tee.handicap2 || !tee.handicap2[hole]) {
                        return Math.round(tee.handicap[hole] / 2);
                    } else {
                        return Math.round(tee.handicap[hole] / 2) + '/' + (tee.handicap2[hole] / 2);
                    }
                }
                if (!useHcp2 || !tee.handicap2 || !tee.handicap2[hole]) {
                    return tee.handicap[hole];
                } else {
                    return tee.handicap[hole] + '/' + tee.handicap2[hole];
                }
            } else {
                return ' ';
            }
        };
        const lensTotal: Map<string, number> = new Map();
        const cellWidth = '*';
        const startingHolesMarks = new Set();
        const pars4BothTees = teeTodoRef1 && teeTodoRef2 && sumArray(teeTodoRef1.par) !== sumArray(teeTodoRef2.par);
        // HEADER: FRONT NINES
        if (holesRange.first === 0) {
            let startHole;
            if (event.teeTime.mode === 'regular') {
                startHole = startingHoles[0] || 0;
            } else {
                startHole = getTeeTimeShotgunHole(group.order, startingHoles);
            }
            if (startHole < 9 && highlightStartingHoles) {
                startingHolesMarks.add(startHole + 1);
            }
            const parSum = pars4BothTees ? `${sumArray(teeTodoRef1.par.slice(0, 9))}/${sumArray(teeTodoRef2.par.slice(0, 9))}` : teeTodoRef1 ? sumArray(teeTodoRef1.par.slice(0, 9)) : ' ';
            const lensSum: Map<string, number> = new Map();
            for (let hole = 0; hole < 9; hole++) {
                const parVal = pars4BothTees ? `${teeTodoRef1.par[hole]}/${teeTodoRef2.par[hole]}` : teeTodoRef1 ? teeTodoRef1.par[hole] : ' ';
                widths.push(cellWidth);
                labels.push({ text: getHoleLabel(hole, holesRange) });
                par1.push({ text: parVal, fontSize: pars4BothTees ? 9 : 10 });
                hcp1.push({ text: displayHCP(teeTodoRef1, hole, event.handicapSystem === 'WHS_AU'), fontSize: 10 });
                tees.forEach(t => {
                    hcpMap.get(t.id)?.push({
                        text: displayHCP(t, hole, event.handicapSystem === 'WHS_AU'),
                        color: teeColor.get(t.id) || BLACK,
                        fillColor: teeFillColor.get(t.id) || LIGHT_GREY
                    });
                    if (lens.has(t.id)) {
                        if (lensSum.has(t.id)) {
                            lensSum.set(t.id, (lensSum.get(t.id) || 0) + (t.len && t.len[hole] ? t.len[hole] : 0));
                        } else {
                            lensSum.set(t.id, t.len && t.len[hole] ? t.len[hole] : 0);
                        }
                        lens.get(t.id)!.push({
                            text: t.len && t.len[hole] ? t.len[hole] : ' ',
                            color: teeColor.get(t.id) || BLACK,
                            fillColor: teeFillColor.get(t.id) || LIGHT_GREY
                        });
                    }
                });
            }
            tees.forEach(t => {
                if (lensSum.has(t.id)) {
                    lensTotal.set(t.id, lensSum.get(t.id) || 0);
                }
            });
            if (totalHoles === MAX_HOLES) {
                widths.push(pars4BothTees ? 30 : cellWidth);
                labels.push({ text: 'Out  ', bold: true });
                par1.push({ text: parSum || ' ', bold: true, fontSize: pars4BothTees ? 9 : 10 });
                hcp1.push({ text: ' ', bold: true });
                tees.forEach(t => {
                    if (lens.has(t.id)) {
                        lens.get(t.id)!.push({
                            text: lensSum.get(t.id) || ' ',
                            bold: true,
                            color: teeColor.get(t.id) || BLACK,
                            fillColor: teeFillColor.get(t.id) || LIGHT_GREY
                        });
                    }
                    hcpMap.get(t.id)?.push({ text: ' ' });
                });
            }
        }

        // HEADER: MIDDLE SPACE
        let idxMiddle = -1;
        if (totalHoles === MAX_HOLES) {
            widths.push('auto');
            labels.push({ ...emptiness });
            par1.push({ ...emptiness });
            hcp1.push({ ...emptiness });
            tees.forEach(t => {
                if (lens.has(t.id)) {
                    lens.get(t.id)!.push({ ...emptiness });
                }
                hcpMap.get(t.id)?.push({ ...emptiness });
            });

            // NAME
            idxMiddle = labels.length;
            widths.push(isNet ? 60 : 90);
            labels.push({ text: 'Name' });
            par1.push({ text: ' ' });
            hcp1.push({ text: ' ' });
            tees.forEach(t => {
                if (lens.has(t.id)) {
                    lens.get(t.id)!.push({
                        text: ' ',
                        color: teeColor.get(t.id) || BLACK,
                        fillColor: teeFillColor.get(t.id) || LIGHT_GREY
                    });
                }
                hcpMap.get(t.id)?.push({ text: ' ' });
            });
        }

        // HEADER: BACK NINES
        if (holesRange.last === MAX_HOLES) {
            let startHole;
            if (event.teeTime.mode === 'regular') {
                startHole = startingHoles[0] || 0;
            } else {
                startHole = getTeeTimeShotgunHole(group.order, startingHoles);
            }
            if (startHole >= 9 && highlightStartingHoles) {
                if (holesRange.first === 0) {
                    startingHolesMarks.add(startHole - 9 + 13);
                } else {
                    startingHolesMarks.add(startHole - 9 + 1);
                }
            }
            const parSum = pars4BothTees ? `${sumArray(teeTodoRef1.par.slice(9, MAX_HOLES))}/${sumArray(teeTodoRef2.par.slice(9, MAX_HOLES))}` : teeTodoRef1 ? sumArray(teeTodoRef1.par.slice(9, MAX_HOLES)) : ' ';
            const lensSum: Map<string, number> = new Map();
            for (let holeRaw = 9; holeRaw < MAX_HOLES; holeRaw++) {
                const hole = holeRaw % courseHoleCount;
                const parVal = pars4BothTees ? `${teeTodoRef1.par[hole]}/${teeTodoRef2.par[hole]}` : teeTodoRef1 ? teeTodoRef1.par[hole] : ' ';
                widths.push(cellWidth);
                labels.push({ text: getHoleLabel(hole, holesRange) });
                par1.push({ text: parVal, fontSize: pars4BothTees ? 9 : 10 });
                hcp1.push({ text: displayHCP(teeTodoRef1, holeRaw, event.handicapSystem === 'WHS_AU'), fontSize: 10 });
                tees.forEach(t => {
                    hcpMap.get(t.id)?.push({
                        text: displayHCP(t, hole, event.handicapSystem === 'WHS_AU'),
                        color: teeColor.get(t.id) || BLACK,
                        fillColor: teeFillColor.get(t.id) || LIGHT_GREY
                    });
                    if (lens.has(t.id)) {
                        if (lensSum.has(t.id)) {
                            lensSum.set(t.id, (lensSum.get(t.id) || 0) + (t.len && t.len[hole] ? t.len[hole] : 0));
                        } else {
                            lensSum.set(t.id, t.len && t.len[hole] ? t.len[hole] : 0);
                        }
                        lens.get(t.id)!.push({
                            text: t.len && t.len[hole] ? t.len[hole] : ' ',
                            color: teeColor.get(t.id) || BLACK,
                            fillColor: teeFillColor.get(t.id) || LIGHT_GREY
                        });
                    }
                });
            }
            tees.forEach(t => {
                if (lensSum.has(t.id)) {
                    if (lensTotal.has(t.id)) {
                        lensTotal.set(t.id, (lensTotal.get(t.id) || 0) + (lensSum.get(t.id) || 0));
                    } else {
                        lensTotal.set(t.id, (lensSum.get(t.id) || 0));
                    }
                }
            });
            if (totalHoles === MAX_HOLES) {
                widths.push(pars4BothTees ? 30 : cellWidth);
                labels.push({ text: 'In   ', bold: true });
                par1.push({ text: parSum || ' ', bold: true, fontSize: pars4BothTees ? 9 : 10 });
                hcp1.push({ text: ' ', bold: true });
                tees.forEach(t => {
                    if (lens.has(t.id)) {
                        lens.get(t.id)!.push({
                            text: lensSum.get(t.id) || ' ',
                            bold: true,
                            color: teeColor.get(t.id) || BLACK,
                            fillColor: teeFillColor.get(t.id) || LIGHT_GREY
                        });
                    }
                    hcpMap.get(t.id)?.push({ text: ' ' });
                });
            }
        }
        const parTotal = pars4BothTees ? `${sumArray(teeTodoRef1.par)}/${sumArray(teeTodoRef2.par)}` : teeTodoRef1 ? sumArray(teeTodoRef1.par) : ' ';
        widths.push(30);
        labels.push({ text: 'Total', bold: true });
        par1.push({ text: parTotal || ' ', bold: true, fontSize: pars4BothTees ? 9 : 10 });
        hcp1.push({ text: ' ' });
        tees.forEach(t => {
            if (lens.has(t.id)) {
                lens.get(t.id)!.push({
                    text: lensTotal.get(t.id) || ' ',
                    bold: true,
                    color: teeColor.get(t.id) || BLACK,
                    fillColor: teeFillColor.get(t.id) || LIGHT_GREY
                });
            }
            hcpMap.get(t.id)?.push({ text: ' ' });
        });
        if (isNet) {
            widths.push(totalHoles === MAX_HOLES ? 30 : cellWidth);
            labels.push({ text: 'Net', bold: true });
            par1.push({ text: ' ' });
            hcp1.push({ text: ' ' });
            tees.forEach(t => {
                if (lens.has(t.id)) {
                    lens.get(t.id)!.push({
                        text: ' ',
                        color: teeColor.get(t.id) || BLACK,
                        fillColor: teeFillColor.get(t.id) || LIGHT_GREY
                    });
                }
                hcpMap.get(t.id)?.push({ text: ' ' });
            });
        }
        const svg = (cont: string) => `<svg width="24" height="4" viewBox="0 0 24 4"><rect width="100%" height="100%" style="fill:rgba(255,255,255,0);stroke-width:0" />${cont}</svg>`;
        const noLines = new Set();
        const bestBallLines = new Set();

        // TABLE CONTENT
        let rowsHeight = 0;
        groupGolfers.forEach(c => {
            ++rowsHeight;
            const cTee = (eventInfo.isTeeMees() && eventInfo.tees.has(c.id)) ? eventInfo.tees.get(c.id) : null;
            const teeName = cTee ? cTee.name : null;
            const teeColor = cTee && teeFillColor.has(cTee.id) ? teeFillColor.get(cTee.id)! : BLACK;
            const flightName = mainCompetition.flights && golfers.has(c.id) ? (getFlightName(getFlightOfContact(mainCompetition, golfers, teams, golfers.get(c.id)!) || 0, mainCompetition.flightsNaming, false, false)) : undefined;
            const nameOrFlightWidth = totalHoles === NINE_HOLES ? 55 : 38;
            const teeNameWidth = totalHoles === NINE_HOLES ? 50 : 35;
            const fullWidth = 2 * nameOrFlightWidth + teeNameWidth;
            const cw = [nameOrFlightWidth];
            const nameRow: Array<any> = [
                { text: c.name, bold: true, width: '*', alignment: 'left' }
            ];
            const flightNameRow = {
                text: ' ' + flightName,
                width: nameOrFlightWidth,
                background: '#fbfba5',
                bold: true,
                alignment: 'left'
            };
            const teeNameRow = {
                text: ' ' + teeName,
                color: teeColor,
                width: teeNameWidth,
                bold: true,
                alignment: 'left'
            };
            if (flightName && teeName) {
                ++rowsHeight;
                nameRow.push({ text: [flightNameRow, teeNameRow], width: '*' });
                cw.push(nameOrFlightWidth);
                cw[0] += teeNameWidth;
            } else if (flightName) {
                nameRow.push(flightNameRow);
                cw.push(nameOrFlightWidth);
                cw[0] += teeNameWidth;
            } else if (teeName) {
                nameRow.push(teeNameRow);
                cw.push(teeNameWidth);
                cw[0] += nameOrFlightWidth;
            } else {
                cw[0] = fullWidth;
            }
            const row: Array<any> = [[
                { svg: svg('') },
                {
                    table: {
                        widths: cw,
                        body: [nameRow]
                    },
                    layout: 'noBorders'
                }]];
            body.push(row);
            if (c.bestBallLine) {
                bestBallLines.add(body.length - 1);
            }
            if (!c.lineAfter) {
                noLines.add(body.length);
            }
            heights.push(rowHeight);
            const scores = includeScores ? c.scoreItem.score : undefined;
            let scoreTotal = 0;
            const hs = event.handicapSystem ? event.handicapSystem : (c.scoreItem.tee ? c.scoreItem.tee.handicapSystem : 'WHS');
            const circles = (i: number, start: boolean) => {
                if (!start || !isNet) {
                    return svg('');
                }
                let res = '';
                let hcp = getHoleHandicapByHC(hs, i, event.holesType, c.scoreItem.tee, c.scoreItem.courseHandicap);
                let hcpCol = '#000000';
                if (hcp < 0) {
                    hcp = -hcp;
                    hcpCol = WHITE;
                }
                const R = 1.6;
                const DX = 0.2;
                for (let hi = 0; hi < hcp; hi++) {
                    res += `<circle r="${R}" cy="${DX + R}" cx="${DX + R + hi * R * 2.5}" stroke-width="0.4" stroke="#000000" fill="${hcpCol}"/>`;
                }
                return res;
            };
            // CONTENT: FRONT NINES
            if (holesRange.first === 0) {
                let scoreSum = 0;
                for (let i = 0; i < 9; i++) {
                    row.push([
                        { svg: !c.bestBallLine ? svg(circles(i, c.hasHcp)) : svg('') },
                        { text: scores && scores[i] ? scores[i] : ' ' }
                    ]);
                    scoreSum += scores && scores[i] ? scores[i]! : 0;
                }
                if (totalHoles === MAX_HOLES) {
                    row.push([
                        { svg: svg('') },
                        { text: scoreSum || ' ', bold: true }
                    ]);
                }
                scoreTotal += scoreSum;
            }
            // CONTENT: MIDDLE DATA
            if (totalHoles === MAX_HOLES) {
                row.push({ border: noBorder, text: ' ' });
                row.push([
                    { svg: svg('') },
                    { text: c.initials, bold: true, alignment: 'left' }
                ]);
            }
            // CONTENT: BACK NINES
            if (holesRange.last === MAX_HOLES) {
                let scoreSum = 0;
                for (let i = 9; i < MAX_HOLES; i++) {
                    row.push([
                        { svg: !c.bestBallLine ? svg(circles(i, c.hasHcp)) : svg('') },
                        { text: scores && scores[i] ? scores[i] : ' ' }
                    ]);
                    scoreSum += scores && scores[i] ? scores[i]! : 0;
                }
                if (totalHoles === MAX_HOLES) {
                    row.push([{
                        svg: svg('')
                    }, {
                        text: scoreSum || ' ',
                        bold: true,
                    }]);
                }
                scoreTotal += scoreSum;
            }
            row.push([{
                svg: svg('')
            }, {
                text: scoreTotal || ' ',
                bold: true,
            }]);
            if (isNet) {
                row.push([{
                    svg: svg('')
                }, {
                    text: includeScores ? c.scoreItem.net : ' ',
                    bold: true,
                }]);
            }
        });
        const layout = {
            fillColor: (rowIndex: number, node: any, columnIndex: number) => {
                if (rowIndex === 0) {
                    return LIGHT_BLUE;
                }
                if (startingHolesMarks.has(columnIndex)) {
                    return rowIndex < headerSize ? LIGHT_YELLOW_GREY : LIGHT_YELLOW;
                }
                return rowIndex < headerSize ? LIGHT_GREY : bestBallLines.has(rowIndex) ? LIGHT_BROWN : null;
            },
            vLineWidth: (rowIndex: number, node: any, columnIndex: number) => 0.5,
            hLineWidth: (rowIndex: number, node: any, columnIndex: number) => 0.5,
            hLineColor: (rowIndex: number, node: any, columnIndex: number) => noLines.has(rowIndex) ? '#FFFFFF' : null,
            paddingLeft: (rowIndex: number, node: any, columnIndex: number) => rowIndex === 0 || rowIndex === idxMiddle ? 4 : 0,
            paddingRight: (rowIndex: number, node: any, columnIndex: number) => 0,
            paddingTop: (rowIndex: number, node: any, columnIndex: number) => rowIndex < headerSize || rowIndex === idxMiddle ? 5 : 1,
            marginLeft: (rowIndex: number, node: any, columnIndex: number) => 0,
            marginRight: (rowIndex: number, node: any, columnIndex: number) => 0,
        };
        const scorecardOffset = totalHoles === MAX_HOLES ? '*' : 148;
        docDef.content.push({
            columns: [
                { width: scorecardOffset, text: '' },
                {
                    fontSize: 10,
                    alignment: 'center',
                    layout,
                    width: 'auto',
                    table: {
                        heights,
                        widths,
                        body
                    }
                },
                { width: scorecardOffset, text: '' }
            ]
        });
        const signaturesOffset = totalHoles === MAX_HOLES ? 0 : 148;
        const layoutSign = {
            hLineWidth: () => 0.5,
            paddingLeft: () => 0,
            marginLeft: () => 0,
            paddingRight: () => 0,
            marginRight: () => 0,
        };
        if (rowsHeight < 9) {
            docDef.content.push({ text: ' ' });
        }
        docDef.content.push({
            fontSize: 12,
            layout: layoutSign,
            table: {
                widths: ['*', 'auto', '*'],
                body: [[{
                    text: '',
                    border: noBorder,
                    layout: layoutSign,
                    marginLeft: signaturesOffset,
                    table: {
                        widths: ['auto', '*'],
                        body: [[
                            { text: 'Marker:', border: noBorder },
                            { text: ' ', border: [false, false, false, true] },
                        ]]
                    }
                }, {
                    ...emptiness
                }, {
                    text: '',
                    border: noBorder,
                    layout: layoutSign,
                    marginRight: signaturesOffset,
                    table: {
                        widths: ['auto', '*'],
                        body: [[
                            { text: 'Competitor:', border: noBorder },
                            { text: ' ', border: [false, false, false, true] },
                        ]]
                    }
                },
                ]]
            }
        });
        const startingTimeText = teeTimeLabel(event, group.order);
        const startingHoleNumText = teeStartingHoleLabel(event, group.order);
        const QRLink = Urls.makeScorecardInviteDeepLink(event.id, group.id);
        const GPIC = groupCodes.get(group.id) ?? '';
        const optionalRowsShownAmount = (courseName && mainCompetition ? 2 : courseName || mainCompetition ? 1 : 0) +
            teeLines;
        const baseOffsetY = 75 - (((rowsHeight > 5 ? rowsHeight : 0) + optionalRowsShownAmount) * 6);
        docDef.content.push(getCommonElements(baseOffsetY, startingHoleNumText, startingTimeText, GPIC, QRLink,
            startingHoleNumText !== '1' ? '#FDEFC7' : '#F4F4F4'));
        docDef.content.push({ text: ' ' });
        docDef.content.push({
            fontSize: 10,
            table: {
                widths: ['*'],
                body: [[{
                    border: noBorder,
                    layout: layoutSign,
                    table: {
                        widths: ['*'],
                        body: [[
                            { text: notes, border: noBorder },
                        ]]
                    }
                }]]
            }
        });
        if (groupIdx < teeGroups.length - 1) {
            docDef.content[docDef.content.length - 1].pageBreak = 'after';
        }
    });
    return createPdfDoc(docDef);
}

const getCommonElements = (baseOffsetY: number, startingHoleNumText: string, startingTimeText: string, GPIC: string,
    QRLink: string, secondRectColor: string, deltaX2: number = 0, deltaX3: number = 0,
    deltaW3: number = 0, pageBreakNotLast?: 'after'): Array<Style | Content | ContentQr> => {
    return [
        {
            relativePosition: { x: 0, y: baseOffsetY },
            canvas: [
                {
                    type: 'rect',
                    w: 194,
                    h: 118,
                    x: 0,
                    y: 0,
                    color: '#F4F4F4',
                },
                {
                    type: 'rect',
                    w: 194,
                    h: 118,
                    x: 220 + deltaX2,
                    y: 0,
                    color: secondRectColor,
                },
                {
                    type: 'rect',
                    w: 332 + deltaW3,
                    h: 118,
                    x: 440 + deltaX3,
                    y: 0,
                    color: '#F4F4F4',
                },
            ]
        },
        {
            text: 'Starting time:',
            lineHeight: 1.35,
            fontSize: 21,
            bold: true,
            color: '#343434',
            fontFamily: 'Poppins',
            fontStyle: 'normal',
            fontWeight: 600,
            relativePosition: {
                x: 26,
                y: baseOffsetY + 16
            },
        } as Style,
        {
            text: startingTimeText,
            lineHeight: 1.2,
            fontSize: 36,
            bold: true,
            color: '#343434',
            fontFamily: 'Poppins',
            fontStyle: 'normal',
            fontWeight: 600,
            relativePosition: {
                x: startingTimeText.length === 7 ? 25 : 15,
                y: baseOffsetY + 56
            },
        } as Style,
        {
            text: 'Starting hole:',
            lineHeight: 1.35,
            fontSize: 21,
            bold: true,
            color: '#343434',
            fontFamily: 'Poppins',
            fontStyle: 'normal',
            fontWeight: 600,
            relativePosition: {
                x: 246 + deltaX2,
                y: baseOffsetY + 16
            },
        } as Style,
        {
            text: startingHoleNumText,
            lineHeight: 1.2,
            fontSize: 36,
            bold: true,
            color: '#343434',
            fontFamily: 'Poppins',
            fontStyle: 'normal',
            fontWeight: 600,
            relativePosition: {
                x: (startingHoleNumText.length === 1 ? 310 : 300) + deltaX2,
                y: baseOffsetY + 56
            },
        } as Style,
        {
            qr: QRLink,
            fit: 120,
            foreground: '#025987',
            background: '#F4F4F4',
            relativePosition: {
                x: 460 + deltaX3,
                y: baseOffsetY + 10
            },
        } as ContentQr,
        {
            text: 'Scan QR code',
            lineHeight: 1.2,
            fontSize: 21,
            bold: true,
            color: '#343434',
            fontFamily: 'Poppins',
            fontStyle: 'normal',
            fontWeight: 600,
            relativePosition: {
                x: 590 + deltaX3,
                y: baseOffsetY + 16
            },
        } as Style,
        {
            text: 'Or use GPIC',
            lineHeight: 1.5,
            fontSize: 12,
            color: '#343434',
            fontFamily: 'Poppins',
            fontStyle: 'normal',
            fontWeight: 500,
            relativePosition: {
                x: 626 + deltaX3,
                y: baseOffsetY + 56
            },
        } as Style,
        {
            text: '(Golf Pad Invitation Code):',
            lineHeight: 1.5,
            fontSize: 12,
            color: '#343434',
            fontFamily: 'Poppins',
            fontStyle: 'normal',
            fontWeight: 500,
            relativePosition: {
                x: 591 + deltaX3,
                y: baseOffsetY + 70
            },
        } as Style,
        {
            text: [{ text: GPIC, bold: true }, ' in the app'],
            lineHeight: 1.5,
            fontSize: 12,
            color: '#343434',
            fontFamily: 'Poppins',
            fontStyle: 'normal',
            fontWeight: 500,
            relativePosition: {
                x: 616 + deltaX3,
                y: baseOffsetY + 96
            },
            pageBreak: pageBreakNotLast
        } as Style
    ];
};

class GolferInfo {
    competitions: Map<string, Array<{ competition: Competition, hcp: number, teamIndex: number }>> = new Map();
    tees: Map<string, Tee> = new Map();
    allMenTee?: Pair<Tee | null, Competition | null>;
    allWomenTee?: Pair<Tee | null, Competition | null>;
    teeTimes: Map<string, { teeTime?: TeeTime, order?: number }> = new Map();
    teeErrors: Array<string> = [];

    public addCompetition(event: EventBase, competition: Competition, golfers: Map<string, Contact>, teams: Map<string, Team>, groups: Array<GolferGroup>) {
        if (isTeamScoring(competition.scoring)) {
            if (competition.everyone) {
                // TODO: remove
                /*if (isMainScoring(competition.scoring) && !this.allMenTee && !!competition!.tees![0]) {
                    this.allMenTee = { key: competition!.tees![0], value: competition };
                }
                if (isMainScoring(competition.scoring) && !this.allWomenTee && !!competition!.tees![1]) {
                    this.allWomenTee = { key: competition!.tees![1], value: competition };
                }*/
                teams.forEach(team => {
                    if (team.contactIds) {
                        team.contactIds.forEach((golferId, teamIndex) => {
                            const golfer = golfers.get(golferId);
                            this.add(golfer, competition, event, golferTeamHcp(competition, team, golfers, golferId), teamIndex);
                        });
                    }
                });
            } else if (competition.contactIds) {
                competition.contactIds.forEach(teamId => {
                    const team = teams.get(teamId);
                    if (team && team.contactIds) {
                        team.contactIds.forEach((golferId, teamIndex) => {
                            const golfer = golfers.get(golferId);
                            this.add(golfer, competition, event, golferTeamHcp(competition, team, golfers, golferId), teamIndex);
                        });
                    }
                });
            }
        } else {
            if (competition.everyone) {
                // TODO: remove
                /*if (isMainScoring(competition.scoring) && !this.allMenTee && !!competition!.tees![0]) {
                    this.allMenTee = { key: competition!.tees![0], value: competition };
                }
                if (isMainScoring(competition.scoring) && !this.allWomenTee && !!competition!.tees![1]) {
                    this.allWomenTee = { key: competition!.tees![1], value: competition };
                }*/
                golfers.forEach(golfer => {
                    this.add(golfer, competition, event, golferHcp(competition), 0);
                });
            } else if (competition.contactIds) {
                competition.contactIds.forEach(golferId => {
                    const golfer = golfers.get(golferId);
                    this.add(golfer, competition, event, golferHcp(competition), 0);
                });
            }
        }
    }

    private add(golfer: Contact | undefined, competition: Competition, event: EventBase, hcp: number, teamIndex: number) {
        if (!golfer) {
            return;
        }
        if (!this.competitions.has(golfer.id)) {
            this.competitions.set(golfer.id, []);
        }
        const tee: Tee | undefined | null = getTee(event, competition, golfer.gender, golfer);
        if (tee) {
            if (!this.tees.has(golfer.id)) {
                this.tees.set(golfer.id, tee);
                // console.log('golfer.id: ' + golfer.id + ' got tee.id: ' + tee.id);
            } else {
                const hasTee: Tee | undefined | null = this.tees.get(golfer.id);
                // console.log('golfer.id: ' + golfer.id + ' already has tee.id: ' + hasTee!.id + '. conflict with another: ' + tee.id + 'checkTee: ' + checkTee);
                if (!!tee && !!hasTee && tee!.id !== hasTee!.id) {
                    const gName = fullName(golfer);
                    if (this.teeErrors.indexOf(gName) < 0) {
                        this.teeErrors.push(gName);
                    }
                }
            }
        }
        this.competitions.get(golfer.id)!.push({ competition, hcp, teamIndex });
    }

    public setTeeTime(id: string, order?: number, teeTime?: TeeTime) {
        if (teeTime) {
            this.teeTimes.set(id, { order, teeTime });
        }
    }

    public getTeeTime(id: string) {
        return this.teeTimes.get(id)!.teeTime;
    }

    public getTeeTimeOrder(id: string) {
        return this.teeTimes.get(id) ? this.teeTimes.get(id)!.order : 999;
    }

    public getCompetitions(id: string) {
        return this.competitions.get(id);
    }

    public getGroupTees(memberIds: string[]) {
        const teesArray: Array<Tee> = [];
        this.tees.forEach((tee, contactId) => {
            if (memberIds.includes(contactId) && teesArray.findIndex(t => t.id === tee.id) < 0) {
                teesArray.push(tee);
            }
        });
        return teesArray;
    }

    public isGroupTeeMees(memberIds: string[]) {
        const tees = this.getGroupTees(memberIds);
        return tees.filter((tee, i, arr) => arr.findIndex(tt => tt.gender === tee.gender) === i).length > 1;
    }

    public getTees() {
        const teesArray: Array<Tee> = Array.from(this.tees.values());
        return teesArray.filter(
            (tee, i, arr) => arr.findIndex(t => t.id === tee.id) === i
        );
    }

    public isTeeMees() {
        const tees = this.getTees();
        return tees.length > 2 || (tees.length > tees.filter((tee, i, arr) => arr.findIndex(tt => tt.gender === tee.gender) === i).length);
    }

    public isGolferTeeMees() {
        return this.teeErrors.length > 0;
    }

    public teeConflictMessage() {
        return !this.isGolferTeeMees ? '' :
            'Tee selection conflict: ' + this.teeErrors[0] + (this.teeErrors.length === 1 ? ' is ' : ' and ' + (this.teeErrors.length - 1) + ' more golfer(s) are ') + 'already part of a competition with different tees assigned. Please adjust tees or golfers before saving competition.';
    }

    public teeWarningMessage(conflictComp: string) {
        return !this.isGolferTeeMees ? '' :
            'Tee selection conflict: this competition may include some of the same participants as ' + conflictComp + ' but tees are different. Please adjust tees or golfers before saving competition.';
    }
}

export function golferTeamHcp(competition: Competition, team: Team, golfers: Map<string, Contact>, golferId: string) {
    let hcp = 0;
    if (team.contactIds) {
        golfersOfTeam(team, golfers).forEach((golfer, i) => {
            if (golfer.id === golferId && competition.scoring.handicaps) {
                hcp = competition.scoring.handicaps[i];
            }
        });
    }
    return hcp;
}

function golferHcp(competition: Competition) {
    let hcp = 0;
    if (competition.scoring.handicaps) {
        hcp = competition.scoring.handicaps[0];
    }
    return hcp;
}

export function getGolferInfo(event: EventBase, competitions: Array<Competition>, golfers: Map<string, Contact>, teams: Map<string, Team>, groups: Array<GolferGroup>, unsavedCompetition?: Competition) {
    const golferCompetitions = new GolferInfo();
    competitions.forEach(competition => {
        if (!unsavedCompetition || unsavedCompetition.id !== competition.id) {
            golferCompetitions.addCompetition(event, competition, golfers, teams, groups);
        }
    });
    if (unsavedCompetition) {
        golferCompetitions.addCompetition(event, unsavedCompetition, golfers, teams, groups);
    }
    golfers.forEach(golfer => {
        let playerId = '';
        if (event.teamSize > 1) {
            teams.forEach(team => {
                if (team.contactIds.includes(golfer.id)) {
                    playerId = team.id;
                }
            });
        } else {
            playerId = golfer.id;
        }
        const group = groups.find(gr => gr.contactIds.indexOf(playerId) >= 0);
        const holesRange = getHolesRange(event.holesType);
        const teeTime = group ? getTeeTime(event.teeTime, holesRange, group.order) : undefined;
        if (group) {
            golferCompetitions.setTeeTime(golfer.id, group!.order, teeTime);
        }
    });
    return golferCompetitions;
}

export function createGolfersDoc(event: EventBase, competitions: Array<Competition>, golfers: Map<string, Contact>, teams: Map<string, Team>, groups: Array<GolferGroup>) {
    const mainCompetitions = competitions.filter(competition => isMainScoring(competition.scoring));
    const netCompetitions = competitions.filter(competition =>
        (isIndividualScoringOrBB(competition.scoring) || competition.scoring.format === 'skins_individual') && isNetMode(competition.scoring.mode));
    const golferInfo = getGolferInfo(event, netCompetitions, golfers, teams, groups);
    const docDef: any = {
        pageSize: 'LETTER',
        pageOrientation: 'landscape',
        content: [],
        pageMargins: [20, 20, 20, 20],
        styles: docStyles()
    };
    const cardHeader = {
        table: {
            widths: ['100%'],
            body: [
                [{ text: eventName(event), fontSize: 26, bold: true, alignment: 'center' }],
                [{
                    text: getCourseName(event.course) + (getCourseName(event.course) ? ', ' : '') + formatDateDashed1(event.date),
                    alignment: 'center'
                }],
                [{ text: 'GOLFERS', fontSize: 14, bold: true, alignment: 'center' }]
            ]
        },
        layout: 'noBorders'
    };
    docDef.content.push(cardHeader);
    docDef.content.push({ text: ' ' });
    const rowHeightLabels = 18;
    const rowHeight = 16;
    const hcpWidth = 56;
    const heights: Array<number | string> = [rowHeightLabels];
    const labels: Array<any> = [
        { text: 'Name', style: 'tableHeader' },
        { text: event.teeTime.mode === 'regular' ? 'Time' : 'Hole', style: 'tableHeader', alignment: 'center' }
    ];
    const widths: Array<number | string> = ['30%', hcpWidth];
    const body = [labels];
    const withFlights = mainCompetitions.findIndex(c => c.flights && c.flights > 0) >= 0;
    if (withFlights) {
        labels.push({ text: 'Flight', style: 'tableHeader', alignment: 'center' });
        widths.push(hcpWidth);
    }
    labels.push({ text: 'Index', style: 'tableHeader', alignment: 'center' });
    widths.push(hcpWidth);
    const withTee = Array.from(golfers.values()).findIndex(c => !!c.tee) >= 0;
    if (withTee) {
        labels.push({ text: 'Tee', style: 'tableHeader', alignment: 'center' });
        widths.push(hcpWidth);
    }
    const allGolfers: Array<Contact> = Array.from(golfers.values());
    const hcpSet = new Set<number>();
    netCompetitions.forEach(competition => {
        if (competition.scoring.handicaps) {
            competition.scoring.handicaps.forEach(hcp => hcpSet.add(hcp));
        }
    });
    let hcpArray = Array.from(hcpSet.values()).sort((a, b) => b - a);
    if (hcpArray.length > 4) {
        hcpArray = hcpArray.slice(0, 4);
    }
    hcpArray.forEach(hcp => {
        labels.push({ text: 'Playing hcp ' + hcp + '%', style: 'tableHeader', alignment: 'center' });
        widths.push(hcpWidth);
    });
    widths.push('*');
    labels.push({ text: 'Comments', style: 'tableHeader' });
    const compareContacts = (a: Contact, b: Contact) => {
        let orderA = golferInfo.teeTimes.has(a.id) ? golferInfo.getTeeTimeOrder(a.id) : Number.MAX_VALUE;
        let orderB = golferInfo.teeTimes.has(a.id) ? golferInfo.getTeeTimeOrder(b.id) : Number.MAX_VALUE;
        if (orderA === undefined) {
            orderA = 10000;
        }
        if (orderB === undefined) {
            orderB = 10000;
        }
        if (orderA === orderB) {
            return fullLastName(a).localeCompare(fullLastName(b));
        }
        return orderA - orderB;
    };
    const sameNameGolfersIdsSet = getSameNameGolfersIds(allGolfers);
    const mainCompetition = getEventMainCompetition(competitions);
    allGolfers.sort(compareContacts).forEach(golfer => {
        const row: Array<any> = [
            { text: fullLastName(golfer).concat(sameNameGolfersIdsSet.has(golfer.id) && golfer.homeCourseOrCity ? ` (${golfer.homeCourseOrCity})` : '') },
            {
                text: golferInfo.teeTimes.has(golfer.id) ? teeTimeName(golferInfo.getTeeTime(golfer.id)) : 'N/A',
                alignment: 'center'
            }
        ];
        if (withFlights) {
            const flightName = getFlightName(getFlightOfContact(mainCompetition, golfers, teams, golfer) || 0, mainCompetition.flightsNaming, false, true);
            row.push({ text: flightName, alignment: 'center' });
        }
        row.push({ text: formatHandicap(golfer.handicapIndex), alignment: 'center' });
        if (withTee) {
            const teeName = golfer.tee === null || golfer.tee === undefined ? 'Default' : golfer.tee.name;
            row.push({ text: teeName, alignment: 'center' });
        }
        heights.push(rowHeight);
        const comps = golferInfo.getCompetitions(golfer.id);
        hcpArray.forEach(hcp => {
            const compHcp = comps ? comps.find(comp => comp.hcp === hcp) : undefined;
            if (compHcp) {
                const handicapIndex = golfer.handicapIndex;
                const handicapAllowance = getHandicapsAllowance(compHcp.competition.scoring, compHcp.teamIndex);
                const tee = getTee(event, isSkinsScoring(compHcp.competition.scoring) && mainCompetition ? mainCompetition : compHcp.competition, golfer.gender, golfer);
                const playingHandicap = getPlayingHandicap(event.holesType, tee, event.handicapSystem, handicapIndex, handicapAllowance);
                row.push({ text: formatHandicap(playingHandicap), alignment: 'center' });
            } else {
                row.push({ text: '', alignment: 'center' });
            }

        });
        row.push({ text: '' });
        body.push(row);
    });
    docDef.content.push(docTable(body, widths));
    return createPdfDoc(docDef);
}

export function createCartSignsDoc(event: EventBase, cartSigns: CartSignItem[], sameNameGolfersIdsSet: Set<string>, groupCodes: Map<string, string>) {
    if (!cartSigns) {
        return;
    }
    const docDef: PdfDefs.TDocumentDefinitions = {
        pageSize: 'LETTER',
        pageOrientation: 'landscape',
        pageMargins: [20, 20, 20, 0],
        content: [],
        styles: docStyles()
    };
    if (GolfPadLogoImg) {
        docDef.images = { logo: GolfPadLogoImg };
    }
    cartSigns.forEach((crt, i) => {
        const cardHeader: Content = {
            table: {
                widths: ['65%', '35%'],
                body: []
            } as Table,
            layout: 'noBorders'
        };
        const cardBody: Content = {
            table: {
                widths: ['100%'],
                heights: ['auto', 'auto', 160, 'auto', 'auto'],
                body: [],
            } as Table,
            layout: 'noBorders'
        };
        const card: Content = {
            table: {
                widths: ['100%'],
                body: []
            } as Table,
            layout: 'noBorders',
        };
        const names: Content = {
            table: {
                widths: ['100%'],
                body: []
            } as Table,
            layout: 'noBorders',
            margin: [0, 35, 0, 0]
        };
        const noBorder = [false, false, false, false];
        const emptiness = { border: noBorder, text: '_', color: WHITE, fillColor: WHITE };
        cardHeader.table.body.push([
            {
                text: eventName(event),
                margin: [0, 4, 0, 0],
                fontSize: 24,
                bold: true,
                color: '#343434',
                fontFamily: 'Poppins',
                fontStyle: 'normal',
                fontWeight: 600,
                lineHeight: '135%',
                alignment: 'left'
            },
            {
                image: 'logo',
                alignment: 'right',
                width: 212,
                height: 60,
            }
        ],
            [
                {
                    text: getCourseName(event.course),
                    fontSize: 18,
                    alignment: 'left',
                    lineHeight: '120%',
                    color: '#343434',
                    fontFamily: 'Poppins',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    marginTop: -22
                },
                emptiness
            ]);
        card.table.body.push([cardHeader]);
        crt.golfers.forEach((g, i) => {
            if ((g.firstName ? g.firstName!.length : 0) + g.lastName.length < 18) {
                names.table.body.push([{
                    text: fullName(g).toLocaleUpperCase().concat(sameNameGolfersIdsSet.has(g.id) && g.homeCourseOrCity ? ` (${g.homeCourseOrCity})` : ''),
                    fontSize: 54,
                    margin: [0, crt.golfers.length > 1 ? 10 : 20, 0, 0],
                    bold: true,
                    alignment: 'center',
                    lineHeight: 1.2,
                    fontWeight: 800,
                    color: '#343434',
                    fontFamily: 'Poppins',
                    fontStyle: 'normal'
                }]);
            } else {
                names.table.body.push([{
                    text: (g.firstName ? cutString(g.firstName.toLocaleUpperCase(), 20) + '\n' : '') + cutString(g.lastName.toLocaleUpperCase(), 20) + (sameNameGolfersIdsSet.has(g.id) && g.homeCourseOrCity ? ` (${g.homeCourseOrCity})` : ''),
                    fontSize: 42,
                    margin: [0, crt.golfers.length > 1 ? 0 : 5, 0, i === 0 ? 4 : 0],
                    bold: true,
                    alignment: 'center',
                    lineHeight: 1.2,
                    fontWeight: 800,
                    color: '#343434',
                    fontFamily: 'Poppins',
                    fontStyle: 'normal'
                }]);
            }
        });
        cardBody.table.body.push([names]);
        const holesRange = getHolesRange(event.holesType);
        const teeTime = getTeeTime(event.teeTime, holesRange, crt.group.order);
        const startTime = evalStartTime(event.teeTime);
        let startingTimeText: string;
        let startingHoleNumText: string;
        if (isRegular(teeTime)) {
            const holes = getStartingHoles(event.teeTime.startingHolesType, event.teeTime.startingHoles, holesRange, event.teeTime.mode);
            let startHole = holes.length > 0 ? holes[0] : 0;
            startingHoleNumText = `${(startHole + 1)}`;
            if (holesRange.first !== 0) {
                startHole -= holesRange.first;
            }
            startingTimeText = formatTime(teeTime);
        } else {
            startingTimeText = formatTime(startTime);
            startingHoleNumText = teeTime;
        }
        card.table.body.push([cardBody]);
        const qrLink = Urls.makeScorecardInviteDeepLink(event.id, crt.group.id);
        const GPIC = groupCodes.get(crt.group.id) ?? '';
        (docDef.content as Array<Style | Content | ContentQr>).push([{
            table: {
                heights: 395,
                widths: ['100%'],
                body: [[card]],
            },
            layout: 'noBorders',
            margin: [4, 0, 4, 0],
        }],
            getCommonElements(-30, startingHoleNumText, startingTimeText, GPIC, qrLink, '#F4F4F4',
                -5, -10, -10, (i !== cartSigns.length - 1) ? 'after' : undefined) as Array<Content>
        );
    });
    return createPdfDoc(docDef);
}

export function createMoneyListDoc(event: Event, competitions: Array<Competition>, golferScores: Map<string, Score>, teamScores: Map<string, Score>, reportedScores: Map<string, ReportedScore>, reportedTeamScores: Map<string, ReportedScore>, golfers: Map<string, Contact>, teams: Map<string, Team>, groups: Array<GolferGroup>, distances: Map<string, Distance>) {
    const content: Array<Content> = [
        {
            text: event.name,
            style: 'header'
        },
        {
            text: 'TOTAL MONEY LIST',
            style: 'subheader'
        }
    ];
    const docDef: PdfDefs.TDocumentDefinitions = {
        content,
        pageOrientation: 'landscape',
        pageMargins: [10, 10, 10, 10],
        styles: docStyles()
    };

    const [splitMainCompetitions, splitSideCompetitions] = getSplitCompetitionsWithPayouts(competitions);
    const allCompetitions = splitMainCompetitions.concat(splitSideCompetitions);
    const isTeam = event.teamSize > 1;
    const widths: Array<string | number> = ['*'];
    const body: Array<Array<Content>> = [];
    const skinsMixed = competitions.some(comp => comp.scoring.format === ScoringFormatTeams.best_ball);
    const row: Array<Content> = [
        { text: isTeam ? 'Team' : 'Golfer', style: 'tableHeader' }
    ];
    const columnWidth = allCompetitions.length > 8 ? 60 :
        allCompetitions.length > 5 ? 65 :
            allCompetitions.length === 5 ? 70 :
                allCompetitions.length === 4 ? 80 :
                    allCompetitions.length === 3 ? 95 : 115;
    const totalWidth = 65;
    splitMainCompetitions.forEach(comp => {
        if (isNetPayouts(comp)) {
            row.push({ text: makeFriendlyString(comp.scoring.format + ', Net', true), style: 'tableHeader' });
            widths.push(columnWidth);
        }
        if (isGrossPayouts(comp)) {
            row.push({ text: makeFriendlyString(comp.scoring.format + ', Gross', true), style: 'tableHeader' });
            widths.push(columnWidth);
        }
    });

    splitSideCompetitions.forEach(comp => {
        if (isSideScoringWithPayouts(comp)) {
            row.push({
                text: Scoring.scoringName(comp, event.eventGender, comp.competitionGender, skinsMixed),
                style: 'tableHeader'
            });
            widths.push(columnWidth);
        }
    });

    row.push({ text: 'Total', style: 'tableHeader' });
    widths.push(totalWidth);

    body.push(row);
    const moneyList: ContactPayoutState[] = eventPayoutsStates(event, competitions, golferScores, teamScores, reportedScores, reportedTeamScores, golfers, teams, groups, distances, getSameNameGolfersIds(Array.from(golfers.values())));
    moneyList.filter(ps => ps.total > 0).forEach(ps => {
        const golferName = ps.names.join(' + ');
        const row: Array<Content> = [
            { text: golferName, style: 'tableData' }
        ];
        splitMainCompetitions.forEach(comp => {
            if (isNetPayouts(comp)) {
                row.push({
                    text: '$' + (ps.awards.has(comp.id + 'net') ? formatCurrency(ps.awards.get(comp.id + 'net')!) : '0'),
                    style: 'tableData'
                });
            }
            if (isGrossPayouts(comp)) {
                row.push({
                    text: '$' + (ps.awards.has(comp.id + 'gross') ? formatCurrency(ps.awards.get(comp.id + 'gross')!) : '0'),
                    style: 'tableData'
                });
            }
        });
        splitSideCompetitions.forEach(comp => {
            if (isSideScoringWithPayouts(comp)) {
                row.push({
                    text: '$' + (ps.awards.has(comp.id) ? formatCurrency(ps.awards.get(comp.id)!) : '0'),
                    style: 'tableData'
                });
            }
        });
        row.push({ text: '$' + formatCurrency(ps.total), style: 'tableData' });
        body.push(row);
    });

    if (moneyList.length > 0) {
        const paidOutRow: Array<Content> = [
            { text: 'Paid out', style: 'tableData' }
        ];
        let wasRed = false;
        let paidOutTotal: number = 0;
        const competitionIds = allCompetitions.map(comp => isMainScoring(comp.scoring) ? (comp.id + comp.scoring.mode) : comp.id);
        competitionIds.forEach(id => {
            let competitionPaidOutSum: number = 0;
            for (const payoutState of moneyList) {
                competitionPaidOutSum += payoutState.awards?.get(id) ?? 0;
            }
            const competition = allCompetitions.find(comp => id === (isMainScoring(comp.scoring) ? (comp.id + comp.scoring.mode) : comp.id))!;
            const purse = getFullCompetitionPurse(event, competition, golfers, teams, competitions, golferScores, teamScores, reportedScores, reportedTeamScores, groups);
            competitionPaidOutSum = round(competitionPaidOutSum, 2);
            paidOutTotal += competitionPaidOutSum;
            const significantPayoutAndPurseDifference = (Math.abs(purse - competitionPaidOutSum) > (0.005 * purse)); // to avoid difference as a result of rounding, (0.5%)
            wasRed = wasRed || significantPayoutAndPurseDifference;
            paidOutRow.push({
                text: `$${competitionPaidOutSum}`,
                style: significantPayoutAndPurseDifference ? 'tableDataRed' : 'tableData'
            });
        });
        paidOutRow.push({ text: `$${round(paidOutTotal, 2)}`, style: wasRed ? 'tableDataRed' : 'tableData' });
        body.push(paidOutRow);
    }

    const purseRow: Array<Content> = [
        { text: 'Purse', style: 'tableData' }
    ];
    let totalPurse: number = 0;
    allCompetitions.forEach(competition => {
        let competitionPurse: number;
        if (isNetPayouts(competition) || isGrossPayouts(competition)) {
            competitionPurse = getFullCompetitionPurse(event, competition, golfers, teams, competitions, golferScores, teamScores, reportedScores, reportedTeamScores, groups);
            totalPurse += competitionPurse;
            purseRow.push({ text: `$${competitionPurse}`, style: 'tableData' });
        }
    });
    purseRow.push({ text: `$${round(totalPurse, 2)}`, style: 'tableData' });
    body.push(purseRow);
    (docDef.content as Array<Content>).push(docTable(body, widths));
    return createPdfDoc(docDef);
}
