import * as React from 'react';
import 'react-quill/dist/quill.snow.css';
import { CommonEditDialogProps, EditDialogProps } from '../WithEditDialog';
import RichTextQuill from './RichTextQuill';
import RichTextView from './RichTextView';
import withEditableField from '../WithEditField';
import { FlexCenter } from '../../../common/Misc';

interface RichTextProperties {
    label: string;
    value: string;
    template: string;
    placeholder: string;
    save: (val: string) => void;
}

type Props = EditDialogProps<string> & RichTextProperties & CommonEditDialogProps<string>;

class RichTextField extends React.Component<Props> {

    private handleChange = (val: string) => {
        this.props.setValue(val);
    }

    render() {
        const { value } = this.props;
        const { template } = this.props;
        return <FlexCenter padding={0} margin={'8px auto'} height={'90%'} maxWidth={1012}><RichTextQuill value={value || template} onChange={this.handleChange} /></FlexCenter>;
    }
}

function asHtml(val: string) {
    return <RichTextView value={val} />;
}

export default withEditableField<RichTextProperties, string>({ toShow: asHtml, fullScreen: true, skipEnterKey: true, contentPadding: '0' })(RichTextField);
