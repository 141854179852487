import Quill from 'quill';

const BlockEmbed = Quill.import('blots/block/embed');

interface Props {
    src: string;
    width?: string;
    style?: string;
}

export default class ImageBlot extends BlockEmbed {

    static create({src, width, style}: Props) {
        const img = document.createElement('img');
        img.setAttribute('src', src);
        if (width) {
            img.setAttribute('width', width);
        }
        if (style) {
            img.setAttribute('style', style);
        }
        return img;
    }

    static value(img: HTMLElement) {
        const width = img.getAttribute('width');
        const style = img.getAttribute('style');
        const src = img.getAttribute('src');
        return {src, width, style};
    }
}

ImageBlot.blotName = 'image';
ImageBlot.tagName = 'img';
