export abstract class BaseModule {
    protected overlay: any;
    protected img: any;
    protected requestUpdate: any;

    constructor(img: HTMLImageElement, overlay: HTMLDivElement, requestUpdate: () => void) {
        this.img = img;
        this.overlay = overlay;
        this.requestUpdate = requestUpdate;
    }

    /**
     *  onCreate will be called when a new element is selected
     *  Here you can create any UI for the selected element
     */
    abstract onCreate: () => void;

    /**
     * onDestroy will be called when the element is de-selected, or when this module otherwise needs to tidy up.
     * If you created any DOM elements in onCreate, please remove them from the DOM and destroy them here.
     */
    abstract onDestroy: () => void;
}
