import * as React from 'react';
import {
    List, ListItem, ListItemButton, TextField, Typography, Divider, Checkbox, Radio, RadioGroup, MenuItem,
    FormControlLabel, Accordion, AccordionSummary, AccordionDetails, DialogContent, DialogActions
} from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import ArrowDown from '@mui/icons-material/ArrowDropDown';
import ArrowUp from '@mui/icons-material/ArrowDropUp';
import SortIcon from "@mui/icons-material/ArrowDownward";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import DataTable, { TableColumn, Alignment, TableStyles } from "react-data-table-component";
import axios, { AxiosResponse } from 'axios';
import { CSVLink } from 'react-csv';
import * as Backend from '../util/firebase';
import { EVENT_DATETIME_FORMAT_DLG, Urls } from '../util/config';
import { showProgress } from '../redux/ReduxConfig';
import AppButton from '../common/components/AppButton';
import ButtonBar from '../common/components/ButtonBar';
import { UserAware, userProviderContextTypes } from '../auth/Auth';
import { pushUrl } from '../redux/ReduxConfig';
import { styles } from '../styles';
import * as Utils from '../util/utility';
import { processEnterKey } from '../util/react_utils';
import { Container, Item, TitledLink } from '../common/Misc';
import { showAlert, showAlertProps } from '../redux/ReduxConfig';
import { EventLog, Event, EventStats, UserStats, Announcement } from '../types/EventTypes';
import ChangelogDetailsDialog from '../event/tabs/tools/ChangelogDetailsDialog';
import RecentCoursesSelectionDialog from '../event/tabs/settings/general/course/RecentCoursesSelectionDialog';
import { DateRangeStatisticsInfo, RangeStatisticsContents } from "./DateRangeStatisticsDialog";
import { errMsg, setWithMergePromise, toArray } from "../util/firebase";
import { BkCloseButton } from 'src/common/Icons';
import { XSMobileDialog } from "../common/dialog/MobileDialog";
import MaterialDate from "../common/MaterialDate";
import DialogAppBar from "../common/dialog/DialogAppBar";
import { getUserToday } from "../util/utility";
import { AppColors } from "../main/Theme";
import RichTextQuill from 'src/common/form/richtext/RichTextQuill';
import AnnouncementAlert from 'src/main/AnnouncementAlert';

const USERS_PER_PAGE = 50;
const EVENTS_PER_PAGE = 50;
const DETAILS_RECENT = 1;
const DETAILS_SAVED = 2;
const EVENTS_LIST = 3;
const USERS_PER_REQUEST = 1000;

const TEXT_FIELD_SIZE = 'small';
const TEXT_FIELD_VARIANT = 'filled';

export type SupMode = 'announcement' | 'users' | 'logs' | 'events' | 'active_admins' | 'fbquery' | 'bigquery';
export const SupModes = ['announcement', 'users', 'logs', 'events', 'active_admins', 'fbquery', 'bigquery'] as Array<SupMode>;
export const SupModeLabels = ['Announcement', 'Users', 'Change logs', 'Events', 'Active Admins', 'FirebaseQuery', 'BigQuery'];

const collGroup = true;
const FB_COLLECTIONS = [
    { name: 'events' },
    { name: 'event-competitions' },
    { name: 'competitions', collGroup },
    { name: 'event-golfers' },
    { name: 'contacts', collGroup },
    { name: 'groups', collGroup },
    { name: 'event-logs' },
    { name: 'logs', collGroup },
    { name: 'event-mapping' },
    { name: 'event-payments' },
    { name: 'event-scores' },
    { name: 'scores', collGroup },
    { name: 'event-stats' },
    { name: 'images' },
    { name: 'portals' },
    { name: 'reported-scores' },
    { name: 'user-invites' },
    { name: 'user-stats' },
    { name: 'users' },
    { name: 'users-pub' },
];

interface Dataset {
    id: string;
}

const PD = (dateField?: string) => `FORMAT_DATETIME('%Y %h %e, %R', PARSE_DATETIME('%s', CAST(TRUNC(${dateField}/1000) AS STRING)))`;

type QueryElem = {
    name: string;
    dates?: Array<string>;
    dateField?: string;
    expandable?: boolean;
    description?: React.ReactNode;
    aliases?: Map<string, string>;
    query?: (dataset: string) => string;
};

type QueryParam = {
    name: string;
    value: any;
};

const EVENTS_DATES = ['date', 'lastModified'];

const BQ_QUERIES: Array<QueryElem> = [{
    name: 'Events basic info',
    description: 'Events list with basic information',
    expandable: true,
    dates: EVENTS_DATES,
    aliases: new Map([
        ['eventId', 'events.__key__.name']
    ]),
    query: (dataset: string) => `
SELECT
  events.__key__.name AS eventId,
  publicId,
  name AS eventName,
  userId,
  userName,
  userEmail,
  course.name AS courseName,
  handicapSystem,
  teeTime.mode AS mode,
  ${PD('date')} AS eventDate,
  ${PD('lastModified')} AS lastModifiedDate
FROM
  ${dataset}.events
WHERE
  leaderboard IS FALSE WHERE_OTHER
ORDER BY
  date DESC`
}, {
    name: 'Events & Admins',
    description: 'Events list with admin names',
    dates: EVENTS_DATES,
    aliases: new Map([
        ['eventId', 'events.__key__.name']
    ]),
    query: (dataset: string) => `
SELECT
  __key__.name AS eventId,
  ${PD('date')} AS eventDate,
  userId,
  userName,
  userEmail
FROM
  ${dataset}.events
WHERE
  leaderboard IS FALSE WHERE_OTHER
ORDER BY
  date DESC`
}, {
    name: 'All admins emails',
    description: 'List all admin emails',
    dates: EVENTS_DATES,
    aliases: new Map([
        ['eventId', 'events.__key__.name']
    ]),
    query: (dataset: string) => `
  SELECT DISTINCT
    userEmail
  FROM
    ${dataset}.events
  WHERE
    userEmail IS NOT NULL AND userEmail != '' WHERE_OTHER`
}, {
    name: 'Events & Reported Scores',
    description: 'Events list with corresponding calculated scores (stroke sums)',
    expandable: true,
    dates: EVENTS_DATES,
    aliases: new Map([
        ['eventId', 'events.__key__.name']
    ]),
    query: (dataset: string) => `
WITH
  reported_scores AS (
    WITH
      reported_scores_i AS (
      SELECT
        __key__.path AS reported_scores_path,
        TRIM(REPLACE(SPLIT(__key__.path)[1], '"', '')) as reported_scores_event_id,
        TRIM(REPLACE(SPLIT(__key__.path)[3], '"', '')) as reported_scores_id,
        ustroke,
      FROM ${dataset}.\`reported-scores\`, UNNEST(strokes) AS ustroke )
    SELECT reported_scores_event_id, reported_scores_id, SUM(ustroke) AS reportedScore
    FROM reported_scores_i
    GROUP BY reported_scores_event_id, reported_scores_id ),
  admin_scores AS (
    WITH
      admin_scores_i AS (
      SELECT
        __key__.path AS admin_score_path,
        TRIM(REPLACE(SPLIT(__key__.path)[1], '"', '')) as admin_scores_event_id,
        TRIM(REPLACE(SPLIT(__key__.path)[3], '"', '')) as admin_scores_id,
        gross,
        ugross,
      FROM ${dataset}.scores, UNNEST(gross) AS ugross )
    SELECT admin_scores_event_id, admin_scores_id, SUM(ugross) AS adminScore
    FROM admin_scores_i
    GROUP BY admin_scores_event_id, admin_scores_id )
SELECT
  events.__key__.name AS eventId,
  userId,
  userName,
  PARSE_DATETIME('%s', CAST(TRUNC(date/1000) AS STRING)) AS eventDate,
  handicapSystem,
  course.name AS courseName,
  handicapSystem,
  teeTime.mode AS mode,
  reported_scores_id as golferId,
  reportedScore,
  adminScore
FROM ${dataset}.events AS events
LEFT JOIN reported_scores ON reported_scores_event_id = events.__key__.name
LEFT JOIN admin_scores ON admin_scores_event_id = events.__key__.name AND reported_scores_id = admin_scores_id
WHERE 
  leaderboard=FALSE WHERE_OTHER
ORDER BY
  date DESC, eventId, reported_scores_id, admin_scores_id`
}, {
    name: 'Events & Raw Scores',
    description: 'Events list with non-empty score values',
    dates: EVENTS_DATES,
    query: (dataset: string) => `
WITH
  event_scores AS (
  SELECT
    events.__key__.name AS eventId,
    userId,
    userName,
    date,
    FORMAT_DATETIME('%Y %h %e, %R', PARSE_DATETIME('%s', CAST(TRUNC(date/1000) AS STRING))) AS eventDate,
    course.name AS courseName,
    handicapSystem,
    teeTime.mode AS mode,
    --gross[0] AS firstGross,
    TRIM(REPLACE(SPLIT(scores.__key__.path)[1], '"', '')) AS evntId,
    TRIM(REPLACE(SPLIT(scores.__key__.path)[3], '"', '')) AS golferId,
    gross,
    deleted
  FROM
    ${dataset}.events AS events
  LEFT JOIN
    ${dataset}.scores AS scores
  ON
    INSTR(scores.__key__.path, events.__key__.name) > 0 )
SELECT
  eventId, userName, userId, eventDate, handicapSystem, mode, golferId, gross
FROM
  event_scores
WHERE
  gross IS NOT NULL WHERE_OTHER
ORDER BY
  date DESC, eventId, golferId`
}, {
    name: 'Events per User',
    description: 'Selects # of events per user',
    dates: EVENTS_DATES,
    query: (dataset: string) => `
SELECT
  userId,
  userName,
  userEmail,
  COUNT(*) AS num,
  PARSE_DATETIME('%s', CAST(TRUNC(MIN(date)/1000) AS STRING)) AS dateMin,
  PARSE_DATETIME('%s', CAST(TRUNC(MAX(date)/1000) AS STRING)) AS dateMax
FROM
  ${dataset}.events AS events
WHERE
  leaderboard=FALSE WHERE_OTHER
GROUP BY
  userId,
  userName,
  userEmail
ORDER BY
  num DESC`
}, {
    name: 'Events per Date',
    dates: EVENTS_DATES,
    description: 'Selects # of events per day',
    query: (dataset: string) => `
SELECT
  FORMAT_DATE('%e %b %Y', PARSE_DATETIME('%s', CAST(TRUNC(date/1000) AS STRING))) AS day,
  count(*) as num
FROM 
  ${dataset}.events AS events
WHERE
  leaderboard=FALSE WHERE_OTHER
GROUP BY day
ORDER BY PARSE_DATE('%e %b %Y', day) DESC`
}, {
    name: 'Events per Month',
    dates: EVENTS_DATES,
    description: 'Selects # of events per month',
    query: (dataset: string) => `
SELECT
  FORMAT_DATE('%b %Y', PARSE_DATETIME('%s', CAST(TRUNC(date/1000) AS STRING))) AS month,
  count(*) as num
FROM 
  ${dataset}.events AS events
WHERE
  leaderboard=FALSE WHERE_OTHER
GROUP BY month
ORDER BY PARSE_DATE('%b %Y', month) DESC`
}, {
    name: 'Events per Month & User',
    dates: EVENTS_DATES,
    description: 'Selects # of events per month and per user',
    query: (dataset: string) => `
SELECT
  FORMAT_DATE('%b %Y', PARSE_DATETIME('%s', CAST(TRUNC(date/1000) AS STRING))) AS month,
  userId,
  userName,
  userEmail,
  COUNT(*) AS num
FROM
  ${dataset}.events AS events
WHERE
  leaderboard=FALSE WHERE_OTHER
GROUP BY
  month,
  userId,
  userName,
  userEmail
ORDER BY
  PARSE_DATE('%b %Y', month) DESC`
}, {
    name: 'Events & Golfers',
    description: 'Event list with participant information',
    dates: EVENTS_DATES,
    aliases: new Map([
        ['eventId', 'events.__key__.name']
    ]),
    query: (dataset: string) => `
WITH
  contacts AS (
  SELECT
    --__key__.path AS contact_path,
    TRIM(REPLACE(SPLIT(__key__.path)[1], '"', '')) AS golferEventId,
    TRIM(REPLACE(SPLIT(__key__.path)[3], '"', '')) AS golferId,
    email,
    firstName,
    lastName,
    gender,
    handicapId,
    handicapIndex.provided AS hcpType,
    playingHandicap.provided AS playingHcpType,
    CASE WHEN handicapIndex.float IS NOT NULL THEN handicapIndex.float ELSE handicapIndex.integer END handicapIndex,
    CASE WHEN playingHandicap.float IS NOT NULL THEN playingHandicap.float ELSE playingHandicap.integer END playingHandicap,
    disqualified,
    withdrawn,
    homeCourseOrCity
  FROM
    ${dataset}.contacts
  WHERE
    hidden=FALSE )
SELECT
  events.__key__.name AS eventId,
  events.name AS eventNname,
  golferId,
  email,
  firstName,
  lastName,
  gender,
  handicapId,
  --hcpType,
  handicapIndex,
  --playingHcpType,
  playingHandicap,
  CASE WHEN disqualified IS TRUE THEN 'TRUE' ELSE 'FALSE' END disqualified,
  CASE WHEN withdrawn IS TRUE THEN 'TRUE' ELSE 'FALSE' END withdrawn,
  homeCourseOrCity
FROM
  ${dataset}.events AS events
LEFT JOIN
  contacts
ON
  --INSTR(contact_path, events.__key__.name) > 0
  golferEventId = events.__key__.name
WHERE
  leaderboard=FALSE WHERE_OTHER
ORDER BY
  date DESC,
  eventId,
  golferId`
}, {
    name: 'Events & Participants Count',
    description: 'Event list with participant count info',
    dates: EVENTS_DATES,
    aliases: new Map([
        ['eventId', 'events.__key__.name']
    ]),
    query: (dataset: string) => `
SELECT
  events.__key__.name AS eventId,
  name AS eventName,
  userId,
  userName,
  userEmail,
  PARSE_DATETIME('%s', CAST(TRUNC(events.date/1000) AS STRING)) AS eventDate,
  --ARRAY_AGG(contacts.__key__.path) AS contactIds,
  ARRAY_LENGTH(ARRAY_AGG(contacts.__key__.path)) AS participantsCount
FROM
  ${dataset}.events AS events
LEFT JOIN
  ${dataset}.contacts AS contacts
ON
  INSTR(contacts.__key__.path, events.__key__.name) > 0
WHERE
  contacts.__key__.path IS NOT NULL
  AND contacts.hidden=FALSE
  AND leaderboard=FALSE WHERE_OTHER
GROUP BY
  events.__key__.name,
  events.name,
  userId,
  userName,
  userEmail,
  date
ORDER BY
  date DESC,
  eventId`
}, {
    name: 'Roster',
    description: 'User roster - the historic list of all user golfers',
    query: (dataset: string) => `
SELECT
  TRIM(REPLACE(SPLIT(__key__.path)[1], '"', '')) AS userId,
  __key__.name as golferId,
  email,
  firstName,
  lastName,
  gender,
  handicapId,
  CASE WHEN handicapIndex.float IS NOT NULL THEN handicapIndex.float ELSE handicapIndex.integer END handicapIndex,
FROM
  ${dataset}.roster
WHERE
  TRUE WHERE_OTHER
ORDER BY
  userId, golferId`
}, {
    name: 'All record counts',
    description: 'Event list with participant count info',
    query: (dataset: string) => `
WITH
  counts AS (
  SELECT
    'competitions' AS name,
    COUNT(*) AS num
  FROM
    ${dataset}.competitions
  UNION ALL
  SELECT
    'contacts' AS name,
    COUNT(*) AS num
  FROM
    ${dataset}.contacts
  UNION ALL
  SELECT
    'events' AS name,
    COUNT(*) AS num
  FROM
    ${dataset}.events
  UNION ALL
  SELECT
    'groups' AS name,
    COUNT(*) AS num
  FROM
    ${dataset}.groups
  UNION ALL
  SELECT
    'participants' AS name,
    COUNT(*) AS num
  FROM
    ${dataset}.participants
  UNION ALL
  SELECT
    'reported-scores' AS name,
    COUNT(*) AS num
  FROM
    ${dataset}.\`reported-scores\`
  UNION ALL
  SELECT
    'scores' AS name,
    COUNT(*) AS num
  FROM
    ${dataset}.scores
  UNION ALL
  SELECT
    'team-scores' AS name,
    COUNT(*) AS num
  FROM
    ${dataset}.\`team-scores\`
  UNION ALL
  SELECT
    'teams' AS name,
    COUNT(*) AS num
  FROM
    ${dataset}.teams )
SELECT
  *
FROM
  counts
UNION ALL
SELECT
  'all' AS name,
  SUM(num) AS num
FROM
  counts`
}, {
    name: '', // separator
}, {
    name: 'events',
    dates: EVENTS_DATES,
    dateField: 'date'
}, {
    name: 'scores',
    dates: ['updateTime'],
}, {
    name: 'competitions'
}, {
    name: 'contacts'
}, {
    name: 'groups'
}, {
    name: 'participants'
}, {
    name: 'reported-scores'
}, {
    name: 'team-scores'
}, {
    name: 'teams'
}, {
    name: '', // separator
}, {
    name: 'custom',
    description: 'Provide custom query below'
}];

function getQuery(queryDataset: string, querySelection: string, queryLimit: number, queryDateField?: string, from?: string, to?: string, selectedFields?: Array<QueryParam>, deleted?: boolean) {
    const q = BQ_QUERIES.find(q => q.name === querySelection);
    const dates = q?.dates;
    const expandable = q?.expandable ?? !q?.query;
    const selectArgs = q?.dateField ? `${PD(q.dateField)} as dateFormatted, *` : '*';
    const description = q?.description ?? (q?.query ? '' : <span>Selects all fields from <b>{querySelection}</b> collection</span>);
    queryDataset = '`' + queryDataset + '`';
    const table = '`' + querySelection + '`';
    let condition = '';
    if (queryDateField) {
        if (from) {
            if (condition) {
                condition += 'AND ';
            }
            condition += `${queryDateField} >= ${new Date(from).getTime()} `;
        }
        if (to) {
            if (condition) {
                condition += 'AND ';
            }
            condition += `${queryDateField} <= ${new Date(to).getTime()} `;
        }
    }
    if (selectedFields) {
        selectedFields.forEach(selectedField => {
            if (condition) {
                condition += 'AND ';
            }
            const selectedFieldName = q?.aliases?.get(selectedField.name) ?? selectedField.name;
            if (selectedField.value === 'is null') {
                condition += `${selectedFieldName} IS NULL `;
            } else if (selectedField.value === 'is not null') {
                condition += `${selectedFieldName} IS NOT NULL `;
            } else {
                condition += `${selectedFieldName} = '${selectedField.value ?? ''}' `;
            }
        });
    }
    let query = (q?.query ? q.query(queryDataset) : `
SELECT
  ${selectArgs}
FROM
  ${queryDataset}.${table}`);
    if (query.indexOf(`${queryDataset}.events`) >= 0) {
        if (condition) {
            condition += 'AND ';
        }
        if (deleted) {
            condition += 'deleted is TRUE';
        } else {
            condition += 'deleted is NOT TRUE';
        }
    }
    if (condition) {
        if (query.indexOf('WHERE_OTHER') >= 0) {
            query = query.replace('WHERE_OTHER', ` AND ${condition}`);
        } else {
            query += `
WHERE
  ${condition}`;
        }
    }
    query = query.replace('WHERE_OTHER', '');
    query += `
LIMIT
  ${queryLimit}`;
    query = query.trim();
    return { query, description, dates, expandable };
}

export const ExpandedComponent = ({ data }: { data: any }) => <pre>{JSON.stringify(data, null, 2)}</pre>;

enum PageChangedType {
    NEXT,
    PREVIOUS,
    FIRST,
    LAST
}

enum PageState {
    FIRST,
    LAST,
    BOTH
}

type EventsListPageState = {
    pageState: PageState,
    pageOffset: number,
    maxOffset?: number
};

class DataRow extends Array<string> {
    static readonly fromObj = (obj: any, props: Array<string>): DataRow => {
        return props.map(prop => toCell(obj[prop]));
    }
}

class DataAndHeaders {
    public headers = new DataRow;
    public data = new Array<DataRow>;
};

const cellStyle = {
    borderRightStyle: 'solid',
    borderRightWidth: '1px',
    borderRightColor: '#d3d3d3'
};

export const dataTableStyle = {
    header: {
        style: {
            minHeight: 40
        },
    },
    headRow: {
        style: {
            borderTopStyle: 'solid',
            borderTopWidth: '1px',
            borderColor: '#a9a9a9'
        },
    },
    headCells: {
        style: {
            '&:not(:last-of-type)': cellStyle,
        },
    },
    cells: {
        style: {
            '&:not(:last-of-type)': cellStyle,
        },
    },
} as TableStyles;

const eventTableColumns: TableColumn<EventData>[] = [
    {
        name: "Event date",
        selector: row => Utils.formatDateDashed1(row.date),
        sortable: true,
        center: true,
        sortFunction: (a, b) => a.date - b.date
    },
    {
        name: "Last update",
        selector: row => Utils.formatDateDashed1(row.lastModified),
        sortable: true,
        center: true,
        sortFunction: (a, b) => a.lastModified - b.lastModified
    },
    {
        name: "Name",
        selector: row => row.name,
        sortable: true,
        center: true,
        sortFunction: (a, b) => a.name.localeCompare(b.name)
    },
    {
        name: "UID",
        selector: row => row.userId,
        center: true,
        sortable: false
    },
    {
        name: "E-mail",
        cell: row => row.email ?? '',
        center: true,
        sortable: false
    },
    {
        name: "Event ID, Event site",
        cell: row => row.eventIdAndPortal,
        center: true,
        sortable: false
    },
    {
        name: "# scores / # golfers or teams",
        selector: row => row.scoresAndParticipants,
        center: true,
        sortable: false
    },
    {
        name: "# of App scores",
        selector: row => row.appScoresAmount,
        center: true,
        sortable: false
    }
];

function toCell(f: any): string {
    return Utils.objProps(f ?? '', 1, 100, true);
}

function getDataAndHeaders(entities: Array<any>): DataAndHeaders {
    const set = new Set<string>();
    entities.forEach(ent => Object.keys(ent).forEach(p => {
        if (p !== 'id') {
            set.add(p);
        }
    }));
    const hasId = entities.length > 0 && Boolean(entities[0].id);
    const headers = (hasId ? ['id'] : []).concat(Array.from(set));
    const data = entities.map(ent => DataRow.fromObj(ent, headers));
    return {
        headers,
        data
    };
}

function getColumns(entities: Array<any>, onClick?: (p: string, v: any) => void): Array<TableColumn<any>> {
    const set = new Set<string>();
    entities.forEach(ent => Object.keys(ent).forEach(p => {
        if (p !== 'id') {
            set.add(p);
        }
    }));
    const clickableCell = (p: string, row: any) => onClick ?
        <span style={{ cursor: 'pointer' }} onClick={e => {
            e.preventDefault();
            onClick(p, row[p]);
        }}>
            {toCell(row[p])}
        </span> : toCell(row[p]);
    const hasId = entities.length > 0 && Boolean(entities[0].id);
    const res = hasId ? [{
        name: 'id',
        //selector: row => toCell(row.id)
        cell: row => clickableCell('id', row)
    } as TableColumn<any>] : [];
    return res.concat(Array.from(set).map(p => {
        return {
            name: p,
            sortable: true,
            //selector: row => toCell(row[p]),
            cell: row => clickableCell(p, row)
        }
    }));
}

interface User {
    uid: string;
    email?: string;
    creationTime: number;
    lastSignInTime: number;
    exp: number;
    eventsDates: number[];
}

type UserItemHeaderProps = {
    sortCol: string;
    onColumnSort: (col: keyof User) => void;
    asc: boolean;
    sortDisabled: boolean;
};

const UserItemHeader = withStyles(styles)((props: UserItemHeaderProps & WithStyles<typeof styles>) => {
    const { classes, sortCol, asc, sortDisabled } = props;
    const icon = sortDisabled ? '' : asc ? <ArrowDown /> : <ArrowUp />;
    return <>
        <ListItem className={classes.listItemHeaderWhite + ' ' + classes.clickable}>
            <Container>
                <Item onClick={() => { if (!sortDisabled) { props.onColumnSort('uid'); } }} xs={2}>User UID {sortCol === 'uid' ? icon : ''}</Item>
                <Item onClick={() => { if (!sortDisabled) { props.onColumnSort('email'); } }} xs={2}>E-mail {sortCol === 'email' ? icon : ''}</Item>
                <Item xs={1}>Total Events</Item>
                <Item xs={1}>Upcoming Events</Item>
                <Item onClick={() => { if (!sortDisabled) { props.onColumnSort('creationTime'); } }} xs={1}>Created {sortCol === 'creationTime' ? icon : ''}</Item>
                <Item onClick={() => { if (!sortDisabled) { props.onColumnSort('lastSignInTime'); } }} xs={1}>Signed In {sortCol === 'lastSignInTime' ? icon : ''}</Item>
                <Item xs={1}>Pro {sortCol === 'exp' ? icon : ''}</Item>
                <Item xs={2}>Courses</Item>
                <Item xs={1}>Events list</Item>
            </Container>
        </ListItem>
        <Divider />
        <Divider />
    </>;
});

type ProExpirationDialogProps = {
    expirationDate: number,
    userId: string,
    onClose: () => void,
    onProStatusChanged: (newDate?: Date) => void
};

const ProExpirationDialog = (props: ProExpirationDialogProps) => {
    const { userId, onProStatusChanged, onClose } = props;
    const [expirationDate, setExpirationDate] = React.useState(new Date(props.expirationDate || getUserToday()));
    const onSave = async () => {
        await setWithMergePromise(Backend.proExpirationDb, { exp: expirationDate.getTime() }, userId);
        onClose();
        onProStatusChanged(expirationDate);
    };
    const onClear = async () => {
        if (props.expirationDate > 0) {
            await Backend.remove(Backend.proExpirationDb, { id: userId });
            onProStatusChanged();
        }
        onClose();
    };
    return (
        <XSMobileDialog open={true} maxWidth="xs" fullWidth={true}>
            <DialogAppBar label="Update event start time" close={onClose} />
            <DialogContent>
                <MaterialDate
                    icon
                    disablePast
                    enableUnderline
                    label="Pro expiration date"
                    value={expirationDate.getTime()}
                    onChange={setExpirationDate}
                    format={EVENT_DATETIME_FORMAT_DLG}
                    autoFocus
                    popperPlacement="bottom"
                    style={{ width: '100%', maxWidth: 320 }} />
            </DialogContent>
            <DialogActions>
                <AppButton color="info" onClick={onClear}>Clear</AppButton>
                <AppButton color="secondary" onClick={onSave}>Save</AppButton>
            </DialogActions>
        </XSMobileDialog>
    );
};

type UserItemProps = { user: User, clickHandler: (user: User, details?: number) => void };

const UserItem = withStyles(styles)((props: UserItemProps & WithStyles<typeof styles>) => {
    const { classes, user } = props;
    const now = Date.now();
    const upcomingEventsAmount = user.eventsDates?.filter(date => now < date).length ?? 0;
    const onClick = () => props.clickHandler(user);
    const [proExpDialogOpened, setProExpDialogOpened] = React.useState(false);
    const onAppButtonClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.stopPropagation();
        setProExpDialogOpened(true);
    };
    const [userExp, setUserExp] = React.useState(user.exp);
    const userExpElement = <AppButton sx={{ color: AppColors.primary }} onClick={onAppButtonClick} variant="text" color="info">
        {userExp > now ? Utils.formatDateDashed1(userExp) : 'Grant Pro'}
    </AppButton>;
    return <>
        <ListItemButton>
            <Container>
                <Item onClick={onClick} xs={2}><PersonIcon className={classes.textIcon} />{user.uid}</Item>
                <Item onClick={onClick} xs={2}><TitledLink>{user.email ?? 'n/a'}</TitledLink></Item>
                <Item onClick={onClick} xs={1}>{user.eventsDates?.length ?? 0}</Item>
                <Item onClick={onClick} xs={1}>{upcomingEventsAmount}</Item>
                <Item onClick={onClick} xs={1}>{Utils.formatDateDashed1(user.creationTime)}</Item>
                <Item onClick={onClick} xs={1}>{Utils.formatDateDashed1(user.lastSignInTime)}</Item>
                <Item onClick={onClick} xs={1}>{userExpElement}</Item>
                <Item xs={2}>
                    <TitledLink title="Recent courses used by user" onClick={() => props.clickHandler(user, DETAILS_RECENT)}>Recent</TitledLink> &nbsp;
                    <TitledLink title="Saved courses modified by user" onClick={() => props.clickHandler(user, DETAILS_SAVED)}>Saved</TitledLink> &nbsp;
                </Item>
                <Item xs={1}>
                    <TitledLink title={"Events list"} onClick={() => props.clickHandler(user, EVENTS_LIST)}>Events</TitledLink>
                </Item>
            </Container>
        </ListItemButton>
        <Divider />
        {proExpDialogOpened && <ProExpirationDialog
            userId={user.uid}
            expirationDate={userExp}
            onClose={() => setProExpDialogOpened(false)}
            onProStatusChanged={date => setUserExp(date?.getTime() ?? 0)}
        />}
    </>;
});

const LogItemHeader = withStyles(styles)((props: WithStyles<typeof styles>) => {
    const { classes } = props;
    return <>
        <ListItem className={classes.listItemHeaderWhite + ' ' + classes.clickable}>
            <Container>
                <Item xs={2} noWrap variant="body2">Date/Time</Item>
                <Item xs={1} noWrap variant="body2">Event ID</Item>
                <Item xs={2} noWrap variant="body2">Source</Item>
                <Item xs={2} noWrap variant="body2">Action</Item>
                <Item xs={5} noWrap variant="body2">Details</Item>
            </Container>
        </ListItem>
        <Divider />
        <Divider />
    </>;
});

type LogItemProps = { log: EventLog, clickHandler: (log: EventLog) => void };

const LogItem = withStyles(styles)((props: LogItemProps & WithStyles<typeof styles>) => {
    const { log } = props;
    return <>
        <ListItemButton onClick={() => props.clickHandler(log)}>
            <Container wrap="nowrap">
                <Item xs={2} noWrap variant="body2">{Utils.formatDateTime(log.datetime)}</Item>
                <Item xs={1} noWrap variant="body2">{log.eventId}</Item>
                <Item xs={2} noWrap variant="body2">{log.source}</Item>
                <Item xs={2} noWrap variant="body2">{log.action}</Item>
                <Item xs={5} noWrap variant="body2">{log.details}</Item>
            </Container>
        </ListItemButton>
        <Divider />
    </>;
});

type EventData = {
    date: number,
    lastModified: number,
    userId: string,
    name: string,
    email: JSX.Element,
    eventIdAndPortal: JSX.Element,
    scoresAndParticipants: string,
    appScoresAmount: string
};

export type EventUserInfo = {
    userId: string;
    userEmail?: string;
    userName?: string;
    legitEventsCount: number;
    createdEventsCount: number;
};

type Props = WithStyles<typeof styles>;

interface State {
    mode: SupMode;
    curPage: number;
    users: Array<User>;
    events: Array<Event>;
    logs: Array<EventLog>;
    logEntry?: EventLog;
    lastUser?: User;
    eventId: string;
    eventName: string;
    emailOrUid: string;
    from?: string;
    to?: string;
    sortCol: string;
    asc: boolean;
    exportToCSV?: boolean;
    pageIndex: number;
    pageQueries: Map<number, Backend.Query>;
    pageTimeStamps: Map<number, number>;
    showRecentCourses?: User;
    showSavedCourses?: User;
    eventsUser?: User;
    eventsListPageState: EventsListPageState;
    excludeLLs: boolean;
    dateRangeStatisticsInfo?: DateRangeStatisticsInfo;
    firebaseCollection: string;
    firebaseEntities: Array<Backend.Entity>;
    firebaseColumns: Array<any>;
    firebaseError?: string;
    querySelection: string;
    queryLimit: number;
    queryDataset?: string;
    queryDatasets: Array<Dataset>;
    queryDatasetsStatus?: string;
    queryDateField?: string;
    queryRecords: Array<any>;
    queryColumns: Array<any>;
    queryError?: string;
    queryTime?: number;
    expanded?: boolean;
    customQuery?: string;
    selectedFields: Array<QueryParam>;
    mainAnnouncementText: string;
}

const ITEMS_PER_PAGE = 100;

class UserList extends React.Component<Props, State> {

    private unsubscribe: () => void;

    private tryUnsubscribe = () => {
        if (this.unsubscribe) {
            this.unsubscribe();
        }
    }

    constructor(props: Props) {
        super(props);
        this.state = {
            mode: 'announcement',
            curPage: 0,
            users: [],
            events: [],
            logs: [],
            eventId: '',
            eventName: '',
            emailOrUid: '',
            sortCol: 'lastSignInTime',
            asc: false,
            pageIndex: 0,
            pageQueries: new Map<number, Backend.Query>(),
            pageTimeStamps: new Map<number, number>([[0, 0]]),
            eventsListPageState: {
                pageState: PageState.FIRST,
                pageOffset: 0
            },
            excludeLLs: true,
            queryDatasets: [],
            firebaseCollection: 'events',
            querySelection: BQ_QUERIES[0].name,
            queryLimit: 100,
            firebaseEntities: [],
            firebaseColumns: [],
            queryRecords: [],
            queryColumns: [],
            selectedFields: [],
            mainAnnouncementText: ''
        };
        this.unsubscribe = () => { };
    }

    static contextTypes = userProviderContextTypes;
    context!: UserAware;

    componentDidMount() {
        this.loadAnnouncement();
    }

    componentWillUnmount() {
        this.tryUnsubscribe();
    }

    private handleLoadStart = () => this.handleLoad();
    private handleUnsup = () => this.context.setEffectiveUserId(undefined);
    private handleExportToCSV = () => this.setState({ exportToCSV: true });

    private onUsers = (users: Array<User>, curPage: number, sortCol?: string, asc?: boolean) => {
        const lastUser = users.length > 0 ? users[users.length - 1] : undefined;
        this.setState({ users, lastUser, curPage, mode: 'users', sortCol: sortCol ?? this.state.sortCol, asc: asc ?? this.state.asc });
    }

    private onLogs = (logs: Array<EventLog>) => {
        this.setState({ logs, mode: 'logs' });
    }

    private getDataSortedByColumn = (sortCol: keyof User) => {
        let { asc } = this.state;
        asc = sortCol === this.state.sortCol ? !asc : asc;
        this.handleLoad(undefined, sortCol, asc);
    }

    private showOpenUserAlert = (user: User) => {
        showAlert(`Go ${user.uid} ${user.email ?? ''} ?`, [
            { title: 'Cancel' },
            { title: 'OK', color: 'secondary', action: () => { this.context.setEffectiveUserId(user.uid); pushUrl('/events'); } }
        ]);
    }

    private handleOpenUser = (user: User, details?: number) => {
        if (details === DETAILS_RECENT) {
            this.setState({ showRecentCourses: user });
        } else if (details === DETAILS_SAVED) {
            this.setState({ showSavedCourses: user });
        } else if (details === EVENTS_LIST) {
            this.handleOpenEventsList(user);
        } else {
            this.showOpenUserAlert(user);
        }
    }

    private handleOpenEvent = () => {
        const { logEntry } = this.state;
        if (logEntry) {
            this.findEventPub(logEntry.eventId)
                .then(this.findEventPri)
                .catch((_err: any) => this.findEventPri(logEntry.eventId));
        }
    }

    private handleLoadNext = () => {
        const { lastUser, sortCol, asc } = this.state;
        this.handleLoad(lastUser?.uid, sortCol, asc);
    }

    private handleLoad = async (next?: string, sortColumn?: string, ascending?: boolean) => {
        if (Backend.firebaseAuth.currentUser) {
            this.tryUnsubscribe();
            const hideProgress = showProgress();
            try {
                const token = await Backend.firebaseAuth.currentUser.getIdToken(false);
                const sortCol = sortColumn ?? this.state.sortCol;
                const asc = ascending ?? this.state.asc;
                const count = USERS_PER_PAGE;
                const res = await axios.post(Urls.listUsers, { token, next, count, sortCol, asc });
                hideProgress('List users OK');
                this.onUsers(res.data ?? [], next ? this.state.curPage + 1 : 0, sortCol, asc);
            } catch (err: any) {
                hideProgress('Failed to list users: ' + Utils.getServerErrorMessage(err));
                this.onUsers([], 0);
            }
        }
    }

    private findUser = () => {
        const { emailOrUid } = this.state;
        this.findUserByEmailOrUid((res: AxiosResponse) => this.onUsers([res.data], 0), emailOrUid);
    }

    private findEventPub = async (pubEventId: string) => {
        if (!pubEventId) {
            return Promise.reject('Event ID not specified!');
        }
        try {
            const doc = await Backend.getDocument(Backend.eventMappingDb, pubEventId.trim());
            if (doc.exists() && doc.data()!.eventId) {
                return Promise.resolve(doc.data()!.eventId);
            } else {
                return Promise.reject(`Event ${pubEventId} not found!`);
            }
        }
        catch (_err: any) {
            return Promise.reject(`Failed to find Event by pub ID ${pubEventId}!`);
        }
    }

    private findEventPri = async (eventId: string) => {
        if (!eventId) {
            return;
        }
        try {
            const doc = await Backend.getDoc(Backend.eventFields(eventId));
            if (doc.data()) {
                localStorage.setItem('setEID', doc.data()!.userId);
                window.open(`${window.location.origin}/events/${eventId}`, '_blank');
            } else {
                alert(`Event not found: ${eventId}`);
            }
        } catch (err: any) {
            alert(`Failed to find event: ${eventId} - ${err.message}`);
        }
    }

    private openEvent = () => this.openEventById(this.state.eventId);

    private handleOpenChangelog = () => {
        this.tryUnsubscribe();
        const query = Backend.query(Backend.adminLogsDb(), Backend.orderBy('datetime', 'desc'), Backend.limit(ITEMS_PER_PAGE));
        const hideProgress = showProgress();
        this.unsubscribe = Backend.onSnapshot(query, data => setTimeout(() => {
            hideProgress();
            const dataArray = Backend.toArray<EventLog>(data);
            this.onLogs(dataArray);
        }));
        this.state.pageQueries.set(0, query);
        this.setState({ mode: 'logs' });
    }

    private getEventsQuery = (user?: User, pageType?: PageChangedType) => {
        const { events, excludeLLs } = this.state;
        let query: Backend.Query = Backend.eventsDb;
        if (user?.uid) {
            query = Backend.query(query, Backend.where('userId', '==', user.uid));
        }
        if (excludeLLs) {
            query = Backend.query(query, Backend.where('leaderboard', '==', false));
        }
        query = Backend.query(query, Backend.orderBy('date', 'desc'));
        if ((pageType === PageChangedType.NEXT || pageType === PageChangedType.PREVIOUS) && events && events.length > 0) {
            if (pageType === PageChangedType.NEXT) {
                query = Backend.query(query, Backend.startAfter(events[events.length - 1].date));
            } else if (pageType === PageChangedType.PREVIOUS) {
                return Backend.query(query, Backend.endBefore(events[0].date), Backend.limitToLast(EVENTS_PER_PAGE));
            }
        }
        if (pageType === PageChangedType.LAST) {
            return Backend.query(query, Backend.limitToLast(EVENTS_PER_PAGE));
        }
        return Backend.query(query, Backend.limit(EVENTS_PER_PAGE));
    }

    private handleOpenEventsList = (eventsUser?: User, pageType?: PageChangedType) => {
        this.tryUnsubscribe();
        const eventQuery = this.getEventsQuery(eventsUser, pageType);
        this.unsubscribe = Backend.onSnapshot(eventQuery, data => {
            const dataArray = Backend.toArray<Event>(data);
            const hideProgress = showProgress();
            const { events, eventsListPageState } = this.state;
            if (dataArray.length === 0 && events && events.length > 0 && eventsUser === this.state.eventsUser) {
                hideProgress('No data to display');
                eventsListPageState.maxOffset = eventsListPageState.maxOffset == null ? eventsListPageState.pageOffset : eventsListPageState.maxOffset;
                eventsListPageState.pageState = pageType === PageChangedType.PREVIOUS ? PageState.FIRST : PageState.LAST;
                eventsListPageState.pageOffset = 0;
                this.setState({ eventsListPageState, mode: 'events' });
            } else if (dataArray.length < EVENTS_PER_PAGE) {
                hideProgress('Events: ' + dataArray.length);
                this.setState({
                    eventsListPageState: {
                        pageState: pageType == null ? PageState.BOTH :
                            (pageType === PageChangedType.PREVIOUS || pageType === PageChangedType.FIRST) ? PageState.FIRST : PageState.LAST,
                        maxOffset: eventsListPageState.maxOffset == null ? eventsListPageState.pageOffset : eventsListPageState.maxOffset, pageOffset: 0
                    },
                    events: dataArray, eventsUser: eventsUser, mode: 'events',
                });
            } else {
                hideProgress('Events: ' + dataArray.length);
                if (pageType == null) {
                    this.setState({ events: dataArray, eventsUser, mode: 'events', eventsListPageState: { pageState: PageState.FIRST, pageOffset: 0 } });
                } else {
                    this.setState({ events: dataArray, eventsUser, mode: 'events' });
                }
            }
        }, console.log);
    }

    private async findUserByEmailOrUid(actionAfterResponse: (res: AxiosResponse) => void, emailOrUid?: string) {
        const emailOrUserId = emailOrUid?.trim();
        if (!emailOrUserId || !Backend.firebaseAuth.currentUser) {
            return;
        }
        this.tryUnsubscribe();
        const hideProgress = showProgress();
        const findByUid = emailOrUserId.startsWith('W-') && !Utils.emailFormat.test(emailOrUserId);
        try {
            const token = await Backend.firebaseAuth.currentUser.getIdToken(false);
            const result = await (findByUid ?
                axios.post(Urls.findUserById, { token, userId: emailOrUserId, fullInfo: true }) :
                axios.post(Urls.findUser, { token, email: emailOrUserId })
            );
            const { email, uid } = result.data;
            hideProgress(`Found user: ${uid} - ${email ?? 'n/a'}`);
            actionAfterResponse(result);
        } catch (err: any) {
            hideProgress();
            const errStr = typeof err.response?.data === 'string' ? err.response.data : err.message;
            alert(`Failed to find user for ${emailOrUserId}: ${errStr}`);
        }
    }

    private openEventById(publicEventId: string) {
        if (publicEventId) {
            this.findEventPub(publicEventId)
                .then(this.findEventPri)
                .catch((_err: any) => this.findEventPri(publicEventId));
        }
    }

    private findEventByName = async () => {
        const { eventName } = this.state;
        const eventsSnapshot = await Backend.getDocs(Backend.query(Backend.eventsDb, Backend.where('name', '==', eventName)));
        const events = toArray<Event>(eventsSnapshot);
        this.setState({ events });
    }

    private openNextPage = () => {
        const { eventsListPageState } = this.state;
        eventsListPageState.pageOffset = eventsListPageState.pageState === PageState.LAST ? eventsListPageState.pageOffset - 1 : eventsListPageState.pageOffset + 1;
        this.setState({ eventsListPageState });
        this.handleOpenEventsList(this.state.eventsUser, PageChangedType.NEXT);
    }

    private openPrevPage = () => {
        const { eventsListPageState } = this.state;
        eventsListPageState.pageOffset = eventsListPageState.pageState === PageState.FIRST ? eventsListPageState.pageOffset - 1 : eventsListPageState.pageOffset + 1;
        this.setState({ eventsListPageState });
        this.handleOpenEventsList(this.state.eventsUser, PageChangedType.PREVIOUS);
    }

    private openFirstPage = () => {
        const { eventsListPageState } = this.state;
        this.setState({ eventsListPageState: { pageState: PageState.FIRST, pageOffset: 0, maxOffset: eventsListPageState.maxOffset } });
        this.handleOpenEventsList(this.state.eventsUser, PageChangedType.FIRST);
    }

    private openLastPage = () => {
        const { eventsListPageState } = this.state;
        this.setState({ eventsListPageState: { pageState: PageState.LAST, pageOffset: 0, maxOffset: eventsListPageState.maxOffset } });
        this.handleOpenEventsList(this.state.eventsUser, PageChangedType.LAST);
    }

    private onPrevLogs = () => {
        const { pageQueries } = this.state;
        const pageIndex = this.state.pageIndex - 1;
        const query = pageQueries.get(pageIndex);
        if (query) {
            this.tryUnsubscribe();
            const hideProgress = showProgress();
            this.unsubscribe = Backend.onSnapshot(query, data => setTimeout(() => {
                hideProgress();
                const dataArray = Backend.toArray<EventLog>(data);
                this.onLogs(dataArray);
            }));
        } else {
            this.onLogs([]);
        }
        this.setState({ pageIndex });
    }

    private onNextLogs = () => {
        this.tryUnsubscribe();
        const { logs, pageTimeStamps, pageQueries } = this.state;
        const timeStamp = logs ? logs[logs.length - 1].datetime : 0;
        const pageIndex = this.state.pageIndex + 1;
        pageTimeStamps.set(pageIndex + 1, timeStamp);
        const query = Backend.query(Backend.adminLogsDb(), Backend.orderBy('datetime', 'desc'), Backend.startAfter(timeStamp), Backend.limit(ITEMS_PER_PAGE));
        const hideProgress = showProgress();
        pageQueries.set(pageIndex, query);
        this.unsubscribe = Backend.onSnapshot(query, data => setTimeout(() => {
            hideProgress();
            const dataArray = Backend.toArray<EventLog>(data);
            this.onLogs(dataArray);
        }));
        this.setState({ pageIndex });
    }

    private getExportData(): DataAndHeaders {
        const { mode } = this.state;
        let exportData = new DataAndHeaders();
        if (mode === 'users') {
            const { users } = this.state;
            exportData.headers.push('index', 'uid', 'email', 'creationTime', 'lastSignInTime');
            users && users.forEach((user, index) => {
                const exportRow: string[] = [];
                exportRow.push(String(index + 1));
                exportRow.push(user.uid);
                exportRow.push(user.email || '');
                exportRow.push(Utils.formatDateDashed1(user.creationTime));
                exportRow.push(Utils.formatDateDashed1(user.lastSignInTime));
                exportData.data.push(exportRow);
            });
        } else if (mode === 'bigquery') {
            const { queryRecords } = this.state;
            exportData = getDataAndHeaders(queryRecords);
        } else if (mode === 'fbquery') {
            const { firebaseEntities } = this.state;
            exportData = getDataAndHeaders(firebaseEntities);
        }
        return exportData;
    }

    private getRangeStatistics = async () => {
        const { from, to } = this.state;
        const fromVal = from ? new Date(from).getTime() : 0;
        const toVal = to ? new Date(to).getTime() : new Date().getTime();
        if (fromVal > toVal) {
            showAlert('\'From\' date can not be later than \'To\' date.');
            this.setState({ dateRangeStatisticsInfo: undefined });
        }
        const hideProgress = showProgress();
        try {
            const eventsSnapshot = await Backend.getDocs(Backend.query(Backend.eventsDb, Backend.where('date', '>=', fromVal), Backend.where('date', '<=', toVal)));
            if (!eventsSnapshot.empty) {
                const events = toArray<Event>(eventsSnapshot);
                const adminsData = new Map<string, EventUserInfo>();
                let legitEventsAmount = 0;
                for (const { leaderboard, exists, stats, userId, userEmail, userName } of events) {
                    const isLegit = !leaderboard && exists && stats &&
                        (stats.participantsWithScores > 0 || stats.participantsWithAppScores > 0);
                    if (!adminsData.has(userId)) {
                        adminsData.set(userId, {
                            userId,
                            userEmail,
                            userName,
                            legitEventsCount: isLegit ? 1 : 0,
                            createdEventsCount: 1
                        } as EventUserInfo);
                    } else {
                        const userInfo: EventUserInfo = adminsData.get(userId)!;
                        if (!userInfo.userEmail && userEmail) {
                            userInfo.userEmail = userEmail;
                        }
                        if (!userInfo.userName && userName) {
                            userInfo.userName = userName;
                        }
                        if (isLegit) {
                            userInfo.legitEventsCount++;
                        }
                        userInfo.createdEventsCount++;
                    }
                    if (isLegit) {
                        legitEventsAmount++;
                    }
                }
                adminsData.forEach((eventUserInfo, userId, map) => {
                    if (!eventUserInfo.legitEventsCount) {
                        map.delete(userId);
                    }
                });
                const dateRangeStatisticsInfo: DateRangeStatisticsInfo = {
                    adminsData,
                    legitEventsAmount,
                    events
                };
                hideProgress();
                this.setState({ dateRangeStatisticsInfo });
            } else {
                hideProgress("An error fetching stats occurred.");
                this.setState({ dateRangeStatisticsInfo: undefined });
            }
        } catch (err) {
            hideProgress(errMsg(err));
            console.log(errMsg(err));
            this.setState({ dateRangeStatisticsInfo: undefined });
        }
    }

    private getContactList = async () => {
        const { classes } = this.props;
        const hideProgress = showProgress();
        try {
            const userStats = await Backend.mapEntities<UserStats>(Backend.userStatsDb);
            const eventsAdminsIds = new Array<string>();
            const llsOnlyAdminsIds = new Array<string>();
            for (const stats of Array.from<UserStats>(userStats.values())) {
                if (!stats?.exists || !stats.eventsDates) {
                    continue;
                }
                const eventsAdmin = stats.eventsDates.some(stat => !Boolean(stat.ll));
                if (eventsAdmin) {
                    eventsAdminsIds.push(stats.id);
                } else {
                    llsOnlyAdminsIds.push(stats.id);
                }
            }
            if (Backend.firebaseAuth.currentUser) {
                const eventAdminEmailsSet = new Set<string>();
                const llOnlyAdminEmailsSet = new Set<string>();
                const noEventsOrLLsEmailsSet = new Set<string>();
                const token = await Backend.firebaseAuth.currentUser.getIdToken(false);
                let next: string | undefined = undefined;
                let allUsersCount = 0;
                let users: Array<User> | undefined = undefined;
                do {
                    const res = await axios.post(Urls.listUsersEmails, {
                        token,
                        next,
                        count: USERS_PER_REQUEST,
                    });
                    if (!res || res?.data?.lenght === 0) {
                        break;
                    }
                    users = res.data as Array<User>;
                    users.forEach(user => {
                        if (user?.email?.length && !userStats.has(user.uid)) {
                            noEventsOrLLsEmailsSet.add(user.email);
                        }
                    });
                    const usersMap = new Map<string, User>(users.map(user => [user.uid, user]));
                    allUsersCount += usersMap.size;
                    for (const userId of eventsAdminsIds) {
                        const email = usersMap.get(userId)?.email;
                        if (email?.length) {
                            eventAdminEmailsSet.add(email);
                        }
                    }
                    for (const userId of llsOnlyAdminsIds) {
                        const email = usersMap.get(userId)?.email;
                        if (email?.length) {
                            llOnlyAdminEmailsSet.add(email);
                        }
                    }
                    next = users[users.length - 1].uid;
                } while (users.length === USERS_PER_REQUEST);
                showAlertProps({
                    content: <span>
                        <span className={classes.modeListTitle}>Emails associated with LL creation only:&nbsp;
                            <span className={classes.emailsList}>{Array.from<string>(llOnlyAdminEmailsSet).join(', ')}.</span>
                        </span>
                        <br />
                        <br />
                        <span className={classes.modeListTitle}>Emails associated with events and LLs:&nbsp;
                            <span className={classes.emailsList}>{Array.from<string>(eventAdminEmailsSet).join(', ')}.</span>
                        </span>
                        <br />
                        <br />
                        <span className={classes.modeListTitle}>Emails not associated with events or LLs:&nbsp;
                            <span className={classes.emailsList}>{Array.from<string>(noEventsOrLLsEmailsSet).join(', ')}.</span>
                        </span>
                        <br />
                        <br />
                        <span className={classes.modeListTitle}>Details:&nbsp;
                            <span className={classes.emailsList}>{`User stats in db: ${userStats.size}, all db users: ${allUsersCount},
                    LL only admins: ${llOnlyAdminEmailsSet.size}, event and LL admins: ${eventAdminEmailsSet.size}, no events or LLs admins: ${noEventsOrLLsEmailsSet.size}`}</span>
                        </span>
                    </span>,
                    maxWidth: 'xl'
                });
            }
        } catch (err) {
            console.log(errMsg(err));
        } finally {
            hideProgress();
        }
    }

    renderUsers = () => {
        const { classes } = this.props;
        const { effectiveUserId } = this.context;
        const { eventId, emailOrUid, users, asc, sortCol, curPage, exportToCSV } = this.state;
        const now = Date.now();
        const exportFile = `users-${Utils.formatDateDashed1(now)}.csv`;
        const exportData = exportToCSV ? this.getExportData() : new DataAndHeaders();
        return <>
            <ListItem>
                {effectiveUserId && <>
                    <Typography className={classes.childrenCentered + ' ' + classes.listItem1}>{effectiveUserId}</Typography>
                    <AppButton onClick={this.handleUnsup}>Unsup</AppButton>
                    &nbsp;&nbsp;&nbsp;
                </>}
                <TextField
                    type="text"
                    label="Event ID"
                    value={eventId}
                    size={TEXT_FIELD_SIZE}
                    variant="outlined"
                    sx={{ width: 240 }}
                    onKeyDown={event => processEnterKey(event, this.openEvent)}
                    onChange={e => this.setState({ eventId: e.target.value })} />
                &nbsp;&nbsp;&nbsp;
                <AppButton color="info" onClick={this.openEvent}>Open Event</AppButton>
                &nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;
                <TextField
                    type="text"
                    label="User email or uid"
                    value={emailOrUid}
                    size={TEXT_FIELD_SIZE}
                    variant="outlined"
                    sx={{ width: 240 }}
                    onKeyDown={event => processEnterKey(event, this.findUser)}
                    onChange={e => this.setState({ emailOrUid: e.target.value })} />
                &nbsp;&nbsp;&nbsp;
                <AppButton color="info" onClick={this.findUser}>Find user by email or uid</AppButton>
                &nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;
                <AppButton color="info" onClick={this.getContactList}>Show contact list</AppButton>
            </ListItem>
            <hr />
            <ListItem>
                <Typography className={classes.childrenCentered + ' ' + classes.listItem1}>Page {curPage + 1} ({users.length} users)</Typography>
                <AppButton color="info" onClick={this.handleLoadStart}>List Start</AppButton>
                &nbsp;&nbsp;&nbsp;
                <AppButton color="info" onClick={this.handleLoadNext}>List Next</AppButton>
                &nbsp;&nbsp;&nbsp;
                {users.length > 0 && <CSVLink data={exportData.data} headers={exportData.headers} filename={exportFile} style={{ textDecoration: 'none' }}>
                    <AppButton color="info" onClick={this.handleExportToCSV}>Export results</AppButton></CSVLink>}
            </ListItem>
            <UserItemHeader onColumnSort={this.getDataSortedByColumn} asc={asc} sortCol={sortCol} sortDisabled={users.length === 1} />
            {users.map(user => <UserItem key={user.uid} user={user} clickHandler={this.handleOpenUser} />)}
        </>;
    }

    renderLogs = () => {
        const { pageIndex, logs, logEntry } = this.state;
        return <>
            <ListItem>
                <AppButton color="primary" onClick={this.handleOpenChangelog}>Load change logs</AppButton>
                &nbsp;&nbsp;&nbsp;
            </ListItem>
            <LogItemHeader />
            {logs && logs.map(item => <LogItem key={item.id} log={item} clickHandler={log => this.setState({ logEntry: log })} />)}
            {logs && <ButtonBar margin>
                <AppButton color="secondary" onClick={this.onPrevLogs} disabled={pageIndex < 1}>Recent</AppButton>
                <AppButton color="secondary" onClick={this.onNextLogs} disabled={logs.length < ITEMS_PER_PAGE}>Previous</AppButton>
            </ButtonBar>}
            {!!logEntry && <ChangelogDetailsDialog
                open={!!logEntry}
                logEntry={logEntry}
                onClose={() => this.setState({ logEntry: undefined })}
                openEvent={this.handleOpenEvent} />}
        </>;
    }

    private eventsListData = () => {
        const { classes } = this.props;
        const { events } = this.state;
        const data = events.map(event => {
            const { name, publicId, leaderboard, date, lastModified, userId, userEmail } = event;
            const portalUrl = `${window.location.origin}/event/${publicId}/${leaderboard ? 'standings' : 'about'}`;
            const eventIdAndPortal = (
                <span>
                    <TitledLink onClick={() => this.openEventById(publicId)}>{publicId}</TitledLink> &nbsp;
                    <TitledLink href={portalUrl} target="_blank">Event site</TitledLink> &nbsp;
                </span>
            );
            const email = (
                <span>
                    <TitledLink onClick={() => this.findUserByEmailOrUid(res => this.showOpenUserAlert(res.data as User), userEmail)}>
                        {userEmail ?? this.state.eventsUser?.email}
                    </TitledLink>
                </span>
            );
            const { participants, participantsWithScores, participantsWithAppScores } = event.stats ?? {} as EventStats;
            return {
                date,
                name,
                email,
                userId,
                lastModified,
                eventIdAndPortal,
                scoresAndParticipants: `${participantsWithScores ?? 0} / ${participants ?? 0}`,
                appScoresAmount: `${participantsWithAppScores ?? 0}`
            } as EventData;
        });
        return (
            <div className={classes.eventsDataTable}>
                <DataTable
                    dense
                    data={data}
                    fixedHeader={true}
                    sortIcon={<SortIcon />}
                    columns={eventTableColumns}
                    customStyles={dataTableStyle}
                    fixedHeaderScrollHeight={'800px'}
                    subHeaderAlign={Alignment.CENTER} />
            </div>
        );
    };

    renderEvents = () => {
        const { eventName, excludeLLs, eventsListPageState } = this.state;
        return <>
            <ListItem>
                <TextField
                    type="text"
                    label="Event Name"
                    value={eventName}
                    size={TEXT_FIELD_SIZE}
                    variant="outlined"
                    sx={{ width: 240 }}
                    onKeyDown={event => processEnterKey(event, this.findEventByName)}
                    onChange={e => this.setState({ eventName: e.target.value })} />
                &nbsp;&nbsp;&nbsp;
                <AppButton color="info" onClick={this.findEventByName}>Find Event</AppButton>
                &nbsp;&nbsp;&nbsp;
                <AppButton color="primary" onClick={() => this.handleOpenEventsList()}>List Events</AppButton>
                &nbsp;&nbsp;&nbsp;
                <FormControlLabel label="Show LLs"
                    control={<Checkbox color="secondary" checked={!excludeLLs} />}
                    onChange={() => this.setState({ excludeLLs: !this.state.excludeLLs })} />
                <AppButton color="info" onClick={this.openFirstPage} disabled={(eventsListPageState.pageState !== PageState.LAST && eventsListPageState.pageOffset === 0) ||
                    (eventsListPageState.pageState === PageState.LAST && eventsListPageState.pageOffset === eventsListPageState.maxOffset)}>First page</AppButton>
                &nbsp;&nbsp;&nbsp;
                <AppButton color="info" onClick={this.openPrevPage} disabled={(eventsListPageState.pageState !== PageState.LAST && eventsListPageState.pageOffset === 0) ||
                    (eventsListPageState.pageState === PageState.LAST && eventsListPageState.pageOffset === eventsListPageState.maxOffset)}>Previous page</AppButton>
                &nbsp;&nbsp;&nbsp;
                <AppButton color="info" onClick={this.openNextPage} disabled={(eventsListPageState.pageState !== PageState.FIRST && eventsListPageState.pageOffset === 0) ||
                    (eventsListPageState.pageState === PageState.FIRST && eventsListPageState.pageOffset === eventsListPageState.maxOffset)}>Next page</AppButton>
                &nbsp;&nbsp;&nbsp;
                <AppButton color="info" onClick={this.openLastPage} disabled={(eventsListPageState.pageState !== PageState.FIRST && eventsListPageState.pageOffset === 0) ||
                    (eventsListPageState.pageState === PageState.FIRST && eventsListPageState.pageOffset === eventsListPageState.maxOffset)}>Last page</AppButton>
                &nbsp;&nbsp;&nbsp;
            </ListItem>
            {this.eventsListData()}
        </>;
    }

    renderActiveAdmins = () => {
        const { classes } = this.props;
        const { from, to, dateRangeStatisticsInfo } = this.state;
        return <>
            <ListItem>
                <TextField
                    type="date"
                    label="From date"
                    size={TEXT_FIELD_SIZE}
                    variant={TEXT_FIELD_VARIANT}
                    sx={{ width: 200 }}
                    InputLabelProps={{ shrink: true }}
                    value={from ?? 0}
                    onChange={e => this.setState({ from: e.target.value })} />
                &nbsp;&nbsp;&nbsp;
                <TextField
                    type="date"
                    label="To date"
                    size={TEXT_FIELD_SIZE}
                    variant={TEXT_FIELD_VARIANT}
                    sx={{ width: 200 }}
                    InputLabelProps={{ shrink: true }}
                    value={to ?? 0}
                    onChange={e => this.setState({ to: e.target.value })} />
                &nbsp;&nbsp;&nbsp;
                <AppButton color="primary" style={{ marginRight: 8 }} onClick={this.getRangeStatistics}>Get range statistics</AppButton>
            </ListItem>
            {dateRangeStatisticsInfo &&
                <RangeStatisticsContents classes={classes} {...dateRangeStatisticsInfo} />}
        </>;
    }

    doQuery = async () => {
        const { firebaseCollection, queryLimit } = this.state;
        const colletionGroup = FB_COLLECTIONS.find(c => c.name === firebaseCollection)?.collGroup;
        const query = Backend.query(Backend.dbCollection(firebaseCollection, colletionGroup), Backend.limit(queryLimit));
        const hideProgress = showProgress();
        this.setState({ firebaseError: '', firebaseEntities: [] });
        try {
            const firebaseEntities = await Backend.getEntities(query);
            const firebaseColumns = getColumns(firebaseEntities);
            this.setState({ firebaseEntities, firebaseColumns });
            hideProgress();
        } catch (err: any) {
            const firebaseError = Utils.getServerErrorMessage(err);
            hideProgress(firebaseError);
            console.log(firebaseError);
            this.setState({ firebaseError });
        }
    }

    renderFirebaseQuery = () => {
        const { classes } = this.props;
        const { firebaseCollection, firebaseEntities, firebaseColumns, firebaseError, queryLimit, exportToCSV } = this.state;
        const exportFile = `${firebaseCollection}.csv`;
        const exportData = exportToCSV ? this.getExportData() : new DataAndHeaders();
        return <>
            <ListItem>
                <TextField
                    select
                    label="Collection"
                    size={TEXT_FIELD_SIZE}
                    variant={TEXT_FIELD_VARIANT}
                    value={firebaseCollection}
                    onChange={e => this.setState({ firebaseCollection: e.target.value })}
                >
                    {FB_COLLECTIONS.map(coll => <MenuItem key={coll.name} value={coll.name}>{coll.name}</MenuItem>)}
                </TextField>
                &nbsp;&nbsp;&nbsp;
                <TextField
                    label="Limit"
                    size={TEXT_FIELD_SIZE}
                    variant={TEXT_FIELD_VARIANT}
                    sx={{ width: 60 }}
                    value={queryLimit}
                    onChange={e => this.setLimit(e.target.value)} />
                &nbsp;&nbsp;&nbsp;
                <AppButton color="primary" onClick={this.doQuery}>Do Query</AppButton>
                &nbsp;&nbsp;&nbsp;
                {Utils.withS(firebaseEntities.length, 'document')}
                &nbsp;&nbsp;&nbsp;
                {firebaseError}
                {firebaseEntities.length > 0 &&
                    <CSVLink data={exportData.data} headers={exportData.headers} filename={exportFile} style={{ textDecoration: 'none' }}>
                        <AppButton color="info" onClick={this.handleExportToCSV}>Export results</AppButton></CSVLink>}
            </ListItem>
            <div className={classes.eventsDataTable}>
                <DataTable
                    dense
                    data={firebaseEntities}
                    columns={firebaseColumns}
                    fixedHeader={true}
                    customStyles={dataTableStyle}
                    fixedHeaderScrollHeight={'600px'}
                    subHeaderAlign={Alignment.CENTER} />
            </div>
        </>;
    }

    loadDatasets = async (refresh?: boolean) => {
        if (!Backend.firebaseAuth.currentUser) {
            return;
        }
        const { queryDatasets } = this.state;
        if (queryDatasets.length > 0 && !refresh) {
            return;
        }
        let { queryDataset, queryLimit } = this.state;
        this.setState({ queryDatasetsStatus: 'Loading...' });
        const hideProgress = showProgress();
        try {
            const token = await Backend.firebaseAuth.currentUser.getIdToken(false);
            const result = await axios.post(Urls.bgQuery, { token, datasets: 1 });
            const queryDatasets = (result.data as Array<Dataset>).sort((a, b) => -a.id.localeCompare(b.id));
            if (!queryDataset) {
                queryDataset = queryDatasets[0]?.id;
            }
            const { query: customQuery } = getQuery(queryDataset, 'events', queryLimit);
            this.setState({ queryDatasets, queryDataset, customQuery, queryDatasetsStatus: undefined });
            hideProgress();
        } catch (err: any) {
            const queryError = 'Failed to load datasets: ' + Utils.getServerErrorMessage(err);
            hideProgress(queryError);
            console.log(queryError);
            this.setState({ queryDatasetsStatus: 'Failed to load datasets' });
        }
    }

    private removeSelectedField = (name: string) => {
        const { selectedFields } = this.state;
        let index = selectedFields.findIndex(selectedField => selectedField.name === name);
        if (index >= 0) {
            selectedFields.splice(index, 1);
            this.setState({ selectedFields });
        }
    }

    private addSelectedField = (name: string, value: any) => {
        const { selectedFields } = this.state;
        let index = selectedFields.findIndex(selectedField => selectedField.name === name);
        if (index < 0) {
            selectedFields.push({ name, value });
        } else {
            selectedFields[index].value = value;
        }
        this.setState({ selectedFields });
    }

    private updateSelectedField = (name: string, value: any) => {
        const { selectedFields } = this.state;
        let index = selectedFields.findIndex(selectedField => selectedField.name === name);
        if (index >= 0) {
            selectedFields[index].value = value;
            this.setState({ selectedFields });
        }
    }

    private setDataset = (queryDataset: string) => {
        if (queryDataset === 'refresh') {
            this.loadDatasets(true);
        } else {
            this.setState({ queryDataset })
        }
    }

    private setLimit = (value: string) => {
        let queryLimit = Utils.parseInt(value, 1, 2000);
        if (isNaN(queryLimit)) {
            queryLimit = 1;
        }
        this.setState({ queryLimit });
    }

    private setQuery = (querySelection: string) => {
        this.setState({ querySelection, queryDateField: undefined, selectedFields: [] });
    }

    private processKeyInCustomQuery(ev: React.KeyboardEvent) {
        if (ev.ctrlKey && ev.key === 'Enter') {
            ev.preventDefault();
            this.runBigQuery();
        }
    }

    private runBigQuery = async () => {
        const { queryDataset, querySelection, queryLimit, queryDateField, from, to, selectedFields, customQuery } = this.state;
        if (!Backend.firebaseAuth.currentUser || !queryDataset) {
            return;
        }
        const hideProgress = showProgress();
        this.setState({ queryError: '', queryTime: undefined, queryRecords: [] });
        try {
            const startTime = Date.now();
            let { query } = getQuery(queryDataset, querySelection, queryLimit, queryDateField, from, to, selectedFields);
            if (querySelection === 'custom' && customQuery) {
                query = customQuery;
            }
            const token = await Backend.firebaseAuth.currentUser.getIdToken(false);
            const result = await axios.post(Urls.bgQuery, { token, query });
            const queryRecords = result.data as Array<any>;
            const queryColumns = getColumns(queryRecords, (selectedField: string, selectedFieldValue: any) => this.addSelectedField(selectedField, selectedFieldValue));
            const queryTime = Date.now() - startTime;
            this.setState({ queryTime, queryRecords, queryColumns });
            hideProgress();
        } catch (err: any) {
            const queryError = Utils.getServerErrorMessage(err);
            hideProgress(queryError);
            console.log(queryError);
            this.setState({ queryError });
        }
    }

    private renderBigQuery = () => {
        const { classes } = this.props;
        const { queryDatasets, queryDataset, queryDatasetsStatus, querySelection, queryLimit, queryDateField, from, to,
            queryRecords, queryTime, queryColumns, queryError, customQuery, selectedFields, expanded, exportToCSV } = this.state;
        const { query, description, dates, expandable } = getQuery(queryDataset ?? 'DATASET', querySelection, queryLimit, queryDateField, from, to, selectedFields);
        const exportFile = `${querySelection} (${queryDataset}).csv`;
        const exportData = exportToCSV ? this.getExportData() : new DataAndHeaders();
        return <>
            <div className={classes.eventsDataTable}>
                <TextField
                    select
                    label="Dataset"
                    size={TEXT_FIELD_SIZE}
                    variant={TEXT_FIELD_VARIANT}
                    value={queryDatasetsStatus ?? queryDataset ?? ''}
                    onChange={e => this.setDataset(e.target.value)}
                >
                    {queryDatasetsStatus && <MenuItem value={queryDatasetsStatus}>{queryDatasetsStatus}</MenuItem>}
                    {queryDatasets.map(ds => <MenuItem key={ds.id} value={ds.id}>{ds.id}</MenuItem>)}
                    <Divider />
                    <MenuItem value={'refresh'}>Refresh</MenuItem>
                </TextField>
                &nbsp;&nbsp;&nbsp;
                <TextField
                    select
                    label="Query"
                    size={TEXT_FIELD_SIZE}
                    variant={TEXT_FIELD_VARIANT}
                    value={querySelection}
                    onChange={e => this.setQuery(e.target.value)}
                >
                    {BQ_QUERIES.map((coll, idx) => coll.name ? <MenuItem key={coll.name} value={coll.name}>{coll.name}</MenuItem> : <Divider key={idx} />)}
                </TextField>
                &nbsp;&nbsp;&nbsp;
                <TextField
                    label="Limit"
                    size={TEXT_FIELD_SIZE}
                    variant={TEXT_FIELD_VARIANT}
                    sx={{ width: 80 }}
                    value={queryLimit}
                    onChange={e => this.setLimit(e.target.value)} />
                {dates && <>
                    &nbsp;&nbsp;&nbsp;
                    <TextField
                        select
                        label="Date field"
                        size={TEXT_FIELD_SIZE}
                        variant={TEXT_FIELD_VARIANT}
                        value={queryDateField ?? 'none'}
                        onChange={e => this.setState({ queryDateField: e.target.value !== 'none' ? e.target.value : undefined })}
                    >
                        <MenuItem key={'none'} value={'none'}>none</MenuItem>
                        {dates.map(d => <MenuItem key={d} value={d}>{d}</MenuItem>)}
                    </TextField>
                </>}
                {selectedFields.map(selectedField => <React.Fragment key={selectedField.name}>
                    &nbsp;&nbsp;&nbsp;
                    <TextField
                        label={<span>
                            {selectedField.name}
                            &nbsp;&nbsp;&nbsp;
                            <BkCloseButton onClick={() => this.removeSelectedField(selectedField.name)} />
                        </span>}
                        size={TEXT_FIELD_SIZE}
                        variant={TEXT_FIELD_VARIANT}
                        value={selectedField.value}
                        onChange={e => this.updateSelectedField(selectedField.name, e.target.value)} />
                </React.Fragment>)}
                {queryDateField && <>
                    &nbsp;&nbsp;&nbsp;
                    <TextField
                        type="date"
                        label={`From ${queryDateField}`}
                        size={TEXT_FIELD_SIZE}
                        variant={TEXT_FIELD_VARIANT}
                        sx={{ width: 180 }}
                        InputLabelProps={{ shrink: true }}
                        value={from ?? 0}
                        onChange={e => this.setState({ from: e.target.value })} />
                    &nbsp;&nbsp;&nbsp;
                    <TextField
                        type="date"
                        label={`To ${queryDateField}`}
                        size={TEXT_FIELD_SIZE}
                        variant={TEXT_FIELD_VARIANT}
                        sx={{ width: 180 }}
                        InputLabelProps={{ shrink: true }}
                        value={to ?? 0}
                        onChange={e => this.setState({ to: e.target.value })} />
                </>}
                <span style={{ height: 48, display: 'inline-block', }}>
                    <span style={{ height: 48, display: 'inline-flex', alignItems: 'center', textAlign: 'center' }}>
                        &nbsp;&nbsp;&nbsp;
                        <AppButton color="primary" onClick={this.runBigQuery}>Run query</AppButton>
                        &nbsp;&nbsp;&nbsp;
                        {Utils.withS(queryRecords.length, 'record')}
                        {queryTime && <span>, spent {queryTime} ms</span>}
                        &nbsp;&nbsp;&nbsp;
                        {queryError}
                        {queryRecords.length > 0 &&
                            <CSVLink data={exportData.data} headers={exportData.headers} filename={exportFile} style={{ textDecoration: 'none' }}>
                                <AppButton color="info" onClick={this.handleExportToCSV}>Export results</AppButton></CSVLink>}
                    </span>
                </span>
            </div>
            <div className={classes.eventsDataTable}>
                <Accordion expanded={expanded || querySelection === 'custom'} onChange={(_e, expanded) => this.setState({ expanded })} sx={{ width: '100%' }}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>{description}</AccordionSummary>
                    <AccordionDetails> {querySelection === 'custom' ?
                        <TextField multiline fullWidth
                            variant='outlined'
                            placeholder="Type SQL query…"
                            value={customQuery}
                            onChange={e => this.setState({ customQuery: e.target.value })}
                            onKeyDown={event => this.processKeyInCustomQuery(event)} /> :
                        <pre>{query}</pre>}
                    </AccordionDetails>
                </Accordion>
            </div>
            <div className={classes.eventsDataTable}>
                <DataTable
                    dense
                    fixedHeader
                    expandableRows={expandable}
                    expandableRowsComponent={ExpandedComponent}
                    data={queryRecords}
                    columns={queryColumns}
                    customStyles={dataTableStyle}
                    fixedHeaderScrollHeight={'600px'}
                    subHeaderAlign={Alignment.CENTER} />
            </div>
        </>;
    }

    private saveAnnouncement = () => {
        showAlert(`Confirm to save and publish Announcement - it will be updated and shown on all opened admin pages.`, [
            { title: 'Cancel' },
            { title: 'Publish Announcement', color: 'secondary', action: this.publishAnnouncement }
        ]);
    }

    private publishAnnouncement = () => {
        const { mainAnnouncementText: text = '' } = this.state;
        const timestamp = Date.now();
        const announcement = { text, timestamp };
        Backend.updateDoc(Backend.mainAnnouncementDoc(), announcement);
    }

    private loadAnnouncement = async () => {
        const hideProgress = showProgress();
        try {
            const announcement = Backend.fromEntity<Announcement>(await Backend.getDoc(Backend.mainAnnouncementDoc()));
            this.setState({ mainAnnouncementText: announcement.text });
            hideProgress();
        } catch (err) {
            const queryError = Utils.getServerErrorMessage(err);
            hideProgress(queryError);
            console.log(queryError);
        }
    }

    private clearAnnouncement = () => this.setState({ mainAnnouncementText: '' }, () => this.setState({ mainAnnouncementText: '' }, this.publishAnnouncement));
    private setAnnouncement = (mainAnnouncementText: string) => this.setState({ mainAnnouncementText });

    renderAnnouncement = () => {
        const { mainAnnouncementText } = this.state;
        return <>
            <ListItem>
                <RichTextQuill value={mainAnnouncementText} onChange={txt => this.setAnnouncement(txt)} />
            </ListItem>
            <ListItem>
                <AppButton onClick={this.clearAnnouncement}>Clear</AppButton>
                &nbsp;&nbsp;&nbsp;
                <AppButton onClick={this.loadAnnouncement}>Get from DB</AppButton>
            </ListItem>
            <ListItem>PREVIEW:</ListItem>
            <AnnouncementAlert announcement={{ text: mainAnnouncementText } as Announcement} />
            <ListItem>
                {mainAnnouncementText && <pre>{mainAnnouncementText}</pre>}
            </ListItem>
            <ListItem>
                <AppButton color="secondary" onClick={this.saveAnnouncement}>Publish Announcement</AppButton>
            </ListItem>
        </>;
    }

    private setMode = (mode: SupMode) => {
        this.setState({ mode }, () => {
            if (mode === 'bigquery') {
                this.loadDatasets();
            }
        });
    }

    render() {
        const { mode, showRecentCourses, showSavedCourses } = this.state;
        return (
            <List>
                <ListItem>
                    <RadioGroup row value={mode} onChange={v => this.setMode(v.target.value as SupMode)}>
                        {SupModes.map((m, i) => <FormControlLabel key={m} value={m} control={<Radio />} label={SupModeLabels[i]} />)}
                    </RadioGroup>
                </ListItem>
                <hr />
                {mode === 'announcement' && this.renderAnnouncement()}
                {mode === 'users' && this.renderUsers()}
                {mode === 'logs' && this.renderLogs()}
                {mode === 'events' && this.renderEvents()}
                {mode === 'active_admins' && this.renderActiveAdmins()}
                {mode === 'fbquery' && this.renderFirebaseQuery()}
                {mode === 'bigquery' && this.renderBigQuery()}
                {showRecentCourses && <RecentCoursesSelectionDialog
                    userId={showRecentCourses.uid}
                    title="Recent courses for"
                    titleHint={showRecentCourses.email ?? showRecentCourses.uid}
                    allowDelete={true}
                    handleClose={() => this.setState({ showRecentCourses: undefined })}
                    handleFacilitySelect={() => { }} />}
                {showSavedCourses && <RecentCoursesSelectionDialog
                    userId={showSavedCourses.uid}
                    title="Saved courses for"
                    titleHint={showSavedCourses.email ?? showSavedCourses.uid}
                    allowDelete={true}
                    savedCourses={true}
                    handleClose={() => this.setState({ showSavedCourses: undefined })}
                    handleFacilitySelect={() => { }} />}
            </List>
        );
    }
}

export default withStyles(styles)(UserList);
