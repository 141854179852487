import * as React from "react";
import { XSMobileDialog } from "../../common/dialog/MobileDialog";
import DialogAppBar from "../../common/dialog/DialogAppBar";
import { Box, DialogContent, Typography, useMediaQuery } from "@mui/material";
import { AppColors, theme } from "../../main/Theme";
import { Contact, Event, PaymentStatus } from "../../types/EventTypes";
import CurrencyList from "currency-list";
import { PayPalPayment } from "./PayPalPayment";

type RegistrationPayPalPaymentDialogProps = {
    event: Event;
    contact: Contact;
    handlePaymentStatusChanged: (status: PaymentStatus, paymentDocId?: string) => void;
    handleClose: () => void;
};

export const RegistrationPayPalPaymentDialog = (props: RegistrationPayPalPaymentDialogProps) => {
    const { event, contact, handlePaymentStatusChanged, handleClose } = props;
    const paymentSettings = event.paymentSettings!;
    const currencySymbol = React.useMemo(
        () => paymentSettings ? CurrencyList.get(paymentSettings.currencyCode)?.symbol ?? '' : '',
        [paymentSettings?.currencyCode]
    );
    const isXs = useMediaQuery(theme.breakpoints.down('sm'));
    return (
        <XSMobileDialog open={true} PaperProps={{ style: { maxWidth: isXs ? 360 : 443, margin: isXs ? 28 : 'unset',
                maxHeight: isXs ? 520 : 800
            }}} maxWidth={'xs'} fullWidth={true}>
            <DialogAppBar label="Payment of entry fee" close={handleClose} />
            <DialogContent style={{ padding: '20px 16px 8px' }}>
                <Box display="flex" flexDirection="column" fontFamily="poppins, sans-serif" fontSize="14px"
                     lineHeight="21px">
                    <Typography style={{ fontSize: 16, lineHeight: '24px', color: AppColors.webBlack }}>
                        {`Pay ${currencySymbol}${currencySymbol.length > 1 ? ' ' : ''}${paymentSettings.feeCost} entry fee to register in the tournament:`}
                    </Typography>
                    <Box height={8} />
                    <Typography style={{ color: AppColors.webGrey }}>Entry fee</Typography>
                    <PayPalPayment
                        style={{ padding: '12px 0px' }}
                        feeCost={paymentSettings.feeCost}
                        payeeEmailAddress={paymentSettings.payeeEmailAddress}
                        currencyCode={paymentSettings.currencyCode}
                        event={event}
                        eventId={event.id}
                        contact={contact}
                        skipPaymentAvailable={true}
                        onPaymentSuccess={(withException, paymentDocId) => handlePaymentStatusChanged(
                            withException ? PaymentStatus.SUCCESSFUL_PAYMENT_WITH_EXCEPTION :
                                PaymentStatus.SUCCESSFUL_PAYMENT, paymentDocId
                        )}
                        onPaymentFailure={() => handlePaymentStatusChanged(PaymentStatus.PAYMENT_FAILURE)}
                        onRegisterWithoutPayment={() => handlePaymentStatusChanged(PaymentStatus.PAY_LATER)} />
                </Box>
            </DialogContent>
        </XSMobileDialog>
    );
};
