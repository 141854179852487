import * as React from 'react';
import { AppBar, Toolbar, Typography, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

interface DialogBarProps {
    label?: React.ReactNode;
    close?: () => void;
    children?: React.ReactNode;
    bar?: boolean;
    margin?: boolean;
    toolbarBarStyle?: React.CSSProperties;
    appBarStyle?: React.CSSProperties;
    noUpperCase?: boolean;
}

const DialogAppBar = (props: DialogBarProps) => {
    const { label, close, children, bar, margin, appBarStyle, noUpperCase } = props;
    let { toolbarBarStyle } = props;
    if (margin && !toolbarBarStyle) {
        toolbarBarStyle = { padding: 0, margin: 24 };
    }
    const showToolbar = Boolean(label) || Boolean(bar) || Boolean(close);
    return (
        <AppBar position="static" elevation={0} style={appBarStyle}>
            {showToolbar && <Toolbar variant={'dense'} style={toolbarBarStyle}>
                {Boolean(label) && <Typography variant="h6" color="inherit" style={{ flex: 1, textTransform: noUpperCase ? 'none' : 'uppercase' }}>{label}</Typography>}
                {Boolean(bar) && children}
                {Boolean(close) && <IconButton onClick={close} color="inherit" size="large"><CloseIcon /></IconButton>}
            </Toolbar>}
            {!bar && children}
        </AppBar>
    );
};

export default DialogAppBar;
