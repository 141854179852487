import {BaseModule} from './BaseModule';

const handleStyles = {
    position: 'absolute',
    height: '12px',
    width: '12px',
    backgroundColor: 'white',
    border: '1px solid #777',
    boxSizing: 'border-box',
    opacity: '0.80'
};

interface Boxes {
    tl: HTMLDivElement;
    tr: HTMLDivElement;
    br: HTMLDivElement;
    bl: HTMLDivElement;
}

export class Resize extends BaseModule {
    private boxes: Boxes = {} as Boxes;
    private dragBox: HTMLDivElement | null = null;
    private dragStartX = 0;
    private preDragWidth = 0;

    onCreate = () => {
        const handleXOffset = `${-parseFloat(handleStyles.width) / 2}px`;
        const handleYOffset = `${-parseFloat(handleStyles.height) / 2}px`;

        this.addBox('tl', 'nwse-resize', {left: handleXOffset, top: handleYOffset}); // top left
        this.addBox('tr', 'nesw-resize', {right: handleXOffset, top: handleYOffset}); // top right
        this.addBox('br', 'nwse-resize', {right: handleXOffset, bottom: handleYOffset}); // bottom right
        this.addBox('bl', 'nesw-resize', {left: handleXOffset, bottom: handleYOffset}); // bottom left
    }

    onDestroy = () => {
        // reset drag handle cursors
        this.setCursor('');
    }

    private addBox = (dir: keyof Boxes, cursor: string, pos: object) => {
        const box = document.createElement('div');
        Object.assign(box.style, handleStyles);
        box.style.cursor = cursor;
        Object.assign(box.style, pos);
        // listen for mousedown on each box
        box.addEventListener('mousedown', this.handleMousedown, false);
        // add drag handle to document
        this.overlay.appendChild(box);
        // keep track of drag handle
        this.boxes[dir] = box;
    }

    private handleMousedown = (evt: MouseEvent) => {
        this.dragBox = evt.target as HTMLDivElement;
        this.dragStartX = evt.clientX;
        this.preDragWidth = this.img.width || this.img.naturalWidth;
        this.setCursor(this.dragBox.style.cursor);
        document.addEventListener('mousemove', this.handleDrag, false);
        document.addEventListener('mouseup', this.handleMouseup, false);
    }

    private handleMouseup = () => {
        this.setCursor(null);
        document.removeEventListener('mousemove', this.handleDrag);
        document.removeEventListener('mouseup', this.handleMouseup);
    }

    private handleDrag = (evt: MouseEvent) => {
        const deltaX = evt.clientX - this.dragStartX;
        if (this.dragBox === this.boxes.tl || this.dragBox === this.boxes.bl) {
            // left-side resize handler; dragging right shrinks image
            this.img.width = Math.round(this.preDragWidth - deltaX);
        } else {
            // right-side resize handler; dragging right enlarges image
            this.img.width = Math.round(this.preDragWidth + deltaX);
        }
        this.requestUpdate();
    }

    private setCursor = (value: string | null) => {
        [document.body, this.img].forEach((el) => {
            el.style.cursor = value;
        });
    }
}
