import * as React from 'react';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom';
import { Tabs, Tab, Typography, Badge, Hidden } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import DateRangeIcon from '@mui/icons-material/DateRange';
import GolfersIcon from '@mui/icons-material/PeopleAlt';
import { LocationIcon } from '../../common/Icons';
import {
    Event, EventData, Portal, Contact, ContactDetails, Team, GolferGroup, Competition, AcceptedInvite, getCourseName, generateGroups,
    getHolesRange, Round, correctHandicapSystem, EventBase, isRound, fixLegacyRounds, ContactInvite, fixLegacyTees,
    rollEvents, Tee,
    Course,
    isCompoundCourse
} from '../../types/EventTypes';
import { generateTeeTimes } from '../TeeTimes';
import { eventBadgeUrl, everybodyIsScheduledEventOrRounds, everybodyIsPairedEventOrRounds, formatEventDateAndTime, golfersAvailableEventOrRounds, everybodyHasTees, formatDateUniversal } from '../Event';
import SettingsTab from '../tabs/settings/SettingsTab';
import { DEBUG, ITEM_NEW_EVENT_ID, MONTH_DATETIME_FORMAT, TIME_FORMAT } from '../../util/config';
import * as Backend from '../../util/firebase';
import * as Utils from '../../util/utility';
import { pushUrl } from '../../redux/ReduxConfig';
import GolfersTab from '../tabs/golfers/GolfersTab';
import ScoreList from '../tabs/scores/ScoreList';
import ToolsTab from '../tabs/tools/ToolsTab';
import { UserAware, userProviderContextTypes } from '../../auth/Auth';
import { WithDataItem, withDataItemFromUrl, FirebaseDocComponent, FirebaseDataComponent, FirebaseUserPubDataComponent, FirebaseUserDataComponent } from '../../common/WithData';
import { ErrorPage } from '../../main/ErrorBoundary';
import EventCreatedDialog from './EventCreatedDialog';
import { ArcStepper } from './ArcStepper';
import { appStyles } from '../../styles';
import AppButton from 'src/common/components/AppButton';
import CompleteEventSetupDialog from './CompleteEventSetupDialog';
import { Flex, IconLinkElement } from 'src/common/Misc';
import { AppColors } from 'src/main/Theme';
import { fixTeamsHandicapAndGender } from 'src/scoring/handicap';

type SelectedTab = 'settings' | 'golfers' | 'scores' | 'tools' | 'raw';

const EventShortInfo = (props: { eventData: EventData }) => {
    const classes = appStyles();
    const { eventData } = props;
    const { event, rounds, selectedRound, portal, golfersMap, loadedGolfers, loadedTeams, loadedGroups } = eventData;
    const [completeSetup, setCompleteSetup] = React.useState(0);
    const eventOrRound = rounds[0] ?? event;
    const golfers = golfersMap.get(eventOrRound.id) ?? new Map<string, Contact>();
    const clickComplete = () => setCompleteSetup(1);
    const clickCustomize = () => setCompleteSetup(2);
    const clickStandings = () => pushUrl(`/event/${event.publicId}/standings`);
    const eventSetupClosed = (target?: string) => {
        setCompleteSetup(0);
        if (target) {
            pushUrl(target);
        }
    };
    const linkPortal = <IconLinkElement href={`/event/${event.publicId}`} target="portal">Event site</IconLinkElement>;
    const linkTV = <IconLinkElement href={`/tv/${event.publicId}`} target="tv">TV</IconLinkElement>;
    let name = event.name;
    let extInfo = '' as React.ReactFragment;
    if (DEBUG && selectedRound) {
        extInfo = <span style={{ color: AppColors.webGrey300 }}>&nbsp;&nbsp;&nbsp;R{selectedRound.roundOrder}-{selectedRound.id}</span>;
    }
    if (DEBUG && event.legitDate) {
        name += ' (legit)';
    }
    if (DEBUG && event.legitAppDate) {
        name += ' (legit-app)';
    }
    if (event.deleted) {
        name += ' (deleted)';
    }
    const stepsTotal = 3;
    let participantsOk = false;
    let scheduleOk = false;
    let teesOk = false;
    let stepsCompleted = 0;
    const dataLoaded = loadedGolfers > 0 && (event.teamSize === 1 || loadedTeams > 0) && loadedGroups > 0;
    const { allTeamsPaired } = everybodyIsPairedEventOrRounds(eventData);
    if (dataLoaded && golfers.size > 0 && allTeamsPaired) {
        stepsCompleted++;
        participantsOk = true;
    }
    const { allScheduled, groupsCount } = everybodyIsScheduledEventOrRounds(eventData);
    if (dataLoaded && allScheduled && groupsCount > 0) {
        stepsCompleted++;
        scheduleOk = true;
    }
    if (dataLoaded && everybodyHasTees(eventData)) {
        stepsCompleted++;
        teesOk = true;
    }
    const today = Utils.getUserToday();
    const eventDate = event.date;
    if (today <= eventDate && eventDate < today + Utils.DAY_MILLIS && stepsCompleted === stepsTotal) {
        stepsCompleted++;
    }
    return <div className={classes.eventHeader}>
        <Grid container spacing={2} alignItems="center">
            <Hidden mdDown>
                <Grid>
                    <ArcStepper
                        onClickSteps={clickComplete}
                        badgeUrl={dataLoaded ? eventBadgeUrl(event, portal) : ''}
                        stepsTotal={stepsTotal}
                        stepsCompleted={stepsCompleted}
                        size={80}
                    />
                </Grid>
            </Hidden>
            <Grid flex={1}>
                <Grid container spacing={2} alignItems="center">
                    <Grid><Typography variant="h5" >{name}</Typography></Grid>
                    <Grid><Typography className={classes.link} noWrap>{linkPortal}</Typography></Grid>
                    <Grid><Typography className={classes.link} noWrap>{linkTV}</Typography></Grid>
                </Grid>
                <Grid container spacing={2} alignItems="center">
                    <Grid>
                        <Typography noWrap>
                            <Flex>
                                <DateRangeIcon className={classes.textIcon + ' ' + classes.secondaryText} />
                                {formatEventDateAndTime(event, rounds, true, false)}
                            </Flex>
                        </Typography>
                    </Grid>
                    <Grid>
                        <Typography noWrap>
                            <Flex>
                                <LocationIcon className={classes.textIcon + ' ' + classes.secondaryText} />
                                {getCourseName(event.course)}
                            </Flex>
                        </Typography>
                    </Grid>
                    <Grid>
                        <Typography noWrap>
                            <Flex>
                                <GolfersIcon className={classes.textIcon + ' ' + classes.secondaryText} />
                                {Utils.withS(golfers.size, 'golfer') + ' registered'}
                                {extInfo}
                            </Flex>
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Hidden mdUp>
                <Grid>
                    <ArcStepper
                        onClickSteps={clickComplete}
                        badgeUrl={dataLoaded ? eventBadgeUrl(event, portal) : ''}
                        stepsTotal={stepsTotal}
                        stepsCompleted={stepsCompleted}
                        size={80}
                    />
                </Grid>
                <Grid xs={12} />
            </Hidden>
            <Grid>
                {dataLoaded && stepsCompleted < stepsTotal &&
                    <AppButton color="secondary" onClick={clickComplete}>Complete event setup</AppButton>}
                {dataLoaded && stepsCompleted > stepsTotal &&
                    <AppButton color="secondary" onClick={clickStandings}>Standings</AppButton>}
                {dataLoaded && stepsCompleted === stepsTotal &&
                    <AppButton color="info" onClick={clickCustomize}>Customize event</AppButton>}
            </Grid>
        </Grid>
        {completeSetup > 0 && <CompleteEventSetupDialog event={event} participantsOk={participantsOk} scheduleOk={scheduleOk} teesOk={teesOk} handleClose={eventSetupClosed} customize={completeSetup === 2} />}
    </div>;
};

interface EventHeaderProperties {
    eventData: EventData;
    value: SelectedTab;
    onTabChange: (event: React.SyntheticEvent, value: SelectedTab) => void;
}

const EventHeader = (props: EventHeaderProperties) => {
    const classes = appStyles();
    const { onTabChange, value, eventData } = props;
    const golfersOk =
        golfersAvailableEventOrRounds(eventData) &&
        everybodyIsPairedEventOrRounds(eventData).allTeamsPaired &&
        everybodyIsScheduledEventOrRounds(eventData).allScheduled;
    const scoringOk = everybodyHasTees(eventData);
    return <>
        <div>
            <EventShortInfo eventData={eventData} />
        </div>
        <div className={classes.stickyTop} style={{ background: 'white' }}>
            <Tabs className={classes.eventTabs} value={value} onChange={onTabChange} indicatorColor="primary" textColor="primary"
                variant="scrollable" scrollButtons="auto" >
                <Tab label="Settings" value="settings" />
                <Tab label={<Badge color="error" variant="dot" overlap="rectangular" invisible={golfersOk}>Golfers &nbsp;</Badge>} value="golfers" />
                <Tab label={<Badge color="error" variant="dot" overlap="rectangular" invisible={scoringOk}>Scoring &nbsp;</Badge>} value="scores" />
                <Tab label="Tools" value="tools" />
            </Tabs>
        </div>
    </>;
};

const Ty = ({ label, children }: { label: string, children: React.ReactNode }) => <Typography><b>{label}:</b> {children}</Typography>;

const CourseRawDetails = ({ course }: { course: Course }) => {
    return <>
        <Ty label="course">{getCourseName(course)}</Ty>
        <Ty label="isCompound">{isCompoundCourse(course) ? 'TRUE' : 'FALSE'}</Ty>
    </>;
};

const TeeRawDetails = ({ name, tee }: { name: string, tee?: Tee }) => {
    if (tee) {
        return <>
            <Ty label={`tee`}>{name} - {tee.name} - {tee.id}</Ty>
            <pre>
                par: [{tee.par.join('][')}]
            </pre>
            <pre>
                hcp: [{tee.handicap.join('][')}]
            </pre>
        </>;
    } else {
        return <>
            <Ty label={`tee`}>{name} - N/A</Ty>
        </>;
    }
}

const CompetitionRawDetails = ({ eventOrRound, eventData }: { eventOrRound: EventBase, eventData: EventData }) => {
    const competitons = eventData.competitionsMap.get(eventOrRound.id) ?? [];
    const namePrefix = isRound(eventOrRound) ? `Round ${eventOrRound.roundOrder} (${eventOrRound.id})` : '';
    return <>
        <TeeRawDetails name={'men'} tee={eventOrRound.teeMen} />
        <TeeRawDetails name={'women'} tee={eventOrRound.teeWomen} />
        {competitons.map(competiton => <div>
            <Ty label={`${namePrefix} competiton`}>{competiton.name ?? ''} {competiton.scoring.format} {competiton.scoring.mode}</Ty>
        </div>)}
    </>;
};

const EventRawDetails = ({ eventData }: { eventData: EventData }) => {
    const { event, rounds } = eventData;
    const props: Array<keyof Event> = [
        'id', 'type', 'name', 'userId', 'publicId', 'userName', 'userEmail', 'teamSize',
        'eventGender', 'date', 'dateUTC', 'lastModified', 'hideLiveScores', 'badgeUrl',
        'bannerUrl', 'legitDate', 'legitAppDate', 'handicapSystem', 'holesType'
    ];
    const propsRound: Array<keyof Round> = [
        'id', 'type', 'name', 'eventId', 'roundOrder',
        'userId', 'publicId', 'userName', 'userEmail', 'teamSize',
        'eventGender', 'date', 'dateUTC', 'lastModified', 'handicapSystem', 'holesType'
    ];
    return <div style={{ padding: 20 }}>
        {props.map((prop, idx) => <Ty key={idx} label={prop}>{event[prop]}</Ty>)}
        <Ty label='formatted date'>{formatEventDateAndTime(event, rounds, true, false)}</Ty>
        <Ty label='formatted tee time'>{formatDateUniversal(event.teeTime.startTime ?? 0, TIME_FORMAT)}</Ty>
        <Ty label='formatted lastModified'>{formatDateUniversal(event.lastModified ?? 0, MONTH_DATETIME_FORMAT)}</Ty>
        {event.course ?
            <CourseRawDetails course={event.course} /> :
            <Ty label='course'>N/A</Ty>}
        {rounds.map((round, idx) => <div key={idx}>
            {propsRound.map((prop, idx) => <Ty key={idx} label={`[round ${round.roundOrder}] ${prop}`}>{round[prop]}</Ty>)}
        </div>)}
        {rollEvents(event, rounds).map((eventOrRound, idx) => <CompetitionRawDetails key={idx} eventOrRound={eventOrRound} eventData={eventData} />)}
    </div>;
}

type RenderInfoProps = { event: Event, tab: SelectedTab, url: string };
type RenderInfoState = EventData & { recent: Map<string, string> };

class RenderInfo extends React.Component<RenderInfoProps, RenderInfoState> {
    state: RenderInfoState = {
        event: this.props.event,
        recent: new Map(),
        portal: {} as Portal,
        portalLoaded: false,
        teamsMap: new Map(),
        teamsListMap: new Map(),
        groupsMap: new Map(),
        loadedGroupsMap: new Map(),
        golfers: new Map(),
        golfersMap: new Map(),
        invitedContacts: new Map(),
        roster: new Map(),
        golfersAggregated: new Map(),
        competitionsMap: new Map(),
        acceptedInvites: new Map(),
        teeTimesMap: new Map,
        rounds: [],
        loadedTeams: 0,
        loadedGroups: 0,
        loadedGolfers: 0,
        loadedRoster: 0,
        loadedRounds: 0,
        loadedCompetitions: 0,
        loadedInvited: 0,
        genTeeTimes: () => { },
        setSelectedRound: () => { }
    };

    componentDidMount() {
        this.setState({
            genTeeTimes: this.genTeeTimes,
            setSelectedRound: this.setSelectedRound
        });
    }

    componentDidUpdate(prevProps: Readonly<RenderInfoProps>) {
        if (this.props.event !== prevProps.event) {
            const event = this.props.event;
            this.setState({ event });
        }
    }

    private setSelectedRound = (selectedRound?: Round) => this.setState({ selectedRound });
    private onUserData = (adminEmail: string) => this.setState({ adminEmail });

    private showTotalMode = () => {
        const { event } = this.props;
        const { selectedRound } = this.state;
        return event.type === 'multiday' && !selectedRound;
    }

    private eventOrRound() {
        const { event } = this.props;
        const { selectedRound } = this.state;
        return selectedRound ?? event;
    }

    private eventOrRounds() {
        const { event } = this.props;
        const { rounds } = this.state;
        return event.type === 'multiday' ? rounds : [this.eventOrRound()];
    }

    private genTeeTimes = (eventOrRound: EventBase) => {
        const { event } = this.props;
        const { golfersMap, teamsMap, loadedGroupsMap, groupsMap, loadedGolfers, loadedTeams, teeTimesMap } = this.state;
        if (event.teamSize === 1 && loadedGolfers > 0 || event.teamSize > 1 && loadedTeams > 0) {
            const teams = teamsMap.get(eventOrRound.id) ?? new Map<string, Team>();
            const loadedGroups = loadedGroupsMap.get(eventOrRound.id) ?? [];
            const golfers = golfersMap.get(eventOrRound.id) ?? new Map<string, Contact>();
            const fixedGroups = generateGroups(eventOrRound, golfers, teams, loadedGroups);
            const holesRange = getHolesRange(eventOrRound.holesType);
            const teeTimes = generateTeeTimes(eventOrRound.teeTime, holesRange, fixedGroups.length);
            teeTimesMap.set(eventOrRound.id, teeTimes);
            groupsMap.set(eventOrRound.id, fixedGroups);
            this.setState({ teeTimesMap, groupsMap });
        }
    }

    private updateContactsFromRoster = () => {
        const { roster, golfersAggregated } = this.state;
        golfersAggregated.forEach(golferAggregated => {
            const contactInRoster = roster.get(golferAggregated.id)
            if (contactInRoster) {
                golferAggregated.email = contactInRoster.email;
                golferAggregated.phone = contactInRoster.phone;
                golferAggregated.notes = contactInRoster.notes;
            }
        })
    }

    private updateContacts = (fromRoster: boolean) => {
        const { event } = this.props;
        const { rounds, golfersMap, golfersAggregated } = this.state;
        if (!fromRoster) {
            const eventOrRounds = rollEvents(event, rounds);
            if (eventOrRounds.length > 0) {
                const golfers = golfersMap.get(eventOrRounds[0].id);
                if (golfers) {
                    golfers.forEach(golfer => {
                        golfersAggregated.set(golfer.id, { ...golfer, tee: undefined });
                    });
                    const hiddenGolfers = Array.from(golfersAggregated.values()).filter(golferAggregated => !golfers.has(golferAggregated.id));
                    hiddenGolfers.forEach(hiddenGolfer => golfersAggregated.delete(hiddenGolfer.id));
                }
            }
            golfersAggregated.forEach(golferAggregated => {
                const roundTees: { [s: string]: Tee; } = {};
                eventOrRounds.forEach(eventOrRound => {
                    const golfers = golfersMap.get(eventOrRound.id);
                    if (golfers && golfers.has(golferAggregated.id)) {
                        const roundGolfer = golfers.get(golferAggregated.id)!;
                        if (roundGolfer.tee) {
                            roundTees[eventOrRound.id] = roundGolfer.tee;
                        }
                    }
                });
                golferAggregated.roundTees = roundTees;
                golferAggregated.tee = undefined;
            })
        }
        this.updateContactsFromRoster();
        this.setState({ golfersAggregated });
    }

    private onRoster = (roster: Map<string, ContactDetails>) => {
        const { loadedRoster } = this.state;
        this.setState({ roster, loadedRoster: loadedRoster + 1 }, () => this.updateContacts(true));
    }

    private onGolfers = (eventOrRound: EventBase, golfers: Map<string, Contact>) => {
        const { golfersMap, loadedGolfers } = this.state;
        golfersMap.set(eventOrRound.id, golfers);
        if (this.showTotalMode()) {
            golfers = new Map();
        }
        this.setState({ golfersMap, golfers, loadedGolfers: loadedGolfers + 1 }, () => {
            this.updateContacts(false);
            this.genTeeTimes(eventOrRound);
        });
    }

    private onInvitedList = (invited: Map<string, ContactInvite>) => {
        const { loadedInvited } = this.state;
        this.setState({ invitedContacts: invited, loadedInvited: loadedInvited + 1 });
    }

    private onTeams = (eventOrRound: EventBase, teamsList: Array<Team>, teams: Map<string, Team>) => {
        const { loadedTeams, golfersMap, teamsMap, teamsListMap } = this.state;
        const golfers = golfersMap.get(eventOrRound.id) ?? new Map<string, Contact>();
        const golferTeam = new Map<string, Team>();
        fixTeamsHandicapAndGender(teams, golfers);
        teamsList.push({ id: '', contactIds: [], order: teamsList.length });
        teams.forEach(team => team.contactIds.forEach(golferId => golferTeam.set(golferId, team)));
        teamsMap.set(eventOrRound.id, teams);
        teamsListMap.set(eventOrRound.id, teamsList);
        this.setState({ teamsMap, teamsListMap, loadedTeams: loadedTeams + 1 });
        this.genTeeTimes(eventOrRound);
    }

    private onGroups = (eventOrRound: EventBase, groups: Array<GolferGroup>) => {
        const { loadedGroups, loadedGroupsMap } = this.state;
        loadedGroupsMap.set(eventOrRound.id, groups);
        this.setState({ loadedGroupsMap, loadedGroups: loadedGroups + 1 });
        this.genTeeTimes(eventOrRound);
    }

    private fixLegacyTees = (baseEventId: string, competitions: Array<Competition>) => {
        const { event } = this.props;
        if (baseEventId === event.id) {
            fixLegacyTees(event, competitions);
        }
    }

    private onCompetitions = (eventOrRound: EventBase, competitions: Array<Competition>) => {
        const { competitionsMap, loadedCompetitions } = this.state;
        competitionsMap.set(eventOrRound.id, competitions);
        competitions.forEach(competition => competition.eventOrRoundId = eventOrRound.id);
        if (isRound(eventOrRound)) {
            competitions.forEach(competition => competition.roundOrder = eventOrRound.roundOrder);
        }
        this.setState({ competitionsMap, loadedCompetitions: loadedCompetitions + 1 }, () => this.fixLegacyTees(eventOrRound.id, competitions));
    }

    private onRounds = (rounds: Array<Round>) => {
        const { loadedRounds } = this.state;
        let { selectedRound } = this.state;
        fixLegacyRounds(rounds);
        rounds.sort((a, b) => a.roundOrder - b.roundOrder);
        selectedRound = rounds.find(r => r.id === selectedRound?.id);
        if (!selectedRound && rounds.length > 0) {
            selectedRound = rounds[0];
        }
        this.setState({ rounds, selectedRound, loadedRounds: loadedRounds + 1 });
        rounds.forEach(round => this.genTeeTimes(round));
    }

    render() {
        const { event, tab, url } = this.props;
        const { recent, ...eventData } = this.state;
        const events = this.eventOrRounds();
        const changeTab = (_: React.ChangeEvent<{}>, value: SelectedTab) => pushUrl(`${url}/${value}`);
        const onSelectedTab = (t: string, sub: string) => recent.set(t, sub);
        return <>
            <EventHeader eventData={eventData} value={tab} onTabChange={changeTab} />
            {tab === 'settings' &&
                <SettingsTab eventData={eventData} onSelectedTab={subtab => onSelectedTab(tab, subtab)} initial={recent.get(tab)} />}
            {tab === 'golfers' &&
                <GolfersTab url={url} eventData={eventData} onSelectedTab={subtab => onSelectedTab(tab, subtab)} initial={recent.get(tab) ?? 'list'} />}
            {tab === 'scores' &&
                <ScoreList eventData={eventData} />}
            {tab === 'tools' &&
                <ToolsTab eventData={eventData} onSelectedTab={subtab => onSelectedTab(tab, subtab)} initial={recent.get(tab)} />}
            {tab === 'raw' &&
                <EventRawDetails eventData={eventData} />}
            <FirebaseDocComponent
                onDoc={doc => this.setState({ portal: Backend.fromEntity<Portal>(doc), portalLoaded: true })}
                docReference={Backend.portalFields(event.id)} />
            <FirebaseDataComponent
                name="invites"
                query={Backend.acceptedInvitesCollection(event.userId)}
                onMap={(acceptedInvites: Map<string, AcceptedInvite>) => this.setState({ acceptedInvites })} />
            <FirebaseDataComponent
                name="roster"
                query={Backend.rosterDb(event.userId)}
                onMap={this.onRoster} />
            {events.map(round => <FirebaseDataComponent<Contact>
                key={round.id}
                name="golfers"
                queryPath={round.id}
                query={Backend.eventGolfersQuery(round.id)}
                onMap={golfers => this.onGolfers(round, golfers)} />)}
            {<FirebaseDataComponent<ContactInvite>
                key={event.id}
                name="invited"
                queryPath={event.id}
                query={Backend.eventInvitedQuery(event.id)}
                onMap={invitedList => this.onInvitedList(invitedList)} />}
            {events.map(round => <FirebaseDataComponent<Competition>
                key={round.id}
                name="competitions"
                queryPath={round.id}
                query={Backend.eventCompetitionsQuery(round.id)}
                onData={competitions => this.onCompetitions(round, competitions)} />)}
            {events.map(round => <FirebaseDataComponent<Team>
                key={round.id}
                name="teams"
                queryPath={round.id}
                query={Backend.eventTeamsQuery(round.id)}
                onDataMap={(teamsList, teams) => this.onTeams(round, teamsList, teams)} />)}
            {events.map(round => <FirebaseDataComponent<GolferGroup>
                key={round.id}
                name="groups"
                queryPath={round.id}
                query={Backend.golferGroupDb(round.id)}
                onData={groups => this.onGroups(round, groups)} />)}
            {event.type === 'multiday' && <FirebaseDataComponent
                name="rounds"
                queryPath={event.id}
                query={Backend.eventRoundsQuery(event.id)}
                onData={this.onRounds} />}
            <FirebaseUserDataComponent onData={data => this.onUserData(data.email)} />
            <FirebaseUserPubDataComponent
                uid={event.userId}
                onData={data => this.setState({ units: data.units })} />
        </>;
    }
}

type EventInfoProps = WithDataItem<Event> & WithDataItem<Portal> & RouteComponentProps<{ tab: SelectedTab }>;

class EventInfo extends React.Component<EventInfoProps> {
    static contextTypes = userProviderContextTypes;
    context!: UserAware;
    state: { isNewEvent?: boolean } = {};

    componentDidMount() {
        const event = this.props.item;
        const addedId = localStorage.getItem(ITEM_NEW_EVENT_ID);
        if (addedId === event?.id || this.context.eventId === event?.id) {
            this.context.setEventId(undefined);
            localStorage.removeItem(ITEM_NEW_EVENT_ID);
            setTimeout(() => this.setState({ isNewEvent: true }), 100);
        } else {
            /// setTimeout(() => this.setState({ isNewEvent: true }), 1000); // DEBUG: HIDE or REMOVE!!!
        }
    }

    private handleCloseNewEventDlg = (page: string) => {
        const parentUrl = this.props.match.url;
        this.setState({ isNewEvent: false }, () => pushUrl(`${parentUrl}/${page}`))
    }

    render() {
        const event = this.props.item;
        const { isNewEvent } = this.state;
        if (!event?.exists) {
            const err = 'Event not found: ' + event?.id;
            return <ErrorPage error={err} errorInfo={""} noheader />;
        }
        const { user, effectiveUserId } = this.context;
        if (!user) {
            const err = 'Incorrect user context!';
            return <ErrorPage error={err} errorInfo={""} noheader />;
        }
        const uid = effectiveUserId ?? user.uid;
        if (event.userId !== uid || (event.deleted && !effectiveUserId)) {
            return null;
        }
        event.handicapSystem = correctHandicapSystem(event.handicapSystem);
        const parentUrl = this.props.match.url;
        return <>
            <Switch>
                <Route path={`${parentUrl}/:tab`} render={p => <RenderInfo event={event} tab={p.match.params.tab as SelectedTab} url={parentUrl} />} />
                <Redirect to={`${parentUrl}/settings`} />
            </Switch>
            {isNewEvent && <EventCreatedDialog
                event={event}
                handleClose={this.handleCloseNewEventDlg} />}
        </>;
    }
}

export default withDataItemFromUrl<Event, {}>(Backend.eventsDb)(EventInfo);
