import * as React from 'react';
import { DialogProps } from '@mui/material/Dialog';
import { EventLog } from '../../../types/EventTypes';
import { XSMobileDialog } from '../../../common/dialog/MobileDialog';
import DialogAppBar from '../../../common/dialog/DialogAppBar';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import AppButton from '../../../common/components/AppButton';
import { DialogActions, DialogContent, InputLabel, Typography, FormControl, Grid } from '@mui/material';
import { formatDateTime } from '../../../util/utility';
import { processEnterKey } from '../../../util/react_utils';
import { styles } from '../../../styles';

type Props = {
    logEntry: EventLog;
    openEvent: (eventId: string) => void;
} & WithStyles<typeof styles> & DialogProps;

class ChangelogDetailsDialog extends React.Component<Props> {

    private handleClose = () => {
        const close = this.props.onClose;
        if (close) {
            close({} as React.SyntheticEvent<any>, 'escapeKeyDown');
        }
    }

    private handleOpenEvent = () => {
        const { logEntry, openEvent } = this.props;
        if (openEvent) {
            openEvent(logEntry.eventId);
        }
    }

    render() {
        const { logEntry } = this.props;
        const title = 'Changelog entry';
        return (
            <React.Fragment>
                <XSMobileDialog open={true} onClose={this.handleClose}>
                    <DialogAppBar label={title} close={this.handleClose} />
                    <DialogContent onKeyDown={uiEvent => processEnterKey(uiEvent, this.handleOpenEvent)}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}><FormControl variant="standard" margin="dense">
                                <InputLabel shrink>EventID</InputLabel>
                                <Typography style={{ marginTop: 15 }}>{logEntry.eventId}</Typography>
                            </FormControl></Grid>
                        </Grid>
                        <Grid container spacing={3}>
                            <Grid item xs={6}><FormControl variant="standard" margin="dense">
                                <InputLabel shrink>Entry Date/Time</InputLabel>
                                <Typography style={{ marginTop: 15 }}>{formatDateTime(logEntry.datetime)}</Typography>
                            </FormControl></Grid>
                            <Grid item xs={6}><FormControl variant="standard" margin="dense">
                                <InputLabel shrink>Source</InputLabel>
                                <Typography style={{ marginTop: 15 }}>{logEntry.source}</Typography>
                            </FormControl></Grid>
                        </Grid>
                        <Grid container spacing={3}>
                            <Grid item xs={6}><FormControl variant="standard" margin="dense">
                                <InputLabel shrink>Actoin</InputLabel>
                                <Typography style={{ marginTop: 15 }}>{logEntry.action}</Typography>
                            </FormControl></Grid>
                            <Grid item xs={6}><FormControl variant="standard" margin="dense">
                                <InputLabel shrink>Specs</InputLabel>
                                <Typography style={{ marginTop: 15 }}>{logEntry.specs}</Typography>
                            </FormControl></Grid>
                        </Grid>
                        <Grid container spacing={3}>
                            <Grid item xs={12}><FormControl variant="standard" margin="dense">
                                <InputLabel shrink>Details</InputLabel>
                                <Typography style={{ marginTop: 15 }}>{logEntry.details}</Typography>
                            </FormControl></Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <AppButton color="info" onClick={this.handleClose}>Close</AppButton>
                        <AppButton color="secondary" onClick={this.handleOpenEvent}>Open Event</AppButton>
                    </DialogActions>
                </XSMobileDialog>
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(ChangelogDetailsDialog);