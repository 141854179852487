import { Contact, Distance, Event, EventData, EventPaymentSettings, ReportedScore, Score, Team, masterEventId } from "../types/EventTypes";
import { Emails, Urls } from "./config";
import * as Backend from "./firebase";
import { getGolferGroup, getSchedule } from "../event/TeeTimes";
import { createGolfersDoc, createScheduleDoc, createScoringDoc } from "../event/EventFormat";
import { elog, Email, getRegistrationDate, prepareInviteCodes } from "../event/Event";
import { fullName } from "../contact/Contact";
import axios from "axios";
import juice from "juice";
import { QUILL_CORE_STYLE as QUILL_CORE_STYLE_ } from "../quillstyles";
import { showProgress } from "../redux/ReduxConfig";
import { formatCurrency, formatDateDashed1, formatDateUS, withS } from "./utility";
import CurrencyList from "currency-list";

interface EmailElement {
    text: string;
    align?: string;
}

export interface EmailContent {
    top?: EmailElement;
    mid?: EmailElement;
    bottom?: EmailElement;
}

export enum EmailVariant {
    default = 0,
    eventUpcomingPayment = 1
}

export function emailDecorated(content: EmailContent, showLinkNote: boolean = true, additionalTags?: string) {
    return `
<html><body>
${additionalTags ?? ''}
<table style="width:100%; max-width: 800px;" cellpadding="0" cellspacing="20" border="0" align="center"><tr><td>
<table style="width:100%;" cellpadding="0" cellspacing="0" border="0" align="center">
<tr><td><img width="100%" alt="" src="https://firebasestorage.googleapis.com/v0/b/project-8831674143385441783.appspot.com/o/public%2Fgolfpad_events_email_top.png?alt=media"></td></tr>
<tr><td>
<span style="width: 32px; height: 36px; float: left; padding-left: 1em;"><img alt="" src="https://firebasestorage.googleapis.com/v0/b/project-8831674143385441783.appspot.com/o/public%2Fgolfpad_events_email_ball.png?alt=media" /></span>
<span style="font-family: sans-serif; font-weight: 800; font-size: 2em; color: #3E3E3E; padding-left: 4px;">Golf</span><span style="font-family: sans-serif; font-weight: 800; font-size: 2em; color: #005B88;">Pad</span><span style="font-family: sans-serif; font-weight: 400; font-size: 2em; color: #3E3E3E;">Events<span>
</td></tr>
<tr><td align="${content.top?.align || ''}">${content.top?.text || ''}</td></tr>
<tr><td height="200" align="${content.mid?.align || ''}">${content.mid?.text || ''}</td></tr>
<tr style="background-color: #f4f4f4"><td align="${content.bottom?.align || ''}">${content.bottom?.text || ''}</td></tr>
<tr style="background-color: #f4f4f4"><td><img width="100%" alt="" src="https://firebasestorage.googleapis.com/v0/b/project-8831674143385441783.appspot.com/o/public%2Fgolfpad_events_email_bottom.png?alt=media"></td></tr>
${showLinkNote ? `<tr><td><p style="font-family: sans-serif">Note: if link does not work, please update the Golf Pad app. <a href='https://play.google.com/store/apps/details?id=com.contorra.golfpad'>Google Play</a> | <a href='https://apps.apple.com/us/app/golf-pad-golf-gps-scorecard/id446320556'>Apple App Store</a></p></td></tr>` : ''}
</table>
</td></tr></table>
</body></html>
`;
}

export function initialEmailTemplate(event: Event) {
    const portalLink = `${Urls.baseUrl}/event/${event.publicId}`;
    return `
<p class="ql-align-center"><span class="ql-size-huge">Congratulations!</span></p>
<p class="ql-align-center"><span class="ql-size-large"><strong>You have been added to <a href='${portalLink}'>${event.name}</a>!</strong><span></p>
<br/>
<br/>
<br/>
<br/>
<p class="ql-align-center"><span style="color: gray;">Organiser’s email</span></p>
<br/>
<br/>
<br/>
<br/>
`;
}

export function inviteEmailTemplate(event: Event) {
    const portalLink = `${Urls.baseUrl}/event/${event.publicId}`;
    const confirmLink = `${Urls.baseUrl}/invite-accept/{iid}`;
    const declineLink = `${Urls.baseUrl}/invite-decline/{iid}`;
    const formatMode = event.teamSize > 1 ? 'a team' : 'an individual';
    const registrationDeadLine = !!getRegistrationDate(event) ? `<p class="ql-align-center"><span style="color: gray;">The deadline for registration is ${formatDateUS(getRegistrationDate(event)!)}</span></p>` : '';
    return `
<html><body>

<p class="ql-align-center">
<strong style="color: rgb(0, 0, 0);" class="ql-size-large">Hello!</strong>
    </p>
    <p class="ql-align-center">
<span style="color: rgb(0, 0, 0);">You have been invited to play in </span><a href='${portalLink}' target="null" style="color: rgb(0, 102, 204);"><strong>${event.name}!</strong></a></p>
<p class="ql-align-center">
<span style="color: rgb(0, 0, 0);">on ${formatDateUS(event.date)} in ${formatMode} format.</span></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p style="text-align: center">
    <a clicktracking=off href='${confirmLink}' style="text-decoration: none">
        <strong>Register</strong>
    </a>
    <span> | </span>
    <a clicktracking=off href='${declineLink}' style="text-decoration: none">
        <strong>Decline</strong>
    </a>
</p> 
<p><br></p>
${registrationDeadLine}
<p><br></p>
<p><br></p>
<p><br></p>
</body></html>
    `;
}

export function paymentTemplate(event: Event) {
    const { name, date, userName, paymentSettings } = event;
    if (!paymentSettings?.enabled) {
        return '';
    }
    return `
    <p class="ql-align-center"><span class="ql-size-huge">${name}</span></p>
    <br/>
    <br/>
    <p><span>Greetings! You've been requested to pay the event entry fee by ${userName}</span></p>
    <br/>
    <p><span>Event: ${name}</span></p>
    <p><span>Event date: ${formatDateDashed1(date)}</span></p>
    <p><span>Fee: ${getPaymentFeeStr(paymentSettings)}</span></p>
    ${paymentSettings.feeDescription ? `<p><span>Fee description: ${paymentSettings.feeDescription}</span></p>` : ''}
    <p><span>PayPal payment link: {paymentLink}</span></p>
    <br/>
    <br/>
    `;
}

function emailFooterInvited(event: Event, adminEmail?: string | null, fontSize: string = '1em') {
    const appLink = Urls.makeAppLink(event.id);
    const content = `
<p><span style="font-family: sans-serif; font-weight: 400; font-size: ${fontSize}; ">You can use the <strong>Golf Pad app on your phone</strong> to view schedule and <br/>standings, receive updates and post live scores.</span></p><br/>
<p><a clicktracking=off href='${appLink}' style="text-decoration: none">
<span style="font-family: sans-serif; font-weight: 400; font-size: ${fontSize}; color: white; background: linear-gradient(268.05deg, #1278AB 5.93%, #2090C8 99.82%); height: 50px; min-height: 50px; border-radius: 24px; padding-left: 13px; padding-right: 13px; padding-top: 6px; padding-bottom: 6px;">
View event in the app</span></a></p><br/>
`;
    return addCommonFooterContent(content, adminEmail);
}

function emailFooterUninvited(event: Event, adminEmail?: string | null, fontSize: string = '1em') {
    const deepLink = Urls.makeDeepLink(event.id);
    const content = `
<p><span style="font-family: sans-serif; font-weight: 400; font-size: ${fontSize}; ">Add this event to the <strong>Golf Pad app on your phone</strong> to view schedule<br/>
and standings, receive updates and post live scores.</span></p><br/>
<p><span style="font-family: sans-serif; font-weight: 400; font-size: ${fontSize}; ">If you haven't already, please download the free Golf Pad app first<br/>
from <a href='https://play.google.com/store/apps/details?id=com.contorra.golfpad'>Google Play</a>
or <a href='https://apps.apple.com/us/app/golf-pad-golf-gps-scorecard/id446320556'>Apple App Store</a>.</span></p><br/>
<p><a clicktracking=off href='${deepLink}' style="text-decoration: none">
<span style="font-family: sans-serif; font-weight: 400; font-size: ${fontSize}; color: white; background: linear-gradient(268.05deg, #1278AB 5.93%, #2090C8 99.82%); height: 50px; min-height: 50px; border-radius: 24px; padding-left: 13px; padding-right: 13px; padding-top: 6px; padding-bottom: 6px;">
<span>Add event to the app</span></span></a></p><br/>
<p><span style="color: gray;">*This link is unique for {name}, please do not share it with others.</span></p>
`;
    return addCommonFooterContent(content, adminEmail);
}

const addCommonFooterContent = (content: string, adminEmail?: string | null) => {
    return `
        ${content}
        <p>Or add this event in the app using Golf Pad Invite Code (GPIC): <strong>{GPIC}</strong></p><br/>
        ${adminEmail ? `<p>Questions or comments? Please <a href="mailto:${adminEmail}">e-mail your tournament organizer</a>.</p><br/>` : ''}
        `;
};

export type SendEmailInfo = {
    firebaseToken: string;
    eventData: EventData;
    attachSchedule?: boolean;
    attachResults?: boolean;
    attachPlayers?: boolean;
    files?: File[];
    golferScores?: Map<string, Score>;
    teamScores?: Map<string, Score>;
    distances?: Map<string, Distance>;
    reportedScores?: Map<string, ReportedScore>
    reportedTeamScores?: Map<string, ReportedScore>;
    newMail: Email;
    variant: EmailVariant;
};

export const sendInviteEmail = async (email: Email, eventData: EventData) => {
    if (Backend.firebaseAuth.currentUser) {
        const hideProgress = showProgress();
        const getInviteEmailInfo = (firebaseToken: string): InviteEmailInfo => {
            return {
                eventData,
                firebaseToken,
                email
            };
        };
        return Backend.firebaseAuth.currentUser.getIdToken(false)
            .then(token => sendInviteMail(getInviteEmailInfo(token)))
            .then(() => {
                hideProgress(`${withS(email.recipients.length, 'invite')} sent`);
                return Promise.resolve();
            })
            .catch(err => {
                hideProgress('Failed to send invite emails: ' + err.message)
                return Promise.reject();
            });
    }
    return Promise.reject();
};

export const sendMail = async (props: SendEmailInfo) => {
    const {
        attachSchedule, attachResults, attachPlayers, eventData, firebaseToken, variant,
        golferScores, teamScores, reportedScores, reportedTeamScores, files, distances, newMail
    } = props;
    const contactsWithEmail = newMail.recipients.filter(c => c.email);
    if (!contactsWithEmail.length) {
        return;
    }
    const { event, selectedRound, competitionsMap, golfersMap, teamsMap, groupsMap } = eventData;
    const eventOrRound = selectedRound ?? event; // ???
    const teams = teamsMap.get(eventOrRound.id) ?? new Map<string, Team>();
    const groups = groupsMap.get(eventOrRound.id) ?? [];
    const golfers = golfersMap.get(eventOrRound.id) ?? new Map<string, Contact>();
    const competitions = competitionsMap.get(eventOrRound.id) ?? [];
    const attachments: Array<any> = [];
    const user = Backend.firebaseAuth.currentUser;
    const docPromises: Array<Promise<void>> = [];
    if (attachSchedule) {
        const schedule = getSchedule(eventOrRound, groups, golfers, teams);
        if (schedule) {
            const getScheduleDoc = new Promise<void>((resolve) => {
                createScheduleDoc(eventOrRound, schedule, golfers).getBase64((doc: string) => {
                    attachments.push({
                        content: doc,
                        type: 'application/pdf',
                        filename: 'schedule.pdf'
                    });
                    resolve();
                });
            });
            docPromises.push(getScheduleDoc);
        }
    }
    if (attachResults) {
        const getResultsDoc = new Promise<void>((resolve) => {
            createScoringDoc(eventOrRound, competitions, golferScores || new Map(), teamScores || new Map(), reportedScores || new Map(), reportedTeamScores || new Map(), golfers, teams, groups, distances || new Map())
                .getBase64((doc: string) => {
                    attachments.push({
                        content: doc,
                        type: 'application/pdf',
                        filename: 'results.pdf'
                    });
                    resolve();
                });
        });
        docPromises.push(getResultsDoc);
    }
    if (attachPlayers) {
        const getPlayersDoc = new Promise<void>((resolve) => {
            createGolfersDoc(eventOrRound, competitions, golfers, teams, groups).getBase64((doc: string) => {
                attachments.push({
                    content: doc,
                    type: 'application/pdf',
                    filename: 'golfers.pdf'
                });
                resolve();
            });
        });
        docPromises.push(getPlayersDoc);
    }
    if (files) {
        files.forEach(f => {
            const getFile = new Promise<void>((resolve) => {
                const fr = new FileReader();
                fr.onloadend = () => resolve();
                fr.addEventListener('load', (e: ProgressEvent) => {
                    const data = (e.target as any).result as string;
                    const basePos = data.indexOf(';base64,');
                    const type = 'application/octet-stream';
                    attachments.push({
                        content: data.substring(basePos + 8),
                        type,
                        filename: f.name
                    });
                    resolve();
                });
                fr.readAsDataURL(f);
            });
            docPromises.push(getFile);
        });
    }
    await Promise.all(docPromises);
    const groupCodesMap = await prepareInviteCodes(groups, masterEventId(eventOrRound));
    const to = contactsWithEmail.map(contactWithEmail => {
        const group = getGolferGroup(eventOrRound, contactWithEmail.id, groups, Array.from<Team>(teams.values()));
        const inviteCode = group ? groupCodesMap.get(group.id) : '';
        return {
            email: contactWithEmail.email!,
            cid: contactWithEmail.id,
            name: fullName(contactWithEmail),
            inviteCode,
            contactPaid: Boolean(contactWithEmail.feePaid || contactWithEmail.paymentId)
        };
    });
    const paymentEmail = variant === EmailVariant.eventUpcomingPayment;
    const emailContentInvited: string = getEmailContent(newMail.text, eventOrRound, true, user?.email);
    const emailContentUninvited: string = getEmailContent(newMail.text, eventOrRound, false, user?.email);
    const paypalEmailContent = paymentEmail ? getPaypalEmailContent(newMail.text, eventOrRound) : undefined;
    const msg = {
        token: firebaseToken,
        individual: true,
        eventId: masterEventId(eventOrRound),
        eventUserId: eventOrRound.userId,
        publicEventId: eventOrRound.publicId,
        mailData: {
            to,
            from: fromEmail(),
            replyTo: newMail.replyTo || user?.email || '',
            subject: paymentEmail ? 'Payment request' : newMail.subject,
            emailContentInvited,
            emailContentUninvited,
            paypalEmailContent,
            attachments
        }
    };
    await axios.post(Urls.sendMail, msg);
    const sentTo = contactsWithEmail.map(c => fullName(c)).join(',');
    elog(eventOrRound, 'Sent email', `Email sent, Subject: ${newMail.subject}, Sent to: ${sentTo}`, `Id: ${masterEventId(eventOrRound)}`);
};

export type InviteEmailInfo = {
    firebaseToken: string;
    eventData: EventData;
    email: Email;
};

export const sendInviteMail = async (props: InviteEmailInfo) => {
    const { firebaseToken, email, eventData } = props;
    const contactsWithEmail = email.recipients.filter(c => c.email);
    if (!contactsWithEmail.length) {
        return;
    }
    const user = Backend.firebaseAuth.currentUser;
    const eventOrRound = eventData.event;
    const to = contactsWithEmail.map(contactWithEmail => {
        return {
            email: contactWithEmail.email!,
            cid: contactWithEmail.id,
            name: fullName(contactWithEmail)
        };
    });
    const msg = {
        token: firebaseToken,
        individual: true,
        eventId: masterEventId(eventOrRound),
        eventUserId: eventOrRound.userId,
        publicEventId: eventOrRound.publicId,
        mailData: {
            to,
            from: fromEmail(),
            replyTo: email.replyTo || user?.email || '',
            subject: email.subject,
            text: juice(`<style>${QUILL_CORE_STYLE}</style>${email.text}`)
        }
    };
    await axios.post(Urls.sendInviteMail, msg);
    const sentTo = contactsWithEmail.map(c => fullName(c)).join(',');
    elog(eventOrRound, 'Sent email', `Email sent, Subject: ${email.subject}, Sent to: ${sentTo}`, `Id: ${masterEventId(eventOrRound)}`);
};

const getEmailContent = (emailText: string, event: Event, invited: boolean, userEmail?: string | null) => {
    const emailTextInvited = emailDecorated({
        mid: { text: emailText },
        bottom: { text: (invited ? emailFooterInvited : emailFooterUninvited)(event, userEmail), align: 'center' }
    });
    return juice(`<style>${QUILL_CORE_STYLE}</style>${emailTextInvited}`);
};

const getPaypalEmailContent = (emailText: string, event: Event) => {
    if (!Boolean(event.paymentSettings?.enabled)) {
        return;
    }
    const emailHtmlText = emailDecorated({
        mid: {
            text: emailText
        }
    }, false);
    return juice(`<style>${QUILL_CORE_STYLE}</style>${emailHtmlText}`);
};

const fromEmail = () => {
    const email = Emails.updatesEmail;
    const name = 'Golf Pad Events';
    return { name, email };
};

const QUILL_CORE_STYLE = QUILL_CORE_STYLE_.replace(/\.ql-editor /g, '');

export const sendPaymentMessage = async (
    contactsToSend: Contact[], eventData: EventData, golferScores: Map<string, Score>,
    teamScores: Map<string, Score>, reportedScores: Map<string, ReportedScore>,
    reportedTeamScores: Map<string, ReportedScore>, distances: Map<string, Distance>, variant: EmailVariant
) => {
    if (Backend.firebaseAuth.currentUser) {
        const hideProgress = showProgress();
        const getSendEmailInfo = (firebaseToken: string): SendEmailInfo => {
            const files = new Array<File>();
            const newMail = {
                subject: `Payment request: ${eventData.event.name}`,
                replyTo: eventData.event.userEmail ?? '',
                recipients: contactsToSend.filter(c => Boolean(c.email)),
                text: paymentTemplate(eventData.event)
            };
            return {
                eventData,
                attachSchedule: false,
                attachResults: false,
                attachPlayers: false,
                firebaseToken,
                files,
                golferScores,
                teamScores,
                reportedScores,
                reportedTeamScores,
                distances,
                newMail,
                variant
            };
        };
        Backend.firebaseAuth.currentUser.getIdToken(false)
            .then(token => sendMail(getSendEmailInfo(token)))
            .then(() => hideProgress('E-mail has been sent'))
            .catch(err => hideProgress('Failed to send e-mail: ' + err.message));
    }
};

function getPaymentFeeStr(paymentSettings?: EventPaymentSettings): string { // $16
    const currencySymbol = paymentSettings ? CurrencyList.get(paymentSettings.currencyCode)?.symbol ?? '' : '';
    return paymentSettings ? `${currencySymbol}${currencySymbol.length > 1 ? ' ' : ''}${paymentSettings.feeCost ? formatCurrency(paymentSettings.feeCost) : 0}` : '';
}
