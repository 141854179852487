import * as React from 'react';
import { ResultStatus, CourseResponse } from '../../../../../../types/EventTypes';
import { getFacilitiesForLocation } from '../../../../../Courses';

function getPosition() {
    return new Promise<GeolocationPosition>((resolve, reject) => navigator.geolocation?.getCurrentPosition(resolve, reject, { timeout: 10000 }));
}

interface Props {
    onResult: (loadStatus: ResultStatus, courseResponse?: CourseResponse, err?: any) => void;
    withTeesOnly: boolean;
}

export default class LocationFacilitiesProvider extends React.Component<Props> {

    state: { position?: GeolocationPosition } = {}

    private mounted: boolean = false;
    private controller = new AbortController();

    componentDidMount() {
        this.mounted = true;
        this.load();
    }

    componentWillUnmount() {
        this.mounted = false;
        this.controller.abort();
    }

    render() {
        return null;
    }

    private getPosition = async () => {
        const { position } = this.state;
        if (position) {
            return position;
        }
        return await getPosition();
    }

    private onResult = (loadStatus: ResultStatus, courseResponse?: CourseResponse, err?: any) => {
        if (this.mounted) {
            const { onResult } = this.props;
            onResult(loadStatus, courseResponse, err);
        }
    }

    public load = async () => {
        if (!navigator.geolocation) {
            return this.onResult('error', undefined, 'Location not supported');
        }
        this.onResult('in_progress');
        try {
            const position = await this.getPosition();
            if (!this.mounted) {
                return;
            }
            this.setState({ position });
        } catch (err) {
            return this.onResult('error', undefined, 'Unable to acquire location at this time. Please verify location is enabled and try again.');
        }
        try {
            const { position } = this.state;
            const courseResponse = await getFacilitiesForLocation(this.props.withTeesOnly, position, this.controller);
            this.onResult('ok', courseResponse);
        } catch (err) {
            this.onResult('error', undefined, err);
        }
    }
}
