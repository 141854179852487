import * as React from 'react';
import { DialogContent, DialogActions, FormControl } from '@mui/material';
import { XSMobileDialog } from '../../../common/dialog/MobileDialog';
import TypedFormRadioLabel from '../../../common/form/TypedFormRadioLabel';
import DialogAppBar from '../../../common/dialog/DialogAppBar';
import AppButton from '../../../common/components/AppButton';
import TextField, { maxLength, required } from '../../../validation/ValidatedTextField';
import withForm, { ValidateProps } from '../../../validation/ValidatedForm';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { processEnterKey } from '../../../util/react_utils';
import { Competition } from '../../../types/EventTypes';
import { styles } from '../../../styles';

type Props = {
    open: boolean;
    competition: Competition;
    competitions: Array<Competition>;
    handleClose: () => void;
    handleSave: (name: string) => void;
} & WithStyles<typeof styles> & ValidateProps;

type NAME_TYPE = "generated" | "custom";

type State = {
    name: string;
    type: NAME_TYPE;
};

function uniqueName(competition: Competition, competitions: Array<Competition>) {
    competitions = competitions.filter(c => c !== competition && c.id !== competition.id);
    return {
        valid: (value?: string) => {
            if (value) {
                let name = value.trim().toUpperCase();
                return !competitions.find(c => c.name && c.name.toUpperCase() === name)
            } else {
                return true;
            }
        },
        text: `This name is already used for another competition`
    };
}

class CompetitionNameDialog extends React.Component<Props, State> {

    state: State = {
        name: this.props.competition.name || '',
        type: this.props.competition.name ? 'custom' : 'generated'
    };

    private handleHandicapChange = (type: NAME_TYPE) => this.setState({ type });
    private handleNameChange = (name: string) => this.setState({ name });
    private handleClose = () => this.props.handleClose();

    private handleSave = () => {
        const { name, type } = this.state;
        this.props.handleSave(type === 'custom' ? name : '');
    }

    private handleCloseX = (uiEvent: string, reason: string) => {
        if ("backdropClick" === reason) { 
            return;
        }
        this.handleClose();
    }

    render() {
        const { open, classes, register, competition, competitions } = this.props;
        const { type, name } = this.state;
        let error = false;
        const rules = type === 'custom' ? [required, maxLength(40), uniqueName(competition, competitions)] : [];
        rules.forEach(rule => error = error || !rule.valid(name));
        return (
            <XSMobileDialog open={open} onClose={this.handleCloseX}
                onKeyDown={e => processEnterKey(e, this.handleSave, error)}>
                <DialogAppBar label={'Competition name'} close={this.handleClose} />
                <DialogContent>
                    <FormControl variant="standard" margin="dense">
                        <TypedFormRadioLabel value="generated" label="Generate name automatically based on competition settings" currentValue={type}
                            handleChange={this.handleHandicapChange} className={classes.formSelector} />
                        <TypedFormRadioLabel value="custom" label="Use a custom name" currentValue={type}
                            handleChange={this.handleHandicapChange} className={classes.formSelector} />
                        <div style={{ height: 60 }}>
                            {type === 'custom' && <TextField
                                textFieldProps={{
                                    onChange: e => this.handleNameChange(e.target.value),
                                    value: name,
                                    variant: 'outlined',
                                    hidden: type !== 'custom',
                                    disabled: type !== 'custom',
                                    autoFocus: true,
                                }}
                                rules={rules}
                                register={register} />}
                        </div>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <AppButton color="info" onClick={this.handleClose}>{'Cancel'}</AppButton>
                    <AppButton color="secondary" onClick={this.handleSave} disabled={error}>{'Save'}</AppButton>
                </DialogActions>
            </XSMobileDialog>
        );
    }
}

export default withStyles(styles)(withForm(CompetitionNameDialog));
