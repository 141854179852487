import * as React from 'react';
import TextField from '../validation/ValidatedTextField';

export interface ValidateProps {
    valid?: () => boolean;
    validate?: () => boolean;
    validateField?: (fieldName: string, val?: any) => boolean;
    errorHighlighted?: () => boolean;
    register?: (name: string, field: TextField | null) => void;
}

interface State {
    valid: boolean;
}

export default function withForm<P = {}>(Component: React.ComponentType<P & ValidateProps>) {
    return class extends React.Component<P & ValidateProps, State> {
        fields: Map<string, TextField | null> = new Map();

        setRef = (name: string, field: TextField | null) => this.fields.set(name, field);

        validate = () => {
            let valid = true;
            this.fields.forEach(value => {
                if (value && !value.validate()) {
                    valid = false;
                }
            });
            return valid;
        }

        validateField = (fieldName: string, val?: any) => {
            const field = this.fields.get(fieldName);
            return field ? field.validate(val) : false;
        }

        valid = () => {
            const iterator = this.fields.values();
            let valid = true;
            while (true) {
                let next = iterator.next();
                if (next.done) {
                    break;
                }
                valid = (!next.value || next.value.valid()) && valid;
            }
            return valid;
        }

        errorHighlighted = () => {
            const iterator = this.fields.values();
            while (true) {
                let next = iterator.next();
                if (next.done) {
                    break;
                }
                if (next.value && next.value.errorHighlighted()) {
                    return true;
                }
            }
            return false;
        }

        render() {
            return <Component {...this.props} register={this.setRef} valid={this.valid} validate={this.validate} validateField={this.validateField} errorHighlighted={this.errorHighlighted} />;
        }
    };
}
