import * as React from 'react';
import * as ReactDOM from 'react-dom';
import 'typeface-poppins';
import 'typeface-roboto';
import App from './main/App';
import './index.css';

const splash = document.getElementById('splash_elem');
if (splash) {
    setTimeout(() => splash.classList.add('hidden'), 300);
}

ReactDOM.render(
    <App />,
    document.getElementById('root') as HTMLElement
);
