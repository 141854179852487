import * as React from 'react';
import { HANDICAP_SYSTEM_NAMES, HANDICAP_SYSTEM_IDS, EventData } from '../../../../../types/EventTypes';
import HandicapSystemSelectionDialog from './HandicapSystemSelectionDialog';
import LabeledField from '../../../../../common/form/LabeledField';
import { ListElem } from '../../../../../common/Misc';

interface Props {
    eventData: EventData;
}

interface State {
    dialogOpened?: boolean;
}

const HCP_ELEM = 'hcp_select_elem';

class HandicapSystemSelectionField extends React.Component<Props, State> {
    state: State = {};

    private handleOpen = () => this.setState({ dialogOpened: true });
    private handleClose = () => this.setState({ dialogOpened: false });

    render() {
        const { eventData } = this.props;
        const { dialogOpened } = this.state;
        const title = (eventData.event.handicapSystem && HANDICAP_SYSTEM_NAMES[HANDICAP_SYSTEM_IDS.indexOf(eventData.event.handicapSystem)]) || 'Not selected';
        return <>
            <LabeledField label="Handicap System" value={(<ListElem id={HCP_ELEM} title={title} subtitle=" " />)} edit={this.handleOpen} />
            {dialogOpened && <HandicapSystemSelectionDialog
                open
                eventData={eventData}
                onClose={this.handleClose}
            />}
        </>;
    }
}

export default HandicapSystemSelectionField;
