import * as React from 'react';
import { DialogActions, DialogContent, Typography } from '@mui/material';
import { Contact, Gender, Competition } from '../../../types/EventTypes';
import { fullName, getSameNameGolfersIds } from '../../../contact/Contact';
import { XSMobileDialog } from '../../../common/dialog/MobileDialog';
import DialogAppBar from '../../../common/dialog/DialogAppBar';
import AppButton from '../../../common/components/AppButton';
import GridSelector, { GridItem } from '../../../common/GridSelector';

class ContactGridItem implements GridItem {
    contact: Contact; 
    selectGender?: Gender;
    homeCourseOrCity?: string;
    constructor(contact: Contact, selectGender?: Gender, homeCourseOrCity?: string) {
        this.contact = contact;
        this.selectGender = selectGender;
        this.homeCourseOrCity = homeCourseOrCity;
    }
    id = () => this.contact.id;
    isDisabled = () => !!this.selectGender && this.selectGender !== this.contact.gender;
    getBadge = () => '';
    getLabel = () => (
        <React.Fragment>
            {fullName(this.contact)}
            <div style={{ fontSize: '0.6rem' }}>
                {this.homeCourseOrCity ? ` (${this.homeCourseOrCity})` : ''}
            </div>
        </React.Fragment>
    );
}

interface Props {
    label: string;
    okLabel?: string;
    golfers: Map<string, Contact>;
    selectedGolferIds?: Array<string>;
    selectMode?: 'single' | 'single-or-none';
    selectGender?: Gender;
    competition?: Competition;
    handleSelect: (contacts: Array<Contact>) => void;
    handleCancel: () => void;
}

interface State {
    selectedCount: number;
}

class SelectGolfersDialog extends React.Component<Props, State> {
    readonly state: State;
    readonly gridSelector: React.RefObject<GridSelector>;

    constructor(props: Props) {
        super(props);
        this.state = {
            selectedCount: props.selectedGolferIds?.length ?? 0
        };
        this.gridSelector = React.createRef();
    }

    private selectAll = () => this.gridSelector.current?.selectAll();
    private selectNone = () => this.gridSelector.current?.selectNone();
    private handleCancel = () => this.props.handleCancel();
    private handleSelected = (items: Array<GridItem>, checked: boolean) => this.setState({ selectedCount: this.gridSelector.current?.selectedCount() ?? 0 });

    private handleOk = () => {
        if (!this.gridSelector.current) {
            return;
        }
        const { handleSelect, golfers } = this.props;
        const { selected } = this.gridSelector.current.state;
        const contacts = Array.from(golfers.values()).filter(g => selected.has(g.id));
        handleSelect(contacts);
    }

    render() {
        const { competition, golfers, okLabel, selectMode, selectGender, selectedGolferIds } = this.props;
        const { selectedCount } = this.state;
        const emptyStatus = 'No recent golfers yet';
        let contacts: Array<Contact> = [];
        if (!competition || (competition.everyone && (!competition.competitionGender || competition.competitionGender === 'both'))) {
            contacts = Array.from(golfers.values());
        } else if (competition.everyone && (competition.competitionGender && competition.competitionGender !== 'both')) {
            contacts = Array.from(golfers.values()).filter(golfer => golfer.gender === (competition.competitionGender === 'men' ? 'male' : 'female'));
        } else if (competition.contactIds) {
            competition.contactIds.forEach(id => {
                const golfer = golfers.get(id);
                if (golfer) {
                    contacts.push(golfer);
                }
            });
        }
        const sameNameGolfersIdsSet = getSameNameGolfersIds(contacts);
        const contactItems = contacts.map(contact => new ContactGridItem(contact, selectGender, contact.homeCourseOrCity && sameNameGolfersIdsSet.has(contact.id) ? contact.homeCourseOrCity : undefined));
        return (
            <React.Fragment>
                <XSMobileDialog open={true} onClose={this.handleCancel} maxWidth={'lg'}>
                    <DialogAppBar label={this.props.label} close={this.handleCancel} />
                    {!selectMode && <DialogActions style={{ padding: 20, paddingBottom: 4 }}>
                        <AppButton color="secondary" onClick={this.selectAll}>Select all</AppButton>
                        <AppButton color="info" onClick={this.selectNone}>Select none</AppButton>
                        <span style={{ flex: '1 1 0%' }} />
                        <Typography variant="body1">{contacts.length} total, {selectedCount} selected</Typography>
                    </DialogActions>}
                    <DialogContent>
                        {contacts.length > 0 &&
                            <GridSelector ref={this.gridSelector} selectMode={selectMode} items={contactItems} preselected={selectedGolferIds} handleSelected={this.handleSelected} />}
                        {contacts.length === 0 &&
                            <Typography variant="body1" style={{ margin: 16 }}>{emptyStatus}</Typography>}
                    </DialogContent>
                    {contacts.length > 0 && <DialogActions>
                        <AppButton color="info" onClick={this.handleCancel}>Cancel</AppButton>
                        <AppButton color="secondary" onClick={this.handleOk}>{okLabel ?? 'Save'}</AppButton>
                    </DialogActions>}
                </XSMobileDialog>
            </React.Fragment>
        );
    }
}

export default SelectGolfersDialog;
