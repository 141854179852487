import * as React from 'react';
import { ChangeEvent } from 'react';
import Typography from '@mui/material/Typography';
import { DialogContent, ImageList, ImageListItem } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { WithStyles, StyleRules } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { XSMobileDialog } from '../../../../common/dialog/MobileDialog';
import DialogAppBar from '../../../../common/dialog/DialogAppBar';
import AppButton from '../../../../common/components/AppButton';
import { userImgDb, defaultImgDb, updateOrAdd, ImageType, ImageSrc, portalImagesRef, query, where } from '../../../../util/firebase';
import { withData } from '../../../../common/WithData';
import { WithUserId, withUserId } from '../../../../auth/Auth';
import ImageCropDialog from '../../../../common/components/ImageCropDialog';
import { showError } from '../../../../redux/ReduxConfig';
import { styles } from "../../../../styles";

const descriptions: Record<ImageType, any> = {
    badge: {
        header: 'Badges',
        name: 'badge',
        w: 300,
        h: 300,
        cols: 1
    },
    banner: {
        header: 'Banners',
        name: 'banner',
        w: 1000,
        h: 250,
        cols: 4
    }
};

const style = (theme: Theme) => {
    return {
        margin: {
            marginTop: theme.spacing(2)
        },
        imageList: {
            height: 450,
            width: '100%',
            overflow: 'unset'
        }
    } as StyleRules;
};

interface ChooseImgDialogProps {
    open: boolean;
    imageType: ImageType;
    handleClose: () => void;
    handleSelection: (url: string) => void;
}

interface Images {
    defaultImgs: ImageSrc[];
    userImgs: ImageSrc[];
}

interface ChooseImgDialogState {
    file?: File;
}

type PropsType = ChooseImgDialogProps & WithStyles<typeof style> & Images & WithUserId;

class ChooseImgDialog extends React.Component<PropsType, ChooseImgDialogState> {
    state: ChooseImgDialogState = {};

    private handleFile = (ev: ChangeEvent<HTMLInputElement>) => {
        if (!ev.target.files) {
            return;
        }
        if ('image/jpeg' !== ev.target.files[0].type
            && 'image/jpg' !== ev.target.files[0].type
            && 'image/png' !== ev.target.files[0].type) {
            showError('Could not read file');
            return;
        }
        this.setState({ file: ev.target.files[0] });
        ev.currentTarget.value = '';
    }

    private setImage = (url: string) => {
        const { imageType, userId } = this.props;
        const data = { url: url, type: imageType } as ImageSrc;
        updateOrAdd(userImgDb(userId), data);
        this.props.handleSelection(url);
        this.handleCropDialogClose();
    }

    private handleCropDialogClose = () => {
        this.setState({ file: undefined });
    }

    render() {
        const { classes, open, defaultImgs, userImgs, imageType, handleClose, handleSelection } = this.props;
        const desc = descriptions[imageType];
        const images = [...defaultImgs];
        images.push(...userImgs);
        return (
            <React.Fragment>
                <XSMobileDialog open={open} onClose={handleClose}>
                    <DialogAppBar label={desc.header} close={handleClose} />
                    <DialogContent>
                        <Typography variant="body2">Upload a new {desc.name}</Typography>
                        <Typography variant="body1">
                            Select an image to upload from your computer.
                            Recommended resolution: {desc.w}x{desc.h}&nbsp;pixels.
                        </Typography>
                        <AppButton color="secondary">
                            <label>
                                Upload...
                                <input onChange={this.handleFile} accept="image/jpeg,image/jpg,image/png"
                                    style={{ display: 'none' }} type="file" />
                            </label>
                        </AppButton>
                        <Typography variant="body2" className={classes.margin}>Use one from from the
                            library:</Typography>
                        <ImageList rowHeight={160} cols={4} className={classes.imageList}>
                            {images.map(src => (
                                <ImageListItem key={src.url} cols={desc.cols}>
                                    <img src={src.url} alt="" height={150} onClick={() => handleSelection(src.url)} />
                                </ImageListItem>
                            ))}
                        </ImageList>
                    </DialogContent>
                </XSMobileDialog>
                <ImageCropDialog
                    storageRef={portalImagesRef}
                    setImage={this.setImage}
                    file={this.state.file}
                    close={this.handleCropDialogClose}
                    width={desc.w}
                    height={desc.h}
                    maxWidth={desc.w > 300 ? 'sm' : 'xs'}
                    rounded={false} />
            </React.Fragment>
        );
    }
}

interface Params {
    imageType: ImageType;
}

function getDefaultImgDb(params?: Params) {
    return query(defaultImgDb, where('type', '==', params!.imageType));
}

function getUserImgDb(params?: Params & WithUserId) {
    return query(userImgDb(params!.userId), where('type', '==', params!.imageType));
}

const ChooseImgDialogU = withUserId(ChooseImgDialog);

const WPublicImages = withData<ImageSrc, Images, Params>(getDefaultImgDb, 'defaultImgs')<ChooseImgDialogProps & WithStyles<typeof styles>>(ChooseImgDialogU);

const WPrivateImages = withData<ImageSrc, Partial<Images>, Params & WithUserId>(getUserImgDb, 'userImgs')<ChooseImgDialogProps & WithStyles<typeof styles>>(WPublicImages);

export default withStyles(style)(withUserId(WPrivateImages));
