import * as React from 'react';
import { StaticTimePicker } from "@mui/x-date-pickers";
import moment, { Moment } from "moment";
import { Box, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import AppButton from "./components/AppButton";
import { AppColors } from "../main/Theme";
import { TimeIcon } from "./Icons";

type MaterialTimeProps = {
    value: number | null,
    onChange: (date: Date) => void,
    onClose: () => void,
    onHeightChange: (expanded: boolean) => void
};

type CustomToolbarProps = {
    time: Moment,
    setSelectedTime: (moment: Moment) => void,
    showTips: boolean
};

const setInputValueRange = (min: number, max: number, prevVal: string, setValue: (val: string) => void) =>
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const val = event.target.value;
        if (!val) {
            setValue('');
            return;
        }
        if (val.length === 2 && val.startsWith('0')) {
            setValue(val);
            return;
        }
        let numberVal = parseInt(val, 10);
        if ((!numberVal && numberVal !== 0) || numberVal > max || numberVal < min) {
            setValue(prevVal);
            return;
        }
        setValue(numberVal + '');
    };

const getTwoDigitTimeStr = (time: string) => {
    return !time.length ? '00' : time.length === 1 ? ('0' + time) : time;
};

const strToNum = (time: string) => {
    return parseInt(time, 10);
};

const convert24TimeTo12 = (moment: Moment) => {
    const hours = moment.hours();
    if (hours % 12 === 0) {
        return "" + 12;
    }
    return getTwoDigitTimeStr("" + (moment.hours() % 12))
};

const convertHours12to24 = (hours12: string, isPm: boolean) => {
    let hours = strToNum(hours12 === '12' ? '00' : hours12);
    if (isPm) {
        hours += 12;
    }
    return hours;
}

const CustomToolbar = ({ time, setSelectedTime, showTips }: CustomToolbarProps) => {
    const [hours, setHours] = React.useState<string>(convert24TimeTo12(time));
    const [minutes, setMinutes] = React.useState<string>(getTwoDigitTimeStr("" + time.minutes()));
    React.useEffect(() => {
        setHours(convert24TimeTo12(time));
        setMinutes(getTwoDigitTimeStr("" + time.minutes()));
    }, [time]);
    const [isAm, setIsAm] = React.useState(time.hours() < 12);
    const [isHoursFocused, setHoursFocused] = React.useState(true);
    const [isMinutesFocused, setMinutesFocused] = React.useState(false);
    return <Box style={{ margin: '20px 10px 10px' }}>
        <Box display="flex" flexDirection="row" alignItems="center">
            <Box display="flex" flexDirection="column">
                <Box style={{
                    backgroundColor: isHoursFocused ? 'rgb(226, 236, 245)' : AppColors.webGreyLight, width: 100,
                    borderRadius: '8px', height: 80
                }}>
                    <TextField
                        variant="outlined"
                        value={hours}
                        onChange={setInputValueRange(0, 12, hours, setHours)}
                        focused={isHoursFocused}
                        onFocus={() => {
                            setHoursFocused(true);
                            setMinutesFocused(false);
                        }}
                        onBlur={() => {
                            setHours(getTwoDigitTimeStr(hours));
                            setSelectedTime(
                                moment(
                                    time.set({ hour: convertHours12to24(hours, !isAm), minute: strToNum(minutes) })
                                )
                            );
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                ":not(&.Mui-focused) fieldset": {
                                    border: 'none'
                                }
                            }
                        }}
                        InputProps={{
                            style: {
                                color: isHoursFocused ? 'rgb(88, 147, 199)' : AppColors.webBlack, height: 80,
                                textAlign: 'center', fontSize: 57
                            }
                        }} />
                </Box>
                <Box height={8} />
                {showTips && <Typography style={{ fontSize: 12, lineHeight: '16px', color: '#949494' }}>
                    Hour
                </Typography>}
            </Box>
            <SeparatorIcon />
            <Box display="flex" flexDirection="column">
                <Box style={{
                    backgroundColor: isMinutesFocused ? 'rgb(226, 236, 245)' : AppColors.webGreyLight, width: 100,
                    borderRadius: '8px'
                }}>
                    <TextField
                        variant="outlined"
                        value={minutes}
                        onChange={setInputValueRange(0, 59, minutes, setMinutes)}
                        focused={isMinutesFocused}
                        onFocus={() => {
                            setHoursFocused(false);
                            setMinutesFocused(true);
                        }}
                        onBlur={() => {
                            setMinutes(getTwoDigitTimeStr(minutes));
                            setSelectedTime(moment(
                                time.set({ hour: convertHours12to24(hours, !isAm), minute: strToNum(minutes) })
                            ));
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                ":not(&.Mui-focused) fieldset": {
                                    border: 'none'
                                }
                            }
                        }}
                        InputProps={{
                            style: {
                                color: isMinutesFocused ? 'rgb(88, 147, 199)' : AppColors.webBlack, height: 80,
                                textAlign: 'center', fontSize: 57
                            }
                        }}
                    />
                </Box>
                <Box height={8} />
                {showTips && <Typography style={{ fontSize: 12, lineHeight: '16px', color: '#949494' }}>
                    Minute
                </Typography>}
            </Box>
            <Box display="flex" flexDirection="column" marginLeft={2} alignSelf="flex-start">
                <Box onClick={() => {
                    if (!isAm) {
                        setIsAm(true);
                        setSelectedTime(moment(time.set({ hour: time.hours() - 12, minute: strToNum(minutes) })));
                    }
                }}>
                    <Typography sx={{
                        fontWeight: 500, lineHeight: '23px', fontSize: 16, padding: '8px 16px',
                        cursor: 'pointer', color: isAm ? '#153E5F' : AppColors.webGrey200, letterSpacing: '0.15px',
                        backgroundColor: isAm ? '#BBD5EC' : 'white', border: `1px solid ${AppColors.webBlue500}`,
                        borderTopRightRadius: '8px', borderTopLeftRadius: '8px', borderBottom: 'none'
                    }}>AM</Typography>
                </Box>
                <Box onClick={() => {
                    if (isAm) {
                        setIsAm(false);
                        setSelectedTime(moment(time.set({ hour: time.hours() + 12, minute: strToNum(minutes) })));
                    }
                }}>
                    <Typography sx={{
                        fontWeight: 500, lineHeight: '23px', fontSize: 16, padding: '8px 16px',
                        cursor: 'pointer', color: isAm ? AppColors.webGrey200 : '#153E5F', letterSpacing: '0.15px',
                        backgroundColor: isAm ? 'white' : '#BBD5EC', border: `1px solid ${AppColors.webBlue500}`,
                        borderBottomRightRadius: '8px', borderBottomLeftRadius: '8px'
                    }}>PM</Typography>
                </Box>
            </Box>
        </Box>
    </Box>;
};

const KeyboardIcon = ({ onClick, style }: { onClick: () => void, style?: React.CSSProperties }) => {
    return (
        <svg onClick={onClick} style={style} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" fill="#949494"
                d="M20 5H4C2.9 5 2.01 5.9 2.01 7L2 17C2 18.1 2.9 19 4 19H20C21.1 19 22 18.1 22 17V7C22 5.9 21.1 5 20 5ZM20 7V17H4V7H20ZM13 8H11V10H13V8ZM11 11H13V13H11V11ZM10 8H8V10H10V8ZM8 11H10V13H8V11ZM7 11H5V13H7V11ZM5 8H7V10H5V8ZM16 14H8V16H16V14ZM14 11H16V13H14V11ZM16 8H14V10H16V8ZM17 11H19V13H17V11ZM19 8H17V10H19V8Z" />
        </svg>
    );
};

const SeparatorIcon = () => {
    return (
        <svg width="24" height="32" viewBox="0 0 8 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill="#212121" d="M0.799316 28.2725C0.799316 27.4004 1.06836 26.6675 1.60645 26.0737C2.16309 25.4614 2.96094 25.1553 4 25.1553C5.03906 25.1553 5.82764 25.4614 6.36572 26.0737C6.92236 26.6675 7.20068 27.4004 7.20068 28.2725C7.20068 29.126 6.92236 29.8496 6.36572 30.4434C5.82764 31.0371 5.03906 31.334 4 31.334C2.96094 31.334 2.16309 31.0371 1.60645 30.4434C1.06836 29.8496 0.799316 29.126 0.799316 28.2725ZM0.827148 3.69678C0.827148 2.82471 1.09619 2.0918 1.63428 1.49805C2.19092 0.885742 2.98877 0.57959 4.02783 0.57959C5.06689 0.57959 5.85547 0.885742 6.39355 1.49805C6.9502 2.0918 7.22852 2.82471 7.22852 3.69678C7.22852 4.55029 6.9502 5.27393 6.39355 5.86768C5.85547 6.46143 5.06689 6.7583 4.02783 6.7583C2.98877 6.7583 2.19092 6.46143 1.63428 5.86768C1.09619 5.27393 0.827148 4.55029 0.827148 3.69678Z" />
        </svg>
    );
};

type CustomActionBarProps = {
    timePickerOpened: boolean,
    onCancel: () => void,
    onOk: () => void,
    onIconClick: () => void
};

const CustomActionBar = ({ onCancel, onOk, onIconClick, timePickerOpened }: CustomActionBarProps) => {
    const grey = 'rgb(148, 148, 148)';
    return (
        <Box display="flex" flexDirection="row" justifyContent="space-between" padding="16px 8px 8px">
            <Box display="flex" alignItems="center">
                {timePickerOpened ? <KeyboardIcon style={{ cursor: 'pointer' }} onClick={onIconClick} /> :
                    <TimeIcon style={{ cursor: 'pointer' }} onClick={onIconClick} color={grey} />}
            </Box>
            <Box display="flex" flexDirection="row" alignItems="center">
                <AppButton color="info" sx={{ textTransform: 'none' }} onClick={onCancel}>
                    Cancel
                </AppButton>
                <Box width={12} />
                <AppButton color="secondary" onClick={onOk}>Ok</AppButton>
            </Box>
        </Box>
    );
};

function MaterialTime({ value, onChange, onClose, onHeightChange }: MaterialTimeProps) {
    const [selectedTime, setSelectedTime] = React.useState(value ? moment(value) : moment());
    const [timePickerOpened, setTimePickerOpened] = React.useState(false);
    const onOk = () => {
        onChange(selectedTime?.toDate() ?? new Date());
        onClose();
    };
    const changeTimePickerOpened = () => {
        onHeightChange(!timePickerOpened);
        setTimePickerOpened(!timePickerOpened);
    };
    return (<>
        <CustomToolbar showTips={!timePickerOpened} time={selectedTime} setSelectedTime={setSelectedTime} />
        {timePickerOpened && <StaticTimePicker
            ampm
            slotProps={{
                toolbar: {
                    hidden: true,
                }
            }}
            slots={{
                actionBar: _ => <></>,
            }}
            orientation="portrait"
            value={selectedTime}
            onChange={m => setSelectedTime(m ?? moment())}
        />}
        <CustomActionBar timePickerOpened={timePickerOpened} onCancel={onClose}
            onOk={onOk} onIconClick={changeTimePickerOpened} />
    </>
    );
}

export default MaterialTime;
