import * as React from 'react';
import { Box, List, ListItem, ListItemButton, SxProps, Typography, useMediaQuery, useTheme } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import Grid, { GridSize } from '@mui/material/Grid';
import PersonIcon from '@mui/icons-material/Person';
import PersonsIcon from '@mui/icons-material/PeopleAlt';
import * as Backend from '../util/firebase';
import {
    EventBase, Round, Competition, ScoringData, Event, Contact, GolferGroup, Score, ReportedScore, ContactPayoutState, Team,
    isIndividualScoring, PayoutSettings, isNetMode, isGrossMode, isTeamFormat, isSkinsScoring, isTeamScoring, Units,
    getTee, getTotalHoles, teamsOfFlight, contactsOfFlight, teamsOf, golfersOfCompetition, isStablefordScoringOrMode,
    Tee, ContactScoringState, isNetPayouts, isGrossPayouts, CalculatedScores, isTeamScoringExceptBB,
    ScoringFormatTeams, isCompatibleCompetitionSkins, getEventMainCompetition, isTeamFormatExceptBB,
    getHolesRange, ScoringFormatSkins, HOLES_9, HOLES_9_9, getHoleLabel, isMainScoring, isDistanceScoring, Distance,
    ScoringFormatDistance, getRoundsCompetitions, sortCompetitions, hasFirstRoundCompetition, rollEvents,
    ContactRoundsScores, isRound, isTotalingCompetition
} from '../types/EventTypes';
import {
    HolesScoringState, getSkinsScores, SkinsScoringState, eventPayoutsStates, getDistanceScores, formatDistance, hasTees,
    genderFromEvent, golferMainCompetitionAward, getPayouts, isFullScoresCompetition, isFullScoresCompetitions,
    getFullCompetitionPurse, processSkins, getSplitCompetitionsWithPayouts, getActualSkin, skinCompetitionAward
} from '../event/Event';
import { getFlightName } from '../event/TeeTimes';
import { ScorecardRow, Edit, scoresOf, reportedScoresOf } from '../event/tabs/scores/EditScoreDialog';
import * as Scoring from '../scoring/scoring';
import { fullName, formatHandicap, getSameNameGolfersIds, golfersOfTeam, golferShortTeamNameArray } from '../contact/Contact';
import { PortalEditScoreDialog } from '../event/tabs/scores/EditScoreDialog';
import AppButton from '../common/components/AppButton';
import { FirebaseDataComponent, FirebaseUserPubDataComponent } from '../common/WithData';
import { Container, Item, ItemS, NoWrapOverflow, ListTitle, Spacing, FlexGrid, Label } from '../common/Misc';
import { getHoleHandicap, getGolferRangeHandicap, hardestTee, getPlayingHandicap, getHandicapsAllowance, fixTeamsHandicapAndGender } from '../scoring/handicap';
import { randomString, range } from '../util/utility';
import { appStyles, styles } from '../styles';
import { round, formatCurrency } from '../util/utility';
import { getEventInfo } from "./About";
import { Urls } from "../util/config";
import { showAlertProps } from "../redux/ReduxConfig";
import useDidUpdateEffect from "../hooks/useDidUpdateEffect";
import styled, { keyframes } from "styled-components";
import { AppColors } from "../main/Theme";
import { Theme } from "@mui/material/styles";
import ButtonBar from 'src/common/components/ButtonBar';

const linkIconImage = '/img/link_icon_transparent14x14.png';
const skinsTieImage = '/img/skinsTiePic24x24.png';
const skinsWinImage = '/img/skinsWinPic24x24.png';

const holeByHoleWidth = 1000;
const holeByHoleNamesWidth18 = 200;
const holeByHoleNamesWidth9 = 525;

enum ScoreCompetitionInfoState {
    SCORING_NAME,
    LL_INFO,
    NONE
}

interface HeaderProps {
    event: EventBase;
    scoring: ScoringData;
    scoreRounds?: Array<number>;
    holeView?: boolean;
    withWinnings?: boolean;
    holeByHoleNamesWidth: number;
    fullHoleByHoleWidth: number;
}

const Header = (props: HeaderProps) => {
    const { event, scoring, scoreRounds, withWinnings, holeView, holeByHoleNamesWidth, fullHoleByHoleWidth } = props;
    const classes = appStyles();
    const isTeam = isTeamScoring(scoring);
    const isNet = isNetMode(scoring.mode);
    const isGross = isGrossMode(scoring.mode);
    const isStableford = isStablefordScoringOrMode(scoring);
    const isSkins = isSkinsScoring(scoring);
    const tightMode = window.screen.width < 500;
    const columnStyle: React.CSSProperties = { fontSize: tightMode ? 9 : "inherit" };
    if (holeView) {
        const holesRange = getHolesRange(event.holesType);
        const holeLabelsGen = (hole: number) => {
            if (hole === -1) {
                return isNet ? <span>Net<br />OUT</span> : 'OUT';
            }
            if (hole === -2) {
                return isNet ? <span>Net<br />IN</span> : 'IN';
            }
            if (hole === -3) {
                return isNet ? 'Total Net' : 'Total';
            }
            if (hole === -4 && isNet) {
                return 'Total Gross';
            }
            if (hole < 0) {
                return '';
            }
            return getHoleLabel(hole, holesRange);
        };
        return (
            <ListItem className={classes.portalListItemHeader} style={{ width: fullHoleByHoleWidth }}>
                <Container wrap="nowrap">
                    <Item width={30}>Pos</Item>
                    <Item width={holeByHoleNamesWidth}>{isTeam ? 'Team' : 'Golfer'}</Item>
                    <ItemS>
                        <div className={classes.portalScorecardPanel}>
                            <ScorecardRow
                                label=""
                                inOutForm={2}
                                holesType={event.holesType}
                                gen={holeLabelsGen}
                                classes={{
                                    cellsInOut: classes.portalCellsInOut,
                                    cellsRoot: classes.portalCellsRoot,
                                    cells: classes.portalCells,
                                    cell: classes.portalHeaderCell,
                                    row: classes.portalHeader
                                }}
                            />
                        </div>
                    </ItemS>
                </Container>
            </ListItem>
        );
    }
    const namesXs = (((isNet && withWinnings) ? 6 : (isNet || withWinnings) ? 7 : 8) + ((isSkins && isNet) ? 1 : 0)) as 6 | 7 | 8 | 9;
    return (
        <ListItem className={classes.listItemHeader}>
            <Container wrap="nowrap">
                <Item xs={1} lg={1}><span style={columnStyle}>Pos</span></Item>
                <Item xs={namesXs} lg={namesXs}><span style={columnStyle}>{isTeam ? 'Team' : 'Golfer'}</span></Item>
                {scoreRounds && <>
                    {scoreRounds.map(scoreRound => <Item key={scoreRound} xs={1}>R{scoreRound}</Item>)}
                    <Item xs={1}>Total</Item>
                </>}
                {!scoreRounds && <>
                    <Item xs={1} lg={1}><span style={columnStyle}>{isSkins ? 'Skins' : isNet ? 'Net' : 'Score'}</span></Item>
                    <Item xs={1} lg={1}><span style={columnStyle}>Thru</span></Item>
                    {!(isSkins && isNet) && <Item xs={1} lg={1}><span style={columnStyle}>Total<br /> {(isNet || isSkins) ? ' Gross' : ''}</span></Item>}
                    {!isStableford && isNet && <Item xs={1} lg={1}><span style={columnStyle}>Total<br /> Net</span></Item>}
                    {isStableford && isGross && <Item xs={1} lg={1}><span style={columnStyle}>Pts</span></Item>}
                    {isStableford && isNet && <Item xs={1} lg={1}><span style={columnStyle}>Pts<br /> Net</span></Item>}
                </>}
                {withWinnings && <Item xs={1} lg={1}><span style={columnStyle}>Purse</span></Item>}
            </Container>
        </ListItem>
    );
};

interface RowProps {
    onClick?: () => void;
    withWinnings?: boolean;
    winning?: number;
    event: EventBase;
    competition: Competition;
    reportedScores: Map<string, ReportedScore>;
    scores: Map<string, Score>;
    golfers: Map<string, Contact>;
    sameNameGolfersIdsSet: Set<string>;
    mainCompetition: Competition;
    roundScorecardLink?: string;
    index: number;
    skinScore?: number;
    skins?: SkinsScoringState[];
    holeByHoleNamesWidth: number;
    fullHoleByHoleWidth: number;
}

const getRoundScorecardLink = (contact?: Contact) => {
    if (contact && contact.roundToken) {
        return `${Urls.baseServerUrl}/round/${contact.roundToken}?puid=${randomString(4)}`;
    }
    return undefined;
}

export const getAnimation = (defaultColour: string) => {
    return keyframes`
      0% {
        background-color: ${defaultColour};
        opacity: 1;
      }
      15% {
        background-color: ${AppColors.reportedColor};
        opacity: .95;
      }
      85% {
        background-color: ${AppColors.reportedColor};
        opacity: .95;
      }
      100% {
          background-color: ${defaultColour};
          opacity: 1;
      }
    `;
};

const ContainerEven = styled.div`
        animation-name: ${getAnimation('#fff')};
        animation-duration: 4s;
    `;

export const ContainerOdd = styled.div`
        animation-name: ${getAnimation('#eeeeee')};
        animation-duration: 4s;
    `;

type RowScoreProps = RowProps & { contactRoundsScores: ContactRoundsScores; };

const RowScoreHoles = (props: RowScoreProps) => {
    const classes = appStyles();
    const { onClick, sameNameGolfersIdsSet, contactRoundsScores, holeByHoleNamesWidth } = props;
    const { event, competition, scores, reportedScores, golfers, mainCompetition, skins, fullHoleByHoleWidth } = props;
    const { scoringStates } = contactRoundsScores;
    const firstScore = scoringStates[0]!;
    const hasRounds = scoringStates.length > 1;
    const { player, contactInfo, courseHandicap, finalGrossScore, tee, nets } = firstScore;
    const scoring = competition.scoring;
    const holesRange = getHolesRange(event.holesType);
    const totalHoles = holesRange.last - holesRange.first;
    const isNet = isNetMode(scoring.mode);
    const bestBall = scoring.format === ScoringFormatTeams.best_ball;
    const isSkins = isSkinsScoring(scoring);
    const courseHcp = courseHandicap == null || isNet == null ? '' : ` (${formatHandicap(courseHandicap)})`;
    const posWinner = hasRounds ?
        contactRoundsScores.pos + (firstScore.winnerIn?.find(w => w === scoring.mode) ? '*' : '') :
        firstScore.pos;
    let withHomeCourseOrCity: boolean = false;
    const setHomeCourseOrCity = (homeCourseOrCity: string | undefined) => {
        withHomeCourseOrCity = true;
        return homeCourseOrCity;
    };
    const golferNames = (golfer: Contact) => {
        return (
            <Container>
                <Item>
                    <NoWrapOverflow>
                        {fullName(golfer, 15, 25)}
                        <span className={classes.homeCourseOrCity}>
                            {golfer.homeCourseOrCity && sameNameGolfersIdsSet.has(golfer.id) ? ` (${golfer.homeCourseOrCity})` : ''}
                        </span>
                        {courseHcp}
                    </NoWrapOverflow>
                </Item>
            </Container>
        );
    };
    const teamNames = (team: Team) => {
        const names = golferShortTeamNameArray(team, golfers, false);
        const contactIds = golfersOfTeam(team, golfers).map(c => c.id);
        const playingHandicaps = contactInfo.playingHandicaps.map(ci => ci == null || isNet == null ? '' : ` (${formatHandicap(ci)})`);
        return (
            <Container>
                {names.map((name, idx) =>
                    <Item key={idx} style={{ marginRight: 8 }}>
                        <NoWrapOverflow>
                            {name}
                            <span className={classes.homeCourseOrCity}>
                                {sameNameGolfersIdsSet.has(contactIds[idx]) && golfers.get(contactIds[idx])?.homeCourseOrCity ? ` (${setHomeCourseOrCity(golfers.get(contactIds[idx])?.homeCourseOrCity)})` : ''}
                            </span>
                            {playingHandicaps[idx]}
                        </NoWrapOverflow>
                    </Item>)}
            </Container>
        );
    };
    const namesOnly = 'contactIds' in player ? teamNames(player) : golferNames(player);
    let totGross1 = 0;
    let totGross2 = 0;
    let totNet1 = 0;
    let totNet2 = 0;
    const courseHoleCount = event.holesType === HOLES_9 || event.holesType === HOLES_9_9 ? 9 : 18;
    const tees = contactInfo.contacts.map(c => getTee(event, competition, c.gender, c));
    const usedTee = hardestTee(tees);
    const { par } = tee || usedTee || {} as Tee;
    const rawScore = scoresOf(firstScore.contactId, event, firstScore.contactId, competition, contactInfo.contacts, scores);
    const reportedScore = reportedScoresOf(firstScore.contactId, event, firstScore.contactId, competition, contactInfo.contacts, reportedScores);
    const score: Score = finalGrossScore;
    const hs = event.handicapSystem ? event.handicapSystem : (usedTee ? usedTee.handicapSystem : 'WHS');
    let playingHandicap = 0;
    if (isTeamScoringExceptBB(scoring) || (scoring.format === ScoringFormatSkins.skins_team && mainCompetition?.scoring?.format && mainCompetition.scoring.format !== ScoringFormatTeams.best_ball)) {
        playingHandicap = contactInfo.teamPlayingHandicap ?? 0;
    } else if (!bestBall && isNet) {
        playingHandicap = getPlayingHandicap(event.holesType, usedTee, event.handicapSystem, getGolferRangeHandicap(event.holesType, event.handicapSystem, contactInfo.handicapIndex), getHandicapsAllowance(scoring, 0));
    }
    for (let hole = holesRange.first; hole < 9; hole++) {
        totGross1 += score.gross[hole];
        if (isNet) {
            totNet1 += (nets && nets[hole] != null) ? nets[hole]! : Scoring.scoreNet(hs, hole, playingHandicap, rawScore, reportedScore, event.holesType, usedTee) || 0;
        }
    }
    for (let hole = 9; hole < holesRange.last; hole++) {
        totGross2 += score.gross[hole];
        if (isNet) {
            totNet2 += (nets && nets[hole] != null) ? nets[hole]! : Scoring.scoreNet(hs, hole, playingHandicap, rawScore, reportedScore, event.holesType, usedTee) || 0;
        }
    }
    let bestBallNetScores: Array<number | undefined> = [];
    if (bestBall) {
        if (nets) {
            bestBallNetScores = nets;
        } else {
            const playingHandicaps = contactInfo.contacts.map((teamContact, teamIndex) => {
                return getPlayingHandicap(event.holesType, getTee(event, competition, teamContact.gender, teamContact), event.handicapSystem, getGolferRangeHandicap(event.holesType, event.handicapSystem, teamContact.handicapIndex), scoring!.handicaps![teamIndex]);
            });
            bestBallNetScores = Scoring.bestBallNet(hs, contactInfo.contacts, playingHandicaps, scores, reportedScores, tees, event.holesType);
        }
    }
    const grossScoresGen = (hole: number) => {
        if (hole === -1) {
            return totGross1 || ' ';
        }
        if (hole === -2) {
            return totGross2 || ' ';
        }
        if (hole === -3) {
            return (totGross1 + totGross2) || ' ';
        }
        if (hole < 0) {
            return '';
        }
        const holeScore = score.gross[hole];
        return (<Edit
            type={'SCORE'}
            value={holeScore}
            onKey={() => { }}
            onValue={() => { }}
            diff={Scoring.getScoreDiffName(holeScore, par && par[hole % courseHoleCount])}
            classes={{ decor: classes.portalDecor }}
            readonly={true}
            withPickUp={score.pickUps && score.pickUps[hole]} />);
    };
    const netScoresGen = (hole: number) => {
        if (hole === -1) {
            return totNet1 || ' ';
        }
        if (hole === -2) {
            return totNet2 || ' ';
        }
        if (hole === -3) {
            return (totNet1 + totNet2) || ' ';
        }
        if (hole === -4) {
            return (totGross1 + totGross2) || ' ';
        }
        if (hole < 0) {
            return '';
        }
        const holeScore = score.gross[hole];
        const netScore = bestBall ? bestBallNetScores[hole] : nets ? nets[hole] : Scoring.scoreNet(hs, hole, playingHandicap, rawScore, reportedScore, event.holesType, usedTee);
        return (<Edit
            type={'SCORE'}
            value={holeScore}
            onKey={() => { }}
            onValue={() => { }}
            handicap={(!bestBall && getHoleHandicap(hole, event.holesType, usedTee, event.handicapSystem, getGolferRangeHandicap(event.holesType, event.handicapSystem, contactInfo.handicapIndex), getHandicapsAllowance(scoring, 0), playingHandicap)) || 0}
            diff={Scoring.getScoreDiffName(bestBall ? bestBallNetScores[hole] : netScore, par && par[hole % courseHoleCount])}
            classes={{ decor: classes.portalDecor }}
            readonly={true}
            additionalDotStyles={{ marginTop: -5 }}
            withPickUp={score.pickUps && score.pickUps[hole]} />);
    };
    const getSkinsCellClassNameGen = (tieColorClassName: string, winColorClassName: string, skins?: SkinsScoringState[]) =>
        (hole: number): string => {
            if (!skins || !skins[hole % totalHoles]?.contacts?.length) {
                return '';
            }
            if (skins[hole % totalHoles].contacts.length === 1 && skins[hole % totalHoles].contacts[0].golferOrTeamId === firstScore.contactId) {
                return ` ${winColorClassName}`;
            }
            if (skins[hole % totalHoles].contacts.findIndex(contactHcp => contactHcp.golferOrTeamId === firstScore.contactId) > -1) {
                return ` ${tieColorClassName}`;
            }
            return '';
        }
    const elemClassName = (event.teamSize < (totalHoles === 9 ? 4 : 3) && !withHomeCourseOrCity) ?
        `${classes.portalListItemZebra} ${classes.height40}` : classes.portalListItemZebra;
    const scorecardClasses = {
        cellsInOut: classes.portalCellsInOut,
        cellsRoot: classes.portalCellsRoot,
        cells: classes.portalCells,
        cell: classes.portalCell,
        row: classes.portalRow
    };
    return (
        <ListItemButton className={elemClassName} style={{ width: fullHoleByHoleWidth }} onClick={onClick}>
            <Container wrap="nowrap">
                <Item width={30} fontFamily="roboto">{posWinner}</Item>
                <Item width={holeByHoleNamesWidth}>{namesOnly}</Item>
                <ItemS>
                    <div className={classes.portalScorecardPanel}>
                        <ScorecardRow
                            label=""
                            inOutForm={2}
                            holesType={event.holesType}
                            gen={isNet ? netScoresGen : grossScoresGen}
                            classes={scorecardClasses}
                            skinsClassNameGen={(isSkins && skins) ? getSkinsCellClassNameGen(classes.skinTieBackground, classes.skinWinBackground, skins) : undefined}
                        />
                    </div>
                </ItemS>
            </Container>
        </ListItemButton>
    );
};

interface NoParticipantsRowProps {
    individual: boolean;
    width?: string | number;
    holeView?: boolean;
}

const NoParticipantsRow = ({ individual, width, holeView }: NoParticipantsRowProps) => {
    const isXs = useMediaQuery(useTheme().breakpoints.down('sm'));
    return (<div style={{
        minHeight: 40, width: width, backgroundColor: AppColors.webGreyLight, display: 'flex',
        justifyContent: 'center', alignContent: 'center', flexDirection: 'column'
    }}>
        <span style={{
            color: AppColors.webBlack, fontSize: 14, lineHeight: '21px', fontFamily: 'poppins, sans-serif',
            margin: '6px 10px', width: isXs && holeView ? window.innerWidth - 20 : 'auto'
        }}>
            {individual ? 'Golfers' : 'Teams'} will be visible here, once players are added or registered for the event.
        </span>
    </div>);
};

type StandingsViewModeButtonsProps = {
    holeView?: boolean;
    setHoleView: (value: boolean) => void;
    alignRight: boolean;
};

const commonSxProps: SxProps<Theme> | undefined = {
    width: '140px',
    height: '32px',
    padding: '6px 16px',
    gap: 10,
    '&:hover': { backgroundColor: AppColors.webBlue100 }
};

const StandingsViewModeButtons = (props: StandingsViewModeButtonsProps) => {
    const { holeView, alignRight, setHoleView } = props;
    const classes = appStyles();
    return <div className={alignRight ? classes.marginLeftAuto : classes.marginBottom16}>
        <AppButton
            color={'info'}
            sx={{ ...commonSxProps, borderRadius: '8px 0px 0px 8px', backgroundColor: holeView ? AppColors.white : AppColors.webBlue100 }}
            onClick={() => setHoleView(false)}>Total scores</AppButton>
        <AppButton
            color={'info'}
            sx={{ ...commonSxProps, borderRadius: '0px 8px 8px 0px', backgroundColor: holeView ? AppColors.webBlue100 : AppColors.white }}
            onClick={() => setHoleView(true)}>Hole-by-hole</AppButton>
    </div>;
};

type SkinsCompetitionHeaderProps = {
    eventOrRound: EventBase;
    competition: Competition;
    holeView?: boolean;
    setHoleView: (value: boolean) => void;
    compCounter: number;
    mainCompetition: Competition;
    skinsMixed?: boolean;
    totalMode?: boolean;
    infoState: ScoreCompetitionInfoState;
};

const CompetitionHeader = (props: SkinsCompetitionHeaderProps) => {
    const classes = appStyles();
    const isXs = useMediaQuery(useTheme().breakpoints.down('sm'));
    const { eventOrRound, holeView, competition, setHoleView, mainCompetition, compCounter, infoState, skinsMixed, totalMode } = props;
    const infoComponent = infoState === ScoreCompetitionInfoState.SCORING_NAME ?
        <ListTitle text={Scoring.scoringName(competition, eventOrRound.eventGender, competition.competitionGender, skinsMixed)}>
            {totalMode && isRound(eventOrRound) && !isTotalingCompetition(competition) && <>
                <Typography>
                    Round {eventOrRound.roundOrder}
                </Typography>
            </>}
        </ListTitle> :
        infoState === ScoreCompetitionInfoState.LL_INFO ? <LeaderBoardInfo classes={classes} event={eventOrRound} mainCompetition={mainCompetition} /> : '';
    return (isXs ? <Grid container direction="column">
        {(compCounter === 1 && !totalMode) && <StandingsViewModeButtons holeView={holeView} setHoleView={setHoleView} alignRight={false} />}
        <Box sx={{ paddingBottom: 1 }}>{infoComponent}</Box>
    </Grid> : <FlexGrid gridPadding={4}>
        {infoComponent}
        {(compCounter === 1 && !totalMode) && <StandingsViewModeButtons holeView={holeView} setHoleView={setHoleView} alignRight={true} />}
    </FlexGrid>)
};

const RowScoreSum = (props: RowScoreProps) => {
    const classes = appStyles();
    const { onClick, winning, sameNameGolfersIdsSet, roundScorecardLink, index } = props;
    const { contactRoundsScores, competition, reportedScores, golfers, skinScore } = props;
    const { scoringStates } = contactRoundsScores;
    const firstScore = scoringStates[0]!;
    const hasRounds = scoringStates.length > 1;
    const { player, pos, courseHandicap } = firstScore;
    const scoring = competition.scoring;
    const isNet = isNetMode(scoring.mode);
    const isGross = isGrossMode(scoring.mode);
    const bestBall = scoring.format === ScoringFormatTeams.best_ball;
    const isStableford = isStablefordScoringOrMode(scoring);
    const isSkins = isSkinsScoring(scoring);
    const golferDisStatus = (score?: ContactScoringState) => score?.contactInfo.withdrawn ? 'WD' : score?.contactInfo.disqualified ? 'DQ' : undefined;
    const relScore = (score?: ContactScoringState) => golferDisStatus(score) ?? Scoring.formatRelativeScore(isNet ? score?.relativeNet ?? 0 : score?.relativeTotal ?? 0);
    const scoreThru = (score?: ContactScoringState) => score?.holes === getTotalHoles(score?.event.holesType) ?
        (hasRounds ? '' : 'F') :
        (score?.holes ? (hasRounds ? 'thru ' : '') + score.holes : '');
    const scoreToDisplay = (score?: ContactScoringState) => golferDisStatus(score) ??
        (
            //isSkins ? (isNet ? score?.ski : score?.stableford) :
            isStableford ? (isNet ? score?.stablefordNet : score?.stableford) :
                Scoring.formatTotalScore(scoringStates.length, isNet ? score?.net : score?.total)
        );
    const scoreGross = (score?: ContactScoringState) => golferDisStatus(score) ?? Scoring.formatTotalAndRelativeScore(scoringStates.length, score?.total, score?.relativeTotal);
    const scoreNet = (score?: ContactScoringState) => golferDisStatus(score) ?? Scoring.formatTotalAndRelativeScore(scoringStates.length, score?.net, score?.relativeNet);
    const scorePts = (score?: ContactScoringState) => golferDisStatus(score) ?? score?.stableford;
    const scoreNetPts = (score?: ContactScoringState) => golferDisStatus(score) ?? score?.stablefordNet;
    const courseHcp = courseHandicap == null || isNet == null ? '' : ` (${formatHandicap(courseHandicap)})`;
    const golferNames = (golfer: Contact) => {
        return (
            <Container>
                <ItemS>
                    <PersonIcon className={classes.textIcon} />
                    <Label paddingLeft={0}>
                        {fullName(golfer, 15, 25)}
                        <span className={classes.homeCourseOrCity}>
                            {golfer.homeCourseOrCity && sameNameGolfersIdsSet.has(golfer.id) ? ` (${golfer.homeCourseOrCity})` : ''}
                        </span>
                        {courseHcp}
                    </Label>
                </ItemS>
            </Container>
        );
    };
    const teamNames = (team: Team) => {
        const names = golferShortTeamNameArray(team, golfers, false);
        const contactIds = golfersOfTeam(team, golfers).map(c => c.id);
        const playingHandicaps = firstScore.contactInfo.playingHandicaps.map(ci => ci == null || isNet == null ? '' : ` (${formatHandicap(ci)})`);
        const home = (idx: number) => sameNameGolfersIdsSet.has(contactIds[idx]) && golfers.get(contactIds[idx])?.homeCourseOrCity ? ` (${golfers.get(contactIds[idx])?.homeCourseOrCity})` : '';
        return !bestBall ?
            <Container>
                {names.map((name, idx) =>
                    <ItemS key={idx} style={{ marginRight: 16 }}>
                        {idx === 0 && <PersonsIcon className={classes.textIcon} />}
                        <Label paddingLeft={idx === 0 ? 0 : undefined}>
                            {name}
                            <span className={classes.homeCourseOrCity}>{home(idx)}</span>
                            {idx === names.length - 1 ? courseHcp : ''}
                        </Label>
                    </ItemS>)}
            </Container> :
            <Container>
                {names.map((name, idx) =>
                    <ItemS key={idx} style={{ marginRight: 16 }}>
                        {idx === 0 && <PersonsIcon className={classes.textIcon} />}
                        <Label paddingLeft={idx === 0 ? 0 : undefined}>
                            {name}
                            <span className={classes.homeCourseOrCity}>{home(idx)}</span>
                            {playingHandicaps[idx]}
                        </Label>
                    </ItemS>)}
            </Container>
    };
    const namesIcons = 'contactIds' in player ? teamNames(player) : golferNames(player);
    const minHeightFunc: (instance: HTMLDivElement | null) => void = listItemRef => {
        if (listItemRef?.className) {
            if (listItemRef.clientWidth === 1000 && !listItemRef.className.includes(classes.minHeight40)) {
                listItemRef.className = listItemRef.className.concat(' ', classes.minHeight40);
            }
        }
    };
    let imageClickFixed = false;
    const onLinkIconImageClicked = () => {
        imageClickFixed = true;
        if (roundScorecardLink) {
            window.open(roundScorecardLink, '_blank');
        }
    };
    const onListItemClicked = () => {
        if (!imageClickFixed && onClick) {
            onClick();
        }
    };
    const withWinnings = !!winning || winning === 0;
    const namesXs = (((isNet && withWinnings) ? 6 : (isNet || withWinnings) ? 7 : 8) + ((isSkins && isNet) ? 1 : 0)) as 6 | 7 | 8 | 9;
    let totalScore = 0;
    if (isStableford) {
        totalScore = (isNet ? contactRoundsScores.stablefordNet : contactRoundsScores.stableford) ?? 0;
    } else {
        totalScore = (isNet ? contactRoundsScores.net : contactRoundsScores.total) ?? 0;
    }
    const gridComponent = (
        <Container wrap="nowrap">
            <Item wide={Boolean(roundScorecardLink)} className={classes.flexRowContainer} xs={1} lg={1} fontFamily="roboto">
                <div style={{ width: '100%', paddingRight: 2, fontFamily: '"roboto' }}>
                    {pos}{roundScorecardLink &&
                        <img onClick={onLinkIconImageClicked} className={classes.linkIconImage} src={linkIconImage} alt='' />}
                </div>
            </Item>
            <Item xs={namesXs} lg={namesXs}>{namesIcons}</Item>
            {hasRounds && <>
                {scoringStates.map((score, idx) =>
                    <Item xs={1} noWrap key={idx}>
                        {scoreToDisplay(score)}<br />{scoreThru(score)}
                    </Item>)}
                <Item xs={1}>{totalScore || '-'}</Item>
            </>}
            {!hasRounds && <>
                <Item xs={1} lg={1}>{isSkins ? skinScore ?? 0 : relScore(firstScore)}</Item>
                <Item xs={1} lg={1}>{scoreThru(firstScore)}</Item>
                {!(isSkins && isNet) && <Item xs={1} lg={1}>{scoreGross(firstScore)}</Item>}
                {!isStableford && isNet && <Item xs={1} lg={1}>{scoreNet(firstScore)}</Item>}
                {isStableford && isGross && <Item xs={1} lg={1}>{scorePts(firstScore)}</Item>}
                {isStableford && isNet && <Item xs={1} lg={1}>{scoreNetPts(firstScore)}</Item>}
                {(!!winning || winning === 0) && <Item xs={1}>{golferDisStatus ?? '$' + winning}</Item>}
            </>}
        </Container>
    );
    const getListItem = (zebra: boolean = false) => (
        <ListItemButton
            key={firstScore.contactId + zebra}
            ref={minHeightFunc}
            className={zebra ? classes.listItemZebra : undefined}
            onClick={onListItemClicked}>
            {gridComponent}
        </ListItemButton>
    );
    const [scoresUpdated, setScoresUpdated] = React.useState<boolean>(false);
    const onAnimationEnd = () => {
        if (scoresUpdated) {
            setScoresUpdated(false);
        }
    };
    const styledComponent = index % 2 ?
        <ContainerOdd onAnimationEnd={onAnimationEnd} className={classes.listItemZebra}>{getListItem()}</ContainerOdd> :
        <ContainerEven onAnimationEnd={onAnimationEnd} className={classes.listItemZebra}>{getListItem()}</ContainerEven>;
    const scoresToCheck = Scoring.getMonitoringReportedScoresArray(reportedScores, getTotalHoles(firstScore.event.holesType), bestBall, firstScore);
    useDidUpdateEffect(() => {
        if (!scoresUpdated) {
            setScoresUpdated(true);
        }
    }, scoresToCheck);
    return scoresUpdated ? styledComponent : getListItem(true);
};

const RowScore = (props: RowScoreProps & { holeView?: boolean }) => {
    const { holeView } = props;
    if (holeView) {
        return <RowScoreHoles {...props} />
    } else {
        return <RowScoreSum {...props} />
    }
};

const LDHeader = (props: { withWinnings: boolean }) => {
    const { withWinnings } = props;
    const classes = appStyles();
    return (
        <ListItem className={classes.listItemHeader}>
            <Container wrap="nowrap">
                <Item xs={1}>Hole</Item>
                <Item xs={withWinnings ? 10 : 11}>Winner</Item>
                {withWinnings && <Item xs={1}>Purse</Item>}
            </Container>
        </ListItem>
    );
};

const LDRow = (props: HolesScoringState & { winning?: number, sameNameGolfersIdsSet: Set<string> }) => {
    const { hole, contacts, winning, sameNameGolfersIdsSet } = props;
    const classes = appStyles();
    const contact: Contact | undefined = contacts[0];
    return (
        <ListItem className={classes.listItemZebra}>
            <Container wrap="nowrap">
                <Item xs={1} fontFamily="roboto">{hole + 1}</Item>
                <ItemS xs={(!!winning || winning === 0) ? 10 : 11}>
                    {contact ?
                        <>
                            <PersonIcon className={classes.textIcon} />
                            <Label>
                                {fullName(contact)}
                            </Label>
                            <span className={classes.homeCourseOrCity}>
                                {contact.homeCourseOrCity && sameNameGolfersIdsSet.has(contact.id) ? ` (${contact.homeCourseOrCity})` : ''}
                            </span>
                        </> :
                        <Label className={classes.notSelected}>
                            {'Not selected'}
                        </Label>}
                </ItemS>
                {(!!winning || winning === 0) && <Item xs={1}>{'$' + winning}</Item>}
            </Container>
        </ListItem>
    );
};

const KPHeader = (props: { withWinnings: boolean }) => {
    const { withWinnings } = props;
    const classes = appStyles();
    return (
        <ListItem className={classes.listItemHeader}>
            <Container wrap="nowrap">
                <Item xs={1}>Hole</Item>
                <Item xs={withWinnings ? 8 : 9}>Winner</Item>
                <Item xs={2}>Distance</Item>
                {withWinnings && <Item xs={1}>Purse</Item>}
            </Container>
        </ListItem>
    );
};

const KPRow = (props: { units?: Units } & HolesScoringState & { winning?: number, sameNameGolfersIdsSet: Set<string> }) => {
    const { units, hole, score, contacts, winning, sameNameGolfersIdsSet } = props;
    const classes = appStyles();
    const contact: Contact | undefined = contacts[0];
    return (
        <ListItem className={classes.listItemZebra}>
            <Container wrap="nowrap">
                <Item xs={1} fontFamily="roboto">{hole + 1}</Item>
                <ItemS xs={(!!winning || winning === 0) ? 8 : 9}>
                    {contact ?
                        <>
                            <PersonIcon className={classes.textIcon} />
                            <Label>
                                {fullName(contact)}
                            </Label>
                            <span className={classes.homeCourseOrCity}>
                                {contact.homeCourseOrCity && sameNameGolfersIdsSet.has(contact.id) ? ` (${contact.homeCourseOrCity})` : ''}
                            </span>
                        </> :
                        <Label className={classes.notSelected}>
                            {'Not selected'}
                        </Label>}
                </ItemS>
                <Item xs={2}>{formatDistance(units, score)}</Item>
                {(!!winning || winning === 0) && <Item xs={1}>{'$' + winning}</Item>}
            </Container>
        </ListItem>
    );
};

export const SkinsHeader = (props: { scoring: ScoringData } & { withWinnings: boolean }) => {
    const { scoring, withWinnings } = props;
    const classes = appStyles();
    const isNet = isNetMode(scoring.mode);
    return (
        <ListItem className={classes.listItemHeader}>
            <Container wrap="nowrap">
                <Item xs={1} fontFamily="roboto">Hole</Item>
                <Item xs={1}>Par</Item>
                <Item xs={1}>Lowest<br /> {isNet ? 'net' : 'gross'}</Item>
                <Item xs={withWinnings ? 8 : 9}>Golfer(s)</Item>
                {withWinnings && <Item xs={1}>Purse</Item>}
            </Container>
        </ListItem>
    );
};

const ItemX = (props: { xs?: GridSize, children?: string | React.ReactNode }) => {
    const { xs, children } = props;
    return (
        <Item xs={xs}>
            <div style={{ display: 'flex', flexWrap: 'wrap', whiteSpace: 'break-spaces' }}>{children}</div>
        </Item>
    );
};

type SkinsRowProps = {
    scoring: ScoringData,
    teamSize: number,
    sameNameGolfersIdsSet: Set<string>,
    winning?: number
}

export const SkinsRow = (props: SkinsRowProps & SkinsScoringState) => {
    const { scoring, hole, par, score, contacts, teamSize, winning, sameNameGolfersIdsSet } = props;
    const classes = appStyles();
    const isNet = isNetMode(scoring.mode);
    const oneWinner = contacts.length === 1;
    const namesIcons = teamSize > 1 ? (
        <Container>
            {contacts.map((contact, idx) =>
                <ItemS key={idx}>
                    <PersonsIcon className={classes.textIcon} />
                    <Label>
                        {contact.contacts.map((c, idx2) => <span key={idx2}>
                            {fullName(c)}
                            <span className={classes.homeCourseOrCity}>
                                {c?.homeCourseOrCity && sameNameGolfersIdsSet.has(c.id) ? ` (${c.homeCourseOrCity})` : ''}
                            </span>
                            {contact.contacts.length - 1 !== idx2 ? ', ' : ''}
                        </span>)}
                        {contact.courseHandicap == null || isNet == null ? '' : ` (${formatHandicap(contact.courseHandicap)})`}
                    </Label>
                </ItemS>)}
        </Container>
    ) : (
        <Container>
            {contacts.map((contact, idx) =>
                <ItemS key={idx}>
                    <PersonIcon className={classes.textIcon} />
                    <Label>
                        {fullName(contact.contacts[0])}
                        <span className={classes.homeCourseOrCity}>
                            {sameNameGolfersIdsSet.has(contact.contacts[0].id) && contact.contacts[0].homeCourseOrCity ? ` (${contact.contacts[0].homeCourseOrCity})` : ''}
                        </span>
                        {contact.courseHandicap == null || isNet == null ? '' : ` (${formatHandicap(contact.courseHandicap)})`}
                    </Label>
                </ItemS>)}
        </Container>
    );
    return (
        <ListItem className={oneWinner ? classes.winnerItem : classes.listItem} style={{ color: 'black' }}>
            <Container wrap="nowrap">
                <Item xs={1} fontFamily="roboto">{hole + 1}</Item>
                <Item xs={1} fontFamily="roboto">{par}</Item>
                <Item xs={1}>{score === null ? '' : score}</Item>
                <Item xs={(!!winning || winning === 0) ? 8 : 9}>{namesIcons}</Item>
                {(!!winning || winning === 0) && <Item xs={1}>{'$' + winning}</Item>}
            </Container>
        </ListItem>
    );
};

type HeaderPayoutProps = {
    splitMainCompetitions: Array<Competition>,
    splitSideCompetitions: Array<Competition>,
    event: Event,
    nameWidth: NameWidth,
    competitionWidth: 1 | 2,
    skinsMixed: boolean
};

const HeaderPayout = (props: HeaderPayoutProps) => {
    const { splitMainCompetitions, splitSideCompetitions, event, nameWidth, competitionWidth, skinsMixed } = props;
    const classes = appStyles();
    let individualCompetitionPresent = false;
    let teamCompetitionPresent = false;
    splitMainCompetitions.forEach(comp => {
        individualCompetitionPresent = individualCompetitionPresent || isIndividualScoring(comp.scoring);
        teamCompetitionPresent = teamCompetitionPresent || isTeamFormat(comp.scoring);
    });
    const teamOrGolfer = (individualCompetitionPresent && teamCompetitionPresent) ? 'Team/Golfer' :
        teamCompetitionPresent ? 'Team' : 'Golfer';
    return (
        <ListItem className={classes.listItemHeader} style={{ height: 52 }}>
            <Container>
                <ItemX xs={nameWidth}>{teamOrGolfer}</ItemX>
                {splitMainCompetitions.map((comp, index) => <ItemX xs={competitionWidth} key={index}>
                    {Scoring.scoringName(comp, event.eventGender, comp.competitionGender, skinsMixed)}
                </ItemX>)}
                {splitSideCompetitions.map((comp, index) => <ItemX xs={competitionWidth} key={index}>
                    {Scoring.scoringName(comp, event.eventGender, comp.competitionGender, skinsMixed)}
                </ItemX>)}
                <ItemX xs={1}>Total</ItemX>
            </Container>
        </ListItem>
    );
};

type NameWidth = 8 | 1 | 2 | 5 | 4 | 3 | 9 | 6 | 7 | 10;

type RowPayoutProps = {
    splitMainCompetitions: Array<Competition>,
    splitSideCompetitions: Array<Competition>,
    sameNameGolfersIdsSet: Set<string>,
    golfers: Map<string, Contact>,
    nameWidth: NameWidth,
    competitionWidth: 1 | 2
};

const RowPayout = (props: ContactPayoutState & RowPayoutProps) => {
    const { names, total, awards, player, sameNameGolfersIdsSet, golfers, splitMainCompetitions, splitSideCompetitions, nameWidth, competitionWidth } = props;
    const classes = appStyles();
    const namesIcons: JSX.Element | string = names.length > 0 && 'contactIds' in player ?
        (<Container>
            {names.map((name, idx) =>
                <ItemS key={idx}>
                    {idx === 0 && <PersonsIcon className={classes.textIcon} />}
                    <Label>
                        {name}
                        <span className={classes.homeCourseOrCity}>
                            {sameNameGolfersIdsSet.has(player.contactIds[idx]) && golfers.get(player.contactIds[idx])?.homeCourseOrCity ? ` (${golfers.get(player.contactIds[idx])?.homeCourseOrCity})` : ''}
                        </span>
                    </Label>
                </ItemS>)}
        </Container>)
        : 'lastName' in player ?
            (<Container>
                {names.map((name, idx) =>
                    <ItemS key={idx}>
                        <PersonIcon className={classes.textIcon} />
                        <Label>
                            {name}
                            <span className={classes.homeCourseOrCity}>
                                {player.homeCourseOrCity && sameNameGolfersIdsSet.has(player.id) ? ` (${player.homeCourseOrCity})` : ''}
                            </span>
                        </Label>
                    </ItemS>)}
            </Container>)
            : '';
    return (
        <ListItemButton className={classes.listItemZebra} >
            <Container wrap="nowrap">
                <ItemX xs={nameWidth}>{namesIcons}</ItemX>
                {splitMainCompetitions.map((comp, index) => <ItemX xs={competitionWidth} key={index}>
                    {'$' + (awards.has(comp.id + comp.scoring.mode) ? formatCurrency(awards.get(comp.id + comp.scoring.mode)!) : '0')}
                </ItemX>)}
                {splitSideCompetitions.map((comp, index) => <ItemX xs={competitionWidth} key={index}>
                    {'$' + (awards.has(comp.id + (isSkinsScoring(comp.scoring) ? comp.scoring.mode : '')) ? formatCurrency(awards.get(comp.id + (isSkinsScoring(comp.scoring) ? comp.scoring.mode : ''))!) : '0')}
                </ItemX>)}
                <ItemX xs={1}>{'$' + formatCurrency(total)}</ItemX>
            </Container>
        </ListItemButton>
    );
};

type PaidOutRowProps = {
    payoutStates: ContactPayoutState[],
    titleWidth: NameWidth,
    competitionWidth: 1 | 2,
    competitions: Competition[],
    golfers: Map<string, Contact>,
    teams: Map<string, Team>,
    event: Event,
    golferScores: Map<string, Score>,
    teamScores: Map<string, Score>,
    reportedScores: Map<string, ReportedScore>,
    reportedTeamScores: Map<string, ReportedScore>,
    groups: Array<GolferGroup>
};

const PaidOutRow = (props: PaidOutRowProps) => {
    const { titleWidth, competitionWidth, payoutStates, competitions, golfers, teams, event, golferScores, teamScores, reportedScores, reportedTeamScores, groups } = props;
    if (!payoutStates || payoutStates.length < 1 || payoutStates[0]?.awards?.size < 1) {
        return null;
    }
    const classes = appStyles();
    const competitionIds = competitions.map(comp => isMainScoring(comp.scoring) ? (comp.id + comp.scoring.mode) : comp.id);
    let wasRed = false;
    let paidOutTotal: number = 0;
    const paidOutComponent = competitionIds.map(id => {
        let competitionPaidOutSum: number = 0;
        for (const payoutState of payoutStates) {
            competitionPaidOutSum += payoutState.awards?.get(id) ?? 0;
        }
        const competition = competitions.find(comp => id === (isMainScoring(comp.scoring) ? (comp.id + comp.scoring.mode) : comp.id))!;
        const purse = getFullCompetitionPurse(event, competition, golfers, teams, competitions, golferScores, teamScores, reportedScores, reportedTeamScores, groups);
        competitionPaidOutSum = round(competitionPaidOutSum, 2);
        const significantPayoutAndPurseDifference = (Math.abs(purse - competitionPaidOutSum) > (0.005 * purse)); // to avoid difference as a result of rounding, (0.5%)
        wasRed = wasRed || significantPayoutAndPurseDifference;
        paidOutTotal += competitionPaidOutSum;
        return <ItemX key={id} xs={competitionWidth} children={<Typography className={significantPayoutAndPurseDifference ? classes.redText : undefined}>${competitionPaidOutSum}</Typography>} />;
    });
    return (
        <ListItemButton className={classes.listItemZebra}>
            <Container wrap="nowrap">
                <ItemX xs={titleWidth}><Typography style={{ margin: 'auto' }}>Paid out</Typography></ItemX>
                {paidOutComponent}
                <ItemX xs={1}><Typography className={wasRed ? classes.redText : undefined}>${round(paidOutTotal, 2)}</Typography></ItemX>
            </Container>
        </ListItemButton>
    );
};

type PurseRowProps = {
    competitions: Competition[],
    titleWidth: NameWidth,
    competitionWidth: 1 | 2,
    golfers: Map<string, Contact>,
    teams: Map<string, Team>,
    event: Event,
    golferScores: Map<string, Score>,
    teamScores: Map<string, Score>,
    reportedScores: Map<string, ReportedScore>,
    reportedTeamScores: Map<string, ReportedScore>,
    groups: Array<GolferGroup>
};

const PurseRow = (props: PurseRowProps) => {
    const { titleWidth, competitions, competitionWidth, golfers, teams, event, golferScores, teamScores, reportedScores, reportedTeamScores, groups } = props;
    const classes = appStyles();
    let totalPurse: number = 0;
    const purseComponent = competitions.map((competition, index) => {
        const competitionPurse: number = getFullCompetitionPurse(event, competition, golfers, teams, competitions, golferScores, teamScores, reportedScores, reportedTeamScores, groups);
        totalPurse += competitionPurse;
        return <ItemX key={index} xs={competitionWidth}><Typography>${competitionPurse}</Typography></ItemX>;
    });
    return (
        <ListItemButton className={classes.listItemZebra}>
            <Container wrap="nowrap">
                <ItemX xs={titleWidth}>Purse</ItemX>
                {purseComponent}
                <ItemX xs={1}>${round(totalPurse, 2)}</ItemX>
            </Container>
        </ListItemButton>
    );
};

type LeaderBoardInfoProps = {
    classes: Record<string, string>,
    event: Event,
    mainCompetition?: Competition
}

const LeaderBoardInfo = ({ classes, event, mainCompetition }: LeaderBoardInfoProps) => {
    const { courseName, eventDate, teeTimeMode, competitionName } = getEventInfo(event, [], mainCompetition);
    const dateTimeAndCompetitionClassName = classes.infoBaseLL + ' ' + classes.dateTimeAndCompetitionLL;
    return (<div>
        <div className={classes.infoBaseLL + ' ' + classes.courseNameLL}>{courseName}</div>
        <div className={classes.paddingBottom4}>
            <span className={dateTimeAndCompetitionClassName + ' ' + classes.darkGrey}>{`${eventDate}${(teeTimeMode ? ` ${teeTimeMode}` : '')}`}</span>
            <span className={`${dateTimeAndCompetitionClassName} ${classes.grey} ${classes.paddingLeft6}`}>{competitionName}</span>
        </div>
    </div>);
}

type DetailedSkinsInfoProps = {
    competition: Competition;
    golfers: Map<string, Contact>;
    teams: Map<string, Team>;
    skins: SkinsScoringState[];
    withWinnings: boolean;
    sameNameGolfersIdsSet: Set<string>;
    skinsMixed: boolean;
    event: EventBase;
}

export const SkinsDetailedInfo = (props: DetailedSkinsInfoProps) => {
    const { competition, withWinnings, golfers, teams, skins, sameNameGolfersIdsSet, event, skinsMixed } = props;
    const classes = appStyles();
    return (
        <List disablePadding>
            <ListTitle text={<Typography className={classes.modeListTitle}>{Scoring.scoringName(competition, event.eventGender, competition.competitionGender, skinsMixed)}</Typography>} />
            <SkinsHeader scoring={competition.scoring} withWinnings={withWinnings} />
            {skins.map(skin => <SkinsRow teamSize={event.teamSize} scoring={competition.scoring}
                key={skin.hole} {...skin}
                winning={withWinnings ? skinCompetitionAward(skin, competition, skins, golfers, teams) : undefined}
                sameNameGolfersIdsSet={sameNameGolfersIdsSet} />)}
        </List>
    );
};

interface StandingsProps {
    event: Event;
    rounds: Array<Round>;
    selectedRound?: Round;
    competitionsMap: Map<string, Array<Competition>>;
    loadedRounds: number;
    loadedCompetitions: number;
    setSelectedRound: (selectedRound?: Round) => void;
}

interface State {
    groupsMap: Map<string, Array<GolferGroup>>;
    golfersMap: Map<string, Map<string, Contact>>;
    teamsMap: Map<string, Map<string, Team>>;
    distancesMap: Map<string, Map<string, Distance>>;
    golferScoresMap: Map<string, Map<string, Score>>;
    teamScoresMap: Map<string, Map<string, Score>>;
    reportedScoresMap: Map<string, Map<string, ReportedScore>>;
    reportedTeamScoresMap: Map<string, Map<string, ReportedScore>>;
    calculatedScoresMap: Map<string, Map<string, CalculatedScores>>;
    loadedTeams: number;
    loadedGroups: number;
    loadedGolfers: number;
    openedScore?: ContactScoringState & { competition: Competition, golfers: Map<string, Contact>, teams: Map<string, Team>, skinsGross?: SkinsScoringState[], skinsNet?: SkinsScoringState[] };
    units?: Units;
    holeView?: boolean;
    roundScorecardLink?: string;
    holeByHoleNamesWidth: number;
    fullHoleByHoleWidth: number;
}

type Props = StandingsProps & WithStyles<typeof styles>;

class Standings extends React.Component<Props, State> {
    state: State;
    private compCounter = 0;

    constructor(props: Props) {
        super(props);
        this.state = {
            groupsMap: new Map(),
            golfersMap: new Map(),
            teamsMap: new Map(),
            distancesMap: new Map(),
            golferScoresMap: new Map(),
            teamScoresMap: new Map(),
            reportedScoresMap: new Map(),
            reportedTeamScoresMap: new Map(),
            calculatedScoresMap: new Map(),
            holeByHoleNamesWidth: holeByHoleNamesWidth9,
            fullHoleByHoleWidth: holeByHoleWidth,
            loadedTeams: 0,
            loadedGroups: 0,
            loadedGolfers: 0
        };
    }

    private showTotalMode = () => {
        const { event, selectedRound } = this.props;
        return event.type === 'multiday' && !selectedRound;
    }

    private getRound = (eventOrRoundId?: number | string) => {
        const { rounds } = this.props;
        if (eventOrRoundId) {
            return typeof eventOrRoundId == 'number' ?
                rounds.find(round => round.roundOrder === eventOrRoundId) :
                rounds.find(round => round.id === eventOrRoundId);
        } else {
            return undefined;
        }
    }

    private getStaff(eventOrRoundId?: number | string) {
        const { event, selectedRound, competitionsMap } = this.props;
        const { golfersMap, teamsMap, groupsMap } = this.state;
        const { distancesMap } = this.state;
        const eventOrRound = this.getRound(eventOrRoundId) ?? selectedRound ?? event;
        const golfers = golfersMap.get(eventOrRound.id) ?? new Map<string, Contact>();
        const teams = teamsMap.get(eventOrRound.id) ?? new Map<string, Team>();
        const groups = groupsMap.get(eventOrRound.id) ?? [];
        const distances = distancesMap.get(eventOrRound.id) ?? new Map<string, Distance>();
        const competitions = competitionsMap.get(eventOrRound.id) ?? [];
        const mainCompetition = getEventMainCompetition(competitions);
        return { eventOrRound, golfers, teams, groups, distances, competitions, mainCompetition };
    }

    private getScores = (eventOrRoundId: string) => {
        const { golferScoresMap, teamScoresMap, reportedScoresMap, reportedTeamScoresMap } = this.state;
        const golferScores = golferScoresMap.get(eventOrRoundId) ?? new Map<string, Score>();
        const teamScores = teamScoresMap.get(eventOrRoundId) ?? new Map<string, Score>();
        const reportedScores = reportedScoresMap.get(eventOrRoundId) ?? new Map<string, ReportedScore>();
        const reportedTeamScores = reportedTeamScoresMap.get(eventOrRoundId) ?? new Map<string, ReportedScore>();
        return { golferScores, teamScores, reportedScores, reportedTeamScores };
    }

    private updateEventOrRoundScores = (eventOrRoundId?: string) => {
        const { competitionsMap } = this.props;
        const { calculatedScoresMap } = this.state;
        if (eventOrRoundId) {
            const competitions = competitionsMap.get(eventOrRoundId);
            if (competitions) {
                this.updateScores(eventOrRoundId, competitions, calculatedScoresMap);
            }
        } else {
            competitionsMap.forEach((competitions, eId) => this.updateScores(eId, competitions, calculatedScoresMap));
        }
        this.setState({ calculatedScoresMap });
    }

    private updateScores = (eventOrRoundId: string, competitions: Array<Competition>, calculatedScoresMap: Map<string, Map<string, CalculatedScores>>) => {
        const { eventOrRound, teams, golfers, groups, mainCompetition } = this.getStaff(eventOrRoundId);
        const { golferScores, teamScores, reportedScores, reportedTeamScores } = this.getScores(eventOrRoundId);
        let calculatedScores = calculatedScoresMap.get(eventOrRoundId) ?? new Map<string, CalculatedScores>();
        calculatedScoresMap.set(eventOrRoundId, calculatedScores);
        const competitionScores = Scoring.getGolferScores(eventOrRound, competitions, golfers, teams, groups, golferScores, teamScores, reportedScores, reportedTeamScores, mainCompetition);
        competitionScores.forEach((competitionScore, competitionId) => calculatedScores.set(competitionId, competitionScore));
    }

    private onGolfers = (eventOrRound: EventBase, golfers: Map<string, Contact>) => {
        const { golfersMap, loadedGolfers } = this.state;
        golfersMap.set(eventOrRound.id, golfers);
        this.setState({ golfersMap, loadedGolfers: loadedGolfers + 1 }, () => this.updateEventOrRoundScores(eventOrRound.id));
    }

    private onGroups = (eventOrRound: EventBase, groups: Array<GolferGroup>) => {
        const { loadedGroups, groupsMap } = this.state;
        groupsMap.set(eventOrRound.id, groups);
        this.setState({ groupsMap, loadedGroups: loadedGroups + 1 }, () => this.updateEventOrRoundScores(eventOrRound.id));
    }

    private onTeams = (eventOrRound: EventBase, allTeams: Map<string, Team>) => {
        const { loadedTeams, golfersMap, teamsMap } = this.state;
        const golfers = golfersMap.get(eventOrRound.id) ?? new Map<string, Contact>();
        const teams = new Map(
            Array.from(allTeams.entries())
                .filter(entry => entry[1].contactIds.some(golferId => golfers.has(golferId)))
        );
        fixTeamsHandicapAndGender(teams, golfers);
        teamsMap.set(eventOrRound.id, teams);
        this.setState({ teamsMap, loadedTeams: loadedTeams + 1 }, () => this.updateEventOrRoundScores(eventOrRound.id));
    }

    private onGolferScores = (eventOrRound: EventBase, golferScores: Map<string, Score>) => {
        const { golferScoresMap } = this.state;
        golferScoresMap.set(eventOrRound.id, golferScores);
        this.setState({ golferScoresMap }, () => this.updateEventOrRoundScores(eventOrRound.id));
    }

    private onTeamScores = (eventOrRound: EventBase, teamScores: Map<string, Score>) => {
        const { teamScoresMap } = this.state;
        teamScoresMap.set(eventOrRound.id, teamScores);
        this.setState({ teamScoresMap }, () => this.updateEventOrRoundScores(eventOrRound.id));
    }

    private onReportedGolferScores = (eventOrRound: EventBase, reportedScores: Map<string, ReportedScore>) => {
        const { reportedScoresMap } = this.state;
        reportedScoresMap.set(eventOrRound.id, reportedScores);
        this.setState({ reportedScoresMap }, () => this.updateEventOrRoundScores(eventOrRound.id));
    }

    private onReportedTeamScores = (eventOrRound: EventBase, reportedTeamScores: Map<string, ReportedScore>) => {
        const { reportedTeamScoresMap } = this.state;
        reportedTeamScoresMap.set(eventOrRound.id, reportedTeamScores);
        this.setState({ reportedTeamScoresMap }, () => this.updateEventOrRoundScores(eventOrRound.id));
    }

    private onDistances = (eventOrRound: EventBase, distances: Map<string, Distance>) => {
        const { distancesMap } = this.state;
        distancesMap.set(eventOrRound.id, distances);
        this.setState({ distancesMap }, () => this.updateEventOrRoundScores(eventOrRound.id));
    }

    private setHoleView = (value: boolean) => {
        const { holeView } = this.state;
        if (value === holeView) {
            return;
        }
        if (value) {
            this.setState({ holeView: true }, this.updateHoleByHole);
        } else {
            this.setState({ holeView: false });
        }
    }

    componentDidUpdate(prevProps: Readonly<Props>) {
        const { selectedRound, loadedCompetitions } = this.props;
        if (selectedRound !== prevProps.selectedRound) {
            this.updateHoleByHole();
        }
        if (loadedCompetitions !== prevProps.loadedCompetitions) {
            this.updateEventOrRoundScores();
        }
    }

    public updateHoleByHole = () => {
        const { event, selectedRound } = this.props;
        const eventOrRound = selectedRound ?? event;
        const totalHoles = getTotalHoles(eventOrRound.holesType);
        const holeByHoleNamesWidth = totalHoles === 18 ? holeByHoleNamesWidth18 :
            window.innerWidth >= holeByHoleWidth ? holeByHoleNamesWidth9 :
                Math.max(holeByHoleNamesWidth18, holeByHoleNamesWidth9 - (holeByHoleWidth - window.innerWidth));
        const fullHoleByHoleWidth = (totalHoles === 9 && window.innerWidth < holeByHoleWidth) ?
            (holeByHoleWidth - holeByHoleNamesWidth9 + holeByHoleNamesWidth) : holeByHoleWidth;
        this.setState({ holeByHoleNamesWidth, fullHoleByHoleWidth });
    }

    Competition = (params: { competition: Competition, allCompetitions: Array<Competition> }) => {
        const { competition, allCompetitions } = params;
        if (this.showTotalMode() && hasFirstRoundCompetition(competition, allCompetitions)) {
            return null;
        }
        return <>
            {isDistanceScoring(competition.scoring) ? this.DistanceCompetition(competition) :
                isSkinsScoring(competition.scoring) ? this.Skins(competition) : this.Scores(competition, allCompetitions)}
        </>;
    }

    Skins = (competition: Competition) => {
        const { mode } = competition.scoring;
        return <>
            {isNetMode(mode) && this.SkinsCompetition(Scoring.netCompetition(competition))}
            {isGrossMode(mode) && this.SkinsCompetition(Scoring.grossCompetition(competition))}
        </>;
    }

    Scores = (competition: Competition, allCompetitions: Array<Competition>) => {
        const { mode } = competition.scoring;
        return <>
            {isNetMode(mode) && this.ScoreCompetition(Scoring.netCompetition(competition), allCompetitions)}
            {isGrossMode(mode) && this.ScoreCompetition(Scoring.grossCompetition(competition), allCompetitions)}
        </>;
    }

    DistanceCompetition = (competition: Competition) => {
        if (competition.scoring.format === ScoringFormatDistance.longest_drive) {
            return this.LDCompetition(competition);
        } else {
            return this.KPCompetition(competition);
        }
    }

    LDCompetition = (competition: Competition) => {
        const { eventOrRound, golfers, distances, mainCompetition } = this.getStaff(competition.roundOrder);
        if (!mainCompetition) {
            return null;
        }
        const gender = genderFromEvent(eventOrRound);
        const distanceInfo = getDistanceScores(eventOrRound, competition, golfers, distances, getTee(eventOrRound, mainCompetition, gender));
        const isNet = isNetMode(competition.scoring.mode);
        const payoutSettings: PayoutSettings | undefined | null = isNet && competition.payoutsNet ? competition.payoutsNet[0] : competition.payoutsGross ? competition.payoutsGross[0] : undefined;
        const sameNameGolfersIdsSet = getSameNameGolfersIds(Array.from(golfers.values()));
        const payoutPerHole = payoutSettings?.payoutPerHole;
        const payoutsEligible = Boolean(payoutSettings?.enabled) && payoutPerHole != null && payoutPerHole >= 0;
        return <>
            <ListTitle text={Scoring.scoringName(competition)}>
                {this.showTotalMode() && isRound(eventOrRound) && <>
                    <Typography>
                        Round {eventOrRound.roundOrder}
                    </Typography>
                </>}
            </ListTitle>
            <List disablePadding>
                <LDHeader withWinnings={payoutsEligible && distanceInfo.findIndex(info => info.contacts && info.contacts.length > 0) > -1} />
                {distanceInfo.map(info => <LDRow key={info.hole} {...info} winning={(payoutsEligible && info.contacts && info.contacts.length > 0) ? payoutPerHole : undefined} sameNameGolfersIdsSet={sameNameGolfersIdsSet} />)}
            </List >
            <Spacing />
        </>;
    }

    KPCompetition = (competition: Competition) => {
        const { units } = this.state;
        const { eventOrRound, golfers, distances, mainCompetition } = this.getStaff(competition.roundOrder);
        if (!mainCompetition) {
            return null;
        }
        const gender = genderFromEvent(eventOrRound);
        const distanceInfo = getDistanceScores(eventOrRound, competition, golfers, distances, getTee(eventOrRound, mainCompetition, gender));
        const isNet = isNetMode(competition.scoring.mode);
        const payoutSettings: PayoutSettings | undefined | null = isNet && competition.payoutsNet ? competition.payoutsNet[0] : competition.payoutsGross ? competition.payoutsGross[0] : undefined;
        const payoutPerHole = payoutSettings?.payoutPerHole;
        const sameNameGolfersIdsSet = getSameNameGolfersIds(Array.from(golfers.values()));
        const payoutsEligible = Boolean(payoutSettings?.enabled) && payoutPerHole != null && payoutPerHole >= 0;
        return <>
            <ListTitle text={Scoring.scoringName(competition)}>
                {this.showTotalMode() && isRound(eventOrRound) && <>
                    <Typography>
                        Round {eventOrRound.roundOrder}
                    </Typography>
                </>}
            </ListTitle>
            <List disablePadding>
                <KPHeader withWinnings={payoutsEligible && distanceInfo.findIndex(info => info.contacts && info.contacts.length > 0) > -1} />
                {distanceInfo.map(info => <KPRow key={info.hole} {...info} units={units} winning={(payoutsEligible && info.contacts && info.contacts.length > 0) ? payoutPerHole : undefined} sameNameGolfersIdsSet={sameNameGolfersIdsSet} />)}
            </List >
            <Spacing />
        </>;
    }

    SkinsCompetition = (competition: Competition) => {
        const { event, classes, loadedRounds, loadedCompetitions } = this.props;
        const { holeView, holeByHoleNamesWidth, fullHoleByHoleWidth, loadedTeams, loadedGroups, loadedGolfers } = this.state;
        const { eventOrRound, groups, golfers, teams, competitions, mainCompetition } = this.getStaff(competition.roundOrder);
        const { golferScores, teamScores, reportedScores, reportedTeamScores } = this.getScores(eventOrRound.id);
        const allLoaded = loadedTeams > 0 && loadedGroups > 0 && loadedGolfers > 0 && loadedRounds > 0 && loadedCompetitions > 0;
        if (!mainCompetition || !isCompatibleCompetitionSkins(competition, mainCompetition) || !allLoaded) {
            return null;
        }
        const competitionScores = Scoring.getGolferScores(eventOrRound, [competition], golfers, teams, groups, golferScores, teamScores, reportedScores || new Map<string, ReportedScore>(), reportedTeamScores || new Map<string, ReportedScore>(), mainCompetition)?.get(competition.id);
        if (!competitionScores) {
            return null;
        }
        const isNet = isNetMode(competition.scoring.mode);
        const scores: ContactScoringState[] | undefined = isNet ? competitionScores.competitionScoresNet.get(0) : competitionScores.competitionScoresGross.get(0);
        if (!scores) {
            return null;
        }
        const noTees = !hasTees(eventOrRound, mainCompetition);
        if (!noTees || competitions.map(c => isMainScoring(c.scoring)).length > 1) {
            this.compCounter++;
        }
        const isGross = isGrossMode(competition.scoring.mode);
        const gender = genderFromEvent(eventOrRound);
        const skinsMixed = !!competitions.find(comp => comp.scoring.format === ScoringFormatTeams.best_ball);
        const tee = getTee(eventOrRound, mainCompetition, gender);
        const skins = getSkinsScores(eventOrRound, competition, scores, tee);
        const withWinnings = (isNetPayouts(competition) || isGrossPayouts(competition))
            && isFullScoresCompetition(eventOrRound, competition, golferScores, teamScores, reportedScores || new Map<string, ReportedScore>(), golfers, teams);
        const sameNameGolfersIdsSet = getSameNameGolfersIds(Array.from(golfers.values()));
        const withLLInfo = (event.type === 'leaderboard' || event.leaderboard) && competition.id === mainCompetition.id;
        const infoState: ScoreCompetitionInfoState = withLLInfo ? ScoreCompetitionInfoState.LL_INFO : ScoreCompetitionInfoState.SCORING_NAME;
        const skinsScoresMap: Map<string, number> = new Map<string, number>();
        const skinsAwardsMap: Map<string, number> = new Map<string, number>();
        const addPlayersAward = (playerId: string, hole: number) => {
            const skin = getActualSkin(hole, getHolesRange(eventOrRound.holesType), skins);
            if (skin) {
                skinsAwardsMap.set(playerId, (skinsAwardsMap.get(playerId) ?? 0) + skinCompetitionAward(skin,
                    competition, skins, golfers, teams)
                );
            }
        };
        processSkins(skins, skinsScoresMap, competition, true, scores, undefined, withWinnings ? addPlayersAward : undefined);
        const isTeam = isTeamFormatExceptBB(competition.scoring);
        return <>
            <CompetitionHeader
                infoState={infoState}
                competition={competition}
                mainCompetition={mainCompetition}
                eventOrRound={eventOrRound}
                holeView={holeView && !this.showTotalMode()}
                setHoleView={this.setHoleView}
                compCounter={this.compCounter}
                skinsMixed={skinsMixed}
                totalMode={this.showTotalMode()}
            />
            {noTees ?
                <Typography color="error">
                    This competition has not been set up by event administrator yet
                </Typography> :
                <List disablePadding>
                    <Header
                        scoring={competition.scoring}
                        withWinnings={withWinnings}
                        holeView={holeView && !this.showTotalMode()}
                        event={eventOrRound}
                        holeByHoleNamesWidth={holeByHoleNamesWidth}
                        fullHoleByHoleWidth={fullHoleByHoleWidth}
                    />
                    {scores.length > 0 ? scores.map((score, idx) => <RowScore
                        key={idx}
                        index={idx}
                        contactRoundsScores={{ ...score, scoringStates: [score] }}
                        winning={withWinnings ? (round(skinsAwardsMap.get(score.contactId) ?? 0, 2)) : undefined}
                        holeView={holeView && !this.showTotalMode()}
                        event={eventOrRound}
                        golfers={golfers}
                        competition={competition}
                        reportedScores={isTeam ? reportedTeamScores || new Map<string, ReportedScore>() : reportedScores || new Map<string, ReportedScore>()}
                        scores={isTeam ? teamScores : golferScores}
                        onClick={() => this.setState({
                            openedScore: {
                                competition, golfers, teams, ...score, skinsGross: isGross ? skins : undefined,
                                skinsNet: isNet ? skins : undefined
                            }, roundScorecardLink: getRoundScorecardLink(golfers.get(score.contactId))
                        })}
                        sameNameGolfersIdsSet={sameNameGolfersIdsSet}
                        mainCompetition={mainCompetition}
                        roundScorecardLink={getRoundScorecardLink(golfers.get(score.contactId))}
                        skinScore={skinsScoresMap.get(score.contactId)}
                        skins={skins}
                        holeByHoleNamesWidth={holeByHoleNamesWidth}
                        fullHoleByHoleWidth={fullHoleByHoleWidth} />) :
                        <NoParticipantsRow
                            holeView={holeView && !this.showTotalMode()}
                            width={holeView && !this.showTotalMode() ? fullHoleByHoleWidth : 'auto'}
                            individual={!isTeam} />}
                </List>}
            {(isTeam && teams.size > 0) || golfers.size > 0 && <div className={classes.flexRowContainer} style={{ marginTop: 8 }}>
                {holeView && <div className={`${classes.flexRowContainer} ${classes.skinColorsExplanation}`}>
                    <img className={classes.size26x26} src={skinsTieImage} alt='' />
                    <Typography className={`${classes.shiftedExplanations} ${classes.width100}`}>Tied scores</Typography>
                    <img className={classes.size26x26} src={skinsWinImage} alt='' />
                    <Typography className={`${classes.shiftedExplanations} ${classes.width160}`}>Skin winning scores</Typography>
                </div>}
                <AppButton color='info' className={classes.skinsSummaryBtn} onClick={() => showAlertProps({
                    maxWidth: 'lg',
                    buttons: [{ title: 'Close', }],
                    appBarLabel: 'Skins summary',
                    content: <div className={classes.minWidth640}>
                        <SkinsDetailedInfo competition={competition} event={eventOrRound} golfers={golfers}
                            teams={teams} skins={getSkinsScores(eventOrRound, competition, scores, tee)}
                            sameNameGolfersIdsSet={getSameNameGolfersIds(Array.from(golfers.values()))}
                            skinsMixed={skinsMixed} withWinnings={withWinnings} />
                        <Spacing backgroundColor={'white'} />
                    </div>
                })}>Skins summary</AppButton>
            </div>}
            <Spacing />
        </>;
    }

    ScoreCompetition = (competition: Competition, allCompetitions: Array<Competition>) => {
        const { mainCompetition } = this.getStaff(competition.roundOrder);
        if (!mainCompetition) {
            return null;
        }
        if (!competition.flights || competition.flights === 0) {
            return this.ScoreCompetitionFlight(competition, 0, allCompetitions);
        } else {
            return <>
                {range(1, competition.flights + 1).map(flight => this.ScoreCompetitionFlight(competition, flight, allCompetitions))}
            </>;
        }
    }

    ScoreCompetitionFlight = (competition: Competition, flight: number, allCompetitions: Array<Competition>) => {
        const { event, classes } = this.props;
        const { eventOrRound, golfers, teams, mainCompetition } = this.getStaff(competition.roundOrder);
        const { calculatedScoresMap } = this.state;
        const roundsCompetitions = this.showTotalMode() ? getRoundsCompetitions(competition, allCompetitions) : [competition];
        const scoreRounds = roundsCompetitions.length > 1 ? roundsCompetitions.map(rc => rc.roundOrder!) : undefined;
        const calculatedScores = calculatedScoresMap.get(eventOrRound.id);
        if (!calculatedScores) {
            return null;
        }
        const competitionScores = calculatedScores.get(competition.id);
        if (!competitionScores || !mainCompetition) {
            return null;
        }
        const roundsScores = this.showTotalMode() ?
            Scoring.combineCompetitionScores(roundsCompetitions, flight, calculatedScoresMap) :
            Scoring.getCompetitionScores(roundsCompetitions, flight, calculatedScoresMap);
        const { holeView, holeByHoleNamesWidth, fullHoleByHoleWidth } = this.state;
        const { golferScores, teamScores, reportedScores, reportedTeamScores } = this.getScores(eventOrRound.id);
        const noTees = !hasTees(eventOrRound, competition) && event.type !== 'leaderboard';
        const winnings = this.getWinnings(competition, flight);
        const payouts = getPayouts(competition, competition.scoring.mode, flight);
        const flightName = flight > 0 ? <Typography noWrap gutterBottom={true} variant="subtitle2">{getFlightName(flight, competition.flightsNaming).toUpperCase()}</Typography> : null;
        const withWinnings = !!payouts && payouts.enabled && isFullScoresCompetition(eventOrRound, competition, golferScores, teamScores, reportedScores || new Map<string, ReportedScore>(), golfers, teams);
        if (!noTees || allCompetitions.map(c => isMainScoring(c.scoring)).length > 1) {
            this.compCounter++;
        }
        const sameNameGolfersIdsSet = getSameNameGolfersIds(Array.from(golfers.values()));
        const withLLInfo = (event.type === 'leaderboard' || event.leaderboard) && competition.id === mainCompetition.id;
        const infoState: ScoreCompetitionInfoState = withLLInfo ? (flight < 2 ? ScoreCompetitionInfoState.LL_INFO : ScoreCompetitionInfoState.NONE) : ScoreCompetitionInfoState.SCORING_NAME;
        const isTeamFormatExceptBB = isTeamScoringExceptBB(competition.scoring);
        const contactsWithRoundsScores = Scoring.contactsWithRoundsScoresOf(roundsScores[0].map(rs => rs.uniqueId), roundsScores, competition);
        return (
            <React.Fragment key={competition.id + competition.scoring.mode + flight}>
                <CompetitionHeader
                    infoState={infoState}
                    competition={competition}
                    mainCompetition={mainCompetition}
                    eventOrRound={eventOrRound}
                    holeView={holeView && !this.showTotalMode()}
                    setHoleView={this.setHoleView}
                    compCounter={this.compCounter}
                    totalMode={this.showTotalMode()}
                />
                {flightName}
                {noTees ?
                    <Typography color="error">This competition has not been set up by event administrator yet</Typography> :
                    <div className={classes.editscoreScrollPanel}>
                        <List disablePadding>
                            <Header
                                scoreRounds={scoreRounds}
                                scoring={competition.scoring}
                                withWinnings={withWinnings}
                                holeView={holeView && !this.showTotalMode()}
                                event={eventOrRound}
                                holeByHoleNamesWidth={holeByHoleNamesWidth}
                                fullHoleByHoleWidth={fullHoleByHoleWidth}
                            />
                            {contactsWithRoundsScores.length > 0 ? contactsWithRoundsScores.map((score, idx) => <RowScore
                                key={idx}
                                index={idx}
                                contactRoundsScores={score}
                                winning={withWinnings ? winnings.get(score.scoringStates[0]?.contactId ?? '') : undefined}
                                holeView={holeView && !this.showTotalMode()}
                                event={eventOrRound}
                                golfers={golfers}
                                competition={competition}
                                reportedScores={isTeamFormatExceptBB ? reportedTeamScores || new Map<string, ReportedScore>() : reportedScores || new Map<string, ReportedScore>()}
                                scores={isTeamFormatExceptBB ? teamScores : golferScores}
                                onClick={() => this.setState({
                                    openedScore: { competition, golfers, teams, ...score.scoringStates[0]! },
                                    roundScorecardLink: getRoundScorecardLink(golfers.get(score.scoringStates[0]?.contactId ?? ''))
                                })}
                                sameNameGolfersIdsSet={sameNameGolfersIdsSet}
                                mainCompetition={mainCompetition}
                                roundScorecardLink={getRoundScorecardLink(golfers.get(score.scoringStates[0]?.contactId ?? ''))}
                                holeByHoleNamesWidth={holeByHoleNamesWidth}
                                fullHoleByHoleWidth={fullHoleByHoleWidth}
                            />) : <NoParticipantsRow
                                holeView={holeView && !this.showTotalMode()}
                                width={holeView && !this.showTotalMode() ? fullHoleByHoleWidth : 'auto'}
                                individual={!isTeamFormatExceptBB}
                            />}
                        </List>
                    </div>}
                <Spacing />
            </React.Fragment>
        );
    }

    PayoutsStates = () => {
        const { eventOrRound, groups, golfers, teams, competitions, distances } = this.getStaff();
        const { golferScores, teamScores, reportedScores, reportedTeamScores, } = this.getScores(eventOrRound.id);
        const payoutStates: ContactPayoutState[] = eventPayoutsStates(eventOrRound, competitions, golferScores, teamScores, reportedScores || new Map<string, ReportedScore>(), reportedTeamScores || new Map<string, ReportedScore>(), golfers, teams, groups, distances);
        const showPayoutsExplanation = !isFullScoresCompetitions(eventOrRound, competitions, golferScores, teamScores, reportedScores || new Map<string, ReportedScore>(), golfers, teams);
        const sameNameGolfersIdsSet = getSameNameGolfersIds(Array.from(golfers.values()));
        const skinsMixed: boolean = competitions.some(competition => competition.scoring.format === ScoringFormatTeams.best_ball);
        const [splitMainCompetitions, splitSideCompetitions] = getSplitCompetitionsWithPayouts(competitions);
        const competitionWidth = (splitMainCompetitions.length + splitSideCompetitions.length) > 3 ? 1 : 2;
        const nameWidth = (11 - (splitMainCompetitions.length + splitSideCompetitions.length) * competitionWidth) as NameWidth;
        const allCompetitions = splitMainCompetitions.concat(splitSideCompetitions);
        return <>
            <ListTitle text={'Money list'} />
            <List disablePadding>
                <HeaderPayout
                    event={eventOrRound}
                    splitMainCompetitions={splitMainCompetitions}
                    splitSideCompetitions={splitSideCompetitions}
                    nameWidth={nameWidth}
                    competitionWidth={competitionWidth}
                    skinsMixed={skinsMixed} />
                {payoutStates.map(payout => <RowPayout
                    key={payout.player.id}
                    {...payout}
                    splitMainCompetitions={splitMainCompetitions}
                    splitSideCompetitions={splitSideCompetitions}
                    sameNameGolfersIdsSet={sameNameGolfersIdsSet}
                    golfers={golfers}
                    nameWidth={nameWidth}
                    competitionWidth={competitionWidth} />)}
                <PaidOutRow
                    payoutStates={payoutStates}
                    titleWidth={nameWidth}
                    competitionWidth={competitionWidth}
                    competitions={allCompetitions}
                    golfers={golfers}
                    teams={teams}
                    event={eventOrRound}
                    golferScores={golferScores}
                    teamScores={teamScores}
                    reportedScores={reportedScores || new Map<string, ReportedScore>()}
                    reportedTeamScores={reportedTeamScores || new Map<string, ReportedScore>()}
                    groups={groups} />
                <PurseRow
                    competitions={allCompetitions}
                    titleWidth={nameWidth}
                    competitionWidth={competitionWidth}
                    golfers={golfers}
                    teams={teams}
                    event={eventOrRound}
                    golferScores={golferScores}
                    teamScores={teamScores}
                    reportedScores={reportedScores || new Map<string, ReportedScore>()}
                    reportedTeamScores={reportedTeamScores || new Map<string, ReportedScore>()}
                    groups={groups} />
            </List>
            {showPayoutsExplanation && eventOrRound.teamSize === 1 && <Typography>
                Payouts will be calculated for individual competitions after all scores are posted.
            </Typography>}
            {showPayoutsExplanation && eventOrRound.teamSize > 1 && <Typography>
                Payouts will be calculated for team competitions after all scores are posted.
            </Typography>}
            <Spacing />
        </>;
    }

    HideLiveScores = () => <ListTitle text={'Live scoring is currently disabled by event administrator'} wrap />;

    getWinnings = (competition: Competition, flight: number) => {
        const { eventOrRound, groups, golfers, teams, mainCompetition } = this.getStaff();
        const { golferScores, teamScores, reportedScores, reportedTeamScores } = this.getScores(eventOrRound.id);
        const winnings = new Map<string, number>();
        if (!flight || flight == 0) {
            if (isTeamFormat(competition.scoring)) {
                if (competition.scoring.format !== ScoringFormatTeams.best_ball) {
                    teamsOf(competition, golfers, teams).forEach(team =>
                        winnings.set(team.id, teamScores.has(team.id) ?
                            golferMainCompetitionAward(team.id, eventOrRound, competition, golferScores, teamScores, reportedScores || new Map<string, ReportedScore>(), reportedTeamScores || new Map<string, ReportedScore>(), golfers, teams, groups, mainCompetition) : 0));
                } else {
                    teamsOf(competition, golfers, teams).forEach(team =>
                        winnings.set(team.id, team.contactIds.filter(cid => golfers.has(cid)).reduce((arr, curr) => arr.length > 0 || !golferScores.has(curr) ? curr : '', '').length === 0 ?
                            golferMainCompetitionAward(team.id, eventOrRound, competition, golferScores, teamScores, reportedScores || new Map<string, ReportedScore>(), reportedTeamScores || new Map<string, ReportedScore>(), golfers, teams, groups, mainCompetition) : 0));
                }
            } else {
                golfersOfCompetition(competition, golfers, teams).forEach(contact =>
                    winnings.set(contact.id, golferScores.has(contact.id) || reportedScores?.has(contact.id) ?
                        golferMainCompetitionAward(contact.id, eventOrRound, competition, golferScores, teamScores, reportedScores || new Map<string, ReportedScore>(), reportedTeamScores || new Map<string, ReportedScore>(), golfers, teams, groups, mainCompetition) : 0));
            }
        } else {
            if (isTeamFormat(competition.scoring)) {
                if (competition.scoring.format !== ScoringFormatTeams.best_ball) {
                    teamsOfFlight(competition, golfers, teams, flight).forEach(team =>
                        winnings.set(team.id, teamScores.has(team.id) && teamScores.get(team.id) ?
                            golferMainCompetitionAward(team.id, eventOrRound, competition, golferScores, teamScores, reportedScores || new Map<string, ReportedScore>(), reportedTeamScores || new Map<string, ReportedScore>(), golfers, teams, groups, mainCompetition) : 0));
                } else {
                    teamsOfFlight(competition, golfers, teams, flight).forEach(team =>
                        winnings.set(team.id, team.contactIds.filter(cid => golfers.has(cid)).reduce((arr, curr) => arr.length > 0 || !golferScores.has(curr) ? curr : '', '').length === 0 ?
                            golferMainCompetitionAward(team.id, eventOrRound, competition, golferScores, teamScores, reportedScores || new Map<string, ReportedScore>(), reportedTeamScores || new Map<string, ReportedScore>(), golfers, teams, groups, mainCompetition) : 0));
                }
            } else {
                contactsOfFlight(competition, golfers, teams, flight).forEach(contact =>
                    winnings.set(contact.id, golferScores.has(contact.id) ?
                        golferMainCompetitionAward(contact.id, eventOrRound, competition, golferScores, teamScores, reportedScores || new Map<string, ReportedScore>(), reportedTeamScores || new Map<string, ReportedScore>(), golfers, teams, groups, mainCompetition) : 0));
            }
        }
        return winnings;
    }

    private eventOrRound() {
        const { event, selectedRound } = this.props;
        return selectedRound ?? event;
    }

    render() {
        const { event, selectedRound, rounds, competitionsMap, loadedRounds, loadedCompetitions, setSelectedRound, classes } = this.props;
        const { openedScore, roundScorecardLink, loadedTeams, loadedGroups, loadedGolfers } = this.state;
        const hideLiveScores = event.hideLiveScores === true || event.hideLiveScores === 'OFF';
        const mobileMode = document.documentElement.clientWidth < 500;
        const multiday = event.type === 'multiday';
        const events = rollEvents(event, rounds);
        const allLoaded = loadedTeams > 0 && loadedGroups > 0 && loadedGolfers > 0 && loadedRounds > 0 && loadedCompetitions > 0;
        this.compCounter = 0;
        let allCompetitions = new Array<Competition>();
        if (this.showTotalMode() && !hideLiveScores) {
            competitionsMap.forEach(roundCompetition => allCompetitions = allCompetitions.concat(roundCompetition));
        } else {
            allCompetitions = competitionsMap.get(this.eventOrRound().id) ?? [];
        }
        const competitionsMain = sortCompetitions(allCompetitions.filter(competition => isMainScoring(competition.scoring)), this.showTotalMode());
        const competitionsSideGames = sortCompetitions(allCompetitions.filter(competition => !isMainScoring(competition.scoring)), this.showTotalMode());
        const withMoneyList = allCompetitions.some(competition => isNetPayouts(competition) || isGrossPayouts(competition));
        return (
            <Box style={mobileMode ? { padding: '0px 10px' } : undefined}>
                {hideLiveScores && <this.HideLiveScores />}
                {!hideLiveScores && <>
                    {multiday && <ButtonBar margin>
                        {rounds.map(round => <AppButton
                            key={round.roundOrder}
                            className={classes.eventRoundButton}
                            color={round.roundOrder === selectedRound?.roundOrder ? "primary" : "info"}
                            sx={{ backgroundColor: round.roundOrder === selectedRound?.roundOrder ? undefined : AppColors.white }}
                            onClick={() => setSelectedRound(round)} >
                            Round {round.roundOrder}
                        </AppButton>)}
                        <AppButton
                            className={classes.eventRoundButton}
                            color={!selectedRound ? "primary" : "info"}
                            onClick={() => setSelectedRound()} >
                            Total
                        </AppButton>
                    </ButtonBar>}
                    {competitionsMain.map((competition, idx) => <this.Competition key={idx} competition={competition} allCompetitions={allCompetitions} />)}
                    {competitionsSideGames.map((competition, idx) => <this.Competition key={idx} competition={competition} allCompetitions={allCompetitions} />)}
                    {withMoneyList && <this.PayoutsStates />}
                    {!allCompetitions.length && allLoaded &&
                        <Typography className={classes.portalInfoNotSet}>
                            Golfers will be visible here, once the scoring format has been selected.
                        </Typography>}
                </>}
                {openedScore && <PortalEditScoreDialog
                    open
                    readonly
                    {...openedScore}
                    eventRoot={event}
                    rounds={rounds}
                    roundScorecardLink={roundScorecardLink}
                    close={() => this.setState({ openedScore: undefined })}
                />}
                {events.map(round => <FirebaseDataComponent<Contact>
                    key={round.id}
                    name="golfers"
                    queryPath={round.id}
                    query={Backend.eventGolfersQuery(round.id)}
                    onMap={golfers => this.onGolfers(round, golfers)} />)}
                {events.map(round => <FirebaseDataComponent<Team>
                    key={round.id}
                    name="teams"
                    queryPath={round.id}
                    query={Backend.eventTeamsQuery(round.id)}
                    onMap={teams => this.onTeams(round, teams)} />)}
                {events.map(round => <FirebaseDataComponent<GolferGroup>
                    key={round.id}
                    name="groups"
                    queryPath={round.id}
                    query={Backend.eventGroupsQuery(round.id)}
                    onData={groups => this.onGroups(round, groups)} />)}
                {events.map(round => <FirebaseDataComponent<Score>
                    key={round.id}
                    name="golferScores"
                    queryPath={round.id}
                    query={Backend.golferScoresDb(round.id)}
                    onMap={scores => this.onGolferScores(round, scores)} />)}
                {events.map(round => <FirebaseDataComponent<ReportedScore>
                    key={round.id}
                    name="reportedGolferScores"
                    queryPath={round.id}
                    query={Backend.reportedGolferScoresDb(round.id)}
                    onMap={scores => this.onReportedGolferScores(round, scores)} />)}
                {events.map(round => <FirebaseDataComponent<Score>
                    key={round.id}
                    name="teamScores"
                    queryPath={round.id}
                    query={Backend.golferTeamScoresDb(round.id)}
                    onMap={scores => this.onTeamScores(round, scores)} />)}
                {events.map(round => <FirebaseDataComponent<ReportedScore>
                    key={round.id}
                    name="reportedTeamScores"
                    queryPath={round.id}
                    query={Backend.reportedTeamScoresDb(round.id)}
                    onMap={scores => this.onReportedTeamScores(round, scores)} />)}
                {events.map(round => <FirebaseDataComponent<Distance>
                    key={round.id}
                    name="distances"
                    queryPath={round.id}
                    query={Backend.golferDistancesDb(round.id)}
                    onMap={distances => this.onDistances(round, distances)} />)}
                <FirebaseUserPubDataComponent uid={event.userId} onData={data => this.setState({ units: data.units })} />
            </Box>
        );
    }
}

export default withStyles(styles)(Standings);
