import * as React from 'react';
import { DialogActions, DialogContent, DialogContentText, Checkbox, FormControlLabel, ButtonOwnProps } from '@mui/material';
import { AnyAction, Reducer } from 'redux';
import { Action, withKey } from './ReduxCommon';
import { isOfType } from './ReduxCommon';
import { connect, DispatchProp } from 'react-redux';
import { XSMobileDialog } from '../common/dialog/MobileDialog';
import AppButton from '../common/components/AppButton';
import { ReactNode } from 'react';
import DialogAppBar from "../common/dialog/DialogAppBar";
import { AppColors } from 'src/main/Theme';

export type MaxWidthType = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;

export interface AlertProps {
    visible?: boolean;
    content?: ReactNode;
    dontShowAgain?: boolean;
    dontShowHandler?: (dontShowAgain: boolean) => void;
    buttons?: AlertButton[];
    ownContent?: boolean;
    fullWidth?: boolean;
    maxWidth?: MaxWidthType;
    appBarLabel?: string;
}

interface AlertDialogProps extends AlertProps {
    handleClose: () => void;
}

const DEFAULT_STATE: AlertProps = {
    visible: false
};

interface AlertState {
    dontShowAgain: boolean;
}

const ALERT_TYPE = 'alert';

export interface AlertAction extends Action, AlertProps {
}

export interface AlertButton {
    title: string;
    color?: ButtonOwnProps['color'];
    action?: () => void;
}

export function showAlertAction(alertProps: AlertProps): AlertAction {
    return { type: ALERT_TYPE, ...alertProps, visible: true };
}

export const hideAlertAction = (props?: AlertProps) => {
    const res = { type: ALERT_TYPE } as AlertAction;
    if (props) {
        Object.assign(res, props);
    }
    res.visible = false;
    return res;
}

export const alertReducer: Reducer<AlertProps> = (props: AlertProps | undefined, action: AnyAction) => {
    if (props && isOfType<AlertAction>(action, ALERT_TYPE)) {
        const copy = { ...props };
        copy.content = action.content ?? copy.content;
        copy.visible = action.visible;
        copy.buttons = action.buttons;
        copy.dontShowAgain = action.dontShowAgain;
        copy.dontShowHandler = action.dontShowHandler;
        copy.ownContent = action.ownContent;
        copy.maxWidth = action.maxWidth;
        copy.appBarLabel = action.appBarLabel;
        return copy;
    }
    return props || DEFAULT_STATE;
};

const AlertDialog = ({ visible, content, dontShowAgain, dontShowHandler, handleClose, buttons, ownContent, fullWidth, maxWidth, appBarLabel }: AlertDialogProps) => {
    const displayButtons = buttons || [{ title: 'Ok', action: handleClose }];
    const [dontShowAgainState, setDontShowAgainState] = React.useState(dontShowAgain);
    const _onchange = (e: React.ChangeEvent<HTMLInputElement>, v: boolean) => {
        e.stopPropagation();
        dontShowHandler!(v);
        setDontShowAgainState(v);
    };
    const _close = () => {
        setDontShowAgainState(undefined);
        handleClose();
    };
    return (
        <XSMobileDialog open={visible ?? false} onClose={_close} fullWidth={fullWidth ?? false} maxWidth={maxWidth ?? 'xs'}>
            {appBarLabel && <DialogAppBar label={appBarLabel} appBarStyle={{ backgroundColor: AppColors.primary }} noUpperCase close={_close} />}
            {ownContent ?
                content :
                <DialogContent>
                    {(content && typeof content === 'object' && (content as any)['type'] === 'div') ?
                        content :
                        <DialogContentText>{content}</DialogContentText>}
                </DialogContent>}
            <DialogActions>
                {dontShowHandler && <FormControlLabel
                    control={<Checkbox color="secondary" onChange={_onchange} checked={dontShowAgainState ?? dontShowAgain ?? false} />}
                    label="Don't show again" style={{ marginLeft: 8 }}
                />}
                {dontShowHandler && <span style={{ flex: '1 1 0%' }} />}
                {displayButtons.map((btn, idx) =>
                    <AppButton
                        key={idx}
                        color={btn.color ?? 'info'}
                        onClick={() => {
                            _close();
                            return btn.action && btn.action();
                        }}>{btn.title}
                    </AppButton>
                )}
            </DialogActions>
        </XSMobileDialog>
    );
};

class AlertHandlerInner extends React.Component<AlertProps & DispatchProp<any>, AlertState> {
    readonly state: AlertState = {
        dontShowAgain: false
    };

    private handleClose = () => {
        this.props.dispatch!(hideAlertAction(this.props));
    }

    render() {
        return <AlertDialog
            {...this.props}
            handleClose={this.handleClose}
        />;
    }
}

export const AlertHandler = connect(withKey<AlertProps>('alertReducer'))(AlertHandlerInner);
