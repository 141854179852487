import * as React from 'react';
import * as Utils from '../../util/utility';
import { AppColors } from '../../main/Theme';

const startedIcon = (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 7.99999L6 11.4641L6 4.53589L12 7.99999Z" fill="white" />
    </svg>
);

const allSetIcon = (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
        <path d="M12.7071 4.79289C13.0976 5.18342 13.0976 5.81658 12.7071 6.20711L7.70711 11.2071C7.31658 11.5976 6.68342 11.5976 6.29289 11.2071L3.29289 8.20711C2.90237 7.81658 2.90237 7.18342 3.29289 6.79289C3.68342 6.40237 4.31658 6.40237 4.70711 6.79289L7 9.08579L11.2929 4.79289C11.6834 4.40237 12.3166 4.40237 12.7071 4.79289Z" fill="white" />
    </svg>
);

export const ArcStepper = (props: { badgeUrl: string, stepsTotal: number, stepsCompleted: number, size: number | string, onClickSteps?: Utils.Func1<React.MouseEvent> }) => {
    const { badgeUrl, stepsTotal, stepsCompleted, size, onClickSteps } = props;
    const badgeSize = 80;
    const arcWidth = 4;
    const arcSpace = 2;
    const imgSize = badgeSize - 2 * arcWidth - 2 * arcSpace;
    const dx = (badgeSize - imgSize) / 2;
    const txtSize = 22;
    const shadowColor = AppColors.webGrey200;
    const completeColor1 = AppColors.webWarning;
    const completeColor2 = AppColors.bluish;
    const angleBetween = 4;
    const stepAngle = stepsTotal > 0 ? 360 / stepsTotal : 360;
    const incomplete = stepsCompleted < stepsTotal;
    const statusTxt = incomplete ? Utils.withS(stepsTotal - stepsCompleted, 'step') + ` left` : stepsCompleted > stepsTotal ? 'Started' : 'All set';
    return (
        <svg width={size} height={size} viewBox={`0 0 ${badgeSize} ${badgeSize + arcWidth}`} fill="none">
            <svg x={dx} y={dx}>
                <defs>
                    <clipPath id="circleView">
                        <circle cx={imgSize / 2} cy={imgSize / 2} r={imgSize / 2} fill="none" />
                    </clipPath>
                </defs>
                // round placeholder while image is loading
                <circle cx={imgSize / 2} cy={imgSize / 2} r={imgSize / 2} fill="white" />
                // clipped round badge image
                {Boolean(badgeUrl) &&
                    <image href={badgeUrl} width={imgSize} height={imgSize} clipPath="url(#circleView)" />}
            </svg>
            <svg x={0} y={0}>
                // arc segments
                {incomplete ? Utils.range(0, stepsTotal).map(step =>
                    <path key={step} fill="none" strokeWidth={arcWidth} stroke={step < stepsCompleted ? completeColor1 : shadowColor}
                        d={Utils.svgArc(badgeSize / 2, badgeSize / 2, badgeSize / 2 - arcSpace, 90 + step * stepAngle + angleBetween / 2, 90 + (step + 1) * stepAngle - angleBetween / 2)} />) :
                    <g>
                        <path fill="none" strokeWidth={arcWidth} stroke={stepsTotal == -1 ? shadowColor : completeColor2}
                            d={Utils.svgArc(badgeSize / 2, badgeSize / 2, badgeSize / 2 - arcSpace, 0, 180)} />
                        <path fill="none" strokeWidth={arcWidth} stroke={stepsTotal == -1 ? shadowColor : completeColor2}
                            d={Utils.svgArc(badgeSize / 2, badgeSize / 2, badgeSize / 2 - arcSpace, 180, 360)} />
                    </g>}
                // status label
                {stepsTotal > 0 &&
                    <svg x={0} y={badgeSize + arcWidth - txtSize} width={badgeSize - arcWidth / 2} height={txtSize}>
                        <rect x={arcWidth / 2} y={0} width={badgeSize - arcWidth} height={txtSize} rx={txtSize / 2} fill="white" stroke="none" />
                        <rect x={arcWidth} y={arcWidth / 2} width={badgeSize - 2 * arcWidth} height={txtSize - arcWidth} rx={(txtSize - arcWidth) / 2} fill={incomplete ? completeColor1 : completeColor2} stroke="none" />
                        {incomplete ? undefined : stepsCompleted > stepsTotal ?
                            <svg x={10} y={2}>{startedIcon}</svg> :
                            <svg x={14} y={2}>{allSetIcon}</svg>}
                        <text
                            x={incomplete ? '50%' : '60%'}
                            y="50%"
                            fill="white"
                            dominantBaseline="middle"
                            textAnchor="middle"
                            onClick={e => {
                                if (incomplete && onClickSteps) {
                                    onClickSteps(e);
                                }
                            }}
                            style={{ fontWeight: 600, fontSize: `11px`, cursor: incomplete ? 'pointer' : '' }}>
                            {statusTxt}
                        </text>
                    </svg>}
            </svg>
        </svg>
    );
};

export default ArcStepper;
