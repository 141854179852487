import { Workbox } from "workbox-window";
import { TEST_SERVER } from "src/util/config";
import { dbgLog, logInfo, logError } from "src/util/utility";

export const SW_URL = `${process.env.PUBLIC_URL}/service-worker.js`;

const SW_UPDATE_DELAY = TEST_SERVER ? 30 : 300;

export async function registerServiceWorker(checkForUpdate?: boolean, onUpdate?: () => void) {
    if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
        const wb = new Workbox(SW_URL);
        dbgLog(`[SW] serviceWorkerUpdate - register...`);
        const reg = await wb.register();
        dbgLog(`[SW] register - ${reg}`);
        reg?.addEventListener('updatefound', () => {
            const newSW = reg.installing;
            dbgLog(`[SW] updatefound - ${newSW?.scriptURL} -> ${newSW?.state}`);
            newSW?.addEventListener("statechange", () => {
                dbgLog(`[SW] statechange ${newSW.scriptURL} -> ${newSW.state}`);
                if (newSW.state == "installed") {
                    onUpdate && onUpdate();
                }
            });
        });
        if (checkForUpdate) {
            setInterval(() => {
                dbgLog('[SW] Periodic check for update...');
                wb.update();
            }, SW_UPDATE_DELAY * 1000);
        }
    } else if ('serviceWorker' in navigator) {
        logInfo('[SW] unregister...');
        navigator.serviceWorker.ready.then(registration => {
            logInfo('[SW] ready unregister');
            registration.unregister();
        }).catch(error => {
            logError(error.message);
        });
    } else {
        dbgLog('ServiceWorker not used');
        // window.location.replace(window.location.pathname);
    }
}

export function detectServiceWorkerUpdate(onUpdate: () => void) {
    registerServiceWorker(true, onUpdate);
}
