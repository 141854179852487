import * as React from 'react';
import { SyntheticEvent } from 'react';
import { DialogProps } from '@mui/material/Dialog';
import FormControl from '@mui/material/FormControl';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { DialogActions, DialogContent, Divider, InputLabel, MenuItem, Select, Typography, Box } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { Tee, Gender, ResultStatus, getCourseName, EventBase, isRound, Contact } from '../../../types/EventTypes';
import TypedFormRadioLabel from '../../../common/form/TypedFormRadioLabel';
import { XSMobileDialog } from '../../../common/dialog/MobileDialog';
import EmailDialog from '../common/EmailDialog';
import DialogAppBar from '../../../common/dialog/DialogAppBar';
import AppButton from '../../../common/components/AppButton';
import { Spacing } from '../../../common/Misc';
import { processEnterKey } from '../../../util/react_utils';
import { getTeeName, compareTee, getGender } from '../../../scoring/handicap';
import EditTeesListDialog from '../../tabs/common/EditTeesListDialog';
import TeesProvider from './TeesProvider';
import { styles } from '../../../styles';
import { Emails } from "../../../util/config";
import { AppColors } from "../../../main/Theme";

const useDefault = 'Use event default tees';
const differsFromDefault = 'Differs from default';
const menDefault = 'Men’s default';
const womenDefault = 'Women’s default';

type Props = DialogProps & WithStyles<typeof styles> & {
    tees?: Array<Tee>;
    eventOrRound: EventBase;
    selectedGolfers?: Array<Contact>;
    variant: 'dialog' | 'dropDownList';
    onSelectTees: (eventOrRound: EventBase, teeMen?: Tee, teeWomen?: Tee) => void;
};

type State = {
    tees: Array<Tee>;
    selectedMenTee?: Tee;
    selectedWomanTee?: Tee;
    editingTees?: boolean;
    actionSendRequestOpen?: boolean;
    teesLoadStatus: ResultStatus;
};

class SelectTeesComponent extends React.Component<Props, State> {

    private readonly teesLoader: React.RefObject<TeesProvider>;

    constructor(props: Props) {
        super(props);
        const { selectedGolfers, eventOrRound } = props;
        this.teesLoader = React.createRef();
        const selectedGolfer = selectedGolfers?.length === 1 ? selectedGolfers[0] : undefined;
        this.state = {
            teesLoadStatus: 'in_progress',
            selectedMenTee: selectedGolfer?.tee ?? eventOrRound.teeMen,
            selectedWomanTee: selectedGolfer?.tee ?? eventOrRound.teeWomen,
            tees: []
        };
        if (this.props.tees?.length) {
            this.teesLoaded("ok", this.props.tees);
        }
    }

    private teesLoaded = (teesLoadStatus: ResultStatus, tees?: Array<Tee>) => this.setState({ tees: tees || [], teesLoadStatus });
    private loadTees = () => this.teesLoader.current?.loadTees();
    private handleEditTees = () => this.setState({ editingTees: true });

    private handleClose = () => {
        const close = this.props.onClose;
        if (close) {
            close({} as SyntheticEvent<any>, 'escapeKeyDown');
        }
    }

    private handleCloseEditTees = (changed: boolean) => {
        this.setState({ editingTees: false });
        if (changed) {
            this.loadTees();
        }
    }

    private handleSave = () => {
        const { onSelectTees, eventOrRound } = this.props;
        const { selectedMenTee, selectedWomanTee } = this.state;
        onSelectTees(eventOrRound, selectedMenTee, selectedWomanTee);
    }

    private onDropDownListValueSelection = () => {
        if (this.props.variant === 'dropDownList') {
            this.handleSave();
        }
    };

    private onSelect = (gender: Gender, tee?: Tee) => {
        if (gender === 'female') {
            this.setState({ selectedWomanTee: tee }, this.onDropDownListValueSelection);
        }
        if (gender === 'male') {
            this.setState({ selectedMenTee: tee }, this.onDropDownListValueSelection);
        }
    };

    render() {
        const { classes, open, eventOrRound, selectedGolfers, variant } = this.props;
        const { tees, selectedWomanTee, selectedMenTee, actionSendRequestOpen, teesLoadStatus, editingTees } = this.state;
        const maleTees = tees.filter(tee => getGender(tee) === 'male').sort(compareTee);
        const femaleTees = tees.filter(tee => getGender(tee) === 'female').sort(compareTee);
        const loading = teesLoadStatus === 'in_progress';
        const maleTeesInfo = loading ? 'Loading tees...' :
            !eventOrRound.course ? 'Please select course in event settings' :
                maleTees.length === 0 ? 'Click view/edit to add the tee setting. If no men are in the competition, please change gender selection in settings.' : '';
        const femaleTeesInfo = loading ? 'Loading tees...' :
            !eventOrRound.course ? 'Please select course in event settings' :
                femaleTees.length === 0 ? 'Click view/edit to add the tee setting. If no women are in the competition, please change gender selection in settings.' : '';
        const contactsMode = selectedGolfers && selectedGolfers.length > 0;
        const men = selectedGolfers?.some(contact => contact.gender === 'male') ?? false;
        const women = selectedGolfers?.find(contact => contact.gender === 'female') ?? false;
        const title = men && women ? 'Default tees' : 'Select tee';
        const displayTees = (men ? maleTees : women ? femaleTees : []);
        const displayTee = displayTees.length > 0 ? men ? selectedMenTee : women ? selectedWomanTee : undefined : undefined;
        return <>
            {variant === 'dialog' ?
                <XSMobileDialog open={open} onClose={this.handleClose}>
                    <DialogAppBar label={title} close={this.handleClose} />
                    <DialogContent onKeyDown={uiEvent => processEnterKey(uiEvent, () => this.handleSave())}>
                        {men && <FormControl
                            variant="standard"
                            margin="dense"
                            fullWidth
                            style={{ flexDirection: 'column' }}>
                            <Typography>{men && women ? 'Men\'s tees:' : 'Tees:'}</Typography>
                            {contactsMode && !loading && <TypedFormRadioLabel
                                key='mensDefault'
                                value={useDefault}
                                currentValue={selectedMenTee?.id ?? useDefault}
                                handleChange={() => this.onSelect('male')}
                                label={useDefault}
                                className={classes.radio} />}
                            {maleTees.map(tee => <TypedFormRadioLabel
                                key={tee.id}
                                value={tee.id}
                                currentValue={selectedMenTee?.id}
                                handleChange={() => this.onSelect('male', tee)}
                                label={getTeeName(tee, eventOrRound.holesType)}
                                className={classes.radio} />)}
                            <Typography color={!loading && eventOrRound.course && maleTees.length === 0 ? 'error' : 'inherit'}>{maleTeesInfo}</Typography>
                            <Spacing />
                        </FormControl>}
                        {women && <FormControl
                            variant="standard"
                            margin="dense"
                            fullWidth
                            style={{ flexDirection: 'column' }}>
                            <Typography>{men && women ? 'Women\'s tees:' : 'Tees:'}</Typography>
                            {contactsMode && !loading && <TypedFormRadioLabel
                                key='womensDefault'
                                value={useDefault}
                                currentValue={selectedWomanTee?.id ?? useDefault}
                                handleChange={() => this.onSelect('female')}
                                label={useDefault}
                                className={classes.radio} />}
                            {femaleTees.map(tee => <TypedFormRadioLabel
                                key={tee.id}
                                value={tee.id}
                                currentValue={selectedWomanTee?.id}
                                handleChange={() => this.onSelect('female', tee)}
                                label={getTeeName(tee, eventOrRound.holesType)}
                                className={classes.radio} />)}
                            <Typography
                                color={!loading && eventOrRound.course && femaleTees.length === 0 ? 'error' : 'inherit'}>{femaleTeesInfo}</Typography>
                            <Spacing />
                        </FormControl>}
                        <AppButton color="info" style={{ width: 166 }} onClick={this.handleEditTees}
                            disabled={!eventOrRound.course}><EditIcon />Edit</AppButton>
                    </DialogContent>
                    <DialogActions>
                        <AppButton color="info" onClick={this.handleClose}>Cancel</AppButton>
                        <AppButton color="secondary" onClick={this.handleSave} disabled={loading}>Save</AppButton>
                    </DialogActions>
                </XSMobileDialog> :
                <Box sx={{ width: '90%' }}>
                    <FormControl variant="standard" margin="dense" fullWidth>
                        <InputLabel id="select-tees-label" shrink>
                            Tee {isRound(eventOrRound) ? ` (round ${eventOrRound.roundOrder})` : ''}
                        </InputLabel>
                        <Select
                            id="select-tees"
                            variant="standard"
                            labelId="select-tees-label"
                            style={{ textAlign: 'left' }}
                            value={displayTee?.id ?? ''}
                            inputProps={{ classes: { icon: classes.blueArrowDown } }}
                            renderValue={selectedTeeId => getTeeName(tees.find(tee => tee.id === selectedTeeId), eventOrRound.holesType)}
                            onChange={e => {
                                if (e.target.value) {
                                    const teeId = e.target.value;
                                    const tee = displayTees.find(tee => tee.id === teeId);
                                    this.onSelect(tee?.gender ?? (men ? 'male' : women ? 'female' : ''), tee);
                                }
                            }}>
                            {displayTees.map(tee =>
                                <MenuItem key={tee.id} value={tee.id}>
                                    <div>
                                        <Typography>
                                            {getTeeName(tee, eventOrRound.holesType)}
                                        </Typography>
                                        <Typography sx={{ color: AppColors.webGrey, fontSize: '11px' }}>
                                            {men && tee?.id === eventOrRound.teeMen?.id ? menDefault : women && tee?.id === eventOrRound.teeWomen?.id ? womenDefault : ' '}
                                            &nbsp;
                                        </Typography>
                                    </div>
                                </MenuItem>)}
                            <Divider variant="middle" />
                            <MenuItem
                                value=""
                                sx={{ color: AppColors.webBlue500, fontSize: '14px', lineHeight: '21px' }}
                                onClick={this.handleEditTees}>Edit</MenuItem>
                        </Select>
                        <Typography sx={{ color: AppColors.webGrey, fontSize: '11px', textAlign: 'left' }}>
                            {men && (selectedMenTee?.id === eventOrRound.teeMen?.id ? menDefault : differsFromDefault)}
                            {women && (selectedWomanTee?.id === eventOrRound.teeWomen?.id ? womenDefault : differsFromDefault)}
                        </Typography>
                    </FormControl>
                </Box>}
            {actionSendRequestOpen && <EmailDialog
                open
                to={Emails.supportEmail}
                subject={'[EVENTS] Missing tees for ' + getCourseName(eventOrRound.course)}
                text="The following tees are missing: "
                handleCancel={() => this.setState({ actionSendRequestOpen: false })}
                handleSent={() => this.setState({ actionSendRequestOpen: false })}
            />}
            {editingTees && eventOrRound.course && <EditTeesListDialog
                eventOrRound={eventOrRound}
                course={eventOrRound.course}
                close={this.handleCloseEditTees}
            />}
            {!this.props.tees?.length && <TeesProvider
                eventOrRound={eventOrRound}
                course={eventOrRound.course}
                onResult={this.teesLoaded}
                ref={this.teesLoader}
            />}
        </>;
    }
}

export default withStyles(styles)(SelectTeesComponent);
